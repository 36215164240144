import React, { useEffect, useState } from 'react'
import { DialogContent, DialogContentText, DialogTitle, Box, Typography, Snackbar, Stack, Button, Dialog, DialogActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import Exportable from "./../../../service/Exportable"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';
import Feedback from "../../../service/Feedback"
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { getCycleSelected } from '../../../store/slices/background';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';

/**
 * Modal  para exportar
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const ExportableModal = ({ show, data, onClose }) => {

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    ////////////////// SHARED STATE ////////////////

    const schoolCycle = useSelector(getCycleSelected)

    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    ////////////////// LOCAL STATE  //////////////////

    const [feedback] = useState(() => new Feedback());
    const [downloading, setDownloading] = useState('');

    let snaphot = null

    if (schoolCycle.cycle_id != -1) {
        snaphot = schoolCycle.snaphot
    }


    const handleClose = () => {
        onClose()
    }

    function onExportExcel() {
        exportNow("excel")
    }

    function onExportPDF() {
        exportNow("pdf")
    }

    /**
     * Funcion que comunica con el sistema de exportacion
     * 
     * @param {*} format 
     */
    function exportNow(format) {

        setDownloading(format)

        Exportable.export(data.report, format, data.query, snaphot).then(data => {
            console.log(data)
            const ext = (format == 'pdf') ? '.pdf' : '.xlsx'

            exportToFile(data.data, "Reporte" + ext)

            onClose()
        }).catch(err => {
            feedback.getMessageAsPromise(err).then(message => {
                showFeedback(message.message)
            })
        }).then(() => {
            setDownloading('')
        })
    }

    /**
     * Exportar el archivo
     * 
     * @param {*} blob 
     * @param {*} filename 
     */
    function exportToFile(blob, filename) {
        try {
            saveAs(blob, filename);
        } catch (err) {
            throw err
        }
    }

    ///////////////////// FEEDBACK ////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            disableEscapeKeyDown={downloading != ''}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                Exportar Archivo
                <Typography variant="body1" component="div">
                    {data.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                    Seleccionar formato a exportar

                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ margin: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color={"success"}
                            loading={downloading == 'xlsx'}
                            loadingPosition="start"
                            onClick={onExportExcel}
                            startIcon={<TableViewIcon />}
                            disabled={downloading != ''}
                        >
                            Excel
                        </LoadingButton>

                        <LoadingButton
                            variant="contained"
                            color={"error"}
                            loading={downloading == 'pdf'}
                            loadingPosition="start"
                            onClick={onExportPDF}
                            startIcon={<PictureAsPdfIcon />}
                            disabled={downloading != ''}
                        >
                            PDF
                        </LoadingButton>



                    </Stack>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    disabled={downloading != ''}
                >
                    Cerrar
                </Button>
            </DialogActions>

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    )

}

export default ExportableModal