import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Connection from "../../../service/Connection";
import Feedback from "../../../service/Feedback";
import Authentication from "../../../service/Login";
import * as constants from "../../../service/const";
import { addManySubjectcatalogs, addOneSubjectCatalog, selectSubjectCatalogByID, upsertOneSubjectcatalog } from "../entities/subjectcatalogs";
import { convertArrayToObject } from "../../../libs/utils";
import { addManyGroups, selectGroupsById, upsertManyGroup } from "../entities/groups";
import { addManyUsers, selectUserById, upsertManyUsers } from "../entities/users";
import { addManySubjects, selectAllSubjects } from "../entities/subjects";
import { upsertOneUser } from "../entities/users";
import _ from "lodash";


const emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {}
}
export const MateriaSlice = createSlice({
    name: 'subjectsUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending'
        }

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + constants.SUBJECTSUI_EXPIRE_TIME))
            state.server.ferchingAt = Date.now()
            state.server.didInvalidate = false

            state.server.statusServer = 'fulfilled'
        })
        builder.addCase(loadUI.pending, pendingServerStatus)
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected'
            state.server.feedback = action.payload.feedback
        })

        //////////////// ALMACENAR MATERIAS //////////////////////

        builder.addCase(storeCatalgSubject.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(storeCatalgSubject.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(storeCatalgSubject.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////// ACTUALZIAR EL CATALOGO ////////////////////

        builder.addCase(updateCatalogSubject.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateCatalogSubject.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateCatalogSubject.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })


    }
});

export const {
    invalidate
} = MateriaSlice.actions;

//////////////////// SELECTORES //////////////////
export const selectGroupsUI = (state) => state.subjectsUI.ui;

export const selectGroupsData = (state) => state.subjectsUI.data;

export const selectGroupsServer = (state) => state.subjectsUI.server;

export const selectStatusServer = (state) => state.subjectsUI.server.statusServer

export const selectStatusOperation = (state) => state.subjectsUI.server.statusOperation


/**
 * Selector para recuperar datos de un catalogo de materia
 * 
 * @param {*} catalogSubjectId Identificador de la materia 
 * @returns 
 */

export const selectSubjectCatalogDataByID = (catalogSubjectId) => {
    return (state) => {
        let catalogSubject = selectSubjectCatalogByID(catalogSubjectId)(state)

        if (!catalogSubject) {
            return null
        }

        const allSubejcts = selectAllSubjects(state)

        let subjectsOfCatalog = allSubejcts.filter(i => i.catalog_subject_id == catalogSubjectId)

        /**
         * Las materias pueden no tener profesor o grupo
         */
        let subjectsWithGroup = subjectsOfCatalog.filter(i => i.group_id != 0 && i.group_id != null)
        let subjectsWithProfessors = subjectsOfCatalog.filter(i => i.professor_id != 0 && i.professor_id != null)

        const groups = subjectsWithGroup.map(i => selectGroupsById(i.group_id)(state))
        const allProfessors = subjectsWithProfessors.map(i => selectUserById(i.professor_id)(state))

        let professors = _.uniqBy(allProfessors, "user_id")

        return {
            ...catalogSubject,
            groups,
            professors
        }
    }
}

export default MateriaSlice.reducer;

/////////////////////////// TRUNKS /////////////////////////////

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'subjectsUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let subjectcatalogs = await Connection.getSubjectCatalogBySchool(schoolId).then(i => i.data.data)
            let groups = await Connection.getResourcesPaginatedFromServer('groupsBySchool', [schoolId]).then(i => i.data)
            let professors = await Connection.professorsBySchool(schoolId).then(i => i.data.data)
            let subjects = await Connection.getResourcesPaginatedFromServer('getSubjectsBySchool', [schoolId]).then(i => i.data)

            thunkAPI.dispatch(addManySubjectcatalogs(convertArrayToObject(subjectcatalogs, 'catalog_subject_id')))
            thunkAPI.dispatch(upsertManyGroup(groups))
            thunkAPI.dispatch(upsertManyUsers(professors))
            thunkAPI.dispatch(addManySubjects(convertArrayToObject(subjects, 'subject_id')))

            return {
                subjectcatalogs,
                groups,
                professors,
                professors
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().subjectsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)

/**
 * Almacenar materias
 */
export const storeCatalgSubject = createAsyncThunk(
    'subjectsUI/store/catalog-subject',
    async ({ schoolId, catalog, profesor, groups, createSubject }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            /**
             * Creacion del catalogo de la materia
             */
            let subjectCatalog = await Connection.setSubjecyCatalogBySchool(schoolId, catalog).then(i => i.data.data)
            const catalogId = subjectCatalog.catalog_subject_id

            let subjects = []

            /**
             * Si el usuario selecciono crear materias
             */
            if (createSubject) {
                if (groups.length > 0) {
                    let groupsUpdated = []
                    for (const group of groups) {
                        try {
                            let object = {
                                catalog_subject_id: catalogId,
                                group_id: group
                            }
                            let subject = null
                            if (profesor) {
                                const userId = Auth.getUserID(profesor)
                                subject = await Connection.setSubjectByProfessor(userId, object).then(i => i.data.data)
                            } else {
                                subject = await Connection.setSubject(object).then(i => i.data.data)
                            }

                            subjects.push(subject)
                            groupsUpdated.push(group)
                        } catch (err) {

                        }
                    }

                    //////////////// ACTUALIZAR GRUPO ////////////////////

                    let group = await Connection.getGroupByIds(groupsUpdated, schoolId).then(i => i.data.data)
                    thunkAPI.dispatch(upsertManyGroup(group))
                }
            }

            /**
             * Si el usuario selecciono algun profesor
             * para la materia
             */
            if (profesor) {
                const userId = Auth.getUserID(profesor)

                let professor = await Connection.getProfessorsById(userId).then(i => i.data.data)
                professor["user_id"] = userId

                thunkAPI.dispatch(upsertOneUser(professor))
            }

            thunkAPI.dispatch(addOneSubjectCatalog({
                ...subjectCatalog,
                groups: subjects.length,
                professors: (profesor) ? 1 : 0
            }))

            thunkAPI.dispatch(addManySubjects(convertArrayToObject(subjects, 'subject_id')))

            return {
                subjectCatalog
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Actualziar catalogo de materias
 */
export const updateCatalogSubject = createAsyncThunk(
    'subjectsUI/update/catalog-subject',
    async ({ catalogSubjectId, catalog }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let subjectCatalog = await Connection.updateSubjectCatalog(catalogSubjectId, catalog).then(i => i.data.data)

            thunkAPI.dispatch((upsertOneSubjectcatalog(subjectCatalog)));

            return {
                subjectCatalog
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)