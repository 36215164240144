import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const settingsAdapter = createEntityAdapter({
    selectId: (setting) => setting.configuration_id,
    sortComparer: (a, b) => a.configuration_id - b.configuration_id
})

/**
 * Slice para las configuraciones de las escuelas
 */
export const settingsSlice = createSlice({
    name: 'settings',
    initialState: settingsAdapter.getInitialState(),
    reducers: {
        addManySettings: settingsAdapter.addMany,
        addOneSetting: settingsAdapter.addOne,
        upsertOneSetting: settingsAdapter.upsertOne,
        upsertManySettings: settingsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return settingsAdapter.getInitialState()
        })
    }
})

/**
 * Acciones generadas por la librerias
 */
export const {
    addManySettings, addOneSetting, upsertOneSetting,
    upsertManySettings
} = settingsSlice.actions

/**
 * Exportaciones por defecto del reducer generado por la libreria
 */
export default settingsSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = settingsAdapter.getSelectors((state) => state.entities.settings)

/**
 * Selector para recuperar todas las entidades 
 * 
 * @param any Estado completo de la app
 * 
 * @returns any
 */
export const selectEntitiesSettings = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar todas las configuraciones 
 * 
 * @param any Estado completo de la app
 *  
 * @returns any[]
 */
export const selectAllSettings = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para seleccionar una configuracion por ID
 * 
 * @param number Identificador de la configuracion
 * 
 * @returns any
 */
export const selectSettingById = (id) => (state) => globalizedSelectors.selectById(state, id)

/**
 * Selector para recuperar todas las configuraciones de una escuela
 * 
 * @param number Identificador de la escuela 
 * 
 * @returns any[]
 */
export const selectSettingBySchoolId = (schoolId) => (state) => selectAllSettings(state).filter(i => i.school_id == schoolId)

/**
 * Selector para recuperar una configuracion de una escuela especifica
 * 
 * @param number Identificador de una escuela 
 * @param string Llave de la configuracion 
 * 
 * @returns any
 */
export const selectOneSettingBySchoolId = (schoolId, key) => (state) => _.find(selectSettingBySchoolId(schoolId)(state), { key })

/**
 * Recupera la primera configuracion que coincida con la llave
 * 
 * @param string Llave de la configuracion a buscar
 * 
 * @returns any
 */
export const selectSettingByKey = (key) => (state) => _.find(selectAllSettings(state), { key })