import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { selectFilesByAssessor } from "../files";
import { selectAllGroupsStudents } from "../groups_students";
import { selectStudentById } from "../students";

const groupsAdapter = createEntityAdapter({
    selectId: (group) => group.group_id,
    sortComparer: (a, b) => a.group_id - b.group_id
})

/**
 * Slice para los parciales
 */
export const GroupsSlice = createSlice({
    name: 'groups',
    initialState: groupsAdapter.getInitialState(),
    reducers: {
        /**
         * Si el grupo no se encuentra en las entidades es agregado
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneGroup: groupsAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyGroups: groupsAdapter.addMany,
        /**
         * Agrega o remplaza el elemento en la entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setOneGroup: groupsAdapter.setOne,
        /**
         * Actualziar los datos de un grupo
         * 
         * @param {*} state 
         * @param {*} action 
         */
        updateGroup: groupsAdapter.updateOne,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        removeOneGroup: groupsAdapter.removeOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneGroup: groupsAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManyGroup: groupsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return groupsAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setOneGroup, addManyGroups, removeOneGroup,
    updateGroup,
    addOneGroup, upsertOneGroup, upsertManyGroup
} = GroupsSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = groupsAdapter.getSelectors((state) => state.entities.groups)

/**
 * Selector para recuperar todos los grupos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectAllGroupsEntities = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar grupos como array
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectAllGroups = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para recuperar grupos como array
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectGroupsAsArray = () => (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para recuperar un grupo por id
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectGroupsById = (id) => (state) => globalizedSelectors.selectById(state, id)

/**
 * Selector para recuperar grupos por nivel
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectGroupsByLevel = (id) => (state) => selectAllGroups(state).filter(i => i.level == id)

/**
 * Selector para recuperar alumnos de un grupo
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectGroupsWithStudents = (groupsiDS) => {
    return (state) => {
        let groups = []

        if (groupsiDS) {
            groups = groupsiDS.map(i => selectGroupsById(i)(state));
        } else {
            groups = selectGroupsAsArray()(state)
        }

        return groups.map(g => {
            let groupStudents = selectAllGroupsStudents(state).filter(i => i.group_id == g.group_id);

            let students = groupStudents.map(i => selectStudentById(i.student_id)(state))

            return {
                ...g,
                students
            }
        })
    }
}

/**
 * Selector para recuperar alumnos de un grupo
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectStudentsByGroup = (groupId) => {
    return (state) => {
        let groupStudents = _.filter(selectAllGroupsStudents(state), { "group_id": groupId });

        return groupStudents.map(i => selectStudentById(i.student_id)(state))
    }
}

/**
 * Selector para recuperar los expedientes de alumnos de un grupo
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectGroupsFiles = (groupId) => {
    return (state) => {
        let group = selectGroupsById(groupId)(state)

        let userId = group.assessor_id

        let files = selectFilesByAssessor(userId)(state)
        let students = selectStudentsByGroup(groupId)(state)

        return _.filter(files, (item) => {
            return _.find(students, { "student_id": item.student_id })
        })
    }
}

export default GroupsSlice.reducer;
