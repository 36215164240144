import React, { useEffect, useState } from 'react';
import {
    Button,
    Switch,
    TextField,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    IconButton,
    Tooltip,
    Snackbar,
} from "@mui/material";
import ModalUsuario from '../../components/Modal/ModalUsuarioAgregar';
import ModalUsuarioPassword from '../../components/Modal/ModalUsuarioPassword';
import { DataGrid, esES, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';

import { useDispatch, useSelector } from "react-redux";
import { selectProfessorsBySchool } from '../../store/slices/entities/users';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { disableUser } from '../../store/slices/usersUI';
import ModalUsuarioCelular from '../../components/Modal/ModalUsuarioCelular';
import AddIcon from '@mui/icons-material/Add';
import GridCellExpand from '../../components/CellContent';
import { useAuth } from '../../hooks';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useHistory } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';

/**
 * Pagina de maestros
 * 
 * @returns 
 */
const UsuariosMaestros = () => {

    const dispatch = useDispatch();
    const history = useHistory()
    const Auth = useAuth()
    const feedbackApp = useFeedback()

    ////////////////// SHARED STATE ////////////////

    const maestros = useSelector(selectProfessorsBySchool(Auth.getUser().school_id))

    ////////////////// LOCAL STATE  ////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalPassword, setOpenModalPassword] = useState(false);
    const [openModalCellphone, setOpenModalCellphone] = useState(false);

    const [usuarioSelected, setUsuarioSelected] = useState([]);

    const columns = [

        {
            field: 'id',
            headerName: 'Id',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'enrollment',
            headerName: 'Matrícula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'cellphone',
            headerName: 'Teléfono',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Correo',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'domicilio',
            headerName: 'Domicilio',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.colony || ''} ${params.row.street || ''} ${params.row.outside_number || ''} C.P. ${params.row.zipcode || ''}`;
            },
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
                );
            }
        },
        {
            field: 'status',
            headerName: 'Activo',
            type: 'singleSelect',
            flex: 0.3,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            valueOptions: ["Activo", "Inactivo"],
            valueGetter: (params) => {
                return params.value == 1 ? "Activo" : "Inactivo"
            },
            renderCell: (params) => {
                return (
                    <Tooltip title="Desactivar/Activar usuario">
                        <Switch
                            checked={(params.row.status === 1) ? true : false}
                            onChange={(event) => { setUsuarioSelected(params.row); cambiarStatus(params.row, event.target.value); }}
                            value={params.row.status}
                            disabled={Auth.getUserID(params.row) == Auth.getUserID()}
                        />
                    </Tooltip>
                )
            },
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.4,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => {

                if (Auth.getUserID(params.row) == Auth.getUserID()) {
                    return ([
                        <GridActionsCellItem
                            icon={<AccountBoxIcon color="primary" />}
                            title="Perfil"
                            label="Perfil"
                            onClick={(event) => {
                                history.push({ pathname: `/perfil` });
                            }}
                            showInMenu
                        />
                    ])
                }


                const actions = [
                    <GridActionsCellItem
                        icon={<EditIcon color="primary" />}
                        title="Editar"
                        label="Editar"
                        onClick={() => { setUsuarioSelected(params.row); setOpenModal(true); }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<VpnKeyIcon color="primary" />}
                        title="Cambiar contraseña"
                        label="Cambiar contraseña"
                        onClick={() => { setUsuarioSelected(params.row); setOpenModalPassword(true); }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<PhoneAndroidIcon color="primary" />}
                        title="Cambiar teléfono celular"
                        label="Cambiar teléfono celular"
                        onClick={() => { setUsuarioSelected(params.row); setOpenModalCellphone(true); }}
                        showInMenu
                    />
                ]

                return actions
            },
        },

    ];

    ////////////////////// ACTIONS //////////////////////

    /**
     * Cambiar el status de un usuario
     * 
     * @param {*} user 
     * @param {*} status 
     */
    const cambiarStatus = async (user, status) => {

        let statusFinal = (status === '1') ? 0 : 1;
        let data = {
            status: statusFinal
        }

        dispatch(disableUser({
            user: user,
            userId: user.user_id,
            data
        }))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Usuario actualizado"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }


    const dataGrid = maestros?.map(row => ({ ...row, id: row.user_id, }));

    ////////////////////// VIEW //////////////////////////

    return (
        <>
            <ModalUsuario
                title="Profesores"
                openModal={openModal}
                setOpenModal={setOpenModal}
                user={usuarioSelected}
                tipoUsuario="PROFESSOR"
            />

            <ModalUsuarioPassword
                openModalPassword={openModalPassword}
                setOpenModalPassword={setOpenModalPassword}
                user={usuarioSelected}
            />

            <ModalUsuarioCelular
                openModal={openModalCellphone}
                setOpenModal={setOpenModalCellphone}
                user={usuarioSelected}
            />

            <Card
                sx={{
                    mt: 5,

                }}
            >
                <CardContent sx={{
                    pt: 0,
                    pl: 0,
                    pr: 0,
                    pb: 0,
                    ' & .MuiDataGrid-root': {
                        border: 'none'
                    },
                    ' & .MuiDataGrid-toolbarContainer': {
                        justifyContent: "flex-end"
                    }
                }}>
                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={dataGrid}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: GridToolbar }}
                        disableDensitySelector
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        variant='contained'
                        onClick={() => { setUsuarioSelected(null); setOpenModal(true); }}
                    >
                        Agregar
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}

export default UsuariosMaestros;
