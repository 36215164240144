import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Services from "../../../service/Connection";
import { GROUPSUI_EXPIRE_TIME } from "../../../service/const";
import Feedback from "../../../service/Feedback";
import * as _ from 'lodash';
import Authentication from "../../../service/Login";
import { addOneSubject } from "../entities/subjects";
import { addOneGroup, upsertManyGroup, upsertOneGroup } from "../entities/groups";
import { upsertManyUsers } from "../entities/users";
import { addOneItem, setAllItems } from "./itemSlice";

const emptyState = {
    expireIn: null,
    ferchingAt: null,
    statusServer: "idle",
    statusOperation: "idle",
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    }
}

/**
 * Slice para el settings UI
 */
export const groupsUIServerSlice = createSlice({
    name: 'groupsUI/server',
    initialState: emptyState,
    reducers: {

        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        const pendingServerStatus = (state, action) => {
            state.statusServer = 'pending'
        }

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME))
            state.ferchingAt = Date.now()
            state.didInvalidate = false

            state.statusServer = 'fulfilled'
        })
        builder.addCase(loadUI.pending, pendingServerStatus)
        builder.addCase(loadUI.rejected, (state, action) => {
            state.statusServer = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// ACTUALIZAR GRUPO /////////////////////////

        builder.addCase(updateGroup.rejected, (state, action) => {
            state.statusOperation = 'rejected'
            state.feedback = action.payload.feedback
        })
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled'
        })
        builder.addCase(updateGroup.pending, (state, action) => {
            state.statusOperation = 'pending'
        })

        /////////////////////// CREAR Grupo/////////////////////////

        builder.addCase(createGroup.rejected, (state, action) => {
            state.statusOperation = 'rejected'
            state.feedback = action.payload.feedback
        })
        builder.addCase(createGroup.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled'
        })
        builder.addCase(createGroup.pending, (state, action) => {
            state.statusOperation = 'pending'
        })

    }
});

export const { invalidate } = groupsUIServerSlice.actions;

export default groupsUIServerSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */

export const selectGroupsData = (state) => state.groupsUI.items;

export const selectGroupsServer = (state) => state.groupsUI.server;

export const selectStatusServer = (state) => state.groupsUI.server.statusServer

export const selectStatusOperation = (state) => state.groupsUI.server.statusOperation

//////////////// TRUNCKS /////////////////


function getEmptyItem(groupId) {
    return {
        data: {
        },
        servers: {
            groups: {
                expireIn: null,
                ferchingAt: null,
                statusServer: "idle",
                statusOperation: "idle",
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                }
            },
            subjects: {
                expireIn: null,
                ferchingAt: null,
                statusServer: "idle",
                statusOperation: "idle",
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                }
            },
            assessor: {
                expireIn: null,
                ferchingAt: null,
                statusServer: "idle",
                statusOperation: "idle",
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                }
            },
            students: {
                expireIn: null,
                ferchingAt: null,
                statusServer: "idle",
                statusOperation: "idle",
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                }
            },
        },
        ui: {
            group_id: groupId
        }
    }
}
/**
 * Cargar informacion de la UI de Grupos
 */
 export const loadUI = createAsyncThunk(
    'groupsUI/server/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()
        const state = thunkAPI.getState()

        try {
            
            let lastPage = 0
            let currentPage = 0
            let groups = []
            let assessors = await Services.getAssesorsBySchool(schoolId).then(res => res.data.data)

            do {
                currentPage++

                let groupsResponse = await Services.groupsBySchool(schoolId, {
                    per_page: 50,
                    page: currentPage,
                }).then(res => res.data)
                let groupsPaginated = groupsResponse.data
                let meta = groupsResponse.meta

                lastPage = meta.lastPage

                groups = groups.concat(groupsPaginated)
            } while (currentPage < lastPage)

            thunkAPI.dispatch(upsertManyGroup(groups))
            thunkAPI.dispatch(upsertManyUsers(assessors))

            const items = groups.reduce((preveState, curr) => {
                preveState[curr.group_id] = getEmptyItem(curr.group_id)
                return preveState
            }, {})

            thunkAPI.dispatch(setAllItems(items))

            return {
                groups
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().groupsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)

/**
 * Actualizar datos de un grupo
 */
 export const updateGroup = createAsyncThunk(
    'groupsUI/server/group/update',
    async ({ groupId, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let group = await Services.updateGroupById(groupId, data).then(i => i.data.data)

            thunkAPI.dispatch(upsertOneGroup(group));

            return group
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)
/**
 * Crea un grupo
 */
export const createGroup = createAsyncThunk(
    'groupsUI/server/group/create',
    async ({ schoolId, data, subjects }, thunkAPI) => {
        let FeedbackService = new Feedback()
        let message = null

        try {
            let subjectsCreated = []

            let group = await Services.setGroupBySchool(schoolId, data).then(i => i.data.data)

            for (const subject of subjects) {
                try {
                    let object = {
                        catalog_subject_id: subject,
                        group_id: group.group_id
                    }

                    let subjectData = await Services.setSubject(object).then(i => i.data.data)

                    subjectsCreated.push(subjectData)

                    thunkAPI.dispatch(addOneSubject(subjectData));
                } catch (err) {
                    message = "Grupo creado, puede que algunas materias no se hayan registrado"
                }
            }

            thunkAPI.dispatch(addOneGroup({
                ...group, ...{
                    subjects: subjectsCreated.length
                }
            }));

            thunkAPI.dispatch(addOneItem(getEmptyItem(group.group_id)));

            return {
                group,
                subjectsCreated,
                message
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

