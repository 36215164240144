import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from "react-redux";
import { getGroupsByLevel } from '../../../../../store/slices/background'
import ScoreGroupSubjectsChart from './ScoreGroupSubjectsChart';
import AssistsGroupSubjectsChart from './AssistsGroupSubjectsChart';

/**
 * Componente que muestra graficos por nivel
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const LevelLineItem = ({ level, group }) => {

    //////////////// SHARES STATE ////////////////////

    const groups = useSelector(getGroupsByLevel(level.key))

    /**
     * Obtenemos los char para las calificaciones
     * 
     * @returns 
     */
    const getScoreGroupsItems = () => {
        let itemUI = []

        for (const group of groups) {
            itemUI.push(
                <Grid item>
                    <ScoreGroupSubjectsChart level={level.key} groupId={group.group_id} />
                </Grid>
            )
        }

        return itemUI
    }

    /**
     * Obtenemos los char para las asistencias
     * 
     * @returns 
     */
    const getAssistsGroupsItems = () => {
        let itemUI = []

        for (const group of groups) {
            itemUI.push(
                <Grid item>
                    <AssistsGroupSubjectsChart level={level.key} groupId={group.group_id} />
                </Grid>
            )
        }

        return itemUI
    }

    /**
     * Obtenemos los char para las asistencias
     * 
     * @returns 
     */
    const getAbcenseGroupsItems = () => {
        let itemUI = []

        for (const group of groups) {
            itemUI.push(
                <Grid item>
                    <AssistsGroupSubjectsChart level={level.key} groupId={group.group_id} />
                </Grid>
            )
        }

        return itemUI
    }

    return (
        <Box>
            <Box>
                <Typography variant="h4" gutterBottom component="div">
                    {level.title}
                </Typography>
            </Box>

            <Box>
                <Typography variant="h6" gutterBottom component="div">
                    Calificaciones
                </Typography>
            </Box>

            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                {getScoreGroupsItems()}
            </Grid>

            <Box>
                <Typography variant="h6" gutterBottom component="div">
                    Asistencias
                </Typography>
            </Box>

            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                {getAssistsGroupsItems()}
            </Grid>

            <Box>
                <Typography variant="h6" gutterBottom component="div">
                    Faltas
                </Typography>
            </Box>

            <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                {getAbcenseGroupsItems()}
            </Grid>
        </Box>
    )
}


export default LevelLineItem