import { IconButton, ListItem, ListItemText, Tooltip } from "@mui/material"
import _ from "lodash"
import { useState } from "react"
import { faArrowUp, faEraser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * Componente que muestra una materia objectivo
 * 
 * @param {*} param0 
 * @returns 
 */
const TargetSubjectListItem = ({ subject, currentSubjects, onRemoveSubject }) => {

    /**
     * Materia de donde se obtendran materias y calificaciones
     */
    let oldSubjectSelected = _.find(currentSubjects, { "subject_id": subject.source_subject_id })

    //////////// ACTIONS /////////////

    /**
     * Manejador para eliminar una materia seleccionada
     */
    const onHandlerRemoveSubject = () => {
        onRemoveSubject(subject)
    }

    return (
        <ListItem
            secondaryAction={
                <Tooltip title="Eliminar materia de origen">
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={!subject.source_subject_id}
                        onClick={onHandlerRemoveSubject}
                    >
                        <FontAwesomeIcon icon={faEraser} />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemText
                primary={`${subject.catalog.folio} ${subject.catalog.title}`}
                secondary={
                    <>
                        {
                            (subject.source_subject_id) && (
                                <>
                                    <FontAwesomeIcon icon={faArrowUp} /> {oldSubjectSelected?.catalog.title}
                                </>
                            )
                        }

                        {
                            (!subject.source_subject_id) && (
                                "Materia no seleccionada"
                            )
                        }
                    </>
                }
            />
        </ListItem>
    )
}

export default TargetSubjectListItem