import React, { useState } from 'react'
import { Typography, Grid, Button } from "@mui/material"
import { Box } from "@mui/system"
import ExportableModal from "../../../components/Chart/ExportableModal"
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFetchStatus, getGroupsReportBy } from '../../../store/slices/background'
import { ColorBox } from '../../../components/Chart/Cards';
import SubjectsTable from './components/SubjectsTable';
import StudentsTable from './components/StudentsTable';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import Empty from '../components/Empty';
import { selectSettingByKey } from '../../../store/slices/entities/settings';

/**
 * Pagina que muestra el detalle de los grupos
 */
const GroupDetais = () => {

    let { id } = useParams();
    const history = useHistory()

    if (isNaN(id)) {
        goGroups()
    }

    //////////// SHARES STATE ////////////////

    const fetchStatus = useSelector(getFetchStatus)
    const titleColorConfig = useSelector(selectSettingByKey('theme-color-title-bar'))
    const group = useSelector(getGroupsReportBy(id))

    let titleColor = titleColorConfig ? titleColorConfig?.value : "#7434a3"

    /////////////////// LOCAL STATE ////////////////////

    const [openExportModal, setOpenExportModal] = useState(false);

    const [reportData, setReportData] = useState({
        report: null,
        title: null,
        query: { group_id: null }
    });


    ///////////////////////////// ACTIONS ////////////////////

    /**
     * Cierra el modal para exportar
     */
    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    /**
     * Muestra el modal para exportar
     * 
     * @param {*} data 
     */
    const onExport = (data) => {
        setReportData(data)
        setOpenExportModal(true);
    }

    /**
     * Redireccion a grupos
     */
    const goGroups = () => {
        history.push({ pathname: `/director/grupos` });
    }

    /**
     * Componente que muestra los detalles del grupo
     * 
     * @param {*} param0 
     * 
     * @returns 
     */
    const GroupDetails = ({ group }) => {

        const levelName = getLevelName(group.level)
        const turnName = getTurnLevel(group.turn)
        const groupName = `${group.grade} ${group.group} ${turnName} ${levelName}`

        return (
            <Box>
                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                    <Grid item xs={12} md={6}>
                        <ColorBox value={groupName} label="Grupo" mainColor={titleColor} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ColorBox value={group.assessor_id} label="Asesor académico" mainColor={titleColor} sx={{ width: "100%" }} />
                    </Grid>
                </Grid>

                <Grid
                    container spacing={2} sx={{ mb: 2, mt: 2 }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.students} label="Alumnos" mainColor={"#c41c1c"} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.average_rating} label="Promedio" mainColor={"#d70206"} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.total_absence} label="Faltas" mainColor={"#5d4037"} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.total_presence} label="Asistencias" mainColor={"#b59dfa"} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.total_reports} label="Reportes" mainColor={"#ffac20"} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <ColorBox value={group.special} label="Especial" mainColor={"#2596be"} sx={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <Typography variant={"h4"} component={"div"}>Detalle del grupo</Typography>
            </Box>
            {
                group && (
                    <>
                        <GroupDetails group={group} />
                        <Box>
                            <SubjectsTable groupId={id} onExport={onExport}></SubjectsTable>
                            <StudentsTable groupId={id} onExport={onExport}></StudentsTable>
                        </Box>
                    </>
                )
            }

            {
                !group && fetchStatus == 'pending' && (
                    <Empty label="Buscando">  </Empty>
                )
            }

            {
                !group && fetchStatus != 'pending' && (
                    <Empty label="Grupo no controntrado"> <Button variant="contained" onClick={goGroups}>Ir a grupos</Button> </Empty>
                )
            }


            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}



export default GroupDetais