import styled from "@emotion/styled";
import { Button, Card, CardActions, CardContent, CardHeader, Snackbar, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid"
import _, { round } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSettingByKey } from "../../../store/slices/entities/settings";
import { selectStudentCalificationsPage } from "../../../store/slices/scoresUI/ItemsSlice";
import EditIcon from '@mui/icons-material/Edit';
import RequestPermission from "../../../components/RequestPermission";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import Feedback from "../../../service/Feedback";
import Services from "../../../service/Connection";
import { upsertManyCalifications } from "../../../store/slices/entities/califications";
import { upsertManySubjects } from "../../../store/slices/entities/subjects";
import { upsertOneStudent } from "../../../store/slices/entities/students";
import ScoresToolBar from "./ScoresToolBar"

/**
 * Componente para mostrar las calificaciones de un alumno
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const ScoresCard = ({ groupId, studentId, schoolId }) => {

    const dispatch = useDispatch()
    let FeedbackService = new Feedback()

    /////////////// SHARED STATE //////////////

    const scoreMin = useSelector(selectSettingByKey('calification-minimum'));

    const [statusOperation, setStatusOperation] = useState('idle')

    const pageData = useSelector(selectStudentCalificationsPage(groupId, studentId, schoolId))

    /////////////// LOCAL STATE ///////////////

    const [allowEdit, setAllowEdit] = useState(false)
    const [openRequestPermission, setOpenRequestPermission] = useState(false)

    const [calificationsRows, setCalificationsRows] = useState([]);
    const [calificationsColumns, setCalificationsColumns] = useState([]);

    const [countDown, setCountDown] = useState(0)

    ////////////// ACTIONS /////////////////////

    useEffect(() => {
        let x = null

        if (allowEdit) {
            var countDownDate = (new Date()).setMinutes(((new Date()).getMinutes() + 30))

            x = setInterval(function () {
                var now = new Date().getTime();

                var distance = countDownDate - now;

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setCountDown(minutes + "." + seconds)

                if (distance < 0) {
                    setAllowEdit(false)
                }
            }, 1000);

            return () => {
                clearInterval(x);
            }

        } else {
            clearInterval(x);
        }
    }, [allowEdit])

    /**
     * Inicia el proceso para dar formato a los datos
     */
    useEffect(() => {
        if (statusOperation == 'idle' || statusOperation == 'fulfilled') {
            const data = buildDataGrid(pageData)

            let rows = data.map((i, index) => ({ id: i.payload.subject_id, ...i }))

            setCalificationsRows(rows)
        }
    }, [allowEdit, groupId, studentId, schoolId, statusOperation])


    /**
     * Funcion para dar formatod a los datos del DataGrid 
     * 
     * @param {*} param0 
     * 
     * @returns 
     */
    const buildDataGrid = ({
        group,
        partials,
        subjects,
        subjectCatalog,
        califications
    }) => {
        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');;

        let level = group.level

        let subjectItems = []

        // Formateamos datos para los totales de las calificaciones y asistencias

        for (let subjet of subjects.map(i => Object.assign({}, i))) {

            let subjectIItem = {}
            let partialsUI = {}

            let catalog = _.find(subjectCatalog, { catalog_subject_id: subjet.catalog_subject_id })

            subjet.detalles = catalog


            subjectIItem.name = {
                name: true,
                value: catalog.title
            }

            let total = 0
            let calificationsNum = 0

            for (let partial of partials) {

                let caliBySubject = _.find(califications, { subject_id: subjet.subject_id, partial_id: partial.partial_id })


                let cal = 0

                if (caliBySubject?.calification) {

                    total += caliBySubject.calification
                    cal = caliBySubject.calification
                    ++calificationsNum

                }

                partialsUI[`p-${partial.partial}`] = {
                    partial: true,
                    value: cal,
                    calificationItem: caliBySubject,
                    partialItem: partial,
                    isExists: (caliBySubject != undefined),
                    partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
                }
            }


            let average = (total > 0) ? total / calificationsNum : 0

            let totalOverage = round(average, 1)

            partialsUI['total'] = {
                total: true,
                value: totalOverage
            }

            subjectItems.push({ ...subjectIItem, ...partialsUI, payload: subjet })
        }

        return subjectItems
    }

    /**
     * Efecto para generar nuevas columnas si es que se
     * modificaron los datos de las calificaciones
     */
    useEffect(() => {
        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');;

        let allColumnsItems = [
            {
                column: 'name',
                title: "MATERIAS",
                sticky: true,
                className: ""
            },
            ...(pageData.partials.map(partial => ({
                column: `p-${partial.partial}`,
                title: `P${partial.partial}`,
                sticky: false,
                editable: true,
                className: "parcial-column",
                partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
            }))),
            {
                column: `total`,
                title: `TOTAL`,
                sticky: false,
                className: "parcial-column"
            }
        ]

        let columns = allColumnsItems.map(i => {
            return {
                field: i.column,
                headerName: i.title,
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row = params.row[params.field]?.partialActive

                    if (row) {
                        return 'partial-active--cell'
                    }

                    return ''
                },
                valueGetter: (params) => {
                    return params.value.value
                },
                valueFormatter: ({ value }) => {
                    return `${value || ''}`;
                },
                renderCell: (params) => {

                    if (params.row[params.field].partial) {

                        if (allowEdit) {
                            return (
                                <CssTextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    defaultValue={params.value}
                                    onBlur={
                                        onInputChange({
                                            subjectId: params.row.payload.subject_id,
                                            cellItem: params.row[params.field],
                                            cell: params.field
                                        })
                                    }
                                />
                            )
                        } else {
                            return (
                                <Typography sx={{ color: params.value < scoreMin?.value ? 'red' : 'green' }}>
                                    {`${params.value || ''}`}
                                </Typography>
                            )
                        }

                    }

                    return `${params.value || ''}`;
                }
            }
        })

        setCalificationsColumns(columns)

    }, [calificationsRows])


    /**
     * 
     */
    const onEditScores = () => {
        setOpenRequestPermission(true)
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = ({ subjectId, cell }) => (event) => {
        let value = event.target.value;

        if (!isNaN(value)) {
            if (value < 0) {
                value = 0;
            }

            if (value > 10) {
                value = 10;
            }
        } else {
            value = 0;
        }

        let nextRows = calificationsRows.map(row => {
            if (subjectId == row.payload.subject_id) {

                return { ...row, [cell]: { ...row[cell], value } }
            }

            return row
        })

        setCalificationsRows(nextRows)
    }

    /**
     * Habilita la edicion de las calificaciones
     * 
     * @param {*} resolved 
     */
    const onAllowPermision = (resolved) => {
        setOpenRequestPermission(false)
        if (resolved == 'allow') {
            setAllowEdit(true)
        }
    }

    /**
     * El usuario cancelo la edicion
     */
    const onCancelEdit = () => {
        setAllowEdit(false)

        let nextRows = calificationsRows.map(row => {
            let newRow = {}

            for (const column in row) {
                if (row[column]?.partial) {
                    newRow[column] = { ...row[column], value: (row[column]?.calificationItem) ? row[column].calificationItem.calification : 0 }
                } else {
                    newRow[column] = row[column]
                }
            }

            return newRow
        })

    }

    /**
     * Se actualizan o se crean ls calificaciones
     */
    const onSaveScores = () => {
        let createRequest = []
        let updateRequest = []

        let calificationNotProceced = []

        /**
         * De cada materia recuperamos sus calificaciones
         */
        for (let subject of calificationsRows) {
            for (const property in subject) {
                if (/p-[0-9]/.test(property)) {
                    calificationNotProceced.push({ ...subject[property], subject: subject.payload })
                }
            }
        }

        /**
         * Filtramos las materias que posiblemente vallan a ser creadas
         * o editadas
         */
        let califications = calificationNotProceced.filter(item => {
            return item.value != 0 && item.value != '' && item.value != null
        })

        updateRequest.push(...(califications.filter((item) => item.isExists)).map(item => {
            return {
                calification: item.value,
                calification_id: item.calificationItem.calification_id
            }
        }))

        createRequest.push(...(califications.filter((item) => !item.isExists).map(item => {
            return {
                is_final: 0,
                calification: item.value,
                partial_id: item.partialItem.partial_id,
                student_id: studentId,
                subject_id: item.subject.subject_id
            }
        })))

        const allCalifications = _.concat(updateRequest, createRequest)

        setStatusOperation('pending')

        storeScores(allCalifications)
            .then(({ scores, subjects, student }) => {
                dispatch(upsertManyCalifications(scores))
                dispatch(upsertManySubjects(subjects))
                dispatch(upsertOneStudent(student))

                showFeedback("Guardado correctamente")
                setStatusOperation('fulfilled')
                setAllowEdit(false)
            }).catch(err => {
                let feedbackError = FeedbackService.getMessage(err)
                setStatusOperation('rejected')
                showFeedback(feedbackError.title)
            })
    }

    /**
     * Actualziaro o crear calificaciones
     * 
     * @param {*} scores 
     */
    const storeScores = async (scores) => {
        const newScores = await Services.saveMutiCalifications(scores).then(i => i.data.data)

        const subjects = await Services.getSubjectsByStudent(studentId).then(i => i.data.data)
        const student = await Services.getStudentById(studentId).then(i => i.data.data)


        return {
            scores: newScores,
            subjects,
            student
        }
    }

    /**
     * Componente para editar las calificaciones
     */
    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.05)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.05)',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.05)',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.05)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
    });

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <div>

            <RequestPermission
                title="Ingresar su contraseña"
                content="Para editar las calificaciones ingrese su contraseña"
                open={openRequestPermission}
                OnPermissionGrantedResolved={onAllowPermision}
            />

            <Card sx={{ mt: 4 }}>
                <CardContent
                    sx={{
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none'
                        }
                    }}
                >
                    <Box
                        sx={{
                            '& .partial-active--cell': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                color: '#1a3e72',
                                fontWeight: '600',
                            },
                        }}>

                        <DataGrid
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            rows={calificationsRows}
                            columns={calificationsColumns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            autoHeight
                            components={{ Toolbar: ScoresToolBar }}
                            disableDensitySelector
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "end"
                    }}
                >
                    {
                        !allowEdit && (
                            <Button
                                size="small"
                                variant="contained"
                                startIcon={<EditIcon />}
                                onClick={onEditScores}
                            >
                                Editar calificaciones
                            </Button>
                        )
                    }
                    {
                        allowEdit && (
                            <>
                                <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<DoDisturbIcon />}
                                    onClick={onCancelEdit}
                                    disabled={statusOperation == 'pending'}
                                >
                                    Terminar edición ({countDown})
                                </Button>

                                <LoadingButton
                                    size="small"
                                    color="primary"
                                    onClick={onSaveScores}
                                    loading={statusOperation == 'pending'}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                >
                                    Guardar
                                </LoadingButton>
                            </>
                        )
                    }
                </CardActions>
            </Card>

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </div>
    )
}


export default ScoresCard