import React from 'react'
import { Button, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import ExportableModal from "../../../components/Chart/ExportableModal"
import { getFetchStatus, getSubjectReportBy } from '../../../store/slices/background';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ColorBox } from '../../../components/Chart/Cards';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import StudentsTable from "./Components/StudentsTable"
import Empty from '../components/Empty';
import { selectSettingByKey } from '../../../store/slices/entities/settings';

/**
 * Pagina para los detalles de la materia
 * 
 * @returns 
 */
const SubjectsDetails = () => {
    const history = useHistory()
    let { id } = useParams();

    if (isNaN(id)) {
        goSubjects()
    }

    const match = useRouteMatch()

    //////////////////// SHARED STATE //////////////////////

    const subject = useSelector(getSubjectReportBy(id))
    const fetchStatus = useSelector(getFetchStatus)

    const titleColorConfig = useSelector(selectSettingByKey('theme-color-title-bar'))
    let titleColor = titleColorConfig ? titleColorConfig?.value : "#7434a3"

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { subject_id: null }
    });

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = (data) => {
        setReportData(data)
        setOpenExportModal(true);
    }

    ///////////////////////////// ACTIONS ////////////////////

    const goSubjects = () => {
        history.push({ pathname: `/director/materias` });
    }

    const goGroup = () => {
        history.push({ pathname: `/director/grupos/${subject.group_id}` });
    }

    /**
     * Componente que muestra los detalles de la materia
     * 
     * @param {*} param0 
     * 
     * @returns 
     */
    const SubjectDetails = ({ subject }) => {

        const professor = subject.payload.professor
        const group = subject.payload.group
        const subjectName = subject.payload.catalogSubject.title

        const turnName = group ? getTurnLevel(group.turn) : ""
        const levelName = group ? getLevelName(group.level) : ""

        const groupLabel = `${subjectName} ${group?.grade || ''} ${group?.group || ''} ${turnName} ${levelName}`

        const profesorName = professor ? `${professor.name} ${professor.last_name} ${professor.second_last_name}` : "Sin profesor"

        return (
            <Box>
                <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ColorBox
                            value={groupLabel}
                            label="Grupo"
                            mainColor={titleColor}
                            sx={{ width: "100%" }}
                            onClick={goGroup}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ColorBox
                            value={profesorName}
                            label="Profesor"
                            mainColor={titleColor}
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <Box>
                            <ColorBox value={subject.average_rating} label="Promedio" mainColor={"#d70206"} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <Box>
                            <ColorBox value={subject.total_absence} label="Faltas" mainColor={"#5d4037"} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <Box>
                            <ColorBox value={subject.total_presence} label="Asistencias" mainColor={"#b59dfa"} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <Box>
                            <ColorBox value={(subject.special) ? "Si" : "No"} label="Especial" mainColor={"#2596be"} sx={{ width: "100%" }} />
                        </Box>
                    </Grid>
                    {/*
                    <Grid item xs={4}>
                        <ColorBox value={subject.payload.group.assessor_id} label="Asesor académico" mainColor={"#7434a3"} sx={{width:340}}/>
                    </Grid>
                    */}

                </Grid>
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <Typography variant={"h4"} component={"div"}>Detalle de la materia</Typography>
            </Box>

            {
                subject && (<SubjectDetails subject={subject} />)
            }

            {
                subject && (
                    <StudentsTable
                        groupId={subject.group_id}
                        level={subject.payload.catalogSubject.level}
                        subjectId={subject.subject_id}
                        onExport={onExport} />
                )
            }

            {
                (subject == null && fetchStatus == 'pending') && (<Empty label="Buscando">  </Empty>)
            }

            {
                (subject == null && fetchStatus != 'pending') &&
                (
                    <Empty label="Materia no controntrado">
                        <Button variant="contained" onClick={goSubjects}>Ir a materias</Button>
                    </Empty>
                )
            }

            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}



export default SubjectsDetails