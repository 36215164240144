import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

const ParentListItem = ({parent, student, onChange}) => {

    const labelId = `checkbox-list-secondary-label-${parent.parent_id}`;

    const [checked, setChecked] = useState(false) 

    useEffect(() => {
        setChecked(parent.checked)
    },[parent])

    /**
     * Activar o desactivar el envio de notificacion a un estudiante de un grupo
     * 
     * @param {*} value 
     * @returns 
     */
    const handleparentToggle = (parent_id, student_id) => (event) => {
        onChange(parent_id, student_id, !checked)
    }

    return (
        <ListItem
            key={`item-${parent.student_id}`}
            disablePadding

        >
            <ListItemButton
                role={undefined}
                onClick={handleparentToggle(parent.parent_id, student.student_id)}
                dense
            >
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </ListItemIcon>
                <ListItemAvatar>
                    <Avatar
                        alt={`Avatar n°${parent.parent_id}`}
                        src={parent.url_photo_profile}
                        sx={{ width: 24, height: 24 }}
                    />
                </ListItemAvatar>
              <ListItemText id={labelId} primary={`${parent.name} ${parent.last_name} ${parent.second_last_name}`} />
            </ListItemButton>

        </ListItem>
    )
}



export default ParentListItem