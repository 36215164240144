import React from 'react'
import { Box, Card, IconButton, Menu, MenuItem, Button, CardHeader } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from '@mui/x-data-grid';
import ExportableModal from "../../../components/Chart/ExportableModal"
import { useSelector } from "react-redux";
import { getStudentsReport } from '../../../store/slices/background'
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import { BestScoreStudentsLine, WorstAbsenceStudentsLine } from "./components/IndicatorsLines"
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import * as constants from "../../../service/const";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../../hooks';

/**
 * Pagina para mostrar los alumnos
 * 
 * @returns 
 */
const StudentsView = () => {
    const history = useHistory()
    const match = useRouteMatch()
    const Auth = useAuth()

    ///////////////// SHARES STATE ///////////////////////

    const students = useSelector(getStudentsReport)

    //////////////// LOCAL STATE /////////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null
    });

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    ///////////////////////////// ACTIONS ////////////////////

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = () => {
        setReportData({
            report: "school-students",
            title: "Listado de alumnos",
            query: { school_id: Auth.getUser().school_id }
        })
        setOpenExportModal(true);
    }


    const columns = [
        //{ field: 'enrollment', headerName: 'Folio', flex: 1, sortable: false},
        { field: 'name', headerName: 'Nombre', flex: 1.5, sortable: true },
        { field: 'grade', headerName: 'Grado', flex: 0.8, sortable: true },
        { field: 'group', headerName: 'Grupo', flex: 0.8, sortable: true },
        { field: 'turn', headerName: 'Turno', flex: 0.8, sortable: true },
        { field: 'level', headerName: 'Nivel', flex: 0.8, sortable: true },
        { field: 'average_rating', headerName: 'Promedio', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 0.8, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            ),
        }
    ];

    let filas = []


    for (const student of students) {

        let group = student.group

        filas.push({
            id: student.student_id,
            //enrollment: student.folio,
            name: `${student.name} ${student.last_name}`,
            grade: group ? student.group.grade : 'Sin Grupo',
            group: group ? student.group.group : 'Sin Grupo',
            turn: group ? getTurnLevel(student.group.turn) : 'Sin Grupo',
            level: group ? getLevelName(student.group.level) : 'Sin Grupo',
            average_rating: student.average_rating,
            total_absence: student.total_absence,
            total_presence: student.total_presence
        })
    }

    /**
     * Funcion para ir al detalle de grupo
     * 
     * @param {*} params 
     * @returns 
     */
    const goDetails = (params) => () => {
        history.push({ pathname: `${match.path}/${params.id}` });
    }


    return (
        <Box>
            <BestScoreStudentsLine />
            <WorstAbsenceStudentsLine />

            <Card>
                <CardHeader
                    title="Alumnos"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 18
                        }
                    }}
                    subheader={"Total:" + students.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={filas} columns={columns}
                />
            </Card>
            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}

export default StudentsView