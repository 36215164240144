import { combineReducers } from "@reduxjs/toolkit";

import distributinModes from "./distributinModesSlice";
import entities from "./entitiesSlice";
import ui from "./uiSlice";

export default combineReducers({
    distributinModes,
    entities,
    ui
})