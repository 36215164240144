import { Avatar, Card, CardContent, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import * as React from 'react';
import { useSelector } from "react-redux";
import { getLevelName, getTurnLevel } from "../libs/utils"
import { selectSchoolById } from "../store/slices/entities/schools";
import { selectOneSettingBySchoolId } from "../store/slices/entities/settings";
import { selectCatalogSubjectById, selectGroupById, selectSubjectById } from "../store/slices/professorModule/entitiesSlice";
/**
 * Tarjeta que muestra informacion de una materia
 * para el modulo de profesores
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const SubjectPreviewCard = ({ subjectId, sx }) => {

    const subject = useSelector(selectSubjectById(subjectId))
    const group = useSelector(selectGroupById(subject.group_id))
    const catalogSubject = useSelector(selectCatalogSubjectById(subject.catalog_subject_id))

    const school = useSelector(selectSchoolById(group.school_id))
    const logoSetting = useSelector(selectOneSettingBySchoolId(group.school_id, 'theme-logo-school'))


    return (
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
            <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={2} >
                        <Box>
                            <Avatar alt="Estudiante" src={logoSetting?.value} sx={{ width: 66, height: 66 }} />
                        </Box>
                        <Box>
                            <Typography variant="h5" component="div">
                                {school.name}
                            </Typography>

                            <Typography variant="h6" component="div">
                                {group.grade}°{group.group} {getTurnLevel(group.turn)} {getLevelName(group.level)} {catalogSubject.title}
                            </Typography>
                        </Box>
                    </Stack>

                    <Stack direction={"column"} sx={{ textAlign: "center" }}>
                        <Typography variant="h4" component="span">
                            {subject.average_rating}
                        </Typography>
                        <Typography variant="caption" component="span">Promedio </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default SubjectPreviewCard