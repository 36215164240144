import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardActions,
    Button,
    Avatar,
    Tooltip,
} from "@mui/material";
import DistributionNoticeModal from '../../components/DistributionNoticeModal'
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../hooks";
import { getNotices, selectStatusServer } from "../../store/slices/noticesModule/selectors";
import { Empty, Error } from "../../components/Feedback";
import moment from 'moment'
import 'moment/locale/es';
import AddIcon from '@mui/icons-material/Add';
import { getNoticeTypeItem } from "../../libs/utils";
import { loadUI } from "../../store/slices/noticesModule/thunks";
import { invalidate } from "../../store/slices/noticesModule";
import GridCellExpand from "../../components/CellContent";
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from "./components/SkeletonPage";
import useFeedback from "../../hooks/useFeedback";
import { NOTICE_TYPES } from "../../constants";

/**
 * Pagina de avisos
 * 
 * @returns 
 */
const Avisos = () => {
    moment.locale('es')
    const dispatch = useDispatch()
    const Auth = useAuth()
    const feedbackApp = useFeedback()

    ///////////////// SHARED STATE ////////////////

    const serverStatus = useSelector(selectStatusServer)
    const noticesAll = useSelector(getNotices)

    //////////////// LOCAL STATE ////////////////

    const [openModal, setOpenModal] = useState(false);


    ///////////////// ACTIONS //////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI({
            professorId: Auth.getUserID(),
            schoolId: Auth.user.school_id
        }))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }



    /////////////////////// EFECTOS SECUNDARIOS //////////////////////////

    useEffect(() => {
        dispatch(loadUI({
            professorId: Auth.getUserID(),
            schoolId: Auth.user.school_id
        }))
    }, []);



    const columns = [
        /*{
            field: 'estatus',
            headerName: 'Estatus',
            flex: 0.5,
            minWidth: 50,
            editable:false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                  let status = getNoticeStatusItem(params.row.payload.status)
       
                return (
                  <Tooltip title={status.title} color={status.color}>
                         {status.icon}
                  </Tooltip>
                )
            },
        },*/
        {
            field: 'type',
            headerName: 'Tipo',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: NOTICE_TYPES.map(i => i.title),
            valueGetter: (params) => {
                let typeItem = getNoticeTypeItem(params.row.type)

                return typeItem.title
            },
        },
        {
            field: 'emisor',
            headerName: 'Emisor',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {

                let emmiterName = "Usuario no encontrado"

                if (row.spokeman) {
                    let spokeman = row.spokeman
                    emmiterName = `${spokeman.name} ${spokeman.last_name} ${spokeman.second_last_name}`
                }

                return emmiterName
            },
            renderCell: (params) => {
                let spokeman = params.row.spokeman

                if (!spokeman) {
                    return params.value
                }

                return (
                    <>
                        <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={spokeman.url_photo_profile} />
                        {params.value}
                    </>
                )
            },
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'description',
            headerName: 'Descripción',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: function renderCellExpand(params) {
                return (
                    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
                );
            }
        },
        {
            field: 'created_at',
            headerName: 'Creación',
            type: 'date',
            flex: 0.8,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format("DD MMMM YYYY")
            }
        },

    ];

    /**
     * Damos formato a los datos de las columnas
     */
    const dataGrid = noticesAll.map(row => ({ id: row.notice_id, ...row }));

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>

            <DistributionNoticeModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            {
                serverStatus == "pending" && (<SkeletonPage />)
            }

            {
                serverStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (serverStatus == "fulfilled") && (
                    <>
                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>
                        <Card sx={{ mt: 5 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: '0 !important',
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: "flex-end"
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataGrid}
                                    columns={columns}
                                    pageSize={10}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                            <CardActions sx={{ direction: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { setOpenModal(true) }}
                                    size="small"
                                    startIcon={<AddIcon />}
                                >
                                    Agregar
                                </Button>
                            </CardActions>
                        </Card>
                    </>
                )
            }
        </Box>
    );
};

export default Avisos;
