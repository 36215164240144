import React, { useEffect, useState } from "react";
import {
    Button,
    Box,
    Card,
    CardContent,
    CardActions,
    Tooltip,
} from "@mui/material";
import AddSubjectsModal from './components/AddSubjectsModal';
import ListGroupsModal from './components/ListGroupsModal';
import ListProfessorsModal from './components/ListProfessorsModal';
import { DataGrid, esES, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { selectStatusServer, loadUI, invalidate } from "../../store/slices/materias";
import { useAuth } from "../../hooks";
import AddIcon from '@mui/icons-material/Add';
import { Error } from "../../components/Feedback";
import { useHistory } from "react-router-dom";
import { getLevelName } from "../../libs/utils";
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import { selectSubjectsCatalogAsArray } from "../../store/slices/entities/subjectcatalogs";
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from "./components/SkeletonPage";
import useFeedback from "../../hooks/useFeedback";
import { SCHOOL_LEVELS } from "../../constants";

/**
 * Pagina para mostrar las materias
 * 
 * @returns 
 */
const Materias = () => {

    const history = useHistory();
    const Auth = useAuth()
    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    ////////////// SHARED STATE -//////////////////

    const statusServer = useSelector(selectStatusServer)
    const materias = useSelector(selectSubjectsCatalogAsArray());

    //////////////// LOCAL STATE /////////////////

    const [openModal, setOpenModal] = useState(false);
    const [openModalGrupos, setOpenModalGrupos] = useState(false);
    const [openProfessorsModal, setOpenProfessorsModal] = useState(false);
    const [catalogSelected, setCatalogSelected] = useState(null);

    const [subjectCatalogId, setSubjectCatalogId] = useState(null);


    /**
     * Muestra el modal para actualziar materia
     * 
     * @param {*} data 
     */
    const actualizar = (data) => {
        setCatalogSelected(data);
        setOpenModal(true);
    }


    const columns = [
        {
            field: 'folio',
            headerName: 'Folio',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Materia',
            flex: 1,
            minWidth: 120,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 1,
            minWidth: 120,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SCHOOL_LEVELS.map(i => i.title),
            valueGetter: (params) => {
                return getLevelName(params.row.level)
            },
        },
        {
            field: 'groups',
            headerName: 'Grupos',
            type: 'number',
            flex: 1,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar grupos">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ListIcon />}
                        onClick={() => { setSubjectCatalogId(params.id); setOpenModalGrupos(true); }}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'professors',
            headerName: 'Maestros',
            type: 'number',
            flex: 1,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar maestros">
                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ListIcon />}
                        onClick={() => { setSubjectCatalogId(params.id); setOpenProfessorsModal(true); }}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 90,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => ([
                <GridActionsCellItem
                    icon={<EditIcon color="primary" />}
                    title="Editar"
                    label="Editar"
                    onClick={() => { actualizar(params.row) }}
                    showInMenu
                />,
            ]),

        },
    ];

    const dataGrid = materias?.map(row => ({ id: row.catalog_subject_id, ...row }));


    /////////////////////// ACTIONS ////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Si el usuario a entrado a la aplicacion 
     * iniciamos el proceso de recuperacion de datos
     * 
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, []);

    return (
        <Box sx={{
            flexGrow: 1,
            paddingTop: {
                xs: 1,
                sm: 2,
                md: 2,
            },
            paddingLeft: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingRight: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingBottom: {
                xs: 1,
                sm: 2,
                md: 5,
            },
        }}>


            <AddSubjectsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                catalog={catalogSelected}
            />

            <ListGroupsModal
                openModal={openModalGrupos}
                setOpenModal={setOpenModalGrupos}
                subjectCatalogId={subjectCatalogId}
            />

            <ListProfessorsModal
                openModal={openProfessorsModal}
                setOpenModal={setOpenProfessorsModal}
                subjectCatalogId={subjectCatalogId}
            />

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>

                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>

                        <Card sx={{ mt: 5 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: 0,
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: "flex-end"
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataGrid}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                            <CardActions sx={{ direction: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { setCatalogSelected(null); setOpenModal(true) }}
                                    size="small"
                                    startIcon={<AddIcon />}
                                >
                                    Agregar
                                </Button>
                            </CardActions>
                        </Card>
                    </>
                )
            }
        </Box>
    );
};

export default Materias;
