import Services from "./EficazAPI"
import ClientHttp from "./HttpClient"
import Token from "./Token"


class Exportable {
    connecion = null

    constructor(connecionService) {
        this.connecion = connecionService
    }

    /**
     * Funcion encargada de exportar los archivos
     * 
     * @param {*} reportName 
     * @param {*} typeFormat 
     */
    export(reportName, typeFormat, query, snaphot) {
        return this.connecion.getExportable(reportName, typeFormat, query, snaphot)
    }
}

let token = new Token()

let IClientHttp = new ClientHttp()
IClientHttp.setToken(token)

let APIService = new Services(IClientHttp)

let APIExportable = new Exportable(APIService)

export default APIExportable