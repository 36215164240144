import { Grid, Skeleton } from "@mui/material"

export default () => {

    return (
        <>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 4 }} animation="wave" />

            <Skeleton variant="rectangular" height={600} sx={{ mt: 2 }} animation="wave" />
        </>
    )
}
