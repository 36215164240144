import { Box, Stack, Typography } from "@mui/material"
import googleApp from './../../assets/images/playstore.png';
import appleApp from './../../assets/images/appstore.png';
import { Link, Redirect, useHistory } from "react-router-dom";
import { textAlign } from "@mui/system";

const FooterDashboard = () => {
    return (
        <Box>
            <div id="footer">
                <div className="container">
                    <Stack direction={"row"} justifyContent="space-around" alignItems="center">
                        <div>
                            <div className="copyright">
                                &copy; Copyright <strong><span>Eficaz</span></strong>. All Rights Reserved
                            </div>
                            <div className="copyright">
                                <strong><span><Link to="/aviso-privacidad" target="_blank" rel="noopener noreferrer">Aviso de privacidad</Link></span></strong>
                            </div>
                        </div>
                        <Box sx={{ textAlign: "center" }}>
                            <Typography component="div" variant="h5">
                                Descarga la aplicación
                            </Typography>
                            <Stack direction={"row"} sx={{ mt: 1 }} justifyContent={"center"}>
                                <a href='https://play.google.com/store/apps/details?id=com.eficaz.appmovil' target="_blank" rel="noreferrer">
                                    <img alt='Disponible en Google Play' style={{ width: "120px" }} src={googleApp} />
                                </a>
                                <a href='https://apps.apple.com/mx/app/eficaz/id1498693599' target="_blank" rel="noreferrer">
                                    <img alt='Disponible en App Store' style={{ width: "145px" }} src={appleApp} />
                                </a>
                            </Stack>
                        </Box>
                    </Stack>
                </div>
            </div>
        </Box >
    )
}

export default FooterDashboard