import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
    TextField, Typography, Grid,
    Dialog, DialogActions, DialogContent, DialogTitle, Button, Snackbar
} from '@mui/material';
import { Box } from '@mui/system';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from "react-redux";
import { addCatalogReport, selectStatusOperation, updateCatalogReport } from "../../../store/slices/reports";
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        //padding: theme.spacing(0),
        //position: 'absolute',
        //top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px',
    }
}));

/**
 * Modal para agregar catalogo de reportes
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
export default ({ openModal, setOpenModal, catalog = null }) => {

    const classes = useStyles();

    const Auth = useAuth()
    let user = Auth.getUser()
    const schoolId = Auth.getUser().school_id

    const dispatch = useDispatch();
    const [catalogItem, setCatalogItem] = useState({
        catalog_report_id: null,
        title: ''
    });

    ////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    /**
     * Guardar datos del catalogo
     */
    const enviarDatos = async () => {

        if (!catalogItem.catalog_report_id) {
            dispatch(addCatalogReport({
                schoolId: schoolId,
                data: catalogItem,
            }))
                .unwrap()
                .then(({ message }) => {
                    showFeedback("Catalogo creado")
                    setCatalogItem({
                        catalog_report_id: null,
                        title: ''
                    });
                }).catch(({ feedback }) => {
                    showFeedback(feedback.title)
                })
        } else {
            dispatch(updateCatalogReport({
                idCatalog: catalogItem.catalog_report_id,
                data: catalogItem,
            }))
                .unwrap()
                .then(({ message }) => {
                    showFeedback("Catalogo actualizado")
                }).catch(({ feedback }) => {
                    showFeedback(feedback.title)
                })
        }
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setCatalogItem({ ...catalogItem, [name]: value });
    }

    /**
     * Cerrar el modal y restablecer los valores
     */
    const closeModal = () => {
        setOpenModal(false)
    }

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!openModal) {
            setCatalogItem({
                catalog_report_id: null,
                title: null
            })
        } else {
            setCatalogItem({ ...catalogItem, ...catalog })
        }
    }, [openModal]);

    //////////////////////// VALIDACIONES ////////////////////////

    const titleValid = () => {
        return {
            invalid: catalogItem.title == '',
            message: "Campo requerido"
        }
    }

    const formInvalid = () => {
        return (
            titleValid().invalid
        )
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    return (

        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogWrapper }}
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={() => {
                closeModal()
            }}
        >
            <DialogTitle
                className={classes.dialogTitle}
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: fontColor.value }}>
                        {
                            catalogItem.catalog_report_id != null && `Actualizar catálogo`
                        }
                        {
                            catalogItem.catalog_report_id == null && `Agregar catálogo`
                        }
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Grid container spacing={1}>
                        <Grid container item xl={12} sm={12} md={12} xs={12} style={{ marginTop: "5px" }}>
                            <TextField
                                id="title"
                                name="title"
                                label="Título del reporte"
                                type="text"
                                size="small"
                                variant="standard"
                                inputProps={{ maxLength: 30 }}
                                fullWidth
                                value={catalogItem.title}
                                onChange={onInputChange}
                                error={titleValid().invalid}
                                helperText={titleValid().invalid ? titleValid().message : false}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    {
                        catalogItem.catalog_report_id != null && `Actualizar`
                    }
                    {
                        catalogItem.catalog_report_id == null && `Agregar`
                    }
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={closeModal}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    )
}