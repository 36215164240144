import { getLevelName, getSpecialName, getTurnLevel } from "../libs/utils"
import round from "./round"

/**
 * Recuperar el nombre del nivel de una escuela
 */
const getNameSchoolLevel = getLevelName

/**
 * Recuperar el label para el valor de especial
 */
const getSpecialsLabel = getSpecialName

/**
 * Recuperar el label para el valor de turno
 */
const getTurnsLabel = getTurnLevel



export { round, getNameSchoolLevel, getSpecialsLabel, getTurnsLabel }