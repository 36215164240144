import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { convertArrayToObject } from "../../../libs/utils";
import Services from "../../../service/Connection";
import { SETTINGSUI_EXPIRE_TIME } from "../../../service/const";
import Feedback from "../../../service/Feedback";
import { addManyPartials, addOnePartial, removeOnePartial, setAllPartials, upsertOnePartial } from "../entities/partials";
import { upsertOneSchool } from "../entities/schools";
import { upsertManySettings, upsertOneSetting } from "../entities/settings";

const emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {

    }
}

/**
 * Slice para el settings UI
 */
export const settingsUISlice = createSlice({
    name: 'settingsUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending'
        }

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + SETTINGSUI_EXPIRE_TIME))
            state.server.ferchingAt = Date.now()
            state.server.didInvalidate = false

            state.server.statusServer = 'fulfilled'
        })
        builder.addCase(loadUI.pending, pendingServerStatus)
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected'
            state.server.feedback = action.payload.feedback
        })

        ////////////////  ACTUALZIAR UN PARCIAL ///////////////
        builder.addCase(updatePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updatePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updatePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })
        /////////////// CREAR PARCIAL ////////////////////
        builder.addCase(storePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(storePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(storePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })
        /////////////// ELIMINAR UN PARCIAL ////////////////
        builder.addCase(deletePartial.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(deletePartial.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(deletePartial.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        ///////////////// ACTUALZIAR CONFIGUIRACION /////////////////
        builder.addCase(saveSetting.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(saveSetting.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(saveSetting.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        /////////////////// ACTUALZIAR LA ESCUELA /////////////////////
        builder.addCase(saveSchool.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(saveSchool.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(saveSchool.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })
        /////////////////// ACTUALZIAR LA ESCUELA /////////////////////
        builder.addCase(updateImageSchool.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateImageSchool.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateImageSchool.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })
    }
});

export const { invalidate } = settingsUISlice.actions;

export default settingsUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectsettingsUI = (state) => state.settingsUI.ui;

export const selectsettingsData = (state) => state.settingsUI.data;

export const selectsettingsServer = (state) => state.settingsUI.server;

export const selectStatusServer = (state) => state.settingsUI.server.statusServer

export const selectStatusOperation = (state) => state.settingsUI.server.statusOperation



//////////////// TRUNCKS /////////////////

/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'settingsUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            const partials = await Services.getPartials(schoolId).then(res => res.data.data)
            const school = await Services.schoolInfo(schoolId).then(r => r.data.data)
            const settings = await Services.getSettingsBySchool(schoolId).then(res => res.data.data)

            thunkAPI.dispatch(setAllPartials(partials))
            thunkAPI.dispatch(upsertOneSchool(school))
            thunkAPI.dispatch(upsertManySettings(settings))

            return {
                partials,
                school
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().settingsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    }
}
)

/**
 * Crear o actualizar parcial
 */
export const storePartial = createAsyncThunk(
    'entities/partials/store',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let partial = await Services.setPartials(schoolId, data).then(res => res.data.data)

            thunkAPI.dispatch(addOnePartial(partial))
            return partial
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }

    }
)

/**
 * Crear o actualizar parcial
 */
export const updatePartial = createAsyncThunk(
    'entities/partials/update',
    async ({ partialId, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let partial = await Services.updatePartial(partialId, data).then(res => res.data.data)
            thunkAPI.dispatch(upsertOnePartial(partial))

            return partial
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Eliminar parcial
 */
export const deletePartial = createAsyncThunk(
    'entities/partials/delete',
    async (partialId, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let partial = await Services.deletePartial(partialId).then(res => res.data.data)
            thunkAPI.dispatch(removeOnePartial(partial.partial_id))

            return partial
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Actualziar parcial
 */
export const saveSetting = createAsyncThunk(
    'entities/setting/update',
    async ({ settingId, value }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let setting = await Services.setSchoolSettings(settingId, value).then(response => response.data.data)
            thunkAPI.dispatch(upsertOneSetting(setting))

            return setting
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Actualziar escuela
 */
export const saveSchool = createAsyncThunk(
    'entities/school/update',
    async (data, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let school = await Services.UpdateSchoolInfo(data.school_id, data).then(r => r.data.data)
            thunkAPI.dispatch(upsertOneSchool(school))

            return school
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Actualziar escuela
 */
export const updateImageSchool = createAsyncThunk(
    'entities/school/update-image',
    async ({ settingId, formData }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let setting = await Services.uploadSchoolImage(settingId, formData).then(r => r.data.data)
            thunkAPI.dispatch(upsertOneSetting(setting))

            return setting
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)