import React from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from "@mui/system"
import { DataGrid } from "@mui/x-data-grid"
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSubjectReportByProfessor } from '../../../../store/slices/background'
import { getLevelName, getTurnLevel } from "../../../../libs/utils";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/**
 * Tabla que muestra las materias de un profesor
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const SubjectsTable = (props) => {
    const { professorId } = props

    const history = useHistory()
    const match = useRouteMatch()

    ///////////////// SHARES STATE  ////////////////////

    const subjects = useSelector(getSubjectReportByProfessor(professorId))

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////
    const onExport = () => {
        props.export({
            report: "professor-subjects",
            title: "Materias del profesor",
            query: { professor_id: professorId }
        })
    }

    /////////////////////////////// ACTIONS ///////////////////////////////

    let rowsSubjects = []

    const goSubjectDetails = (params) => () => {
        console.log(params)
        history.push({ pathname: `/director/materias/${params.id}` });
    }

    const columnsSubjects = [
        //{ field: 'folio', headerName: 'Folio', flex: 0.5, sortable: false, },
        { field: 'title', headerName: 'Titulo', flex: 1.5, sortable: true, },
        { field: 'group', headerName: 'Grupo', flex: 1.5, sortable: true },
        { field: 'special', headerName: 'Especial', flex: 0.8, sortable: true },
        { field: 'average_rating', headerName: 'Promedio', flex: 1, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 1, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 1, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goSubjectDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            )
        }
    ];

    for (const item of subjects) {

        const nameProfesor = (item.professor_id) ? `${item.payload.professor.name} ${item.payload.professor.last_name}` : 'Sin profesor'

        let nameGroups = "Sin grupo"

        if (item.payload.hasOwnProperty('group')) {
            let group = item.payload.group

            const levelName = getLevelName(group.level)
            const turnName = getTurnLevel(group.turn)


            nameGroups = `${group.grade} ${group.group} ${turnName} ${levelName}`
        }

        rowsSubjects.push({
            id: item.subject_id,
            subject_id: item.subject_id,
            //folio: item.catalog_subject.folio,
            title: `${item.payload.catalogSubject.title}`,
            professor: nameProfesor,
            average_rating: item.average_rating,
            total_absence: item.total_absence,
            total_presence: item.total_presence,
            special: (item.special) ? "Si" : "No",
            group: nameGroups
        })
    }

    return (
        <Box>
            <Card sx={{ marginBottom: 4 }}>
                <CardHeader
                    title="Materias"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 18
                        }
                    }}
                    subheader={"Total:" + subjects.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={rowsSubjects} columns={columnsSubjects}
                />
            </Card>

        </Box>
    )
}



export default SubjectsTable