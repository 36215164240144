import Authentication from "./Login";
import enviroment from "./Service";

/**
 * Clase encargada de mapear la API de eficaz
 */
class EficazAPI {
    httpClient = null

    /**
     * 
     * @param {*} httpClient Cliente que sera usado para realizar las peticiones
     */
    constructor(httpClient = null) {
        this.httpClient = httpClient
    }

    /**
     * Retorna el cliente HTTP actual
     * 
     * @returns HttpClient
     */
    getClient() {
        return this.httpClient
    }

    /**
     * Recuperar recursos del servidor paginados
     * 
     * @param {string} method Metodo que sera usado por a API
     * @param {array} params Parametros que seran enviados a method
     * @param {object} queryParameters Parametros de query que ira en la solicitud
     * 
     * @returns 
     */
    async getResourcesPaginatedFromServer(method, params, queryParameters = {}) {
        let lastPage = 0
        let currentPage = 0

        let items = []
        let metas = []

        let parameter = {
            per_page: 50,
            page: currentPage,
        }

        if (queryParameters) {
            parameter = { ...parameter, ...queryParameters }
        }

        do {
            currentPage++

            parameter.page = currentPage

            let itemsResponse = await this[method](...params, parameter).then(res => res.data)

            let itemsPaginated = itemsResponse.data
            let meta = itemsResponse.meta

            lastPage = meta.last_page

            items = items.concat(itemsPaginated)
            metas = metas.concat(meta)
        } while (currentPage < lastPage)

        return {
            metas,
            data: items
        }
    }

    ///////////////////////////////////// CONEXIONES DE LA API ////////////////////////////////////////////////////////

    /**
     * Se puede cambiar contraseña, password e informacion 
     * 
     * @param {*} userID 
     * @param {*} newCredential 
     * @returns 
     */
    updateUser(userID, data) {
        return this.updateGenericUser('/users', userID, data)
    }

    /**
     * Se puede cambiar contraseña, password e informacion 
     * 
     * @param {*} userID 
     * @param {*} newCredential 
     * @returns 
     */
    updateGenericUser(endpoint, userID, data) {
        return this.httpClient.put(`${endpoint}/${userID}`, data);
    }

    /**
     * Actualizar imagen de usuario
     * 
     * @param {*} userID 
     * @param {*} newCredential 
     * @returns 
     */
    uploadUserImage(endpoint, userID, data) {
        return this.httpClient.post(`${endpoint}/${userID}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
     * Actualziar imagen de escuela
     * 
     * @param {*} userID 
     * @param {*} newCredential 
     * @returns 
     */
    uploadSchoolImage(settingId, data) {
        return this.httpClient.post(`settings/${settingId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
     * Recuperar todos los parciales activos de una escuela
     * 
     * @param numeric idSchool
     * @param {*} data 
     * @returns Promise
     */
    getPartials(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/partials?filters={"status":1}&&fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Crear un parcial
     * 
     * @param numeric idSchool
     * @param {*} data 
     * @returns Promise
     */
    setPartials(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/partials`, data);
    }

    /**
     * Actualizar un parcial
     * 
     * @param numeric idPartial
     * @param {*} data 
     * @returns Promise
     */
    updatePartial(idPartial, data) {
        return this.httpClient.patch(`/partials/${idPartial}`, data);
    }

    /**
     * Eliminar un parcial
     * 
     * @param numeric idPartial 
     * @returns Promise
     */
    deletePartial(idPartial) {
        return this.httpClient.delete(`/partials/${idPartial}`);
    }

    loginMe(token) {
        return this.httpClient.get(`${enviroment.RUTA_API}${enviroment.VERSION}/me/information`, {
            headers: {
                Authorization: 'Bearer ' + token
            },
        });
    }

    /**
     * Recuperar todos los grupos de una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters Opciones de filtrado
     * 
     * @returns 
     */
    groupsBySchool(schoolId, queryParameters = null) {
        let endpoint = `/schools/${schoolId}/groups`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar todos los profesores de una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     * 
     * @returns 
     */
    professorsBySchool(idSchool, queryParameters = null) {
        let Auth = new Authentication()
        let endpoint = `/schools/${idSchool}/professors`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`).then(r => {
            let payload = r.data.data

            r.data.data = payload.map(i => ({ ...i, user_id: Auth.getUserID(i) }))

            return r
        })
    }

    /**
     * Recuperar profesor 
     * 
     * @param {*} idProfessor Identificador del profesor
     * @returns 
     */
    getProfessorsById(idProfessor) {
        return this.httpClient.get(`/professors/${idProfessor}?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Recuperar todos los estudiantes de una escuela
     * 
     * @param {*} schoolId Identificador de la escuela
     * @param {*} queryParameters Contiene un Objeto que sera serialisado 
     * 
     * @returns 
     */
    StudentsBySchool(schoolId, queryParameters = {}) {
        let endpoint = `/schools/${schoolId}/students`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Combierte un Objecto de JS en un QueryHttp
     * @param {*} object
     * 
     * @returns 
     */
    encodeQuery(object) {
        return Object.entries(object)
            .map(([key, val]) => {

                let value = this.isObject(val) ? encodeURIComponent(JSON.stringify(val)) : val

                return `${encodeURIComponent(key)}=${value}`
            })
            .join('&');
    }

    isObject(obj) {
        return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
    }

    /**
     * Recuperamos un alumno usando si id
     * 
     * @param {*} idStudent 
     * 
     * @returns 
     */
    getStudentById(idStudent) {
        return this.httpClient.get(`/students/${idStudent}?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Recuperar todos los asesores de una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     * 
     * @returns 
     */
    getAssesorsBySchool(idSchool, queryParameters = {}) {
        let Auth = new Authentication()
        let endpoint = `/schools/${idSchool}/assessors`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`).then(r => {
            let payload = r.data.data

            r.data.data = payload.map(i => ({ ...i, user_id: Auth.getUserID(i) }))

            return r
        })
    }


    /**
     * Recuperar assesores activos
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getAssesorsActivedBySchool(idSchool) {
        let url = `/schools/${idSchool}/assessors?filters={"status":1}&fields={"except":["link","created_at","updated_at"]}`

        return this.httpClient.get(url);
    }


    /**
     * Recuperar profesores activos
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {object} queryParameters
     * 
     * @returns 
     */
    getProfessorsActivedBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/professors`

        const queryEncoded = this.encodeQuery({
            filters: { "status": 1 },
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar los alumnos de un grupo
     * 
     * @param {integer} idGroup 
     * @param {object} queryParameters 
     * 
     * @returns 
     */
    StudentsByGroup(idGroup, queryParameters = {}) {
        let endpoint = `/groups/${idGroup}/students`

        const queryEncoded = this.encodeQuery({
            filters: { "status": 1 },
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
    * Recuperar todos los alumnos de un grupo
    * 
    * @param {interger} idSchool Identificador de la escuela
    * @param {object} queryParameters
    * 
    * @returns 
    */
    getStudentsByGroup(groupID, queryParameters = {}) {
        return this.StudentsByGroup(groupID, queryParameters)
    }

    /**
     * Recuperar todos los alumnos de un grupo que tengan mas de una relacion
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getStudentsHasRelationshipsByGroup(groupID) {
        return this.getStudentsByGroup(groupID).then(response => {
            let students = response.data.data

            response.data.data = students.filter(s => s.relationships > 0)

            return response
        })
    }

    /**
     * Recuperar toas las calificaciones de un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    calificationsByStudent(idStudent, queryParameters = {}) {
        let endpoint = `/students/${idStudent}/califications`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recuperar toas las calificaciones de un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getCalificationaBySubject(subjectID, queryParameters = {}) {
        let endpoint = `/subjects/${subjectID}/califications`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }


    assistsByStudent(idStudent) {
        return this.httpClient.get(`${enviroment.RUTA_API}/students/${idStudent}/assists?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Recuperar informacion de una escuela
     * 
     * @param numeric idSchool
     * @returns Promise
     */
    schoolInfo(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}?fields={"except":["link"]}`);
    }

    /**
     * Recuperar informacion de una escuela
     * 
     * @param numeric idSchool
     * @returns Promise
     */
    getSchoolById(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

       /**
     * Recuperar informacion de una escuela
     * 
     * @param numeric idSchool
     * @returns Promise
     */
       getHomeSupervisor(queryParameters = {}) {
        let endpoint = `/info-school-supervisor`

        const queryEncoded = this.encodeQuery({
            
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}`);
    }

    getCalifications(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Actualiza la informacion de una escuela 
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    UpdateSchoolInfo(idSchool, data) {
        return this.httpClient.patch(`/schools/${idSchool}`, data);
    }

    schooSettings(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/settings?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Actualizar una configuracion de una escuela
     * 
     * @param numeric idSetting
     * @param any valor 
     * @returns Promise
     */
    setSchoolSettings(idSetting, valor) {

        let data = {
            value: valor
        }

        return this.httpClient.patch(`/settings/${idSetting}`, data);
    }

    /**
     * Recuperamos todas las noticias de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    noticesBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/notices`

        const queryEncoded = this.encodeQuery({
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getSpokesmen(spokesman_id) {
        return this.httpClient.get(`/spokesmen/${spokesman_id}`);
    }

    /**
     * Recupera las configuraciones de una escuela
     * @param {*} idSchool 
     * 
     * @returns 
     */
    getSettingsBySchool(idSchool) {

        return this.httpClient.get(`/schools/${idSchool}/settings`);
    }

    /**
     * Recupermos los reportes por escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @returns 
     */
    getReportBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/reports`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     *  Obtenemos el catalogo de reportes
     * 
     * @param {*} idSchool 
     * @returns 
     */
    getReportCatalogBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/reportcatalogs`);
    }

    /**
     * Crear un catalogo de reportes 
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos a registrar
     * 
     * @returns 
     */
    setReportCatalogBySchool(idSchool, data) {

        return this.httpClient.post(`/schools/${idSchool}/reportcatalogs`, data);
    }

    /**
     * Actualizar catalogo
     * 
     * @param {*} idCatalogo Identificador de catalogo
     * @param {*} data Datos a actualizar
     * 
     * @returns 
     */
    updateReportCatalog(idCatalogo, data) {
        return this.httpClient.patch(`/reportcatalogs/${idCatalogo}`, data);
    }

    /**
     * Recuperamos el expediante al que pertenece un reporte
     * 
     * @param {*} idReport 
     * @returns 
     */
    getFilesByReport(idReport) {

        return this.httpClient.get(`/reports/${idReport}/files`);
    }

    getStudentByFile(idFile) {

        return this.httpClient.get(`/files/${idFile}/students`);
    }

    /**
     * Recuperar todo el catalogo de materias de una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     * 
     * @returns 
     */
    getSubjectCatalogBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/subjectcatalogs`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Recueprar el catalogo de materia 
     * 
     * @param {*} idCatalog 
     * @returns 
     */
    getSubjectCatalogById(idCatalog) {
        return this.httpClient.get(`/subjectcatalogs/${idCatalog}?fields={"except":["link","created_at","updated_at"]}`);
    }

    getSchoolBySubjectCatalog(idCatalog) {
        return this.httpClient.get(`/subjectcatalogs/${idCatalog}/schools?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Almacena un catalogo de una materia 
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data datos de la neuva materia 
     * 
     * @returns 
     */
    setSubjecyCatalogBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/subjectcatalogs`, data);
    }

    /**
     * Registrar materia a un profesor
     * 
     * @param {*} idProfessor Identificador del profesor
     * @param {*} data Datos de la materia a asignar
     * 
     * @returns 
     */
    setSubjectByProfessor(idProfessor, data) {
        return this.httpClient.post(`/professors/${idProfessor}/subjects`, data);
    }

    /**
     * Creamos materias
     * 
     * @param {*} data Identificador de la escuela
     * 
     * @returns 
     */
    setSubject(data) {
        return this.httpClient.post(`/subjects`, data);
    }

    /**
     * Eliminar materia
     * 
     * @param {*} subjectId Identificador de la materia
     * 
     * @returns 
     */
    deleteSubject(subjectId) {
        return this.httpClient.delete(`/subjects/${subjectId}`);
    }

    /**
     * Recuperar todas las materias de una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} queryParameters nombre del corte
     * 
     * @returns 
     */
    getSubjectsBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/subjects`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    getCalificationsBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/subjects?fields={"except":["link","created_at","updated_at"]}`);
    }

    getSubjectByCatalog(idSchool) {

        return this.httpClient.get(`/subjectcatalogs/${idSchool}/subjects?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Actualziar un catalogo de materias 
     * 
     * @param {*} idCatalog Identificador del catalogo
     * @param {*} data Datos actualizar
     * 
     * @returns 
     */
    updateSubjectCatalog(idCatalog, data) {
        return this.httpClient.patch(`/subjectcatalogs/${idCatalog}`, data);
    }

    /**
     * Recuperar materias por grupo
     * 
     * @param {integer} idGroup 
     * @param {object} queryParameters
     * 
     * @returns 
     */
    getSubjectByGroup(idGroup, queryParameters = {}) {
        let endpoint = `/groups/${idGroup}/subjects`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Actualiza la informacion de un grupo
     * 
     * @param {*} idGroup 
     * @param {*} data 
     * @returns 
     */
    updateGroupById(idGroup, data) {
        return this.httpClient.patch(`/groups/${idGroup}`, data);
    }

    /**
     * Recupera el grupo pincipal del alumno
     * 
     * @param {*} idStudent 
     * 
     * @returns 
     */
    getGroupByStudent(idStudent) {
        return this.httpClient.get(`/students/${idStudent}/groups?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Recupera el los grupos especiales del alumno
     * 
     * @param {*} idStudent 
     * 
     * @returns 
     */
    getSpecialGroupsByStudent(idStudent) {
        return this.httpClient.get(`/students/${idStudent}/specialgroups?fields={"except":["link","created_at","updated_at"]}`);
    }


    recoverAccount(data) {
        return this.httpClient.post(`/pwd/email`, data);
    }


    resetAccount(data) {
        return this.httpClient.post(`/pwd/reset`, data);
    }

    getActivitiesBySchool(schoolId) {
        return this.httpClient.get(`/schools/${schoolId}/activities?filters={"status":1}`);
    }

    storeActivity(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/activities`, data);
    }

    updateActivity(activityId, data) {
        return this.httpClient.patch(`/activities/${activityId}`, data);
    }

    deleteActivity(activityId) {
        return this.httpClient.delete(`/activities/${activityId}`);
    }

    /**
     * Recuperar todos los ciclso de una escuela
     * 
     * los snaphost no cuenta con la tabla de ciclos
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getCyclesBySchool(schoolId) {
        return this.httpClient.get(`/schools/${schoolId}/cycles`);
    }


    /**
     * Obtenemos un archivo de exportacion del webservice
     * 
     * @param reportName 
     * @param typeFormat 
     * @param queryReports 
     * @returns Promise
     */
    getExportable(reportName, typeFormat, queryReports, cycle = null) {
        let url = `/exportable?report=${reportName}&format=${typeFormat}&data=${JSON.stringify(queryReports)}`;

        if (cycle) {
            url += `&cycle=${cycle}`;
        }

        return this.httpClient.get(url, { responseType: 'blob' });
    }


    /**
     * Obtenemos todos las materias de un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getSubjectsByStudent(studentID, queryParameters = {}) {
        let endpoint = `/students/${studentID}/subjects`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtiene las asistencias de un alumno
     * 
     * @param {*} idStudent 
     * 
     * @returns 
     */
    getAssistensByStudent(idStudent) {
        return this.httpClient.get(`/students/${idStudent}/assists?fields={"except":["link","created_at","updated_at"]}`);
    }

    /**
     * Obtenemos las calificaciones de un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getCalificationbyStudent(idStudent, options = null) {
        let initalFileds = `?fields={"except":["link","created_at","updated_at"]}`

        let url = `/students/${idStudent}/califications${initalFileds}`

        if (options) {
            url += options.filters
        }

        return this.httpClient.get(url);
    }

    getPartialsByLevel(idSchool, level) {
        return this.httpClient.get(`/schools/${idSchool}/partials?filters={"status":1, "level": ${level}}&&fields={"except":["link","created_at","updated_at"]}`, {
            body: {
            }
        });
    }

    getSubjectCatalogBySubject(idSubject) {
        return this.httpClient.get(`/subjects/${idSubject}/subjectcatalogs?fields={"except":["link","created_at","updated_at"]}`)
    }

    /**
     * Recuperamos todos los padres de una escuela
     * 
     * @param {integer} idSchool 
     * @param {object} queryParameters
     * @returns 
     */
    getParentsBySchool(idSchool, queryParameters = {}) {
        let endpoint = `/schools/${idSchool}/parents`

        let parameters = {
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        }

        const parametersEncoded = this.encodeQuery(parameters)

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos datos de un grupo
     * 
     * @param {*} idGroup Identificador del grupo
     * 
     * @returns 
     */
    getGroupById(idGroup) {
        return this.httpClient.get(`/groups/${idGroup}?fields={"except":["link","created_at","updated_at"]}`)
    }

    /**
     * Recuperamos datos de los grupos enviados por parametros
     * 
     * @param schoolId Identificador de la escuela
     * @param {*} groupsIds Identificador del los grupos
     * 
     * @returns 
     */
    getGroupByIds(groupsIds, schoolId = null) {
        // TODO HACER OPCIONA EL ID DE LA ESCUELA
        return this.httpClient.get(`/schools/${schoolId}/groups?filters={"group_id":[${groupsIds}]}&fields={"except":["link","created_at","updated_at"]}`)
    }

    /**
     * Recuperar todos los directores de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    getDirectorsBySchool(idSchool) {
        return this.httpClient.get(`/directors?filters={"school_id":${idSchool}}&fields={"except":["link","created_at","updated_at"]}`)
    }

    /**
     * Crear directores
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    setUserDirectorBySchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/directors`, data);
    }

    /**
     * Crear padres sin escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns 
     */
    setUserParent(data) {
        return this.httpClient.post(`/parents`, data);
    }

    /**
     * Crear padres con una escuela establecida
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns 
     */
    storeParentSchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/parents`, data);
    }

    updateUserDirectorById(idDirector, data) {
        return this.httpClient.patch(`/directors/${idDirector}`, data);
    }

    setUserDirectorrBySchool(idSchool, data) {
        return this.httpClient.get(`/schools/${idSchool}/directors`, data);
    }

    getOrientadoresBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/assessors?fields={"except":["link","created_at","updated_at"]}`)
    }

    updateUserOrientadorById(idOrientador, data) {
        return this.httpClient.patch(`/assessors/${idOrientador}`, data);
    }

    /**
     * Crear un orientador
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    setUserOrientadorBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/assessors`, data);
    }

    /**
     * Recupera los administradores de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    getAdministratorssBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/administrators?fields={"except":["link","created_at","updated_at"]}`)
    }

    updateUserAdministratorsById(idAdministrators, data) {
        return this.httpClient.patch(`/administrators/${idAdministrators}`, data);
    }

    /**
     * Crear un administrador
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    setUserAdministratorsBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/administrators`, data);
    }

    /**
     * Crear un profesor
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    setUserProfessorBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/professors`, data);
    }

    updateUserProfessorBySchool(idProfessor, data) {
        return this.httpClient.patch(`/professors/${idProfessor}`, data);
    }

    /**
     * Recuperar todos los voseros de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    getVocerosBySchool(idSchool) {
        return this.httpClient.get(`/schools/${idSchool}/spokesmen?fields={"except":["link","created_at","updated_at"]}`)
    }

    updateUserVoceroById(idAdministrators, data) {
        return this.httpClient.patch(`/spokesmen/${idAdministrators}`, data);
    }

    setUserVoceroBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/spokesmen`, data);
    }

    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     * 
     * @param {*} idUser 
     * @param {*} data 
     * @returns 
     */
    updateUserById(idUser, data) {
        return this.httpClient.patch(`/users/${idUser}`, data);
    }

    /**
     * Crear grupos
     * 
     * @param {*} idSchool 
     * @param {*} data 
     * @returns 
     */
    setGroupBySchool(idSchool, data) {
        return this.httpClient.post(`/schools/${idSchool}/groups`, data);
    }

    /**
     * Recuperar grupos con alumnos
     * 
     * @param {*} schoolId 
     *
     * @returns 
     */
    getGroupsWithStudents(schoolId) {

        return this.groupsBySchool(schoolId).then(i => {
            let groups = i.data.data

            i.data.data = groups.filter(g => g.students != 0)

            return i
        })
    }

    /**
     * Actualizar materia
     * 
     * @param {*} idSubject 
     * @param {*} data
     * 
     * @returns 
     */
    updateSubjectById(idSubject, data) {
        return this.httpClient.patch(`/subjects/${idSubject}`, data);
    }


    /**
     * Obtenemos los padres de u alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getParentsByStudent(studentID, queryParameters = {}) {
        let endpoint = `/students/${studentID}/parents`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
     * Obtenemos los grupos de un profesor
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getGroupsByProfessor(professorID, snaphost = null) {
        let url = `/professors/${professorID}/groups`
        let params = `?fields={"except":["link","created_at","updated_at"]}`

        if (snaphost) {
            url += params + `&snaphot=${snaphost}`
        }

        return this.httpClient.get(url);
    }

    /**
     * Obtenemos las materias de un profesor
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getSubjectsByProfessor(professorID, snaphost = null) {
        let url = `/professors/${professorID}/subjects`
        let params = `?fields={"except":["link","created_at","updated_at"]}`

        if (snaphost) {
            url += params + `&snaphot=${snaphost}`
        }

        return this.httpClient.get(url);
    }


    /**
     * Obtenemos una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getSchoolByUser(userEndpoint, userId, snaphost = null) {
        let url = `${userEndpoint}/${userId}/schools`
        let params = `?fields={"except":["link","created_at","updated_at"]}`

        if (snaphost) {
            url += params + `&snaphot=${snaphost}`
        }

        return this.httpClient.get(url);
    }

    /**
     * Obtenemos una escuela
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getStudentAssists(userId, subjectId, snaphost = null) {
        let params = `?filters={"subject_id":${subjectId}}&fields={"except":["link","created_at","updated_at"]}`

        let url = `students/${userId}/assists${params}`

        if (snaphost) {
            url += `&snaphot=${snaphost}`
        }

        return this.httpClient.get(url);
    }


    /**
     * Almancear la lsita de asistencia
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    storeMultiAssistsList(postData) {
        let url = `/assists`

        const headers = {
            "Accept": 'application/json',
            'Content-Type': 'application/json'
        }
        return this.httpClient.post(url, postData, headers);
    }

    /**
     * Obtenemos la calificaciones de un alumno usado filtros
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getCalificationbyStudentUsingSubjetID(student_id, id_subject) {
        return this.getCalificationbyStudent(student_id, {
            filters: `&filters={"subject_id":${id_subject}}`
        })
    }


    /**
     * Actualziar calificacion
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    updateCalification(calificationID, dataRequest) {
        let url = `/califications/${calificationID}`

        const headers = {
            "Accept": 'application/json',
            'Content-Type': 'application/json'
        }

        return this.httpClient.put(url, dataRequest);
    }

    /**
     * Crear calificacion
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    createCalification(studentId, subjectId, dataRequest) {
        let url = `/students/${studentId}/subjects/${subjectId}/califications`

        return this.httpClient.post(url, dataRequest);
    }

    /**
     * Recuperar todas las materias de un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getSubjectsByStudent(studentId) {
        let url = `/students/${studentId}/subjects`

        return this.httpClient.get(url);
    }


    /**
     * Obtenemos una materia
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getSubjectById(subjectId) {
        let url = `/subjects/${subjectId}?fields={"except":["link","created_at","updated_at"]}`

        return this.httpClient.get(url);
    }

    /**
     * Crear reportes
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    storeReports(fileId, professorId, requestData) {
        let url = `/files/${fileId}/professors/${professorId}/reports`

        return this.httpClient.post(url, requestData);
    }

    /**
     * Crear expediantes
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    storeFile(studentId, assessorId) {
        let url = `/students/${studentId}/assessors/${assessorId}/files`

        return this.httpClient.post(url);
    }

    /**
     * Crear expediantes
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getInactiveFilesByStudent(studentId) {
        let url = `students/${studentId}/files?filters={"status":0}`

        return this.httpClient.get(url);
    }

    /**
     * Obtenes un expediante
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getFileById(fileId) {
        let url = `/files/${fileId}?fields={"except":["link","created_at","updated_at"]}`

        return this.httpClient.get(url);
    }

    /**
     * Obtener el emisor de una notificacion
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getEmmiterByNoticeId(noticeId) {
        let url = `/notices/${noticeId}/emitters?fields={"except":["link","created_at","updated_at"]}`

        return this.httpClient.get(url);
    }

    /**
     * Guardar una noticias
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    storeNotice(spokesmanID, requestData) {
        let url = `/spokesmen/${spokesmanID}/notices`

        return this.httpClient.post(url, requestData);
    }

    /**
     * Obtenemos informacion general usuarios
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getUsers(usersIds) {
        let url = `/users?filters={"user_id":[${usersIds}]}&fields={"except":["link", "push_token", "push_id"]}`

        return this.httpClient.get(url);
    }

    /**
     * Actualizar cualquier usuario
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    updateUserData(endpoint, userId, data) {
        return this.httpClient.patch(`${endpoint}/${userId}`, data);
    }

    /**
     * Asignar un asesor a un grupo
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    assignAssessorToGroup(groupId, id_assesor) {
        let data = { assessor_id: id_assesor }

        return this.httpClient.post(`/groups/${groupId}/assessors`, data);
    }

    /**
     * Da de baja un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    deleteStudent(studentId, password) {
        return this.httpClient.delete(`/students/${studentId}`, {
            data: {
                password
            }
        });
    }

    /**
     * Quitar grupo a un alumno
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    deleteStudentFromGroup(studentId, groupId) {
        return this.httpClient.delete(`/students/${studentId}/groups/${groupId}`);
    }

    // DELETE students/1/groups/12 

    /**
     * Recuperar alumnos activos sin grupo
     * 
     * @param {*} idSchool Identificador de la escuela
     * 
     * @returns 
     */
    getStudentsActivedWithoutGroup(idSchool, queryParameters) {
        let endpoint = `/schools/${idSchool}/students`

        const queryEncoded = this.encodeQuery({
            filters: { "status": 1, "group_id": null },
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }


    /**
     * Recuperar alumnos activos
     * 
     * @param {*} idSchool Identificador de la escuela
     * 
     * @returns 
     */
    getStudentsActived(idSchool) {
        let url = `/schools/${idSchool}/students?filters={"status":1}&fields={"except":["link","created_at","updated_at"]}`

        return this.httpClient.get(url);
    }


    /**
     * Recuperar alumnos activos con padres
     * 
     * @param {*} idSchool Identificador de la escuela
     * 
     * @returns 
     */
    getStudentsHasRelationships(idSchool) {
        return this.getStudentsActived(idSchool).then(response => {
            let students = response.data.data

            response.data.data = students.filter(s => s.relationships > 0)

            return response
        })
    }

    /**
     * Agregamos un grupo a un alumno
     * 
     * @param {*} studentId Identificador del alumno
     * @param {*} groupId Identificador del alumno
     * 
     * @returns 
     */
    setStudentsToGroup(studentId, groupId) {
        let url = `/students/${studentId}/groups`

        return this.httpClient.post(url, {
            group_id: groupId
        });
    }

    /**
     * Cambiar un grupo a un alumno
     * 
     * @param {*} studentId Identificador del alumno
     * @param {*} currentGroupId Identificador del grupo actual
     * @param {*} newGroupId Identificador del nuevo grupo
     * 
     * @returns 
     */
    changeStudentGroup(studentId, currentGroupId, newGroupId, data = null) {
        let url = `/students/${studentId}/groups/${currentGroupId}`

        return this.httpClient.patch(url, {
            group_id: newGroupId,
            ...data
        });
    }

    /**
     * Crear alumno y asignarlo a un grupo principal
     * 
     * @param {*} idSchool Identificador de la escuela
     * 
     * @returns 
     */
    createAndSetStudentToGroup(schoolId, groupId, data) {
        let url = `/schools/${schoolId}/groups/${groupId}/students`

        return this.httpClient.post(url, data);
    }

    /**
    * Recuperar todas las relaciones de una escuela
    * 
    * @param {*} idSchool Identificador de la escuela
    * 
    * @returns 
    */
    getRelationshipsBySchool(schoolId, queryParameters = {}) {
        let endpoint = `/schools/${schoolId}/relationships`

        const queryEncoded = this.encodeQuery({
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            ...queryParameters
        })

        return this.httpClient.get(`${endpoint}?${queryEncoded}`);
    }

    /**
    * Recuperar los parentescos de un alumno
    * 
    * @param {*} idSchool Identificador de la escuela
    * 
    * @returns 
    */
    getRelationshipsByStudents(studentId) {
        let url = `/students/${studentId}/relationships`

        return this.httpClient.get(url);
    }


    /**
     * Actualizar alumno
     * 
     * @param {*} idUser 
     * @param {*} data 
     * @returns 
     */
    updateStudent(studentId, data) {
        return this.httpClient.patch(`/students/${studentId}`, data);
    }


    /**
     * Crear alumnos
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} data Datos del usuario
     * @returns 
     */
    storeStudentInSchool(schoolId, data) {
        return this.httpClient.post(`/schools/${schoolId}/students`, data);
    }

    /**
     * Recuperamos todos los padres de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    findParents(queryParameters = {}) {
        let endpoint = `/parents`

        let parameters = {
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            'not-calculate-properties': true,
            ...queryParameters
        }

        const parametersEncoded = this.encodeQuery(parameters)

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos todos los padres de una escuela
     * 
     * @param {*} idSchool 
     * @returns 
     */
    findUsers(queryParameters = {}) {
        let endpoint = `/users`

        let parameters = {
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            'not-calculate-properties': true,
            ...queryParameters
        }

        const parametersEncoded = this.encodeQuery(parameters)

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }

    /**
     * Recuperamos un padre por su identificador
     * 
     * @param {*} idSchool Identificador de la escuela
     * @param {*} snaphost nombre del corte
     * 
     * @returns 
     */
    getParentById(parentID, queryParameters = {}) {
        let endpoint = `/parents/${parentID}`

        let parameters = {
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            'not-calculate-properties': true,
            ...queryParameters
        }

        const parametersEncoded = this.encodeQuery(parameters)

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }


    /**
     * Recuperar un usuario por su identificador
     * 
     * @param {*} idSchool Identificador de la escuela
     * 
     * @returns 
     */
    getUserById(userID, queryParameters = {}) {
        let endpoint = `/users/${userID}`

        let parameters = {
            fields: {
                except: ["link", "created_at", "updated_at"]
            },
            'not-calculate-properties': true,
            ...queryParameters
        }

        const parametersEncoded = this.encodeQuery(parameters)

        return this.httpClient.get(`${endpoint}?${parametersEncoded}`);
    }


    /**
     * Asociar padre con un hujo
     * 
     * @param {*} idSchool 
     * @returns 
     */
    storeRelationship(stundetId, parentId, dataRequest) {
        let endpoint = `/students/${stundetId}/parents/${parentId}/relationships`

        return this.httpClient.post(endpoint, dataRequest);
    }


    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     * 
     * @param {*} idUser 
     * @param {*} data 
     * @returns 
     */
    updateRelationshipById(idUser, data) {
        return this.httpClient.patch(`/relationships/${idUser}`, data);
    }

    /**
     * Actualzia contrsseña, username, y el tipo de usuario de un usuario
     * 
     * @param {*} idUser 
     * @param {*} data 
     * @returns 
     */
    updateParentById(idUser, data) {

        return this.httpClient.patch(`/parents/${idUser}`, data);
    }

    /**
     * Permite almacenar/actualiziar un conjunto de
     * calificaciones en un solo request
     *  
     * @param postData 
     * @returns 
     */
    saveMutiCalifications(postData) {
        let endpoint = `/califications`

        const headers = {
            "Accept": 'application/json',
            'Content-Type': 'application/json'
        }

        return this.httpClient.post(endpoint, postData, { headers })
    }

    /**
     * Solicitar permiso al servidor
     *  
     * @param postData 
     * @returns 
     */
    grantPermission(postData) {
        let endpoint = `/grant-permission`

        return this.httpClient.post(endpoint, postData)
    }

    /**
     * Crear ciclo escolar
     *  
     * @param postData 
     * @returns 
     */
    createSnaphot(schoolId, postData) {
        let endpoint = `/schools/${schoolId}/cycles`

        return this.httpClient.post(endpoint, postData)
    }
}

export default EficazAPI;