import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Snackbar, Button, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import UserForm from '../../../components/UserForm';
import Connection from "../../../service/Connection"
import { upsertOneRelationship } from '../../../store/slices/entities/relationships';
import { selectUserById, upsertOneUser } from '../../../store/slices/entities/users';
import { relationShipsNames } from '../../../service/const';
import Feedback from '../../../service/Feedback';
import { selectRelationshipsById } from '../../../store/slices/entities/relationships';

/**
 * Modal para actualizar el parentesco de un alumno
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const UpdateParentModal = ({ openModal, setOpenModal, userId = null, relationshipId = null }) => {

    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    //const student = useSelector(selectStudentById(studentId))
    const user = useSelector(selectUserById(userId))
    const relationship = useSelector(selectRelationshipsById(relationshipId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    let FeedbackService = new Feedback()

    ///////////////// LOCAL STATE ////////////////////

    const [userData, setUserData] = useState({
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: '',
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        group_id: '',
    });

    const [validForm, setValidForm] = useState(false);
    const [relationshipType, setRelationshipType] = useState('')
    const [loading, setLoading] = useState(false)

    ///////////// ACTIONS ///////////////////////

    /**
     * Funcion para reiniciar los malores del modal
     */
    const resetUserData = () => {
        setUserData({
            name: '',
            last_name: '',
            second_last_name: '',
            password: '',
            password_confirmation: '',
            enrollment: '',
            email: '',
            cellphone: '',
            street: '',
            colony: '',
            outside_number: '',
            inside_number: '',
            zipcode: '',
            country_code: '12',
            group_id: '',
        })
    }

    /**
     * Guardar datos del usuario
     */
    const enviarDatos = () => {
        setLoading(true)
        updateLinkAndParent().then(({ relationship, parent }) => {
            if (relationship) {
                dispatch(upsertOneRelationship(relationship))
            }

            dispatch(upsertOneUser(parent))

            setTimeout(() => {
                setOpenModal(false)
            }, 3000)
            showFeedback("Usuario actualizado")

        }).catch(err => {
            const feedback = FeedbackService.getMessage(err)

            showFeedback(feedback.title)
        }).then(() => {
            setLoading(false)
        })
    }

    /**
     * Actualiza padre y su relacion
     */
    const updateLinkAndParent = async () => {
        const userId = Auth.getUserID(userData)

        let userUpdated = await Connection.updateParentById(userId, userData).then(i => i.data.data)

        userUpdated.user_id = userId

        let newRelationship = null

        if (relationshipType != relationship?.relationship) {
            newRelationship = await Connection.updateRelationshipById(relationshipId, {
                relationship: relationshipType
            }).then(i => i.data.data)
        }


        return {
            relationship: newRelationship,
            parent: userUpdated
        }
    }

    /**
     * Efecto lanzado cuando se abre el modal
     */
    useEffect(() => {
        if (!openModal) {
            resetUserData()
            setRelationshipType('')
        } else {
            setUserData(user)
            setRelationshipType(relationship.relationship)
        }
    }, [openModal])

    /**
     * Si el formulario a cambiado
     */
    const onChangeUserForm = ({ value, name, valid }) => {
        setUserData({ ...userData, [name]: value });
        setValidForm(valid)
    }

    ////////////////////////// VALIDACIONES //////////////////////////////////

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        return (
            !validForm
        )
    }

    /**
     * Cambio el tipo de relacion 
     * 
     * @param {*} data 
     */
    const onChangeRelationshiptype = (event) => {
        setRelationshipType(event.target.value)
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    ////////////////////// VIEW //////////////////////////

    return (

        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth={"md"}
            disableEscapeKeyDown={loading}
            onClose={() => {
                setOpenModal(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        Actualizar datos de usuario
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="select-relationship-label">Tipo de relación</InputLabel>
                    <Select
                        labelId="select-relationship-label"
                        id="select-relationship"
                        value={relationshipType}
                        label="Tipo de relación"
                        onChange={onChangeRelationshiptype}
                    >
                        <MenuItem value={-1} selected disabled>
                            Selecciona una opción
                        </MenuItem>
                        {
                            relationShipsNames.map(i => {
                                return (<MenuItem key={i.key} value={i.key}>{i.title}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
                <UserForm
                    value={userData}
                    tipoUsuario={'PARENT'}
                    onChange={onChangeUserForm}
                    hiddenUserCredentials={true}
                />
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Actulizar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpenModal(false) }}
                    disabled={loading}
                >
                    Cerrar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>

    )
}

export default UpdateParentModal;
