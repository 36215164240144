import React from 'react'
import { useSelector } from "react-redux";
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';
import StatsBiColor from '../../../../components/Chart/StatsBiColor';
import { getBestReportsGroupCard } from '../../../../store/slices/background';

/**
 * Componente que muestra el mayor grupo con 
 * reportes
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const BestReportsGroupIndicator = (props) => {

  const bestReportsGroupCard = useSelector(getBestReportsGroupCard)

  return (
    <StatsBiColor
      icon={faFileAlt}
      iconColor={red[500]}
      value={bestReportsGroupCard.total_reports}
      title={`${bestReportsGroupCard.title} ${bestReportsGroupCard.levelName}`}
      label={"Mayor reportes"}
      mainColor={"#3c5424"}
      secundaryColor={"#04b454"}
    >
    </StatsBiColor>
  )
}

export default BestReportsGroupIndicator