import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import Services from "../../../service/Connection";
import { STUDENTSUI_MODALS_EXPIRE_TIME } from "../../../service/const";
import Feedback from "../../../service/Feedback";
import * as _ from 'lodash';
import Authentication from "../../../service/Login";
import { addManyUsers, addOneUser, upsertManyUsers } from "../entities/users";
import { selectStudentById, upsertManyStudents } from "../entities/students";
import { selectAllGroupsEntities, selectGroupsById, upsertManyGroup } from "../entities/groups";
import { addManyGroupsStudents } from "../entities/groups_students"
import { addManySubjects, selectSubjectByGroupId, upsertManySubjects } from "../entities/subjects";
import { selectSubjectcatalogsByLevel, upsertManySubjectcatalog } from "../entities/subjectcatalogs";
import { upsertManyRelationships } from "../entities/relationships";
import { selectPartialsBySchoolLevel, upsertManyPartials } from "../entities/partials";
import { selectCalificationsBy, upsertManyCalifications } from "../entities/califications";
import { selectAllAssists, selectAssistsBy, upsertManyAssists } from "../entities/assists";
import { selectAllCalifications } from "../entities/califications";

const emptyState = {}

/**
 * Slice para el settings UI
 */
export const studentsUIItemsSlice = createSlice({
    name: 'scoresUI/items',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI de alumnos
         */
        invalidateStudentItem: (state, { payload }) => {

            const { groupId, studentId } = payload

            state[groupId].items[studentId].servers.details.didInvalidate = true
        },

        /**
         * Invalidar datos de la UI de grupos
         */
        invalidateGroupItem: (state, { payload }) => {

            state[payload].servers.details.didInvalidate = true
        },

        //addManyItems: itemsAdapter.addMany,
        setAllItems: (state, action) => {
            return action.payload
        },
        //upsertManyItems: itemsAdapter.upsertMany,
        itemUpdated: (state, { payload }) => {

            const { id, changes } = payload
            state[id] = { ...state[id], ...changes }
        },
        //removeOneItem: itemsAdapter.removeOne,
        selectStudentItem: (state, { payload }) => {

            const { itemGroupId, itemStudentId } = payload

            state[itemGroupId].ui.studentSelected = itemStudentId
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        //////////////// GROUPS INFORMATION ///////////////////////////////

        builder.addCase(loadGroupsDetailsUI.fulfilled, (state, { payload }) => {
            const { groupId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state[groupId].servers.details = { ...state[groupId].servers.details, ...newState }
        })

        builder.addCase(loadGroupsDetailsUI.pending, (state, { meta }) => {
            const { groupId } = meta

            state[groupId].servers.details.statusServer = 'pending'
        })

        builder.addCase(loadGroupsDetailsUI.rejected, (state, action) => {
            if (action.payload) {
                const { groupId, feedback } = action.payload

                state[groupId].servers.details.statusServer = 'rejected'
                state[groupId].servers.details.feedback = feedback
            }
        })

        //////////////// STUDENTS INFORMATION ///////////////////////////////

        builder.addCase(loadStudentsDetailsUI.fulfilled, (state, { meta }) => {
            const { arg } = meta
            const { groupId, studentId } = arg

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state[groupId].items[studentId].servers.details = { ...state[groupId].items[studentId].servers.details, ...newState }
        })

        builder.addCase(loadStudentsDetailsUI.pending, (state, { meta }) => {
            const { arg } = meta

            state[arg.groupId].items[arg.studentId].servers.details.statusServer = 'pending'
        })

        builder.addCase(loadStudentsDetailsUI.rejected, (state, action) => {
            if (action.payload) {

                const { groupId, studentId } = action.meta.arg
                const { feedback } = action.payload

                state[groupId].items[studentId].servers.details.statusServer = 'rejected'
                state[groupId].items[studentId].servers.details.feedback = feedback
            }
        })
    }
});

export const {
    addManyItems, upsertManyItems, itemUpdated, setAllItems,
    removeOneItem, selectStudentItem, invalidateStudentItem,
    invalidateGroupItem
} = studentsUIItemsSlice.actions;

export default studentsUIItemsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * Selecciona todos los ItemsGroups
 */

export const selectAllGroupsItems = (store) => {
    let items = Object.values(selectAllGroupsItemsEntities(store))
    let groups = selectAllGroupsEntities(store)

    return items.map(i => ({
        ...groups[i.ui.group_id],
        item: i
    }))
}

export const selectAllGroupsItemsEntities = (store) => store.scoresUI.items

/**
 * Recuperar un GroupItem por ID
 * 
 * @param {*} id 
 * 
 * @returns 
 */
export const selectGroupItemById = (id) => (store) => {
    return selectAllGroupsItemsEntities(store)[id]
}



////////////////////// GROUPS PAGE /////////////////////////

/**
 * Selecciona el grupo actual con informacion adicional
 */
export const selectGroupItemSelected = (itemId) => (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected

    let group = selectGroupsById(groupSelected)(store)

    let item = selectGroupItemById(groupSelected)(store)

    return {
        ...group,
        item,
    }
}

/**
 * Selector para recuperar el status server del detalle del grupo
 */
export const selectGroupsStatusServerItemSelected = (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected

    let item = selectGroupItemById(groupSelected)(store)

    if (groupSelected && item) {
        return item.servers.details.statusServer
    }
    return 'idle'
}

/**
 * Selecciona todos los grupos
 */
export const selectAllStudentsFromGroupItemSelected = (store) => {
    const groupSelected = store.scoresUI.ui.groupSelected
    let groupItem = store.scoresUI.items[groupSelected]


    return Object.values(groupItem.items).map(i => {
        return {
            ...selectStudentById(i.ui.student_id)(store),
            item: i
        }
    })
}

//////////////////////////// STUDENTS PAGES //////////////////////////////////

/**
 * Selecciona toda la informacion necesaria para la pagina de calificaciones por grupo
 */
export const selectStudentCalificationsPage = (groupId, id, schoolId) => {
    return (store) => {
        groupId = parseInt(groupId)
        id = parseInt(id)
        schoolId = parseInt(schoolId)

        const group = selectGroupsById(groupId)(store)
        const student = selectStudentById(id)(store)
        const partials = selectPartialsBySchoolLevel(schoolId, group.level)(store)
        const subjects = selectSubjectByGroupId(group.group_id)(store)
        const subjectCatalog = selectSubjectcatalogsByLevel(group.level)(store)

        const assists = selectAssistsBy({ student_id: id })(store)
        const califications = selectCalificationsBy({ student_id: id })(store)


        return {
            group,
            student,
            partials,
            subjects,
            subjectCatalog,
            califications,
            assists
        }
    }
}


/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */

//export const selectScoresData = (state) => state.scoresUI.items;


/**
 * Selecciona toda la informacion necesaria para la pagina de calificaciones por grupo
 */
export const selectStudentStatusServer = (groupId, studentId) => {
    return (store) => {

        const items = selectAllGroupsItemsEntities(store)

        return items[groupId].items[studentId].servers.details.statusServer
    }
}

//export const selectStatusServer = (state) => state.scoresUI.server.statusServer

export const selectStudentStatusOperation = (groupId, studentId) => {
    return (store) => {

        const items = selectAllGroupsItemsEntities(store)

        return items[groupId].items[studentId].servers.details.statusOperation
    }
}

///////////////////////// TRUNKS ////////////////////////////



function getEmptyItem(studentId) {
    return {
        items: {
        },
        servers: {
            details: {
                expireIn: null,
                ferchingAt: null,
                statusServer: "idle",
                statusOperation: "idle",
                didInvalidate: true,
                feedback: {
                    title: null,
                    message: null,
                    payload: null,
                }
            }
        },
        ui: {
            student_id: studentId
        }
    }
}


/**
 * Cargar informacion todos los alumnos de 
 * el grupo seleccionado
 */
export const loadGroupsDetailsUI = createAsyncThunk(
    'scoresUI/item/fetch/groups',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const groupId = state.scoresUI.ui.groupSelected

        try {

            let students = await Services.getStudentsByGroup(groupId).then(i => i.data.data);

            let groupsStudents = students.map(i => {
                return {
                    student_id: i.student_id,
                    group_id: groupId
                }
            })

            thunkAPI.dispatch(upsertManyStudents(students))
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents))

            const items = students.reduce((preveState, curr) => {
                preveState[curr.student_id] = getEmptyItem(curr.student_id)
                return preveState
            }, {})

            thunkAPI.dispatch(itemUpdated({
                id: groupId,
                changes: { items }
            }))

            return {
                groupId,
                students,
                groupsStudents
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const groupId = state.scoresUI.ui.groupSelected

        let { didInvalidate, expireIn } = state.scoresUI.items[groupId].servers.details

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            groupId: getState().scoresUI.ui.groupSelected
        }
    }
}
)

/**
 * Cargar informacion de los grupos del alumno
 */
export const loadStudentsDetailsUI = createAsyncThunk(
    'scoresUI/item/fetch/students',
    async ({ groupId, studentId, schoolId, level }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {

            let partials = await Services.getPartialsByLevel(schoolId, level).then(i => i.data.data)

            let subjets = await Services.getSubjectsByStudent(studentId).then(i => i.data.data)

            // TODO METERLE EL NIVEL 
            let catalogSubject = await Services.getSubjectCatalogBySchool(schoolId).then(i => i.data.data)

            let allAssitens = await Services.getAssistensByStudent(studentId).then(i => i.data.data)

            let califications = await Services.getCalificationbyStudent(studentId).then(i => i.data.data)

            thunkAPI.dispatch(upsertManyAssists(allAssitens))
            thunkAPI.dispatch(upsertManyCalifications(califications))
            thunkAPI.dispatch(upsertManyPartials(partials))
            thunkAPI.dispatch(upsertManySubjects(subjets))
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogSubject))

            return {
                studentId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                studentId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: ({ groupId, studentId }, { getState, extra }) => {
        const state = getState()

        const studentItemSelected = state.scoresUI.items[groupId].items[studentId]

        let { didInvalidate, expireIn } = studentItemSelected.servers.details

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    }
}
)