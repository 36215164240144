import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { selectSchoolById } from "../store/slices/entities/schools";
import { useAuth } from "../hooks";
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { saveSchool, selectStatusOperation } from "../store/slices/settingsUI";
import useFeedback from "../hooks/useFeedback";

/**
 * Formulario para actualizar datos de una escuela
 * 
 * @returns 
 */
export const SchoolGeneralDataCard = () => {

    const feedbackApp = useFeedback()
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    const dispatch = useDispatch()

    ////////////////// SELECTORS ////////////////

    const school = useSelector(selectSchoolById(schoolId));
    const statusOperation = useSelector(selectStatusOperation)

    ////////////////// STATE ///////////////////

    const [schoolsData, setSchoolData] = useState({
        cellphone: school.cellphone,
        //city_id: school.city_id,
        clave: school.clave,
        colony: school.colony,
        control: school.control,
        inside_number: school.inside_number,
        level: school.level,
        location: school.location,
        name: school.name,
        outside_number: school.outside_number,
        //periode: school.periode,
        school_id: school.school_id,
        street: school.street,
        sustenance: school.sustenance,
        //turn: school.turn,
        zipcode: school.zipcode,
    })

    const [pais, setPais] = useState('México');

    /**
     * Funciona para actualziar los datos
     */
    let actualizarDatos = () => {
        dispatch(saveSchool(schoolsData))
            .unwrap()
            .then(res => {
                feedbackApp.showFeedback({
                    title: "Datos de la escuela guardados correctamente"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setSchoolData({ ...schoolsData, [name]: value });
    }


    ///////////////// VALIDATIONS ////////////////////////////

    const nameValid = () => {
        return {
            invalid: schoolsData.name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const cellphoneValid = () => {
        return {
            invalid: schoolsData.cellphone == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const levelValid = () => {
        return {
            invalid: schoolsData.level == -1,
            message: "Campo requerido o incorrecto"
        }
    }

    const controlValid = () => {
        return {
            invalid: schoolsData.control == -1,
            message: "Campo requerido o incorrecto"
        }
    }

    const claveValid = () => {
        return {
            invalid: schoolsData.clave == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const sustenanceValid = () => {
        return {
            invalid: schoolsData.sustenance == -1,
            message: "Campo requerido o incorrecto"
        }
    }

    const locationValid = () => {
        return {
            invalid: schoolsData.location == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const streetValid = () => {
        return {
            invalid: schoolsData.street == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const colonyValid = () => {
        return {
            invalid: schoolsData.colony == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const outsideNumberValid = () => {
        return {
            invalid: schoolsData.outside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const insideNumberValid = () => {
        return {
            invalid: false,
            message: "Campo requerido o incorrecto"
        }
    }

    const zipcodeValid = () => {
        return {
            invalid: schoolsData.zipcode == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        return (
            nameValid().invalid || cellphoneValid().invalid || levelValid().invalid ||
            controlValid().invalid || claveValid().invalid || sustenanceValid().invalid ||
            locationValid().invalid || streetValid().invalid || colonyValid().invalid ||
            outsideNumberValid().invalid || insideNumberValid().invalid || zipcodeValid().invalid
        )
    }

    return (
        <Card sx={{ mt: 2 }}>
            <CardHeader
                title="Información general"
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item container xl={12} sm={12} md={12} xs={12} >
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                            <TextField
                                id="name"
                                name="name"
                                label="Nombre de la escuela"
                                type="text"
                                size="small"
                                variant="standard"
                                value={schoolsData.name}
                                onChange={onInputChange}
                                fullWidth={true}
                                error={nameValid().invalid}
                                helperText={nameValid().invalid ? nameValid().message : false}
                            />
                        </Grid>
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>

                            <TextField
                                id="cellphone"
                                name="cellphone"
                                label="Teléfono"
                                type="tel"
                                size="small"
                                variant="standard"
                                value={schoolsData.cellphone}
                                onChange={onInputChange}
                                fullWidth={true}
                                error={cellphoneValid().invalid}
                                helperText={cellphoneValid().invalid ? cellphoneValid().message : false}
                            />
                        </Grid>
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                            <FormControl variant="standard" sx={{ width: '100%' }} error={levelValid().invalid}>
                                <InputLabel id="lbl-nivel">Nivel educativo</InputLabel>
                                <Select
                                    labelId="lbl-nivel"
                                    id="level"
                                    name="level"
                                    value={schoolsData.level}
                                    onChange={onInputChange}
                                    label="level"
                                    fullWidth
                                >
                                    <MenuItem value={1}>Educación Básica</MenuItem>
                                    <MenuItem value={2}>Educación Básica y Media Superior</MenuItem>
                                    <MenuItem value={3}>Primaria</MenuItem>
                                    <MenuItem value={4}>Secundaria</MenuItem>
                                    <MenuItem value={5}>Preparatoria</MenuItem>
                                    <MenuItem value={6}>Universidad</MenuItem>
                                </Select>
                                {
                                    levelValid().invalid && <FormHelperText>{levelValid().message}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                            <FormControl variant="standard" sx={{ width: '100%' }} error={controlValid().invalid}>
                                <InputLabel id="lbl-sector">Tipo</InputLabel>
                                <Select
                                    labelId="lbl-sector"
                                    id="control"
                                    name="control"
                                    value={schoolsData.control}
                                    onChange={onInputChange}
                                    label="Sector"
                                    fullWidth
                                >
                                    <MenuItem value={1}>Pública</MenuItem>
                                    <MenuItem value={2}>Privada</MenuItem>
                                </Select>
                                {
                                    controlValid().invalid && <FormHelperText>{controlValid().message}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>

                            <TextField
                                id="clave"
                                name="clave"
                                label="Registro SEP"
                                type="text"
                                size="small"
                                variant="standard"
                                value={schoolsData.clave}
                                onChange={onInputChange}
                                fullWidth={true}
                                error={claveValid().invalid}
                                helperText={claveValid().invalid ? claveValid().message : false}
                            />
                        </Grid>
                        <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                            <FormControl variant="standard" sx={{ width: '100%' }} error={sustenanceValid().invalid}>
                                <InputLabel id="lbl-tipo">Sostenimiento</InputLabel>
                                <Select
                                    labelId="lbl-tipo"
                                    id="sustenance"
                                    name="sustenance"
                                    value={schoolsData.sustenance}
                                    onChange={onInputChange}
                                    label="Tipo"
                                    fullWidth
                                >
                                    <MenuItem value={1}>Estatal</MenuItem>
                                    <MenuItem value={2}>Municipal</MenuItem>
                                    <MenuItem value={3}>Federal</MenuItem>
                                </Select>
                                {
                                    sustenanceValid().invalid && <FormHelperText>{sustenanceValid().message}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardHeader
                title="Información de ubicación"
            />
            <CardContent>
                <Grid item container xl={12} sm={12} md={12} xs={12} style={{ textAlign: "justify" }}>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>

                        <TextField
                            id="pais"
                            name="pais"
                            label="País"
                            type="text"
                            size="small"
                            variant="standard"
                            value={pais}
                            onChange={onInputChange}
                            error={pais === ""}
                            fullWidth={true}
                            disabled
                        />
                    </Grid>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>

                        <TextField
                            id="location"
                            name="location"
                            label="Ciudad"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.location}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={locationValid().invalid}
                            helperText={locationValid().invalid ? locationValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                        <TextField
                            id="calle"
                            name="street"
                            label="Calle"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.street}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={streetValid().invalid}
                            helperText={streetValid().invalid ? streetValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                        <TextField
                            id="colony"
                            name="colony"
                            label="Col / Fracc"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.colony}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={colonyValid().invalid}
                            helperText={colonyValid().invalid ? colonyValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                        <TextField
                            id="outside_number"
                            name="outside_number"
                            label="No Ext"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.outside_number}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={outsideNumberValid().invalid}
                            helperText={outsideNumberValid().invalid ? outsideNumberValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                        <TextField
                            id="inside_number"
                            name="inside_number"
                            label="No Int"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.inside_number}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={insideNumberValid().invalid}
                            helperText={insideNumberValid().invalid ? insideNumberValid().message : false}
                        />
                    </Grid>

                    <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                        <TextField
                            id="zipcode"
                            name="zipcode"
                            label="código postal"
                            type="text"
                            size="small"
                            variant="standard"
                            value={schoolsData.zipcode}
                            onChange={onInputChange}
                            fullWidth={true}
                            error={zipcodeValid().invalid}
                            helperText={zipcodeValid().invalid ? zipcodeValid().message : false}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                }}
            >
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={actualizarDatos}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                    loading={statusOperation == 'pending'}
                >
                    Guardar
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default SchoolGeneralDataCard;
