import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { getLevels} from '../../../store/slices/calendar'

const ActivityLevels = (props) => {
    const {error} = props
    const [level, setLevel] = useState(props.value)

    useEffect(() => {
        setLevel(props.value)
    }, [props.value]);

   
    const levels = useSelector(getLevels)

    const onChangeLevel = (event) => {
        props.onChange(event)
    }


    const getItemsLevels = () => {
        let levelsItems = []

        for (const level of levels) {
            levelsItems.push(<MenuItem key={level.id} value={level.id}>{level.name}</MenuItem>)
        }

        return levelsItems
    }

    return (
        <FormControl fullWidth size="small" error={error}>
            <InputLabel id="demo-simple-select-label">Nivel</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={level}
                label="Nivel escolar"
                onChange={onChangeLevel}
            >
                {props.items}
                {getItemsLevels()}
            </Select>
        </FormControl>
    )
}

export default ActivityLevels