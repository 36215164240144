import React from 'react'
import StatBiColor from "../StatsBiColor"
import { useSelector } from "react-redux";
import { getWorstAbsenceSubjectCard} from '../../../store/slices/background'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';

const WorstAbsenceSubjectsIndicator = (props) => {

    const worstAbsenceSubjectCard = useSelector(getWorstAbsenceSubjectCard)

    return (
      <StatBiColor
        icon={faDoorOpen}
        iconColor={blue["A700"]}
        value={worstAbsenceSubjectCard.total_absence}
        title={`${worstAbsenceSubjectCard.group_name} ${worstAbsenceSubjectCard.title} ${worstAbsenceSubjectCard.level}
        ${worstAbsenceSubjectCard.professor_name}`}
        label={"Mayor faltas"}
        mainColor={"#fc4444"}
        secundaryColor={"#fc7474"}
        valueOptions={{variant: "h5", component: "span"}}
      >  
      </StatBiColor>
    )
  }

export default WorstAbsenceSubjectsIndicator