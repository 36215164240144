
/**
 * Clase encargada de la autenticacion y tipos de usuarios
 */
class Feedback {

    constructor() {
        this.errors = {
            // Alumnos 3100 - 3199
            "3100": {
                title: "El alumno ya pertenece al grupo",
                message: ""
            },
            "3101": {
                title: "El alumno ya tiene grupo principal",
                message: ""
            },
            "3102": {
                title: "El alumno no pertenece grupo",
                message: ""
            },
            "3103": {
                title: "El alumno y el grupo no pertenecen a la misma escuela",
                message: ""
            },
            // Grupos 5900 - 5999
            "5900": {
                title: "El grupo ya se encuentra registrado",
                message: "Los grupos no pueden repetirse"
            },
            "5901": {
                title: "El grupo no le pertenece a la escuela",
                message: "El grupo enviado no se encuntra o no pertenece a la escuela"
            },
            "5902": {
                title: "El grupo no puede ser eliminado, ya que contiene calificaciones o materias",
                message: "Los grupos tienen recursos relacionados"
            },
            "5903": {
                title: "El grupos no puede cambiar de nivel, ya que este contiene, calificaciones o asistencias",
                message: "Los grupos tienen recursos relacionados"
            },
            "5904": {
                title: "El grupo no puede dejar de tener un orientador ya que existen expedientes",
                message: "Eliminar expedientes"
            },
            "5905": {
                title: "Los grupos son incompatibles",
                message: "Los grupos no son del mismo tipo, especial o normal"
            },

            // Parciales 6100 - 6199
            "6100": {
                title: "El parcial ya no puede puede ser modificado",
                message: "El parcial ya ha sido eliminado"
            },
            "6101": {
                title: "El parcial ya se encuentra registrado",
                message: "Los parciales no pueden repetirse"
            },
            "6102": {
                title: "El parcial no puede cambiar de nivel, ya que este contiene, calificaciones o asistencias",
                message: "Los parciales tienen recursos relacionados"
            },
            "6103": {
                title: "Los rangos de fechas entran en conflicto con otro parcial",
                message: "Los parciales no pueden tener fechas superpuestas"
            },
            "6104": {
                title: "El parcial no puede ser eliminado, ya que contiene calificaciones o asistencias",
                message: "Los parciales tienen recursos relacionados"
            },

            "4022": {
                title: "La escuela aun no tiene ningun parcial",
                message: "Los parciales tienen recursos relacionados"
            },
            "4024": {
                title: "La fecha ingresada no concuerda con ningun parcial",
                message: "Los parciales tienen recursos relacionados"
            },
            // Materias 
            "3001": {
                title: "El alumno ya pertenece al grupo",
                message: "Los parciales tienen recursos relacionados"
            },
            "3005": {
                title: "Las materias no tienen una relacion correcta",
                message: "El formato de tranferencia de materias no tiene la relacion correcta"
            },

            // OTROS
            "1025": {
                title: "El recurso ya no puede ser eliminado/deshactivar",
                message: "El recurso ya no puede ser eliminado/deshactivar"
            }
        }
    }

    /**
     * Muestra un mensaje de error generico
     * 
     * @param subHeader Subtitulo
     * @param Message Contenido del mensaje
     * @param Message Botones
     */
    async showGenericMessage(subHeader, Message, buttons) {

    }

    /**
     * Funcion encargada de manejar los errores de la app
     * 
     * @param err Error a ser manejado
     * @param button Boton de accion
     */
    handlerError(err, button = "Aceptar") {
        let message = this.getMessage(err)

        this.showGenericMessage(message.title, message.message, button)

        console.log(err)
    }

    /**
     * Obtenemos un mensaje 
     * 
     * @param err Error de donde se obtendran los datos
     * 
     * @returns Object Objecto de error
     */
    getMessage(err) {
        console.log(err)
        let message = {
            title: 'Se produjo un problema',
            message: 'Por favor intente de nuevo'
        }

        if (typeof err.response === "undefined") {
            return message
        }

        let error = err.response.data


        if (err.response.status == 401) {
            message.title = 'No es posible acceder'
            message.message = 'El usuario y la contraseña son incorrectos'
        }

        if (err.response.status == 404) {
            message.title = 'Recurso no encontrado'
            message.message = 'Favor de comunicarse con el soporte tecnico'
        }

        if (error.code == 2004) {
            message.title = 'El correo o el telefono ya se encuentran registrado intenta con otro'
            message.message = ''
        }

        if (error.code == 2005) {
            message.title = 'El sistema no puede quedar sin administrador'
            message.message = 'El sistema no puede quedar sin administrador'
        }

        if (error.code == 2006) {
            message.title = 'EL profesor tiene materias asignadas'
            message.message = 'EL profesor tiene materias asignadas'
        }

        if (error.code == 2007) {
            message.title = 'El asesor tiene grupos asignados'
            message.message = 'El asesor tiene grupos asignadosr'
        }

        if (error.code == 1032) {
            message.title = 'Relacion ya existente'
            message.message = 'El alumno ya esta asociado a una cuenta con el tipo de relación seleccionada'
        }

        if (error.code == 1002) {
            message.title = 'No es posible acceder'
            message.message = 'El usuario y la contraseña son incorrectos'
        }

        if (error.code == 1017) {
            message.title = 'La solicitud no puede ser procesada'
            message.message = 'Los datos enviados no tienen el formato correcto'
        }

        if (error.code == 4013) {
            message.title = 'Telefono ya registrado'
            message.message = 'El correo o el telefono ya se encuentran registrado intenta con otro'
        }

        if (error.code == 3500) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'El id de reporte no se encuntra registrado o esta erroneo'
        }

        if (error.code == 2003) {
            message.title = 'La contraseña anterior esta incorrecta'
            message.message = 'La contraseña anterior esta incorrecta'
        }


        /////////////////// RECUPERA CONTRASEÑA  4000 - 4099 /////////////////

        if (error.code == 4000) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'Tipo de metodo de recuperacion no esta disponible'
        }

        if (error.code == 4001) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'El usuario o correo no existe'
        }

        if (error.code == 4002) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'No se puede enviar el email para recuperar la contraseña'
        }

        if (error.code == 4003) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'La contraseña no es valida '
        }

        if (error.code == 4004) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'El token es invalido o ya ha expirado, solicitar de nuevo el cambio de contraseña'
        }

        if (error.code == 4005) {
            message.title = 'No se encontro el reporte solicidato'
            message.message = 'No se puede restablecer la contraseña'
        }

        ///////////////////// EXPROTABLES ///////////////////////

        if (error.code == 3500) {
            message.title = 'Reporte aun no disponible'
            message.message = 'Reporte aun no disponible'
        }

        if (error.code == 4015) {
            message.title = 'El folio de la materia ya se encuentra registrada'
            message.message = 'El folio de la materia ya se encuentra registrada'
        }

        ///////////////// CORTE DE CICLO ////////////////////

        if (error.code == 3753) {
            message.title = 'Contraseña incorrecta'
            message.message = 'Contraseña incorrecta'
        }

        if (error.code == 3750) {
            message.title = 'No tienes permisos para hacer corte'
            message.message = 'No tienes permisos para hacer corte'
        }

        if (error.code == 3751) {
            message.title = 'Ya hay un corte de ciclo en proceso'
            message.message = 'Ya hay un corte de ciclo en proceso'
        }

        if (error.code == 3752) {
            message.title = 'No se ha realizado corte del nivel superior'
            message.message = 'No se ha realizado corte del nivel superior'
        }

        if (error.code == 3753) {
            message.title = 'Contraseña incorrecta'
            message.message = 'Contraseña incorrecta'
        }

        if (error.code == 3754) {
            message.title = 'Aun no ha terminado el último ciclo escolar'
            message.message = 'Aun no ha terminado el último ciclo escolar'
        }

        if (error.code == 6000) {
            message.title = 'El folio ya se encuentra registrado'
            message.message = 'El folio ya se encuentra registrado'
        }

        if (this.errors[error.code]) {
            message.title = this.errors[error.code].title
            message.message = this.errors[error.code].message
        }

        return message
    }

    /**
     * Retornamos un mensaje usando una promesa
     * 
     * @param {*} err 
     */
    async getMessageAsPromise(err) {
        // typeof err.response.data.hasOwnProperty('type')

        if (this.isInternalError(err)) {
            console.log(err)
            return {
                title: 'Se produjo un problema',
                message: 'Por favor intente de nuevo'
            }
        }

        return this.convertBlob(err.response.data).then(ServerMessage => {

            err.response.data = ServerMessage

            return this.getMessage(err)
        })

    }

    isInternalError(err) {
        return (typeof err.response === "undefined")
    }

    /**
     * Convierte una respuesta blob en Objecto de js
     * 
     * @param {*} blob 
     */
    async convertBlob(blob) {

        return new Promise((resolve, reject) => {
            const fr = new FileReader();

            fr.onload = (e) => {
                resolve(JSON.parse(e.target.result))
            };

            fr.readAsText(blob);

        })
    }
}

export default Feedback;