import React from 'react'
import { Box, Grid, Stack } from '@mui/material'

import { useSelector, useDispatch } from "react-redux";

import { setCurrentTab } from '../../../store/slices/background'

import TopScoreSubjectUI from "../../../components/Chart/TopScoreSubject"
import TopAbsenceSubjects from "../../../components/Chart/TopAbsenceSubjects"
import TopAbsenceGroups from "../../../components/Chart/TopAbsenceGroups"
import TopScoreGroups from "../../../components/Chart/TopScoreGroups"
import TopBadScoreGroups from "../../../components/Chart/TopBadScoreGroups"
import TopReportsGroups from "../../../components/Chart/TopReportsGroups"
import Stats from "../../../components/Chart/Stats"
import StatsIndex from "../../../components/Chart/StatsIndex"
import ExportableModal from "../../../components/Chart/ExportableModal"

import BestScoreSubjectIndicator from "../../../components/Chart/IndicatorsSubjects/BestScoreSubjectIndicator"

import { faUserGraduate, faChalkboardTeacher, faChartLine, faDoorOpen, faChartBar } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LastAssitsListProfessorsIndicator from '../Professors/Components/LastAssitsListProfessorsIndicator';
import BestScoreStudentsIndicator from '../Students/components/BestScoreStudentsIndicator';
import { useAuth } from '../../../hooks';
import { selectSchoolById } from '../../../store/slices/background';

/**
 * Pagina de inicio del director
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const DirectorHome = (props) => {

    const history = useHistory()
    const match = useRouteMatch()
    const dispatch = useDispatch()
    const Auth = useAuth()

    ///////////////// SHARES STAE /////////////////
    const school = useSelector(selectSchoolById(Auth.getUser().school_id))

    ///////////////// LOCAL STATE  ///////////////

    const [open, setOpen] = React.useState(false);

    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { school_id: null, top_size: 7 }
    });

    /////////////// ACTIONS ////////////////

    const onCloseExportableModal = () => {
        setOpen(false);
    };

    /**
     * Exportar la informacion de los Tops Cards
     * 
     * @param {*} exportOptions 
     */
    const onExport = (exportOptions) => {
        let query = { ...reportData.query, school_id: Auth.getUser().school_id }
        let pre = { ...reportData, query }
        let data = { ...pre, ...exportOptions }

        setReportData(data)
        setOpen(true);
    }

    /**
     * Cambiar de pagina usando los stats
     * 
     * @param {string} details Url de la pagina a donde se dirigira
     */
    const onChangeTab = (details) => {
        history.push({ pathname: `/director${details}` });
    }

    const goStudents = () => {
        history.push({ pathname: `/director/alumnos` });
    }

    const goProfessors = () => {
        history.push({ pathname: `/director/profesores` });
    }


    return (
        <Box>
            <Grid
                container
                spacing={2}
                sx={{ marginBottom: 5 }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Grid item sx={12} sm={6} md={"auto"}>
                    <Stats
                        icon={faUserGraduate}
                        value={school?.student_population} title={"Total de alumnos"}
                        sx={{
                            background: "#5c94d2",
                            color: 'white'
                        }}
                        onClick={goStudents}
                    />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <Stats
                        icon={faChalkboardTeacher}
                        value={school?.teaching_population} title={"Total de maestros"}
                        sx={{
                            background: "#28b569",
                            color: 'white'
                        }}
                        onClick={goProfessors}
                    />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <Stats
                        icon={faChartLine}
                        value={school?.average_rating} title={"Promedio escolar"}
                        sx={{
                            background: "#fcc404",
                            color: 'white'
                        }}
                    />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <Stats
                        icon={faDoorOpen}
                        value={school?.total_absence} title={"Total de faltas"}
                        sx={{
                            background: "#c41c1c",
                            color: 'white'
                        }}
                    />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <StatsIndex
                        good={school?.average_presence}
                        bad={school?.average_absence}
                        title="Índice de asistencia"
                        icon={faChartBar}
                        iconColor={green[500]}>
                    </StatsIndex>
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <BestScoreSubjectIndicator />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <LastAssitsListProfessorsIndicator />
                </Grid>
                <Grid item sx={12} sm={6} md={"auto"}>
                    <BestScoreStudentsIndicator level={-1} />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item >
                    <TopAbsenceSubjects export={onExport} goDetails={onChangeTab}></TopAbsenceSubjects>
                </Grid>
                <Grid item >
                    <Box>
                        <TopAbsenceGroups export={onExport} goDetails={onChangeTab}></TopAbsenceGroups>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <TopScoreSubjectUI export={onExport} goDetails={onChangeTab}></TopScoreSubjectUI>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <TopScoreGroups export={onExport} goDetails={onChangeTab}></TopScoreGroups>
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <TopBadScoreGroups export={onExport} goDetails={onChangeTab}></TopBadScoreGroups>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <TopReportsGroups export={onExport} goDetails={onChangeTab}></TopReportsGroups>
                    </Box>
                </Grid>
            </Grid>
            <ExportableModal
                show={open}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}

export default DirectorHome