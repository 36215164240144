import React from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem, Button, CardActions} from '@mui/material'

import { useSelector, useDispatch } from "react-redux";
import { getTopScoreSubject, setCurrentTab } from '../../../store/slices/background'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import * as Plugin from '../../../libs/chartist-bar-labels.js'

const TopScoreSubjects = (props) => {

    const topScoreSubject = useSelector(getTopScoreSubject)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onExport = () => {
        props.export({
            report: "top-score-subjects",
            title: "Top calificaciones por materias"
        })
    }

    ///////////////////////////// CHART CONFIGURATION ////////////////////

    const dispatch = useDispatch()

    let data = {
        labels: [],
        series: [
            []
        ]
    }
  
    let options = {
        seriesBarDistance: 15,
        reverseData: true,
        horizontalBars: true,
        stackBars: true,
        chartPadding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 0
        },
        axisX: {
            onlyInteger: false
        },
        axisY: {
          offset: 130,
          showGrid: false,
        },
        scaleMinSpace: 100,
        plugins: [
            Chartist.plugins.ctBarLabels({
                labelClass: 'ct-bar-label-black',
                position: {
                    x: function (data) {
                      return data.x1 + 205
                    }
                },
                labelOffset: {
                    y: 5
                },
                labelInterpolationFnc: function (text) {
                    if (text == undefined) {
                        return 0
                    }
                    return text
                }
            })
        ]
    }
  
    const listeners = {
        "draw" : (data) => {
            if(data.type === 'bar') {
                const barWidth = 20

                data.element.attr({
                  style: `stroke-width: ${barWidth}px; stroke: #ec7c34`
                });
            }
        }
    }

    const onDetailClick = () => {
        props.goDetails("/materias")
    }

    /**
     * Top de materias de calificaciones
     * @returns 
     */
    function topSubjectScoreUI () {
        let filas = []
        let serieOne = []

        for (const item of topScoreSubject) {

            data.labels.push(`${item.title} ${item.levelName}`)
            serieOne.push(item.average_rating)

            filas.push({
                id: item.catalog_subject_id,
                title: item.title,
                calification: item.average_rating,
                level: item.levelName
            })
        }

        data.series[0] = serieOne

        return (
            <Card sx={{ width: 360 }} variant="outlined">
                <CardHeader
                    title="Top calificaciones por materias"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 18
                        }
                    }}
                    sx={{
                        paddingBottom: 0
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />
                <ChartistGraph className={'ct-chart ct-square'} data={data} options={options} type="Bar" listener={listeners}/>

                <CardActions>
                    <Button size="small" variant="outlined" sx={{width: "100%"}} onClick={onDetailClick}>Ver mas</Button>
                </CardActions>
            </Card>
        )
    }


    return (topSubjectScoreUI())
}

export default TopScoreSubjects
