import Feedback from "../../../service/Feedback";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import Authentication from "../../../service/Login";
import Services from "../../../service/Connection";
import * as _ from 'lodash';
import { GROUPSUI_EXPIRE_TIME } from "../../../service/const";
import { selectEntitiesSubjectcatalogs, upsertManySubjectcatalog } from "../entities/subjectcatalogs";
import { selectSubjectsByGroupId, upsertManySubjects } from "../entities/subjects";
import { selectAllGroups, selectAllGroupsEntities } from "../entities/groups"
import { addManyUsers, selectAllUsers, selectEntitiesUsers, upsertManyUsers } from "../entities/users";
import { upsertManyStudents } from "../entities/students";
import { addManyGroupsStudents } from "../entities/groups_students";
import { selectItemSelected } from "./uiSlice";

/**
 * Adaptador para grupos
 */
const itemsAdapter = createEntityAdapter({
    selectId: (item) => item.ui.group_id,
    sortComparer: (a, b) => a.ui.group_id - b.ui.group_id
})

/**
 * Slice para el settings UI
 */
export const groupsUIItemsSlice = createSlice({
    name: 'groupsUI/items',
    initialState: itemsAdapter.getInitialState(),
    reducers: {
        addOneItem: itemsAdapter.addOne,
        addManyItems: itemsAdapter.addMany,
        setAllItems: itemsAdapter.setAll,
        upsertManyItems: itemsAdapter.upsertMany,
        itemUpdated: itemsAdapter.updateOne,
        removeOneItem: itemsAdapter.removeOne
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return itemsAdapter.getInitialState()
        })

        //////////////// GROUPS INFORMATION ///////////////////////////////

        builder.addCase(loadGroupsUI.fulfilled, (state, { payload }) => {
            const { group_Id } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[group_Id].servers.groups = { ...state.entities[group_Id].servers.groups, ...newState }
        })
        builder.addCase(loadGroupsUI.pending, (state, { meta }) => {
            const { group_Id } = meta

            state.entities[group_Id].servers.groups.statusServer = 'pending'
        })
        builder.addCase(loadGroupsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { group_Id, feedback } = action.payload

                state.entities[group_Id].servers.groups.statusServer = 'rejected'
                state.entities[group_Id].servers.groups.feedback = feedback
            }
        })

        //////////////// SUBJECTS INFORMATION ///////////////////////////////

        builder.addCase(loadGroupSubjectsUI.fulfilled, (state, { payload }) => {
            const { groupId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[groupId].servers.subjects = { ...state.entities[groupId].servers.subjects, ...newState }
        })
        builder.addCase(loadGroupSubjectsUI.pending, (state, { meta }) => {
            const { groupId } = meta

            state.entities[groupId].servers.subjects.statusServer = 'pending'
        })
        builder.addCase(loadGroupSubjectsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { groupId, feedback } = action.payload

                state.entities[groupId].servers.subjects.statusServer = 'rejected'
                state.entities[groupId].servers.subjects.feedback = feedback
            }
        })
        ///////////////////////// STUDENTS INFO
        builder.addCase(loadGroupStudentsUI.fulfilled, (state, { payload }) => {
            const { groupId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[groupId].servers.students = { ...state.entities[groupId].servers.students, ...newState }
        })
        builder.addCase(loadGroupStudentsUI.pending, (state, { meta }) => {
            const { groupId } = meta

            state.entities[groupId].servers.students.statusServer = 'pending'
        })
        builder.addCase(loadGroupStudentsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { groupId, feedback } = action.payload

                state.entities[groupId].servers.students.statusServer = 'rejected'
                state.entities[groupId].servers.students.feedback = feedback
            }
        })
        ///////////// ASSESSOR INFORMARION

        builder.addCase(loadGroupAssessorUI.fulfilled, (state, { payload }) => {
            const { groupId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[groupId].servers.assessors = { ...state.entities[groupId].servers.assessors, ...newState }
        })
        builder.addCase(loadGroupAssessorUI.pending, (state, { meta }) => {
            const { groupId } = meta

            state.entities[groupId].servers.assessors.statusServer = 'pending'
        })
        builder.addCase(loadGroupAssessorUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { groupId, feedback } = action.payload

                state.entities[groupId].servers.assessors.statusServer = 'rejected'
                state.entities[groupId].servers.assessors.feedback = feedback
            }
        })

    }
});

////////////////////// SELECTORS //////////////////////////

const globalizedSelectors = itemsAdapter.getSelectors((state) => state.groupsUI.items)

/**
 * Selector pare recuperar todos los items de grupos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectAllItems = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para recuperar las entidades de grupos de los items
 * existentes
 */
export const selectAllGroupsItems = createSelector(
    selectAllItems,
    selectAllGroupsEntities,
    (allGroupsItems, allGroupsEntities) => allGroupsItems.map(i => allGroupsEntities[i.ui.group_id])
)

/**
 * Selector para recuperar la entidad del grupo seleccionado
 */
export const selectGroupByItemSelected = createSelector(
    selectAllGroupsEntities,
    selectItemSelected, 
    (allGroupsEntities, groupId) => groupId ? allGroupsEntities[groupId]: null
)

/**
 * Selector para recuperar las entitades de materias del grupo
 * enviado por parametro
 * 
 * @param number groupId 
 * 
 * @returns 
 */
export const selectSubjectsWithDataByGroup = createSelector(
    selectSubjectsByGroupId,
    selectEntitiesSubjectcatalogs,
    selectEntitiesUsers,
    (groupSubjects, allCatalogsubjectEntities, allProfessorsEntities) => {

        return groupSubjects.map((i) => ({
            ...i,
            catalog: allCatalogsubjectEntities[i.catalog_subject_id],
            professor: allProfessorsEntities[i.professor_id]
        }))
    }
) 

/**
 * Selector para recuperar los datos del modal de 
 * se materias para cabiar de profesor
 * 
 * @param {*} state 
 * @returns 
 */
export const selectChangeProfessorSubjectModalData = (id) => {
    return (state) => {

        if (!id) {
            return null
        }

        let subjectcatalogsEntities = Object.values(state.entities.subjectcatalogs.byId)
        let usersEntities = selectAllUsers(state)
        let groupsEntities = selectAllGroups(state)

        let SUBJECT = Object.values(state.entities.subjects.byId).find(i => i.subject_id == id)

        if (!SUBJECT) {
            return null
        }

        let subject = Object.assign({}, SUBJECT)

        let catalog = subjectcatalogsEntities.find(sc => subject.catalog_subject_id == sc.catalog_subject_id)
        let professor = usersEntities.find(sc => subject.professor_id == sc.user_id)
        let group = groupsEntities.find(sc => subject.group_id == sc.group_id)

        subject.catalog = catalog
        subject.professor = professor
        subject.group = group

        return subject
    }
}

/**
 * Cargar informacion del asesor del grupo
 */
/*export const loadAssessor = createAsyncThunk(
    'groupsUI/item/fetch/data',
    async (schoolId, thunkAPI) => {

        let FeedbackService = new Feedback()
        let Auth = new Authentication()
        try {
            let orientador = await Services.getOrientadoresBySchool(schoolId).then(res => res.data.data)
            let users = orientador.map(i => ({
                ...i,
                user_id: Auth.getUserID(i)
            }))

            thunkAPI.dispatch(setAllUsers(users))
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().usersUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)*/

////////////////////////  THUNKS  ///////////////////////////

/**
 * Cargar informacion de las materias del grupo
 */

export const loadGroupsUI = createAsyncThunk(
    'groupsUI/item/fetch/groups',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()

        const groupId  = selectItemSelected(state)
        
        try {
            const subjects = await Services.selectSubjectsByGroupID(groupId).then(res => res.data.data)

            let catalogs = []

            for (const subject of subjects) {
                const speshialGroups = await Services.getSubjectCatalogById(subject.catalog_subject_id).then(res => res.data.data)
                catalogs.push(speshialGroups)
            }

            return {
                groupId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const groupId = state.groupsUI.ui.groupSelected

        let { didInvalidate, expireIn } = state.groupsUI.items.entities[groupId].servers.groups

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            groupId: getState().groupsUI.ui.groupSelected
        }
    }
}
)
/**
 * Cargar materias del grupo seleccionado
 */
export const loadGroupSubjectsUI = createAsyncThunk(
    'groupsUI/item/fetch/subjects',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()
        
        const state = thunkAPI.getState()
        const groupId = selectItemSelected(state)

        try {
            const subjects = await Services.getSubjectByGroup(groupId).then(res => res.data.data)

            let catalogs = []
            let professors = []

            for (const subject of subjects) {
                const subjectCatalogs = await Services.getSubjectCatalogById(subject.catalog_subject_id).then(res => res.data.data)
                catalogs.push(subjectCatalogs)

                if (subject.professor_id) {
                    const professor = await Services.getProfessorsById(subject.professor_id).then(res => res.data.data)

                    professor['user_id'] = subject.professor_id
                    professors.push(professor)
                }
            }
            thunkAPI.dispatch(upsertManySubjects(subjects))
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogs))
            thunkAPI.dispatch(upsertManyUsers(professors))

            return {
                groupId,
                subjects,
                professors,
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

         const groupId = selectItemSelected(state)

        let { didInvalidate, expireIn } = state.groupsUI.items.entities[groupId].servers.subjects

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            groupId: selectItemSelected(getState())
        }
    }
}
)
/**
 * Cargar alumnos del grupo seleccionado                                                    
 */
export const loadGroupStudentsUI = createAsyncThunk(
    'groupsUI/item/fetch/students',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()
        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const groupId = state.groupsUI.ui.groupSelected

        try {
            const students = await Services.getStudentsByGroup(groupId).then(res => res.data.data)

            const groupsStudents = students.map(i => {
                return {
                    group_id: groupId,
                    student_id: i.student_id
                }
            })

            thunkAPI.dispatch(upsertManyStudents(students))
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents))

            return {
                groupId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const groupId = state.groupsUI.ui.groupSelected

        let { didInvalidate, expireIn } = state.groupsUI.items.entities[groupId].servers.students

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            groupId: getState().groupsUI.ui.groupSelected
        }
    }
}
)
/**
 * Cargar orientador del grupo seleccionado
 */
export const loadGroupAssessorUI = createAsyncThunk(
    'groupsUI/item/fetch/assessor',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        const state = thunkAPI.getState()
        const groupId = state.groupsUI.ui.groupSelected

        try {
            const assessors = await Services.getAssesorsActivedBySchool(schoolId).then(i => i.data.data)
            thunkAPI.dispatch(addManyUsers(assessors))


            return {
                groupId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                groupId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const groupId = state.groupsUI.ui.groupSelected

        let { didInvalidate, expireIn } = state.groupsUI.items.entities[groupId].servers.subjects

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            groupId: getState().groupsUI.ui.groupSelected
        }
    }
}
)

/**
 * Selector para recuperar el status server del modal de materias
 */
export const selectSubjectstatusServerItemSelected = (store) => {
    const groupSelected = store.groupsUI.ui.groupSelected
    let item = globalizedSelectors.selectById(store, groupSelected)

    if (groupSelected) {
        return item.servers.subjects.statusServer
    }
    return 'idle'
}

export const selectStudentStatusServerItemSelected = (store) => {
    const groupSelected = store.groupsUI.ui.groupSelected
    let item = globalizedSelectors.selectById(store, groupSelected)

    if (groupSelected) {
        return item.servers.students.statusServer
    }
    return 'idle'
}


export const {
    addOneItem, addManyItems, upsertManyItems, itemUpdated, setAllItems,
    removeOneItem,
} = groupsUIItemsSlice.actions;

export default groupsUIItemsSlice.reducer;