import { createSlice } from "@reduxjs/toolkit";

const emptyState = {
    byId: {}
}

/**
 * Slice para los parciales
 */
export const ReportsCatalogsSlice = createSlice({
    name: 'reportscatalogs',
    initialState: emptyState,
    reducers: {
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setReportCatalogs: (state, action) => {
            state.list = action.payload;
        },
        /**
         * Agrega parciales a la coleccion
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyReportCatalogs: (state, action) => {
            state.byId = { ...state.byId, ...action.payload }
        },
        /**
         * Agrega un parcial a la coleccio
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneReportCatalog: (state, action) => {
            if (!state.byId[action.payload.catalog_report_id]) {
                state.byId[action.payload.catalog_report_id] = action.payload
            }
        },
        updateReportCatalog: (state, action) => {
            const { partial_id, partial, start_date, end_date, limit_date, level } = action.payload
            // find this todo in the list
            const parcial = state.list.find(t => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial;
            parcial.start_date = start_date;
            parcial.end_date = end_date;
            parcial.limit_date = limit_date;
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        deleteOneReportCatalogs: (state, action) => {
            delete state.byId[action.payload.catalog_report_id]
        },
        /**
 * Si el elemento existe realziara una actualziacion superficial
 * y los campos seran fucionados, si el elemento no esta precente este
 * sera agregado a las entidades
 * 
 * @param {*} state 
 * @param {*} action 
 */
        upsertOneReportCatalog: (state, action) => {
            let { catalog_report_id } = action.payload

            state.byId[catalog_report_id] = { ...state.byId[catalog_report_id], ...action.payload }
        },
        /**
        * Si el elemento existe realizara una actualziacion superficial
        * y los campos seran fucionados, si el elemento no esta precente este
        * sera agregado a las entidades
        * 
        * @param {*} state 
        * @param {*} action 
        */
        upsertManyReportsCatalogs: (state, action) => {
            for (const catalog of action.payload) {
                let { catalog_report_id } = catalog

                state.byId[catalog_report_id] = { ...state.byId[catalog_report_id], ...catalog }
            }
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setReportCatalogs, addManyReportCatalogs, deleteOneReportCatalog,
    updateReportCatalog,
    addOneReportCatalog, upsertOneReportCatalog, upsertManyReportsCatalogs
} = ReportsCatalogsSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar todos los parciales de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectReportsCatalogs = (state) => state.entities.reportscatalogs.byId;


/**
 * Selector para recuperar todos los catalogos de reportes
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectAllReportsCatalogs = (state) => {
    return Object.values(state.entities.reportscatalogs.byId)
}

/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectReportsCatalogByID = (reportCatalogId) => {
    return (state) => {

        let data = state.entities.reportscatalogs.byId[reportCatalogId]

        return data
    }
}


/**
 * Selector para recuperar catlogo de materias como array
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectReportsCatalogsAsArray = () => {
    return (state) => {

        let data = Object.values(state.entities.reportscatalogs.byId)

        return data
    }
}

export default ReportsCatalogsSlice.reducer;
