import { Card, CardContent, CardHeader, Divider, Stack } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks";
import useFeedback from "../../../hooks/useFeedback";
import { selectSettingBySchoolId } from "../../../store/slices/entities/settings";
import InputChange from "./InputChange"
import { saveSetting, selectStatusOperation } from '../../../store/slices/settingsUI';

/**
 * Tarjeta que contiene las configuraciones de notificaciones
 * 
 * @returns 
 */
const NotificationsSettingsCard = () => {

    const feedbackApp = useFeedback()
    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    ///////////// SHARE STATE ////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)

    //////////// LOCAL STATE ////////////

    /**
     * Estado de las configuraciones para las notificaciones
     */
    let filesReportLimiteActive = config.find(res => res.key === 'files-report-limite-active')
    let calificationMinimum = config.find(res => res.key === 'calification-minimum')
    let calificationMinimumPositive = config.find(res => res.key === 'calification-minimum-positive')
    let remainingDays = config.find(res => res.key === 'days-startend-campaign-scores')


    //////////// ACTIONS /////////////

    /**
     * Funcion para actualizar las configuraciones
     * 
     * @param string value
     * @param string settingKey
     */
    const onUpdateInputSetting = (value, settingKey) => {
        let setting = config.find(res => res.key === settingKey)

        dispatch(saveSetting({ settingId: setting.configuration_id, value }))
            .unwrap()
            .then((res) => {
                feedbackApp.showFeedback({
                    title: 'Configuración actualizada correctamente',
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    return (
        <Card
            sx={{
                height: 376,
            }}
        >
            <CardHeader
                title="Ajustes de notificaciones"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 2
                }}
            />
            <CardContent>
                <Stack
                    spacing={2}
                    divider={<Divider variant="middle" orientation="horizontal" />}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <InputChange
                        settingKey={'files-report-limite-active'}
                        value={filesReportLimiteActive?.value}
                        onChangeSetting={onUpdateInputSetting}
                        inputProps={{
                            id: "reporte",
                            name: "reporte",
                            label: "Notificación de reportes",
                            variant: "standard",
                            fullWidth: true
                        }}
                        status={statusOperation}
                        tooltip={filesReportLimiteActive?.description}
                    />

                    <InputChange
                        settingKey={'calification-minimum'}
                        value={calificationMinimum?.value}
                        onChangeSetting={onUpdateInputSetting}
                        inputProps={{
                            id: "calificacionMin",
                            name: "calificacionMin",
                            label: "Calificación aprobatoria",
                            variant: "standard",
                            fullWidth: true
                        }}
                        status={statusOperation}
                        tooltip={calificationMinimum?.description}
                    />
                    <InputChange
                        settingKey={'calification-minimum-positive'}
                        value={calificationMinimumPositive?.value}
                        onChangeSetting={onUpdateInputSetting}
                        inputProps={{
                            id: "calificacionMax",
                            name: "calificacionMax",
                            label: "Calificación de excelencia",
                            variant: "standard",
                            fullWidth: true
                        }}
                        status={statusOperation}
                        tooltip={calificationMinimumPositive?.description}
                    />
                    <InputChange
                        settingKey={'days-startend-campaign-scores'}
                        value={remainingDays?.value}
                        onChangeSetting={onUpdateInputSetting}
                        inputProps={{
                            id: "reminder",
                            name: "reminder",
                            label: "Aviso previo a captura de calificaciones",
                            variant: "standard",
                            fullWidth: true
                        }}
                        status={statusOperation}
                        tooltip={remainingDays?.description}
                    />
                </Stack>
            </CardContent>
        </Card>
    )
}

export default NotificationsSettingsCard