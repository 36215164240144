
import React, { Fragment } from 'react'
import { Grid } from '@mui/material'
import * as constants from "../../../../../service/const";
import { selectLevelsAvaiables } from '../../../../../store/slices/background';
import { useSelector } from 'react-redux';
import WorstAbsenceStudentsIndicator from '../WorstAbsenceStudentsIndicator';

/**
 * Componente que muestra los alumnos
 * con mas faltas
 * 
 * @param {*} props 
 * @returns 
 */
const WorstAbsenceStudentsLine = (props) => {

    //////////////// SHARES STATE //////////////

    const levelsAvailables = useSelector(selectLevelsAvaiables)

    let themes = {}

    themes[constants.LEVEL_KINDERGARTEN] = {
        mainColor: "#b59dfa",
        secundaryColor: "#7434a3"
    }

    themes[constants.LEVEL_ELEMENTARY_SCHOOL] = {
        mainColor: "#33ccff",
        secundaryColor: "#0099cc"
    }

    themes[constants.LEVEL_HIGH_SCHOOL] = {
        mainColor: "#3c5424",
        secundaryColor: "#04b454"
    }

    themes[constants.LEVEL_PREPARATORY] = {
        mainColor: "#fc4444",
        secundaryColor: "#fc7474"
    }

    themes[constants.LEVEL_UNIVERSITY] = {
        mainColor: "#fc6404",
        secundaryColor: "#fcb47c"
    }

    return (
        <Grid container spacing={2} sx={{ marginBottom: 5 }} direction="row" justifyContent="space-between" alignItems="center">
            {
                levelsAvailables.map(level => (
                    <Grid item>
                        <WorstAbsenceStudentsIndicator key={level.key} level={level.key} theme={themes[level.key]} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default WorstAbsenceStudentsLine


