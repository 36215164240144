import React from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem, Button, CardActions, Typography, Box} from '@mui/material'
import { useSelector, useDispatch } from "react-redux";
import { getTopAbsenceGroups, setCurrentTab } from '../../../store/slices/background'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import * as Plugin from '../../../libs/chartist-bar-labels.js'

const TopAbsenceGroups = (props) => {

    const topScoreSubject = useSelector(getTopAbsenceGroups)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onExport = () => {
        props.export({
            report: "top-absence-groups",
            title: "Top inasistencias por grupo"
        })
    }

    ///////////////////////////// CHART CONFIGURATION ////////////////////

    const dispatch = useDispatch()

    let data = {
        labels: [],
        series: [
            []
        ]
    }
  
    let options = {
        seriesBarDistance: 15,
        reverseData: true,
        horizontalBars: true,
        stackBars: true,
        chartPadding: {
            top: 0,
            right: 50,
            bottom: 0,
            left: 0
        },
        axisX: {
            onlyInteger: true
        },
        axisY: {
            offset: 130,
            showGrid: false,
        },
        scaleMinSpace: 100,
        plugins: [
            Chartist.plugins.ctBarLabels({
                labelClass: 'ct-bar-label-black',
                position: {
                    x: function (data) {
                      return data.x1 + 205
                    }
                },
                labelOffset: {
                    y: 5
                },
                labelInterpolationFnc: function (text) {
                    if (text == undefined) {
                        return 0
                    }
                    return text
                }
            })
        ]
    }

    const listeners = {
        "draw" : (data) => {
            if(data.type === 'bar') {
                const barWidth = 20

                data.element.attr({
                  style: `stroke-width: ${barWidth}px; stroke: #ffac20`
                });
            }
        }
    }
    
    const onDetailClick = () => {
        props.goDetails("/grupos")
    }


    let filas = []
    let serieOne = []

    for (const item of topScoreSubject) {

        data.labels.push(`${item.title} ${item.levelName}`)
        serieOne.push(item.total_absence)

        filas.push({
            id: item.group_id,
            title: item.title,
            total: item.total_absence,
            level: item.levelName
        })
    }

    data.series[0] = serieOne

    const topAbsenceGroupsUI = () => {
        
        if (topScoreSubject.length > 0) {
            return (
                <ChartistGraph className={'ct-chart ct-square'} data={data} options={options} type="Bar" listener={listeners}/>
            )
        }

        return (
            <Box sx={{height: 200, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <Typography variant="body1" gutterBottom component="div">
                    Tus datos apareceran aqui
                </Typography>
            </Box>
        )
    }


    return (
        <Card sx={{ width: 360}} variant="outlined">
            <CardHeader
                title="Top inasistencias por grupo"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 0
                }}
                action={
                    <div>
                        <IconButton aria-label="settings" id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onExport}>Exportar</MenuItem>
                        </Menu>
                    </div>
                }
            />
                {topAbsenceGroupsUI()}
            <CardActions>
                <Button size="small" variant="outlined" sx={{width: "100%"}} onClick={onDetailClick}>Ver mas</Button>
            </CardActions>
        </Card>
    )
}

export default TopAbsenceGroups
