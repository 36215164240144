import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * Adaptador para grupos
 */
const groupsAdapter = createEntityAdapter({
    selectId: (group) => group.group_id,
    sortComparer: (a, b) => a.group_id - b.group_id 
})

/**
 * Slice para la entidades de grupos
 */
const  groupsSlice = createSlice({
    name: "noticesModalUI/entities/groups",
    initialState: groupsAdapter.getInitialState(),
    reducers: {
        addOneGroup: groupsAdapter.addOne,
        addManyGroups: groupsAdapter.addMany,
        upsertManyGroups: groupsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return groupsAdapter.getInitialState()
        })
        /**
         * Limpiar los datos al cerar el modal
         */
        builder.addCase('noticesModalUI/reset', (state, action) => groupsAdapter.getInitialState())
    }
})

export const { addOneGroup, addManyGroups, upsertManyGroups } = groupsSlice.actions

//////////////////////// SELECTORES ///////////////////////

const globalizedSelectors = groupsAdapter.getSelectors((state) => state.noticesModalUI.entities.groups)

/**
 * Selecciona todos los grupos
 */
export const selectAllGroups = (store) => globalizedSelectors.selectAll(store)


export default groupsSlice.reducer