import React, { useState } from "react";
import { Button, Card, CardContent, FormControl, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import "./Login.css";
import RegistrarModal from "../../components/Modal/ModalRegistrar";
import googleApp from './img/playstore.png';
import appleApp from './img/appstore.png';
import Feedback from "../../service/Feedback"
import { useAuth } from "../../hooks";
import { setToken, setUser } from "../../store/slices/auth";
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import { addManySettings } from "../../store/slices/entities/settings";
import { convertArrayToObject } from "./../../libs/utils"
import { addOneSchool } from "../../store/slices/entities/schools";
import HeaderPage from "../../components/HeaderPage";
const Login = () => {

    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    }

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const [feedback] = useState(() => new Feedback());

    ///////////////////////// ESTADO DEL COMPONENTE ////////////////////////////////

    const [password, setPassword] = useState('');
    const [telefono, setTelefono] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false)

    const history = useHistory();
    const dispatch = useDispatch();
    const Auth = useAuth();


    if (Auth.getUser()) {
        const route = Auth.getHomeRoute()

        return <Redirect to={route} />;
    }


    /**
     * Funcion para logear al usuario
     */
    const enviarDatos = () => {

        if (telefono !== '' && password !== '') {

            setLoading(true)

            Auth.login(telefono, password).then(response => {
                dispatch(addManySettings(response.setting))

                if (response.school != null) {
                    dispatch(addOneSchool(response.school))
                }

                dispatch(setToken(response.token))
                dispatch(setUser(response.userData))

                const route = Auth.getHomeRoute()

                history.push({ pathname: route })

            }).catch(err => {
                console.log(err)
                let message = feedback.getMessage(err)
                console.log(message)
                showFeedback(message.message)
            }).then(() => {
                setLoading(false)
            })

        } else {
            showFeedback("Datos incompletos")
        }
    }

    const onGoRecoverAccount = () => {
        history.push({ pathname: "/recuperar_cuenta" });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enviarDatos()
        }
    }


    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "#3b3b3b", height: 800, margin: 0 }}>

            <HeaderPage />

            <Grid container spacing={4} direction="column" justifyContent="space-around" alignItems="center"
                sx={{ pt: 8 }}
            >
                <Grid item xl={12} sm={6} md={6} xs={12} style={{ textAlign: "center" }}>
                    <img style={{ width: "350px" }} src={`${process.env.PUBLIC_URL}/images/logo-horizontal-eficaz.png`} alt="Login" />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h4" component="div" style={{ color: "#fff" }}>
                            Tu administrador personal
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xl={12} sm={4} md={4} xs={12}>
                    <Card style={{ textAlign: "center" }} sx={{ maxWidth: 400, width: 400 }}>
                        <CardContent>
                            <Box>
                                <Typography
                                    variant="h4"
                                    component="div"
                                    style={{ width: "100%", color: "#000" }}
                                >
                                    Iniciar sesión
                                </Typography>
                            </Box>

                            <Box>
                                <FormControl sx={{ mb: 1 }} fullWidth>
                                    <TextField
                                        helperText={telefono === "" ? "Campo requerido" : " "}
                                        id="telefono"
                                        name="telefono"
                                        label="Teléfono"
                                        type="text"
                                        size="small"
                                        autoComplete="current-password"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        value={telefono}
                                        onChange={(event) => {
                                            setTelefono(event.target.value);
                                        }}
                                        error={telefono === ""}
                                        onKeyDown={handleKeyDown}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl sx={{ mb: 1 }} fullWidth >
                                    <TextField
                                        helperText={password === "" ? "Campo requerido" : " "}
                                        id="password"
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        size="small"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        value={password}
                                        onChange={(event) => {
                                            setPassword(event.target.value);
                                        }}
                                        error={password === ""}
                                        fullWidth
                                        onKeyDown={handleKeyDown}
                                    />
                                </FormControl>
                            </Box>
                            <Grid item xs={12} sx={{ marginBottom: 1 }}>

                                <LoadingButton
                                    loading={loading}
                                    endIcon={<SendIcon />}
                                    loadingPosition="end"
                                    variant="contained"
                                    onClick={enviarDatos}
                                >
                                    Ingresar
                                </LoadingButton>

                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="small"
                                    onClick={onGoRecoverAccount}
                                >
                                    Recuperar contraseña
                                </Button>

                            </Grid>
                            <Box>
                                <Typography variant="caption" component="div" >
                                    v {`${process.env.REACT_APP_VERSION_APP} ${process.env.REACT_APP_NAME}`}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid item xl={12} sm={12} md={12} xs={12} style={{ textAlign: "center", marginTop: "80px" }}>
                <div id="footer">
                    <div className="container">
                        <h3>Descarga la aplicación</h3>
                        <div className="tienda">
                            <div className="playstore">
                                <a href='https://play.google.com/store/apps/details?id=com.eficaz.appmovil' target="_blank" rel="noreferrer">
                                    <img alt='Disponible en Google Play' style={{ width: "200px" }} src={googleApp} />
                                </a>
                            </div>
                            <div className="appstore">
                                <a href='https://apps.apple.com/mx/app/eficaz/id1498693599' target="_blank" rel="noreferrer">
                                    <img alt='Disponible en App Store' style={{ width: "250px" }} src={appleApp} />
                                </a>
                            </div>
                        </div>
                        <div className="copyright">
                            &copy; Copyright <strong><span>Eficaz</span></strong>. All Rights Reserved
                        </div>
                        <div className="copyright">
                            <strong><span><Link to="/aviso-privacidad" target="_blank" rel="noopener noreferrer">Aviso de privacidad</Link></span></strong>
                        </div>
                    </div>
                </div>
            </Grid>

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
                action={
                    <Button color="inherit" size="small" onClick={closeFeedback}>
                        X
                    </Button>
                }
            />

        </Box>
    );
};

export default Login;
