import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import Feedback from "../../../../service/Feedback";
import Authentication from "../../../../service/Login";
import Connection from "../../../../service/Connection";
import { selectAllUsers, selectUsers, upsertManyUsers } from "../entities/usersSlice";
import _, { filter } from "lodash";

const emptyState = {
    steps: {
        users: {
            filters: {
                all: false,
                professors: false,
                administrators: false,
                parents: false,
                assessors: false,
                directors: false,
                spokesman: false,
            },
            selected: [],
            operationStatus: "idle",
            didInvalidate: true
        }
    },
    currentStep: 0,
    completedSteps: {}
}

/**
 * Reductor para los modos de ditribuccion
 */
export const distributinModesSlice = createSlice({
    name: "noticesModalUI/distributinModes/users",
    initialState: emptyState,
    reducers: {
        /**
         * Ir hacia adelante en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        nextStep: (store, {payload}) => {
            const currentStep = store.currentStep
            const nextStep = store.currentStep + 1

            store.currentStep = nextStep
            store.completedSteps = {...store.completedSteps, [currentStep]: payload}
        },
        /**
         * Ir hacia atras en el strapper
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        previusStep: (store, {payload}) => {
            const stepsCompleted = Object.keys(store.completedSteps)
        
            const nextStep = stepsCompleted.length - 1
    
            let currentCompleted = {...store.completedSteps}
    
            delete currentCompleted[parseInt(stepsCompleted[nextStep])]
    
            store.currentStep = parseInt(stepsCompleted[nextStep])
            store.completedSteps = currentCompleted
        },
        /**
         *  Ir hacia al final de los pasoso
         * 
         * @param store 
         * @param payload Paso que ha sido completado
         */
        finalStep: (state, {payload}) => {
            const currentStep = state.currentStep
            const nextStep = 1

            state.currentStep = nextStep
            state.completedSteps = {...state.completedSteps, [currentStep]: payload}
        },
        /**
         * Cambiaron a los padres
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeUsersSelected: (store, {payload}) => {
            store.steps.users.selected = payload
        },
        /**
         * Todos los padres fueron seleccionados
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeFilters: (store, {payload}) => {
            let {key, value} = payload

            let filters = {...store.steps.users.filters, [key]: value}

            let all = false

            if (key == "all") {
                if (value) {
                    filters = {
                        all: true,
                        professors: true,
                        administrators: true,
                        parents: true,
                        assessors: true,
                        directors: true,
                        spokesman: true
                    }
                    
                } else {
                    filters = {
                        all: false,
                        professors: false,
                        administrators: false,
                        parents: false,
                        assessors: false,
                        directors: false,
                        spokesman: false
                    }
                }
            } else {
                filters.all = Object.values(filters).filter((v, index) => index != 0).every(i => i)
            }


            store.steps.users.filters = filters
        },
        /**
         * Cambiar todos los filtros
         * 
         * @param {*} store 
         * @param {*} param1 
         */
        changeAllFilters: (store, {payload}) => {
            store.steps.users.filters = {...store.steps.users.filters, ...payload}
        },
        /**
         * Invalidar los datos que esta disponibles
         * 
         * @param {*} store 
         * 
         * @param {*} param1 
         */
        invalidateStepData: (store, {payload}) => {
            store.steps[payload].didInvalidate = true
            store.steps[payload].all = false
            store.steps[payload].selected = []
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return emptyState
        })

         /** 
         * Reinicia el proceso de seleccion de
         * los modos de ditribucion
         * 
         * @param {*} state 
         * @param {*} action 
         * 
         * @returns 
         */
          builder.addCase('noticesModalUI/distributinModes/reset', (state, action) => {
            state.steps.users = {
                ...state.steps.users,
                didInvalidate: true,
                all: false,
                selected: [],
                filters: {
                    all: false,
                    professors: false,
                    administrators: false,
                    parents: false,
                    assessors: false,
                    directors: false,
                    spokesman: false,
                }
            }

            state.currentStep = 0
            state.completedSteps = {}
        })


        /**
         * Carga de datos del paso de grupos
         */
        builder.addCase(loadUsersStepData.fulfilled, (state, action) => {
            state.steps.users.didInvalidate = false
            state.steps.users.operationStatus = 'fulfilled'
        })
        builder.addCase(loadUsersStepData.pending, (state, action) => {
            state.steps.users.operationStatus = 'pending'
        })
        builder.addCase(loadUsersStepData.rejected, (state, action) => {
            state.steps.users.operationStatus = 'rejected'
        })
    }
})

export const  {
    // STRAPER
    nextStep, previusStep, finalStep,
    // Padres
    changeUsersSelected, changeFilters,
    // compartidos
    invalidateStepData, changeAllFilters
} = distributinModesSlice.actions


//////////////////// SELECTORES //////////////////

//////////////////// SELECTORES //////////////////

/**
 * Consulta para recuperar el paso actual
 * 
 *      0.- Alumnos
 *      1.- Padres
 *      2.- Finalizar
 * 
 * @param {*} state 
 * 
 * @returns 
 */
 export const selectCurrentStep = (state) => state.noticesModalUI.distributinModes.users.currentStep;

 /**
 * Consulta para recuperar los pasos que fueron completados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectCompletedSteps = (state) => state.noticesModalUI.distributinModes.users.completedSteps;


//////////////////// PARENTS //////////////////////


/**
 * Consulta para recuperar los usuarios que estan selecinados en el step
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectUsersSelected = (state) => state.noticesModalUI.distributinModes.users.steps.users.selected;


/**
 * Consulta para recuperar el estado de operacion del paso grupos
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectOperationStatusUsersStep = (state) => state.noticesModalUI.distributinModes.users.steps.users.operationStatus;


 /**
 * Consulta para recuperar informacion de los padres de los alumnos selecionados
 * 
 * @param {*} state 
 * @returns 
 */
  export const selectPreviewItems = createSelector([
    selectUsersSelected, selectUsers
], (usersSelected, users) => {

    return  usersSelected.map(studentId => {
        return users[studentId]
    })
})


///////////////// SWTICH //////////////////

/**
 * Consulta para saber si todos los elementos de grupos estan seleccionados
 * 
 * @param {*} state 
 * @returns 
 */
export const selectUsersIsAll = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.all;

export const selectUsersIsAllProfessors = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.professors;

export const selectUsersIsAllAdministrators = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.administrators;

export const selectUsersIsAllParents = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.parents;

export const selectUsersIsAllAssessors = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.assessors;

export const selectUsersIsAllDirectors = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.directors;

export const selectUsersIsAllSpokesman = (state) => state.noticesModalUI.distributinModes.users.steps.users.filters.spokesman;


/**
 * Consulta para recuperar los elementos 
 * del paso de padres
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectUsersSwitch = createSelector([
    selectUsersIsAll, selectUsersIsAllProfessors, selectUsersIsAllAdministrators, selectUsersIsAllParents,
    selectUsersIsAllAssessors, selectUsersIsAllDirectors, selectUsersIsAllSpokesman
], (all, professors, administrators, parents, assessors, directors, spokesman) => {

    return {
        all, professors, administrators, parents, assessors, directors, spokesman
    }
    
})


export default distributinModesSlice.reducer




/////////////////////// TRUNKS /////////////////////////////////

/**
 * Cargamos todos los grupos 
 */
 export const loadUsersStepData = createAsyncThunk(
    'noticesModalUI/distributinModes/users/step/users',
    async(schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()
        let Auth = new Authentication()

        try {
            let allParents = await Connection.getParentsBySchool(schoolId).then(i => i.data.data)
            let allProfessors = await Connection.getProfessorsActivedBySchool(schoolId).then(i => i.data.data)
            let allAdministrators = await Connection.getAdministratorssBySchool(schoolId).then(i => i.data.data)
            let allAssessors = await Connection.getAssesorsBySchool(schoolId).then(i => i.data.data)
            let allDirectors = await Connection.getDirectorsBySchool(schoolId).then(i => i.data.data)
            let allSpokeman = await Connection.getVocerosBySchool(schoolId).then(i => i.data.data)

            let allUsers = _.concat(allParents, allProfessors, allAdministrators, allAssessors, allDirectors, allSpokeman);
           
            let users = allUsers.map(i => ({
                ...i,
                user_id: Auth.getUserID(i)
            })).filter(u => u.status == 1)

            thunkAPI.dispatch(upsertManyUsers(users))

            return {
                allParents,
                allProfessors,
                users
            }
        }catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    },{
        condition: (arg, { getState, extra } ) => {
            let {didInvalidate} = getState().noticesModalUI.distributinModes.users.steps.users
            
            if (!didInvalidate) {
                return false
            }

        }
    }
)
