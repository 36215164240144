import { Box, CircularProgress, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { Transfer } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks';
import {
    loadUsersStepData, selectOperationStatusUsersStep,
    changeUsersSelected, selectUsersSelected, changeFilters, selectUsersIsAll,
    selectUsersIsAllProfessors, selectUsersIsAllAdministrators, selectUsersIsAllParents,
    selectUsersIsAllAssessors, selectUsersIsAllDirectors, selectUsersIsAllSpokesman, changeAllFilters
} from '../../../../store/slices/noticesModalUI/modes/usersModeSlice';
import { Empty, Error } from "../../../../components/Feedback";
import { selectAllUsers } from '../../../../store/slices/noticesModalUI/entities/usersSlice';
import Authentication, { USERS } from '../../../../service/Login';
import _ from 'lodash';

/**
 * Componente encargado se seleccionar grupos
 * 
 * @returns 
 */
const UsersStep = () => {

    const Auth = useAuth()
    const dispatch = useDispatch()

    let AuthService = new Authentication()

    /**
     * Relacion entre permisos y su reprecentacion en usuarios
     */
    let relationships = {
        'professors': USERS.PROFESSOR,
        'administrators': USERS.ADMINISTER,
        'parents': USERS.PARENT,
        'assessors': USERS.ASSESSOR,
        'directors': USERS.DIRECTOR,
        'spokesman': USERS.SPOKESMAN
    }

    ///////////////// SHARED STATE ////////////////

    const allUsers = useSelector(selectAllUsers)

    const studentsSelected = useSelector(selectUsersSelected)

    const all = useSelector(selectUsersIsAll)
    const professors = useSelector(selectUsersIsAllProfessors)
    const administrators = useSelector(selectUsersIsAllAdministrators)
    const parents = useSelector(selectUsersIsAllParents)
    const assessors = useSelector(selectUsersIsAllAssessors)
    const directors = useSelector(selectUsersIsAllDirectors)
    const spokesman = useSelector(selectUsersIsAllSpokesman)

    const operationStatus = useSelector(selectOperationStatusUsersStep)

    ///////////////// LOCAL STATE ///////////////////

    const [targetKeys, setTargetKeys] = useState(studentsSelected)
    const [selectedKeys, setSelectedKeys] = useState([])

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Efecto para inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUsersStepData(Auth.user.school_id))
    }, []);

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUsersStepData(Auth.user.school_id))
    }

    /**
     * Funcion para intercambiar elementos de la lista
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
    const onChangeList = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);

        dispatch(changeAllFilters({
            all: (nextTargetKeys.length == allUsers.length),
            professors: isAllProfessorsIdsSelected(nextTargetKeys, USERS.PROFESSOR),
            administrators: isAllProfessorsIdsSelected(nextTargetKeys, USERS.ADMINISTER),
            parents: isAllProfessorsIdsSelected(nextTargetKeys, USERS.PARENT),
            assessors: isAllProfessorsIdsSelected(nextTargetKeys, USERS.ASSESSOR),
            directors: isAllProfessorsIdsSelected(nextTargetKeys, USERS.DIRECTOR),
            spokesman: isAllProfessorsIdsSelected(nextTargetKeys, USERS.SPOKESMAN)
        }))

        dispatch(changeUsersSelected(nextTargetKeys))
    }

    /**
     * Verifica si todos profesores fueron seleccionados
     * 
     * @param currentIds Todas las ids seleccionadas
     * @param typeUsertoFind Tipo de usuario
     */
    const isAllProfessorsIdsSelected = (currentIds, typeUsertoFind) => {
        let allIds = getIdsByUserType(typeUsertoFind)

        let userData = currentIds.map(i => {
            const user = allUsers.find(u => u.user_id == i)

            AuthService.setUser(user)

            return {
                user_id: i,
                types: AuthService.getUserTypes()
            }
        })
            .filter(u => u.types.find(ft => ft.id == typeUsertoFind))
            .map(u => u.user_id)

        //console.log(`(Ids para ${typeUsertoFind}: ${allIds}   Total ${allIds.length}) - Seleccionadas ${userData.length}`)

        return allIds.length == userData.length
    }

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {
        const value = event.target.checked
        const key = event.target.name;

        let usersAffected = []

        if (key == "all") {
            usersAffected = (value) ? allUsers.map(i => i.user_id) : []
        } else {
            if (key == "professors") {
                usersAffected = getIdsByUserType(USERS.PROFESSOR)
            } else if (key == "administrators") {
                usersAffected = getIdsByUserType(USERS.ADMINISTER)
            } else if (key == "parents") {
                usersAffected = getIdsByUserType(USERS.PARENT)
            } else if (key == "assessors") {
                usersAffected = getIdsByUserType(USERS.ASSESSOR)
            } else if (key == "directors") {
                usersAffected = getIdsByUserType(USERS.DIRECTOR)
            } else if (key == "spokesman") {
                usersAffected = getIdsByUserType(USERS.SPOKESMAN)
            }

            if (value) {
                let newTargetsMixed = targetKeys.concat(usersAffected)

                usersAffected = _.uniq(newTargetsMixed)
            } else {
                usersAffected = getNewUsersSelected(key)
            }
        }

        dispatch(changeFilters({
            value, key
        }))

        setTargetKeys(usersAffected)
        dispatch(changeUsersSelected(usersAffected))
    }

    /**
     * Recupera todas las ids dependiendo del tipo
     * 
     * @returns 
     */
    const getIdsByUserType = (userType) => {
        return allUsers.filter(i => {
            AuthService.setUser(i)

            return AuthService.belongToGroup(userType)
        }).map(i => i.user_id)
    }

    /**
     * Recuperar los id de los usuarios que actualmente esta
     * seleccionados eliminando solo los que pertenecen a tipo de usuario
     * que se envia por parametro y estos no se encuentren en otros grupos
     * 
     * @param {*} key 
     * @returns 
     */
    const getNewUsersSelected = (key) => {
        let swtichActived = {
            [USERS.PROFESSOR]: professors,
            [USERS.ADMINISTER]: administrators,
            [USERS.PARENT]: parents,
            [USERS.ASSESSOR]: assessors,
            [USERS.DIRECTOR]: directors,
            [USERS.SPOKESMAN]: spokesman
        }

        let userData = targetKeys.map(i => {
            const user = allUsers.find(u => u.user_id == i)

            AuthService.setUser(user)

            return {
                user_id: i,
                types: AuthService.getUserTypes().map(i => i.id).filter(userType => swtichActived[userType])
            }
        }).map(u => {
            return {
                ...u,
                types: u.types.filter(ft => ft != relationships[key])
            }
        }).filter(u => u.types.length > 0)
            .map(u => u.user_id)

        return userData
    }


    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && allUsers.length == 0) && (<Empty label="Sin usuarios" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <Stack direction="row">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={all}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Todos"
                                    labelPlacement="top"
                                    name="all"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={professors}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Profesores"
                                    labelPlacement="top"
                                    name="professors"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={administrators}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Administradores"
                                    labelPlacement="top"
                                    name="administrators"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={parents}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Padres"
                                    labelPlacement="top"
                                    name="parents"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={assessors}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Orientadores"
                                    labelPlacement="top"
                                    name="assessors"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={directors}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Directores"
                                    labelPlacement="top"
                                    name="directors"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={spokesman}
                                            onChange={onChangeAll}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Voseros"
                                    labelPlacement="top"
                                    name="spokesman"
                                />
                            </FormGroup>
                        </Stack>

                        <Transfer
                            style={{ marginTop: '20px' }}
                            showSearch
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            locale={{
                                notFoundContent: ["Sin usuarios", "Sin usuarios"],
                                itemUnit: "Usuarios",
                                itemsUnit: "Usuarios",
                                searchPlaceholder: 'Buscar aquí'
                            }}
                            dataSource={allUsers.map(i => {
                                return { ...i, key: i.user_id }
                            })}
                            titles={['Disponibles', 'Asignados']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChangeList}
                            onSelectChange={onSelectChange}
                            render={item => `${item.name} ${item.last_name} ${item.second_last_name}`}
                            pagination
                        />
                    </>
                )
            }

        </Box>
    )
}


export default UsersStep