import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Card, CardContent, MenuItem, Stack, Typography, Button} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { loadedData, getDataStatus, getLoading, fetchAllActivities, filterActivities} from '../../store/slices/calendar'
import ActivityCalendar from '../../components/Calendar/ActivityCalendar';
import ActivityModal from '../../components/Calendar/ActivityModal';
import ActivityList from '../../components/Calendar/ActivityList';
import ActivityLevels from '../../components/Calendar/ActivityLevels'
import moment from 'moment'
import { useAuth } from '../../hooks';


const CalendarView = () => {

    const Auth = useAuth()
    let user = Auth.getUser()

    const serverState = useSelector(getLoading)
    const dataStatus = useSelector(getDataStatus)
    
    const dispatch = useDispatch()
    const [openActivityModal, setOpenActivityModal] = React.useState(false);
    
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        level: -1
    })

    const [activitySelected, setActivitySelected] = useState(null)

    /**
     * Funcion ejecutada cuendo se cierra el modal de actividades
     */
    const onCloseActivityModal = () => {
        setActivitySelected(null)
        setOpenActivityModal(false)
        dispatch(filterActivities(filters))
    }


    /**
     * Funcion para recuperar datos del servidor
     */
    function onFetchAllData() {
        dispatch(fetchAllActivities(user.school_id)).then(() => {
            dispatch(filterActivities(filters))
        })
    }

    //////////////// FUNCIONES PARA APLICAR FILTROS ////////////////
    const onChangeLevel = (event) => {
        const newfilters = {...filters, level: event.target.value}
        
        setFilters(newfilters);

        dispatch(filterActivities(newfilters))
    };

    const onChangeDate = (date) => {
        let dateMonth = moment(date)

        let dateFilter =  {
            startDate: dateMonth.startOf('month').format('YYYY-MM-DD hh:mm'),
            endDate: dateMonth.endOf('month').format('YYYY-MM-DD hh:mm')
        }

        const newfilters = {...filters, ...dateFilter}
      
        setFilters(newfilters);

        return newfilters
    }

    //////////////////////////////////////////////////////////////////

    const onChangeMonth = (date) => {
        const newfilters = onChangeDate(date)

        dispatch(filterActivities(newfilters))
    }
  
    /**
     * Evento lanzado cuendo el calendario fue lanzado
     * 
     * @param {*} date 
     */
    const onCalendarMounted = (date) => {
        console.log(dataStatus)
        if (dataStatus == 'pending') {
            const newfilters = onChangeDate(date)

            dispatch(fetchAllActivities(user.school_id)).then(() => {
                dispatch(filterActivities(newfilters))
                dispatch(loadedData())
            })
        }
    }
    
    const onClickDay = (date) => {
        setOpenActivityModal(true)
    }

    const onEventClick = (e) => {
        let payload = e.event.extendedProps.payload
        
        setActivitySelected(payload)
        setOpenActivityModal(true)
    }


    //  clickDay={onClickDay}
    return (
        <Box sx={{marginTop: 0, marginLeft: 10, marginBottom: 5, marginRight: 10}}>

            <Stack direction="row" spacing={2}   justifyContent="space-between" alignItems="center" sx={{marginBottom: 2}}>  
                <Box sx={{ maxWidth: 150 }}>
                    <ActivityLevels
                        items={[<MenuItem key={-1} value={-1}>Todas</MenuItem>]}
                        value={filters.level}
                        onChange={onChangeLevel}
                    />
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={onClickDay}
                        sx={{marginRight: 1}}
                    >
                        Crear actividad
                    </Button>
                    <LoadingButton
                        onClick={onFetchAllData}
                        endIcon={<SendIcon />}
                        loading={serverState=="pending"}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Obtener datos
                    </LoadingButton>
                </Box>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Card sx={{minWidth:600, maxWidth:1200, maxHeight: 600}}>
                        <CardContent>
                        </CardContent>
                        <CardContent>
                            <ActivityCalendar
                                nextMonth={onChangeMonth}
                                prevMonth={onChangeMonth}
                                today={onChangeMonth}
                                eventClick={onEventClick}
                                mounted={onCalendarMounted}
                            ></ActivityCalendar>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" gutterBottom component="div">
                        Actividades
                    </Typography>
                
                    <Stack spacing={2}>
                        <ActivityList />
                    </Stack>
                </Grid>
            </Grid>

            <ActivityModal
                show={openActivityModal}
                variant="contained"
                activity={activitySelected}
                close={onCloseActivityModal}
                done={onCloseActivityModal}
            />
        </Box>
    )
}


export default CalendarView