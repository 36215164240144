import React from 'react'
import { DatePicker } from 'antd';

import moment from 'moment';

import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';


const CalendarDatePiker = (props) => {

    const { RangePicker } = DatePicker;

    const onChange = (date) => {
        console.log(date)
        props.onChange(date)
    }


    if (props.isRange == 1) {
        let startDate = null
        let endDate = null

        if (props.start_date) {
            startDate = moment(props.start_date, "YYYY-MM-DD HH:MM:SS")
        }

        if (props.end_date) {
            endDate = moment(props.end_date, "YYYY-MM-DD HH:MM:SS")
        }

        return (
            <RangePicker
                locale={locale}
                value={[startDate,endDate]}
                onCalendarChange={onChange}
            />
        )
    }

    let startDate = null

    if (props.start_date) {
        startDate = moment(props.start_date)
    }

    return (
        <DatePicker
            locale={locale}
            value={startDate}
            onChange={onChange}
        />
    )

}


export default CalendarDatePiker