import { Box, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from "@mui/material"
import { useState } from "react"

/**
 * Componente para editar el contenido de la noticia
 * 
 * @param {*} param0 
 * @returns 
 */
const NoticeEditor = ({ value, onChange }) => {

    ///////////////////// LOCAL STATE ///////////////////////

    const [notice, setNotice] = useState({
        title: null,
        description: null,
        ...value
    })

    ////////////////// FUNCIONES ///////////////////////

    /**
     * Actualiza la informacion de la noticia
     * 
     * @param {*} name 
     * @returns 
     */
    const onchangeInputNotice = (name) => (event) => {
        const noticeValue = { ...notice, [name]: event.target.value }
        setNotice(noticeValue)

        onChange(noticeValue)
    }



    ///////////////////   VALIDACIONES    ///////////////////////

    const titleValdiate = () => {
        return {
            invalid: notice.title == "" || notice.title == null,
            message: "Campo requerido o incorrecto"
        }
    }

    const descripctionValdiate = () => {
        return {
            invalid: notice.description == "" || notice.description == null,
            message: "Campo requerido o incorrecto"
        }
    }


    const inValidForm = () => {
        return (titleValdiate().invalid || descripctionValdiate().invalid)
    }


    return (
        <Stack spacing={2} direction={"column"}>
            <TextField
                id="title"
                name="title"
                label="Título"
                type="text"
                variant="standard"
                inputProps={{ maxLength: 35 }}
                fullWidth={true}
                onChange={onchangeInputNotice('title')}
                value={notice.title}
                error={titleValdiate().invalid}
                helperText={
                    titleValdiate().invalid ?
                        titleValdiate().message : ` Caracteres disponibles: ${notice.title.length}/35`
                }
            />
            <TextField
                id="description"
                name="description"
                label="Descripción"
                multiline
                fullWidth={true}
                rows={8}
                inputProps={{ maxLength: 155 }}
                onChange={onchangeInputNotice('description')}
                value={notice.description}
                error={descripctionValdiate().invalid}
                helperText={
                    descripctionValdiate().invalid ?
                        descripctionValdiate().message : ` Caracteres disponibles: ${notice.description.length}/155`
                }
            />
        </Stack>
    )
}

export default NoticeEditor