import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import Services from "../../../service/Connection";
import { STUDENTSUI_MODALS_EXPIRE_TIME } from "../../../service/const";
import Feedback from "../../../service/Feedback";
import * as _ from 'lodash';
import Authentication from "../../../service/Login";
import { addManyUsers, addOneUser, selectEntitiesUsers, upsertManyUsers } from "../entities/users";
import { selectStudentById, upsertManyStudents } from "../entities/students";
import { selectAllGroups, selectGroupsById, upsertManyGroup } from "../entities/groups";
import { addManyGroupsStudents, selectAllGroupsStudents } from "../entities/groups_students"
import { addManySubjects, upsertManySubjects } from "../entities/subjects";
import { upsertManySubjectcatalog } from "../entities/subjectcatalogs";
import { upsertManyRelationships } from "../entities/relationships";

/**
 * Adaptador para grupos
 */
const itemsAdapter = createEntityAdapter({
    selectId: (item) => item.ui.student_id,
    sortComparer: (a, b) => a.ui.student_id - b.ui.student_id
})

/**
 * Slice para el settings UI
 */
export const studentsUIItemsSlice = createSlice({
    name: 'studentsUI/items',
    initialState: itemsAdapter.getInitialState(),
    reducers: {
        addOneItem: itemsAdapter.addOne,
        addManyItems: itemsAdapter.addMany,
        setAllItems: itemsAdapter.setAll,
        upsertManyItems: itemsAdapter.upsertMany,
        itemUpdated: itemsAdapter.updateOne,
        removeOneItem: itemsAdapter.removeOne
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return itemsAdapter.getInitialState()
        })

        //////////////// GROUPS INFORMATION ///////////////////////////////

        builder.addCase(loadGroupsUI.fulfilled, (state, { payload }) => {
            const { studentId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[studentId].servers.groups = { ...state.entities[studentId].servers.groups, ...newState }
        })
        builder.addCase(loadGroupsUI.pending, (state, { meta }) => {
            const { studentId } = meta

            state.entities[studentId].servers.groups.statusServer = 'pending'
        })
        builder.addCase(loadGroupsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { studentId, feedback } = action.payload

                state.entities[studentId].servers.groups.statusServer = 'rejected'
                state.entities[studentId].servers.groups.feedback = feedback
            }
        })

        //////////////// SUBJECTS INFORMATION ///////////////////////////////

        builder.addCase(loadSubjectsUI.fulfilled, (state, { payload }) => {
            const { studentId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[studentId].servers.subjects = { ...state.entities[studentId].servers.subjects, ...newState }
        })
        builder.addCase(loadSubjectsUI.pending, (state, { meta }) => {
            const { studentId } = meta

            state.entities[studentId].servers.subjects.statusServer = 'pending'
        })
        builder.addCase(loadSubjectsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { studentId, feedback } = action.payload

                state.entities[studentId].servers.subjects.statusServer = 'rejected'
                state.entities[studentId].servers.subjects.feedback = feedback
            }
        })

        //////////////// PARENTS INFORMATION ///////////////////////////////

        builder.addCase(loadParentsUI.fulfilled, (state, { payload }) => {
            const { studentId } = payload

            const newState = {
                expireIn: (new Date()).setMinutes(((new Date()).getMinutes() + STUDENTSUI_MODALS_EXPIRE_TIME)),
                ferchingAt: Date.now(),
                didInvalidate: false,
                statusServer: 'fulfilled',
            }
            state.entities[studentId].servers.parents = { ...state.entities[studentId].servers.parents, ...newState }
        })
        builder.addCase(loadParentsUI.pending, (state, { meta }) => {
            const { studentId } = meta

            state.entities[studentId].servers.parents.statusServer = 'pending'
        })
        builder.addCase(loadParentsUI.rejected, (state, action) => {
            console.log(action)
            if (action.payload) {
                const { studentId, feedback } = action.payload

                state.entities[studentId].servers.parents.statusServer = 'rejected'
                state.entities[studentId].servers.parents.feedback = feedback
            }
        })
    }
});

export const {
    addOneItem, addManyItems, upsertManyItems, itemUpdated, setAllItems,
    removeOneItem
} = studentsUIItemsSlice.actions;

export default studentsUIItemsSlice.reducer;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = itemsAdapter.getSelectors((state) => state.studentsUI.items)

/**
 * Selecciona todos los grupos
 */
export const selectAllStudentsItems = (store) => {
    let items = globalizedSelectors.selectAll(store)

    return items.map(i => {
        return {
            ...selectStudentById(i.ui.student_id)(store),
            item: i
        }
    })
}

/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */

//export const selectStudentsData = (state)=> state.studentsUI.items;

/**
 * Selecciona todos los grupos
 */
export const selectStudentItemSelected = (itemId) => (store) => {
    const studentSelected = store.studentsUI.ui.studentSelected
    let item = globalizedSelectors.selectById(store, studentSelected)

    const groupsEntity = selectAllGroups(store)
    const usersEntity = selectEntitiesUsers(store)
    const relationshipsEntity = Object.values(store.entities.relationships.entities)

    // Recuperar los grupos

    const groupsStudents = _.filter(selectAllGroupsStudents(store), { 'student_id': studentSelected })

    const groups = _.intersectionWith(groupsEntity, groupsStudents, (arrVal, othVal) => {
        return arrVal.group_id == othVal.group_id
    });

    // Recuperar las materias

    let allSubject = _.filter(Object.values(store.entities.subjects.byId), (item) => {
        return _.includes(_.map(groups, 'group_id'), item.group_id);
    }).map(i => {
        return {
            ...i,
            subjectCatalog: store.entities.subjectcatalogs.byId[i.catalog_subject_id],
            group: selectGroupsById(i.group_id)(store)
        }
    })

    // Recuperar los padres

    const relationships = _.filter(relationshipsEntity, { 'student_id': studentSelected }).map(i => {
        return {
            ...i,
            parent: usersEntity[i.parent_id],
        }
    })

    return {
        ...selectStudentById(studentSelected)(store),
        item,
        groupsList: groups,
        subjectsList: allSubject,
        relationshipsList: relationships
    }
}

/**
 * Selector para recuperar el status server del modal de grupos
 */
export const selectGroupsStatusServerItemSelected = (store) => {
    const studentSelected = store.studentsUI.ui.studentSelected
    let item = globalizedSelectors.selectById(store, studentSelected)

    if (studentSelected && item) {
        return item.servers.groups.statusServer
    }
    return 'idle'
}

/**
 * Selector para recuperar el status server del modal de materias
 */
export const selectSubjectstatusServerItemSelected = (store) => {
    const studentSelected = store.studentsUI.ui.studentSelected
    let item = globalizedSelectors.selectById(store, studentSelected)

    if (studentSelected && item) {
        return item.servers.subjects.statusServer
    }
    return 'idle'
}

/**
 * Selector para recuperar el status server del modal de padres
 */
export const selectParentstatusServerItemSelected = (store) => {
    const studentSelected = store.studentsUI.ui.studentSelected
    let item = globalizedSelectors.selectById(store, studentSelected)

    if (studentSelected && item) {
        return item.servers.parents.statusServer
    }
    return 'idle'
}


/**
 * Consulta para recuperar los elementos 
 * del paso de padres
 * 
 * @param {*} state 
 * @returns 
 */

///////////////////////// TRUNKS ////////////////////////////

/**
 * Cargar informacion de los grupos del alumno
 */
export const loadGroupsUI = createAsyncThunk(
    'studentsUI/item/fetch/groups',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const studentId = state.studentsUI.ui.studentSelected

        try {
            const group = await Services.getGroupByStudent(studentId).then(res => res.data.data)
            const speshialGroups = await Services.getSpecialGroupsByStudent(studentId).then(res => res.data.data)

            const allGroups = _.compact([group, ...speshialGroups])

            const groupsIds = _.map(allGroups, 'group_id')

            const groupsStudents = groupsIds.map(i => {
                return {
                    group_id: i,
                    student_id: studentId
                }
            })

            thunkAPI.dispatch(upsertManyGroup(allGroups))
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents))

            return {
                studentId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                studentId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const studentId = state.studentsUI.ui.studentSelected

        let { didInvalidate, expireIn } = state.studentsUI.items.entities[studentId].servers.groups

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            studentId: getState().studentsUI.ui.studentSelected
        }
    }
}
)

/**
 * Cargar informacion de los grupos del alumno
 */
export const loadSubjectsUI = createAsyncThunk(
    'studentsUI/item/fetch/subjects',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const studentId = state.studentsUI.ui.studentSelected

        try {
            const subjects = await Services.getSubjectsByStudent(studentId).then(res => res.data.data)
            const group = await Services.getGroupByStudent(studentId).then(res => res.data.data)
            const speshialGroups = await Services.getSpecialGroupsByStudent(studentId).then(res => res.data.data)

            const allGroups = _.compact([group, ...speshialGroups])

            const groupsIds = _.map(allGroups, 'group_id')

            const groupsStudents = groupsIds.map(i => {
                return {
                    group_id: i,
                    student_id: studentId
                }
            })
            let catalogs = []

            for (const subject of subjects) {
                const speshialGroups = await Services.getSubjectCatalogById(subject.catalog_subject_id).then(res => res.data.data)
                catalogs.push(speshialGroups)
            }

            thunkAPI.dispatch(upsertManyGroup(allGroups))
            thunkAPI.dispatch(addManyGroupsStudents(groupsStudents))
            thunkAPI.dispatch(upsertManySubjects(subjects))
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogs))

            return {
                studentId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                studentId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const studentId = state.studentsUI.ui.studentSelected

        let { didInvalidate, expireIn } = state.studentsUI.items.entities[studentId].servers.subjects

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            studentId: getState().studentsUI.ui.studentSelected
        }
    }
}
)

/**
 * Cargar informacion de los grupos del alumno
 */
export const loadParentsUI = createAsyncThunk(
    'studentsUI/item/fetch/parents',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const studentId = state.studentsUI.ui.studentSelected

        try {
            const parents = await Services.getParentsByStudent(studentId).then(res => res.data.data)
            const relationships = await Services.getRelationshipsByStudents(studentId).then(res => res.data.data)

            let users = parents.map(i => ({ ...i, user_id: Auth.getUserID(i) }))

            thunkAPI.dispatch(upsertManyUsers(users))
            thunkAPI.dispatch(upsertManyRelationships(relationships))

            return {
                studentId
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                studentId,
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        const state = getState()

        const studentId = state.studentsUI.ui.studentSelected

        let { didInvalidate, expireIn } = state.studentsUI.items.entities[studentId].servers.parents

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }
    },
    getPendingMeta: ({ arg, requestId }, { getState, extra }) => {
        return {
            studentId: getState().studentsUI.ui.studentSelected
        }
    }
}
)


