import React from 'react'

import { getLevelName, getTurnLevel } from "../../../../libs/utils";
import { useSelector } from "react-redux";
import { getGroups } from '../../../../store/slices/background'
import BarChartCard from '../../../../components/Chart/BarChartCard.jsx';

/**
 * Card que muestra un grafico de faltas
 * por grupo
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const AbsenceGroupsChart = (props) => {
    ///////////////// SHARES STATE ///////////////////

    const groups = useSelector(getGroups)

    ///////////////// LOCAL STATE ////////////////////

    let postGroups = groups.filter(item => item.level == props.level)

    postGroups.sort((a, b) => {
        return a.grade - b.grade
    })

    return (
        <BarChartCard
            title="Faltas"
            perPage={7}
            items={postGroups}
            label={(item) => {
                const groupName = `${item.grade}°${item.group} ${getTurnLevel(item.turn)}`
                //const level = getLevelName(item.level)
                return groupName
            }}
            value={'total_absence'}
            colorBar={"#ffac20"}
        />
    )
}


export default AbsenceGroupsChart