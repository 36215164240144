import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Autocomplete, Typography, Snackbar,
    Button, Avatar, Stack, Divider, createFilterOptions
} from '@mui/material';
import { Box } from '@mui/system';

import { useDispatch, useSelector } from "react-redux";
import Connection from "../../../service/Connection"
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
    deleteSubjectProfessor,
    selectSetSubjectProfessorOperationStatus,
    setSubjectProfessor,
    selectDeleteSubjectProfessorOperationStatus
} from '../../../store/slices/groupsUI/operationsSlice';
import { selectChangeProfessorSubjectModalData } from '../../../store/slices/groupsUI/itemSlice';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Modal para agregar maestro a una materia
 */
const ChangeProfessorSubjectModal = ({ open, setOpen, subjectId }) => {

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectSetSubjectProfessorOperationStatus)
    const deleteProfessorOperationStatus = useSelector(selectDeleteSubjectProfessorOperationStatus)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');
    let data = useSelector(selectChangeProfessorSubjectModalData(subjectId))

    let hasProfessor = data && data.hasOwnProperty("professor") && data.professor != null && data.professor != undefined

    let isOperationInrogress = statusOperation == 'pending' || deleteProfessorOperationStatus == 'pending'

    ////////////// ACTIONS /////////////

    /**
     * Eliminar profesor
     */
    const eliminarProfesor = () => {
        dispatch(deleteSubjectProfessor(subjectId))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "El profesor fue eliminado de la materia"
                })

                handlerClose()
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Guarda o actualiza un nuevo profesor
     */

    const enviarDatos = () => {
        dispatch(setSubjectProfessor({
            subjectId: subjectId,
            professor: value
        }))
            .unwrap()
            .then(response => {
                let message = "Profesor agregado"

                if (data?.professor) {
                    message = "Nuevo profesor asignado"
                }

                feedbackApp.showFeedback({
                    title: message
                })

                handlerClose()
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Establece los valores del componente AutoComplete
     */
    const resetAutoComplete = () => {
        setOpenSearch(false)
        setValue(null)
        setInputValue('')
        setProfessors([])
    }

    /**
     * Manejador de cierre de modal
     * 
     * @param {*} event 
     * @param {*} reason 
     * @returns 
     */
    const handlerClose = (event, reason) => {
        if (isOperationInrogress || reason === 'backdropClick') {
            return;
        }

        resetAutoComplete()
        setOpen(false)
    }


    ///////////////////////// VALIDACIONES //////////////////////

    const formInvalid = () => {
        return value == null
    }


    ///////////////////////// AUTOCOMPLETE /////////////////////// 

    /**
     * Opciones para el componente Autocomplete
     */
    const filterOptions = createFilterOptions({
        //limit: 10
    });

    const [openSearch, setOpenSearch] = useState(false);
    const [professors, setProfessors] = useState([]);
    const loading = (openSearch && professors.length === 0);

    /**
    * Recupera todos los asesores 
    */
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            let professors = await Connection.getProfessorsActivedBySchool(schoolId, {
                'not-calculate-properties': true,
                'fields': {
                    only: ["user_id", "professor_id", "name", "last_name", "second_last_name"]
                },
            }).then(i => i.data.data)

            if (active) {
                setProfessors([...professors.filter(i => i.professor_id != data?.professor?.user_id)]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    /**
     * Limpiar el buscador
     */
    React.useEffect(() => {
        if (!openSearch) {
            setProfessors([]);
        }
    }, [openSearch]);

    ////////////////////// VIEW //////////////////////////

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="sm"
            onClose={handlerClose}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Profesor
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    {
                        hasProfessor &&
                        (
                            <Box sx={{ mb: 3, textAlign: "center" }}>
                                <Typography variant='subtitle1'>
                                    Profesor Actual
                                </Typography>

                                <Stack justifyContent={"center"} direction="row" alignItems={"center"}>
                                    <Avatar src={data.professor.url_photo_profile} sx={{ mr: 2 }} />

                                    <Typography variant='h6'>
                                        {data.professor.name} {data.professor.last_name} {data.professor.second_last_name}
                                    </Typography>
                                </Stack>
                            </Box>
                        )
                    }

                    <Box>
                        <Divider sx={{ mb: 2 }}>
                            {
                                hasProfessor ? "Seleccionar profesor para actualizar" : "Seleccionar nuevo profesor"
                            }
                        </Divider>
                        <Autocomplete
                            id="controllable-states-demo"
                            loadingText='Buscando...'
                            noOptionsText='No se encontraron resultados'
                            filterOptions={filterOptions}
                            value={value}
                            options={professors}
                            loading={loading}
                            open={openSearch}
                            onOpen={() => {
                                setOpenSearch(true);
                            }}
                            onClose={() => {
                                setOpenSearch(false);
                            }}
                            getOptionLabel={(option) => `${option.name} ${option.last_name} ${option.second_last_name}` }
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Buscar profesor" />}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {
                    hasProfessor && (
                        <LoadingButton
                            size="small"
                            color="error"
                            onClick={eliminarProfesor}
                            loading={ isOperationInrogress }
                            loadingPosition="start"
                            startIcon={<DeleteIcon />}
                            variant="contained"
                        >
                            Eliminar actual
                        </LoadingButton>
                    )
                }
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={ isOperationInrogress }
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    {
                        hasProfessor ? "Actualizar" :  "Agregar"
                    }
                </LoadingButton>

                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handlerClose}
                    disabled={ isOperationInrogress }
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeProfessorSubjectModal