import { Box, Card, CardActions, CardContent } from "@mui/material"
import { DataGrid, esES, } from '@mui/x-data-grid';
import moment from "moment";
import { useEffect, useState } from "react";
import { round } from "../../../helper";
import { selectOneSettingBySchoolId } from "../../../store/slices/entities/settings";
import { selectGroupById, selectPartialsByLevel, selectScoresBySubject, selectStudentsByGroup } from "../../../store/slices/professorModule/entitiesSlice";
import { selectItemOperationStatusSelected, storeCalificationList } from "../../../store/slices/professorModule/ItemsSlice";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton'
import { useDispatch, useSelector } from "react-redux";
import ScoreTextFiend from "./../../../components/common/ScoreTextField"

/**
 * Componente que muestra un un formulario para crear
 * o actualziar calificaciones de un grupo 
 * 
 * @param {*} param0
 *  
 * @returns 
 */
const ScoresCard = ({ schoolId, groupId, subjectId, onSave }) => {
    moment.locale('es')
    const dispatch = useDispatch()

    let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');

    /////////////////// SHARES STATUS //////////////////
    const group = useSelector(selectGroupById(groupId))

    const allCalifications = useSelector(store => selectScoresBySubject(store, subjectId))

    const studentsList = useSelector(store => selectStudentsByGroup(store, groupId))
    const partials = useSelector((store) => selectPartialsByLevel(store, group.level))

    const scoreMin = useSelector(selectOneSettingBySchoolId(schoolId, 'calification-minimum'));

    const operationState = useSelector(selectItemOperationStatusSelected)

    /////////////////////// LOCAL STATE ////////////////////

    let [rows, setRows] = useState([])
    let [columns, setColumns] = useState([])


    let formValid = rows.some((i) => {

        return Object.values(i.partialsItems).some((p) => !p.isValid)

    })

    ////////////////////// ACTIONS //////////////////////////

    useEffect(() => {
        let studentItems = studentsList.map(student => {
            let partialsAdditionalProp = {}
            let partialsItems = {}

            for (let partial of partials) {
                let caliBySubjet = allCalifications.find(d => d.student_id == student.student_id && d.partial_id == partial.partial_id);

                let partialName = `p-${partial.partial_id}`

                partialsAdditionalProp[partialName] = (caliBySubjet?.calification) ? caliBySubjet.calification : 0

                partialsItems[partialName] = {
                    partial: partial,
                    score: caliBySubjet,
                    isExists: (caliBySubjet != undefined),
                    partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date),
                    isValid: true
                }
            }

            return {
                ...student,
                ...partialsAdditionalProp,
                partialsItems
            }
        })

        setRows(studentItems)
    }, [subjectId, allCalifications])



    /**
     * Columnas del datagrid
     */
    useEffect(() => {
        let columnsItems = [
            {
                column: 'student',
                title: "ALUMNOS",
                sticky: true,
                className: ""
            },
            ...partials.map(partial => ({
                column: `p-${partial.partial_id}`,
                title: `P${partial.partial}`,
                sticky: false,
                className: "parcial-column",
                partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
            })),
            {
                column: `total`,
                title: `PROMEDIO`,
                sticky: false,
                className: "parcial-column"
            }
        ]

        //////////////////// FORMATO DE LAS COLUMNAS /////////////////////

        let columns = columnsItems.map(i => {
            return {
                field: i.column,
                headerName: i.title,
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row = params.row.partialsItems[params.field]?.partialActive
                    if (row) {
                        return 'partial-active--cell'
                    }

                    return ''
                },
                valueGetter: (params) => {
                    if (params.field == 'total') {
                        let total = 0
                        let califcationsNum = 0

                        for (const property in params.row) {
                            if (/p-[0-9]+/.test(property)) {
                                let score = parseInt(params.row[property])

                                if (!isNaN(score)) {
                                    total += score

                                    if (score > 0) {
                                        califcationsNum++
                                    }
                                }
                            }
                        }

                        let average = (total > 0) ? total / califcationsNum : 0

                        return round(average, 1)
                    }

                    if (params.field == 'student') {
                        return `${params.row.name} ${params.row.last_name} ${params.row.second_last_name}`
                    }
                    return params.value
                },
                renderCell: (params) => {
                    if (params.field == 'total') {
                        return params.value
                    }

                    if (params.field == 'student') {
                        return params.value
                    }

                    return (
                        <ScoreTextFiend
                            id="outlined-basic"
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            defaultValue={params.value}
                            sx={{ color: params.value < scoreMin?.value ? 'red' : 'green' }}
                            onChange={onInputChange({
                                studentId: params.row.student_id,
                                column: params.field
                            })}
                        />
                    )
                }
            }
        })

        setColumns(columns)
    }, [subjectId, rows, partials])


    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param integer studentId Id del alumno que representa el id del row
     * @param string partialColumnName Nombre de la columna
     */
    const onInputChange = ({ studentId, column }) => (e) => {

        const value = e.target.value

        let regex = /^(100|(\d{1,2})(\.\d{1,2})?)?$/

        let nextRows = rows.map(row => ({
            ...row,
            [column]: (studentId == row.student_id) ? value : row[column],
            partialsItems: {
                ...row.partialsItems,
                [column]: {
                    ...row.partialsItems[column],
                    isValid: regex.test(value)
                }
            }
        }))

        setRows(nextRows)
    }

    /**
     * Registra las calificaciones
     */
    const onSaveCalificationList = () => {
        let calificationNotProceced = []

        for (let student of rows) {
            for (const property in student) {
                if (/p-[0-9]+/.test(property)) {
                    calificationNotProceced.push({
                        value: student[property],
                        student: student,
                        ...student.partialsItems[property]
                    })
                }
            }
        }

        let califications = calificationNotProceced.filter(item => item.value != 0 && item.value != '' && item.value != null)

        const updateRequest = califications.filter((item) => item.isExists)
            .map(item => ({
                calification: item.value,
                calification_id: item.score.calification_id
            }))

        const createRequest = califications.filter((item) => !item.isExists)
            .map(item => ({
                is_final: 0,
                calification: item.value,
                partial_id: item.partial.partial_id,
                student_id: item.student.student_id,
                subject_id: subjectId
            }))

        dispatch(storeCalificationList({
            subjectId,
            update: updateRequest,
            create: createRequest
        }))
            .unwrap()
            .then((data) => {
                onSave('done', data)
            }).catch((err) => {
                onSave('err', err)
            })

    }

    return (
        <Card>
            <CardContent>
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.student_id}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        disableSelectionOnClick
                        autoHeight
                        disableDensitySelector
                    />
                </Box>
            </CardContent>
            <CardActions
                sx={{
                    direction: 'row',
                    justifyContent: "flex-end",
                    alignItems: "center"
                }}
            >
                <LoadingButton
                    size="small"
                    endIcon={<SendIcon />}
                    loading={operationState == 'pending'}
                    loadingPosition="end"
                    variant="contained"
                    onClick={onSaveCalificationList}
                    disabled={formValid}
                >
                    Guardar
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default ScoresCard