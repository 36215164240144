import React, { Fragment } from 'react'
import { Card, CardContent, Box, Typography, CardActionArea } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@mui/material'

const Stats = (props) => {
    const {sx, onClick} = props

    const events = {}


    const contentCard = (
        <Fragment>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
              <FontAwesomeIcon icon={props.icon} size="2x" />
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'column',  alignItems: 'end', flex: 3, padding: "11px 11px 11px 0"}}>
            <Box>
              <Typography variant="h4" component="span">
                {props.value}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <Typography variant="subtitle1" gutterBottom component="span">
                {props.title}
              </Typography>
            </Box>
          </Box>
        </Fragment>
      )


    if (onClick) {
      events["onClick"] = onClick

      return (
        <Card sx={{height: 80, width: 300 }} {...events}>
            <CardActionArea sx={{ display: 'flex', ...sx}}>
              {contentCard}
            </CardActionArea>
        </Card>
      )

    } else {
      return (
        <Card sx={{height: 80, width: 300, display: 'flex', ...sx}} {...events}>
          {contentCard}
        </Card>
      )
    }
  }

export default Stats