import React, { useEffect, useState } from 'react';
import {
    TextField, Typography, FormControl, Grid, InputLabel,
    Select, MenuItem, Button, FormHelperText,
    Dialog, DialogActions, DialogContent, DialogTitle, Switch
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { selectPartialsBySchoolId } from "../../store/slices/entities/partials";
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { selectStatusOperation, storePartial, updatePartial } from '../../store/slices/settingsUI';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { PARTIAL_ADD_LIMIT_DAYS } from '../../service/const';
import useFeedback from '../../hooks/useFeedback';

/**
 * Modal para los parciales
 * 
 * @param {*} param0 
 * @returns 
 */
const ModalParcial = ({ openModal, setOpenModal, partialItem, regularized, setOpen }) => {

    const { RangePicker } = DatePicker;
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false)    
    }


    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const partials = useSelector(selectPartialsBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)


    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    //////////////// ESTADO ///////////////

    const [partial, setPartial] = useState({
        partial: '',
        start_date: '',
        end_date: '',
        limit_date: '',
        level: -1,
        is_regularized: false
    });

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setPartial({ ...partial, [name]: value });
    }

    /**
     * Reinicia la informacion del formulario 
     */
    const resetForm = () => {
        setPartial({
            partial: '',
            start_date: '',
            end_date: '',
            limit_date: '',
            level: -1,
            is_regularized: false
        })
    }


    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (!openModal) {
            resetForm()
        } else {
            if (!partialItem) {
                setPartial({ ...partial, ...partialItem, ...{ is_regularized: regularized } })
            } else {
                setPartial({ ...partial, ...partialItem })
            }
        }
    }, [openModal]);


    /**
     * Guardar o actualizar un parciales
     */
    const enviarDatos = async () => {
        if (partial.hasOwnProperty('partial_id')) {
            dispatch(updatePartial({
                partialId: partial.partial_id,
                data: {
                    ...partial,
                    start_date: `${moment(partial.start_date).format("YYYY-MM-DD")}  00:00:00`,
                    end_date: `${moment(partial.end_date).format("YYYY-MM-DD")} 23:59:59`,
                    limit_date: `${moment(partial.limit_date).format("YYYY-MM-DD")} 23:59:59`
                }
            })).
                unwrap()
                .then(res => {
                    const title = partial.is_regularized ? "Regularización" : "Parcial"

                    feedbackApp.showFeedback({
                        title: `${title} actualizado correctamente`
                    })
                    setOpenModal(false)
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })
        } else {
            dispatch(storePartial({
                schoolId: schoolId,
                data: {
                    ...partial,
                    start_date: moment(partial.start_date).format("YYYY-MM-DD"),
                    end_date: moment(partial.end_date).format("YYYY-MM-DD"),
                    limit_date: moment(partial.limit_date).format("YYYY-MM-DD"),
                    is_regularized: regularized
                }
            })).
                unwrap()
                .then(res => {
                    const title  = partial.is_regularized ? "Regularización" : "Parcial"
                    
                    feedbackApp.showFeedback({
                        title:  `${title} creado correctamente`
                    })

                    resetForm()

                    setOpenModal(false)
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })
        }
    }

    /**
     * Cambia la fecha limite del estado
     * 
     * @param {*} value 
     * @param {*} dateString 
     */
    const AsignarFechaLimite = (value, dateString) => {
        setPartial({
            ...partial, ...{
                limit_date: value.format('YYYY-MM-DD hh:mm:ss')
            }
        })
    }

    /**
     * Cambia el rengo de fechas del estado
     * 
     * @param {*} value 
     * @param {*} dateString 
     */
    const AsignarFechaRango = (value, dateString) => {
        const [fechaInicio, fechaFin] = value;

        setPartial({
            ...partial, ...{
                start_date: fechaInicio.format('YYYY-MM-DD hh:mm:ss'),
                end_date: fechaFin.format('YYYY-MM-DD hh:mm:ss'),
                limit_date: fechaFin.add(PARTIAL_ADD_LIMIT_DAYS, 'days').format('YYYY-MM-DD hh:mm:ss')
            }
        })
    }


    /**
    * Validaciones para deshabilitar las fechas
    * 
    * @param {*} date 
    * @returns 
    */
    const disabledRangeDates = (date) => {
        let partialsList = Object.values(partials)
            .filter(i => i.level == partial.level)
            .filter(i => i.partial_id != partial?.partial_id)
            //.filter(i => i.is_regularized == regularized)

        for (let partialItem of partialsList) {
            let endDate = moment(partialItem.end_date)
            let startDate = moment(partialItem.start_date)

            if (date >= startDate && date <= endDate) {
                return true
            }
        }

        return date < today
    }

    /**
     * Validador para deshabilitar fechas 
     * 
     * @param {*} date 
     * @returns 
     */
    const disabledLimitDate = (date) => {
        if (partial.end_date != '') {
            let endDate = moment(partial.end_date)
            // let startDate = new Date(this.partial.start_date)
            // NOTE SI LA FECHA A EVALUAR ES MENOR A LA FECHA INICIAL DEL PARCIAL LO DESHABILITAMOS
            if (date <= endDate) {
                return true
            }
            // NOTE SI LA FECHA A EVALUAR ESTA DENTRO DEL PARCIAL
            // if (date >= startDate && date <= endDate) {
            //   return true
            // }
        }
    }

    ////////////////////////// VALIDACIONES DEL FORMULARIO //////////////

    const partialNameValid = () => {
        return {
            invalid: partial.partial == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const levelValid = () => {
        return {
            invalid: partial.level == -1,
            message: "Campo requerido o incorrecto"
        }
    }

    const rangeDatesValid = () => {
        return {
            invalid: partial.start_date == '' || partial.end_date == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const limitDateValid = () => {
        return {
            invalid: partial.limit_date == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        return (
            partialNameValid().invalid || levelValid().invalid || rangeDatesValid().invalid ||
            limitDateValid().invalid
        )
    }

    ////////////////////// VIEW //////////////////////////

    /**
     * Mensaje de error de rango de fechas
     * 
     * @returns 
     */
    const errorRangeDateUI = () => {
        let UI = (
            <Typography
                variant='caption'
                component="div"
                sx={{ color: 'red' }}
            >
                Campo requerido o incorrecto
            </Typography>
        )

        let invalid = rangeDatesValid().invalid
        if (invalid) {
            return UI
        }
    }

    /**
     * Mensaje de errores para la fecha limite
     * 
     * @returns 
     */
    const errorLimitDateUI = () => {
        let UI = (
            <Typography
                variant='caption'
                component="div"
                sx={{ color: 'red' }}
            >
                Campo requerido o incorrecto
            </Typography>
        )

        let invalid = limitDateValid().invalid
        if (invalid) {
            return UI
        }
    }

    return (

        <Dialog
            open={openModal}
            fullWidth={true}
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: fontColor.value }}>
                        { partial.hasOwnProperty('partial_id') ? "Actualizar " : "Agregar "}

                        { partial.is_regularized ? "regularización" : "parcial" }
                    </Typography>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Grid container spacing={1}>
                        <Grid container item xl={12} sm={12} md={12} xs={12} style={{ marginTop: "5px" }}>
                            <TextField
                                id="partial"
                                name="partial"
                                label={partial.is_regularized ? "Número de regularización" : "Número de parcial"}
                                size="small"
                                variant="standard"
                                inputProps={{ maxLength: 50 }}
                                value={partial.partial}
                                onChange={onInputChange}
                                fullWidth
                                type='number'
                                error={partialNameValid().invalid}
                                helperText={partialNameValid().invalid ? partialNameValid().message : false}
                            />
                        </Grid>
                        <Grid container item xl={12} sm={12} md={12} xs={12}>
                            <FormControl
                                variant="standard"
                                sx={{ width: '100%' }}
                                error={levelValid().invalid}
                            >
                                <InputLabel id="lbl-nivel">Nivel</InputLabel>
                                <Select
                                    labelId="lbl-nivel"
                                    id="level"
                                    name="level"
                                    value={partial.level}
                                    onChange={onInputChange}
                                    label="Nivel"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>Nivel</MenuItem>
                                    <MenuItem value={1}>Kinder</MenuItem>
                                    <MenuItem value={2}>Primaria</MenuItem>
                                    <MenuItem value={3}>Secundaria</MenuItem>
                                    <MenuItem value={4}>Preparatoria</MenuItem>
                                    <MenuItem value={5}>Universidad</MenuItem>
                                </Select>
                                {
                                    levelValid().invalid && <FormHelperText>{levelValid().message}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid container item xl={12} sm={12} md={12} xs={12} style={{ marginTop: "5px" }}>
                            <Box component="div" style={{ width: '100%' }}>
                                <Typography variant="body2" component="div">
                                    Rango de fechas
                                </Typography>
                                <RangePicker
                                    locale={locale}
                                    onChange={AsignarFechaRango}
                                    value={[
                                        partial.start_date ? moment(partial.start_date, "YYYY-MM-DD") : '',
                                        partial.end_date ? moment(partial.end_date, "YYYY-MM-DD") : '']}
                                    size="large"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    style={{ width: '100%' }}
                                    className="calendario-asd"
                                    dropdownClassName="partial-dropdown-calendar"
                                    disabledDate={disabledRangeDates}
                                    disabled={partial.level == -1}
                                />
                                {errorRangeDateUI()}
                            </Box>


                        </Grid>
                        <Grid container item xl={12} sm={12} md={12} xs={12} style={{ marginTop: "5px" }}>
                            <Box component="div" style={{ width: '100%' }}>
                                <Typography variant="body2" component="div">
                                    Fecha Límite
                                </Typography>
                                <DatePicker
                                    locale={locale}
                                    value={partial.limit_date ? moment(partial.limit_date, "YYYY-MM-DD") : ''}
                                    onChange={AsignarFechaLimite}
                                    size="large"
                                    getPopupContainer={(triggerNode) => {
                                        return triggerNode.parentNode;
                                    }}
                                    style={{ width: '100%' }}
                                    disabledDate={disabledLimitDate}
                                    disabled={partial.level == -1}
                                />
                                {errorLimitDateUI()}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={()=>{
                        enviarDatos()
                    }}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    {
                        partial.hasOwnProperty('partial_id') && "Guardar"
                    }
                    {
                        !partial.hasOwnProperty('partial_id') && "Crear"
                    }
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpenModal(false) }}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalParcial
