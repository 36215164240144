import { createAsyncThunk } from "@reduxjs/toolkit"
import Connection from "../../../service/Connection"
import Feedback from "../../../service/Feedback"
import Authentication from "../../../service/Login"
import { addOneNotice, upsertManyNotices } from "../entities/notices"
import { upsertManyUsers } from "../entities/users"


/**
 * Cargar informacion de la UI
 */
const loadUI = createAsyncThunk(
    'noticesModule/fetch/data',
    async ({ professorId, schoolId }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let notices = await Connection.getResourcesPaginatedFromServer('noticesBySchool', [schoolId], { sort: '-created_at' }).then(res => res.data)

            let emmitersDis = Array.from(new Set(notices.map(item => item.user_id)))

            let emmiters = await Connection.getUsers(emmitersDis.join()).then(response => response.data.data)

            thunkAPI.dispatch(upsertManyUsers(emmiters))
            thunkAPI.dispatch(upsertManyNotices(notices))

            return {
                notices,
                emmiters
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().noticesModule.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)


export { loadUI }