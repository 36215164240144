import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import Services from "../../../service/Connection";
import Feedback from "../../../service/Feedback";
import Authentication from "../../../service/Login";
import * as _ from 'lodash';
import { addOneSubject, upsertOneSubject, removeOneSubject, selectSubjectsBy, addManySubjects } from "../entities/subjects";
import { addManySubjectcatalogs, selectEntitiesSubjectcatalogs} from "../entities/subjectcatalogs";
import { selectEntitiesUsers, upsertOneUser } from "../entities/users";
import { selectAllGroupsEntities, selectGroupsById, selectGroupsFiles, upsertOneGroup } from "../entities/groups";
import { addOneGroupsStudents, removeOneGroupsStudents, selectAllGroupsStudents } from "../entities/groups_students";
import { addOneStudent, upsertOneStudent } from "../entities/students";
import { updateManyFiles } from "../entities/files";
import { GROUPSUI_EXPIRE_TIME } from "../../../service/const";
import { convertArrayToObject } from "../../../libs/utils";

const emptyState = {
    assignAssessor: 'idle',

    deleteStudent: 'idle',
    
    // Modal de alumnos
    addStudentToGroup: 'idle',
    removeStudentFromGroup: 'idle',

    // Modal de materias
    addGroupSubject: 'idle',
    removeGroupSubject: 'idle',
    setSubjectProfessor: 'idle',
    deleteSubjectProfessor: 'idle',
}

export const operationsSlice = createSlice({
    name: 'groupsUI/operations',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        /////////////////////// ACTUALIZAR EL ASSESOR DE UN GRUPO /////////////////////////

        builder.addCase(assignAssessor.fulfilled, (state, action) => {
            state.assignAssessor = 'fulfilled'
        })
        builder.addCase(assignAssessor.pending, (state, action) => {
            state.assignAssessor = 'pending'
        })
        builder.addCase(assignAssessor.rejected, (state, action) => {
            state.assignAssessor = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// Eliminar profesor de materia /////////////////////////

        builder.addCase(deleteSubjectProfessor.fulfilled, (state, action) => {
            state.deleteSubjectProfessor = 'fulfilled'
        })
        builder.addCase(deleteSubjectProfessor.pending, (state, action) => {
            state.deleteSubjectProfessor = 'pending'
        })
        builder.addCase(deleteSubjectProfessor.rejected, (state, action) => {
            state.deleteSubjectProfessor = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// Cambiar o asignar profesor una materia /////////////////////////

        builder.addCase(setSubjectProfessor.fulfilled, (state, action) => {
            state.setSubjectProfessor = 'fulfilled'
        })
        builder.addCase(setSubjectProfessor.pending, (state, action) => {
            state.setSubjectProfessor = 'pending'
        })
        builder.addCase(setSubjectProfessor.rejected, (state, action) => {
            state.setSubjectProfessor = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// Agregar materia a un grupo /////////////////////////

        builder.addCase(addSubjectGroup.fulfilled, (state, action) => {
            state.addGroupSubject = 'fulfilled'
        })
        builder.addCase(addSubjectGroup.pending, (state, action) => {
            state.addGroupSubject = 'pending'
        })
        builder.addCase(addSubjectGroup.rejected, (state, action) => {
            state.addGroupSubject = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// Eliminar profesor de materia /////////////////////////

        builder.addCase(deleteStudent.fulfilled, (state, action) => {
            state.statusOperation = 'fulfilled'
        })
        builder.addCase(deleteStudent.pending, (state, action) => {
            state.statusOperation = 'pending'
        })
        builder.addCase(deleteStudent.rejected, (state, action) => {
            state.statusOperation = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// ELIMINAR ALUMNO DE UN GRUPO /////////////////////////

        builder.addCase(removeStudentFromGroup.fulfilled, (state, action) => {
            state.removeStudentFromGroup = 'fulfilled'
        })
        builder.addCase(removeStudentFromGroup.pending, (state, action) => {
            state.removeStudentFromGroup = 'pending'
        })
        builder.addCase(removeStudentFromGroup.rejected, (state, action) => {
            state.removeStudentFromGroup = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// AGREGA UN ALUMNO A UN GRUPO /////////////////////////

        builder.addCase(addStudentToGroup.fulfilled, (state, action) => {
            state.addStudentToGroup = 'fulfilled'
        })
        builder.addCase(addStudentToGroup.pending, (state, action) => {
            state.addStudentToGroup = 'pending'
        })
        builder.addCase(addStudentToGroup.rejected, (state, action) => {
            state.addStudentToGroup = 'rejected'
            state.feedback = action.payload.feedback
        })

        /////////////////////// CREAR Y AGREGAR ALUMNO A GRUPO /////////////////////////

        builder.addCase(createStudentToGroup.fulfilled, (state, action) => {
            state.addStudentToGroup = 'fulfilled'
        })
        builder.addCase(createStudentToGroup.pending, (state, action) => {
            state.addStudentToGroup = 'pending'
        })
        builder.addCase(createStudentToGroup.rejected, (state, action) => {
            state.addStudentToGroup = 'rejected'
            state.feedback = action.payload.feedback
        })
        ////////////////////// ELIMINAR MATERIA //////////////////////////

        builder.addCase(deleteGroupSubject.fulfilled, (state, action) => {
            state.removeGroupSubject = 'fulfilled'
        })
        builder.addCase(deleteGroupSubject.pending, (state, action) => {
            state.removeGroupSubject = 'pending'
        })
        builder.addCase(deleteGroupSubject.rejected, (state, action) => {
            state.removeGroupSubject = 'rejected'
            state.feedback = action.payload.feedback
        })
        /////////////////////// CARGAR MATERIAS DE UN GRUPO /////////////////////////
        builder.addCase(LoadSubjectGroup.fulfilled, (state, action) => {
            state.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + GROUPSUI_EXPIRE_TIME))
            state.ferchingAt = Date.now()
            state.didInvalidate = false
            state.statusServer = 'fulfilled'
        })
        builder.addCase(LoadSubjectGroup.pending, (state, action) => {
            state.statusOperation = 'pending'
        })
        builder.addCase(LoadSubjectGroup.rejected, (state, action) => {
            state.statusOperation = 'rejected'
            state.feedback = action.payload.feedback
        })
    }
});

export const { invalidate } = operationsSlice.actions;

export default operationsSlice.reducer;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar las operaciones de la pagina de grupos
 * 
 * @param {} state 
 * @returns 
 */
export const selectGroupsOperations = (state) => state.groupsUI.operations

/**
 * Selector para recuperar el estatus de la operacion de cambio de maestro 
 * a una materia
 */
export const selectSetSubjectProfessorOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.setSubjectProfessor
)

/**
 * Selector para recuperar el estatus de la operacion de eliminacion de
 * profesor de una materia
 */
export const selectDeleteSubjectProfessorOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.deleteSubjectProfessor
)

/**
 * Selector para recuperar el estatus de la operacion de eliminacion de
 * profesor de una materia
 */
export const selectRemoveGroupSubjectOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.removeGroupSubject
)

/**
 * Selector para recuperar el estatus de la operacion de agregar
 * materia a un grupo
 */
export const selectAddGroupSubjectOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.addGroupSubject
)

/**
 * Selector para recuperar el estatus de la operacion asignacion o
 * cambio de maestro
 */
export const selectAssignAssessorOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.assignAssessor
)

/**
 * Selector para recuperar el estatus de la operacion de agregar alumnos
 * a un grupo
 */
export const selectAddStudentToGroupOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.addStudentToGroup
)

/**
 * Selector para recuperar el estatus de la operacion de agregar alumnos
 * a un grupo
 */
export const selectRemoveStudentFromGroupOperationStatus = createSelector(
    selectGroupsOperations,
    (operations) => operations.removeStudentFromGroup
)


/**
 * Recuperar materias de un grupo
 * 
 * @param {*} state 
 * @returns 
 */
export const selectSubjectByGroupId = (state, groupId) => {
    const subjectsFiltered = selectSubjectsBy({ group_id: groupId })(state)
    const subjectcatalogoEntities = selectEntitiesSubjectcatalogs(state)
    const usersEntities = selectEntitiesUsers(state)

    let data = subjectsFiltered.map(s => {

        const catalog = subjectcatalogoEntities[s.catalog_subject_id]
        const professor = usersEntities[s.professor_id]

        return { ...s, catalog, professor }
    })

    return data
}

export const LoadSubjectGroup = createAsyncThunk(
    'groupsUI/operations/fetch-subjects',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        const state = thunkAPI.getState()
        const groupId = state.groupsUI.ui.groupSelected
        try {
            let subjectcatalogs = await Services.getSubjectCatalogBySchool(schoolId).then(i => i.data.data)
            let subjects = await Services.getResourcesPaginatedFromServer('getSubjectsBySchool', [schoolId]).then(i => i.data)

            /*for (const subject of subjects) {
                const subjectCatalogs = await Services.getSubjectCatalogById(subject.catalog_subject_id).then(res => res.data.data)
                catalogs.push(subjectCatalogs)
            } */           
            thunkAPI.dispatch(addManySubjectcatalogs(convertArrayToObject(subjectcatalogs, 'catalog_subject_id')))
            thunkAPI.dispatch(addManySubjects(convertArrayToObject(subjects, 'subject_id')))

            /*thunkAPI.dispatch(upsertManySubjects(subjects))
            thunkAPI.dispatch(upsertManySubjectcatalog(catalogs))*/


            return {
                subjectcatalogs,
                groupId
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().subjectsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)
/**
 * Crear usuario
 */
export const assignAssessor = createAsyncThunk(
    'groupsUI/operations/add-assessor',
    async ({ groupId, assessorId }, thunkAPI) => {

        let FeedbackService = new Feedback()
       
        try {

            let state = thunkAPI.getState()
            let assessors = []

            let files = selectGroupsFiles(groupId)(state)

            const assessor = await Services.assignAssessorToGroup(groupId, assessorId).then(i => i.data.data)

            assessor["user_id"] = assessorId

            thunkAPI.dispatch(upsertOneUser(assessor))

            /**
            * Actualizar los expedientes
            */
            let updateObjects = files.map((f) => ({
                id: f.file_id,
                changes: {
                    assessor_id: assessorId,
                }
            }))

            thunkAPI.dispatch(updateManyFiles(updateObjects))
            thunkAPI.dispatch(upsertOneGroup({group_id:groupId,assessor_id:assessorId}))

            return {
                assessor,
                files,
                updateObjects
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)
/**
 * Eliminar alumno de grupo
 */
export const removeStudentFromGroup = createAsyncThunk(
    'groupsUI/operations/remove-student-group',
    async ({ studentId, groupId }, thunkAPI) => {
        let FeedbackService = new Feedback()
        try {

            // ELIMINAMOS EL GRUPO DEL ALUMNO
            let group = await Services.deleteStudentFromGroup(studentId, groupId).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneGroup(group))

            // ACTUALIZAR DATOS DEL ALUMNO
            let studentUpdate = await Services.getStudentById(studentId).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneStudent(studentUpdate))

            // ELIMINACION DE GRUPOS ALUMNOS
            let groupsStudentsEntities = selectAllGroupsStudents(thunkAPI.getState())
            let gsToDelete = groupsStudentsEntities.filter(e => e.student_id == studentId && e.group_id == groupId)

            for (const groupsStudent of gsToDelete) {
                thunkAPI.dispatch(removeOneGroupsStudents(groupsStudent.id))
            }

            return {
                studentUpdate,
                group,
                gsToDelete
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)
/**
 * Organiza usuarios 
 * 
 * @param {*} currentUsers 
 * @param {*} alteredUsers 
 * @returns 
 */
const getOrganizedUsers = (currentUsers, alteredUsers) => {
    let assessorsDeleted = _.differenceWith(currentUsers, alteredUsers, (a, b) => {
        return a.user_id == b.user_id
    })

    let assessorsCreated = _.differenceWith(alteredUsers, currentUsers, (a, b) => {
        return a.user_id == b.user_id
    })

    let assessorsUpdate = _.intersectionWith(currentUsers, alteredUsers, (a, b) => {
        return a.user_id == b.user_id
    });

    return {
        eliminated: assessorsDeleted,
        created: assessorsCreated,
        updated: assessorsUpdate
    }
}
/**
 * Eliminar profesor de materia
 */
export const deleteSubjectProfessor = createAsyncThunk(
    'groupsUI/operations/delete-subject-professor',
    async (subjectId, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let subject = await Services.updateSubjectById(subjectId, {
                professor_id: null
            }).then(i => i.data.data)

            thunkAPI.dispatch(upsertOneSubject(subject))

            return subject
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Agrega o actualiza el profesor de una materia
 */
export const setSubjectProfessor = createAsyncThunk(
    'groupsUI/operations/set-professor',
    async ({ subjectId, professor }, thunkAPI) => {
        let FeedbackService = new Feedback()
        let Auth = new Authentication()
        
        try {
            let subject = await Services.updateSubjectById(subjectId, {
                professor_id: professor.professor_id
            }).then(i => i.data.data)

            thunkAPI.dispatch(upsertOneSubject(subject))

            professor.user_id = Auth.getUserID(professor)

            thunkAPI.dispatch(upsertOneUser(professor))

            return {
                subject,
                professor
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Agregar materias a un grupo
 */
export const addSubjectGroup = createAsyncThunk(
    'groupsUI/operations/add-subject',
    async ({ groupId, subjectsCreate, subjectDelete }, thunkAPI) => {
        let FeedbackService = new Feedback()
        let message = "Materias actualizadas"

        console.log(groupId)

        try {
            let subjectsCreated = []
            let subjectDeleted = []

            //////////////// CREAR MATERIAS //////////////

            for (const subject of subjectsCreate) {
                try {
                    let object = {
                        catalog_subject_id: subject.catalog_subject_id,
                        group_id: groupId
                    }

                    let subjectData = await Services.setSubject(object).then(i => i.data.data)

                    subjectsCreated.push(subjectData)

                    thunkAPI.dispatch(addOneSubject(subjectData));
                } catch (err) {
                    //message = "Materias actualizadas"
                }
            }

            /////////////// ELIMINAR MATERIAS ////////////

            for (const subject of subjectDelete) {
                try {
                    let subjectData = await Services.deleteSubject(subject.subject_id).then(i => i.data.data)

                    subjectDeleted.push(subjectData)

                    thunkAPI.dispatch(removeOneSubject(subjectData));
                } catch (err) {
                    //message = "Materias actualizadas"
                }
            }

            //////////////// ACTUALIZAR GRUPO ////////////////////

            let group = await Services.getGroupById(groupId).then(i => i.data.data)
            console.log(group)
            thunkAPI.dispatch(upsertOneGroup(group))

            if (subjectsCreated.length == 0 && subjectDeleted.length == 0) {
                message = "Estamos teniendo problemas, comunícate con el soporte técnico"
            }

            return {
                group,
                subjectsCreated,
                subjectDeleted,
                message
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Dar de baja un alumno
 */
export const deleteStudent = createAsyncThunk(
    'groupsUI/operations/delete-student',
    async ({ studentId, password }, thunkAPI) => {
        let FeedbackService = new Feedback()
        try {

            /**
             * Comunicacion con el service
             */
            let student = await Services.deleteStudent(studentId, password).then(i => i.data.data)

            /**
             * Eliminamos al alumno de los grupos
             */
            let groupsStudentsEntities = selectAllGroupsStudents(thunkAPI.getState())
            let gsToDelete = groupsStudentsEntities.filter(e => e.student_id == studentId)

            for (const groupsStudent of gsToDelete) {
                thunkAPI.dispatch(removeOneGroupsStudents(groupsStudent.id))
            }

            /**
             * Actualizamos los grupos afectados
             */
            let groupsEntities = selectAllGroupsEntities(thunkAPI.getState())

            let groups = gsToDelete.map(i => groupsEntities[i.group_id])

            for (const group of groups) {
                thunkAPI.dispatch(upsertOneGroup({
                    ...group, ...{
                        students: group.students - 1
                    }
                }));
            }

            thunkAPI.dispatch(upsertOneStudent(student))

            return {
                student,
                groups,
                gsToDelete
            }
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Agregar allumno existente a un grupo
 */
export const addStudentToGroup = createAsyncThunk(
    'groupsUI/operations/add-student-group',
    async ({ groupId, student }, thunkAPI) => {
        let FeedbackService = new Feedback()
       
        try {
            //actualización de relación entre alumno y grupo  

            let groupUpdated = await Services.setStudentsToGroup(student.student_id, groupId).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneGroup(groupUpdated))

            let studentUpdate = await Services.getStudentById(student.student_id).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneStudent(studentUpdate))

            // Agregar al alumno al grupo en la store

            thunkAPI.dispatch(addOneGroupsStudents({
                student_id: student.student_id,
                group_id: groupId
            }))

            return {
                studentUpdate,
                groupUpdated
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)

/**
 * Agregar allumno existente a un grupo
 */
export const createStudentToGroup = createAsyncThunk(
    'groupsUI/operations/create-student-group',
    async ({ schoolId, groupId, student }, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            // Crear y asignar alumno a un grupo

            let studentCreated = await Services.createAndSetStudentToGroup(schoolId, groupId, student).then(i => i.data.data)
            thunkAPI.dispatch(addOneStudent(studentCreated))

            // Recuperamos datos actuales del grupo

            let group = await Services.getGroupById(groupId).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneGroup(group))

            // Agregar al alumno al grupo en la store

            thunkAPI.dispatch(addOneGroupsStudents({
                student_id: studentCreated.student_id,
                group_id: groupId
            }))

            return {
                studentCreated,
                group
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Eliminar una materia de un grupo
 */
export const deleteGroupSubject = createAsyncThunk(
    'groupsUI/operations/delete-group-subject',
    async (subjectId, thunkAPI) => {
        let FeedbackService = new Feedback()
        try {
            let subjectData = await Services.deleteSubject(subjectId).then(i => i.data.data)

            let group = selectGroupsById(subjectData.group_id)(thunkAPI.getState())

            thunkAPI.dispatch(upsertOneGroup({
                ...group, ...{
                    subjects: group.subjects - 1
                }
            }));

            thunkAPI.dispatch(removeOneSubject(subjectData));

            return {
                group,
                subjectData
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)