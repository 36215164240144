
import { Avatar, Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

const Testimonial = ({ message, icon, name }) => {
    return (
        <Card sx={{ height: 220 }}>
            <CardContent>
                <Stack spacing={2} direction={"column"} justifyContent={"space-between"}>
                    <Typography component={"div"} variant={"h6"}>
                        {message}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                        <Avatar>
                            {icon}
                        </Avatar>
                        <Box>
                            <Typography component={"div"} variant={"subtitle1"}>
                                {name}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Testimonial