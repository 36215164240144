import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Typography, Button, Grid
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import StudentsSearch from '../../../components/Search/StudentsSearch';
import useFeedback from '../../../hooks/useFeedback';
import { addStudentToGroup, createStudentToGroup, selectAddStudentToGroupOperationStatus } from '../../../store/slices/groupsUI/operationsSlice';
import { selectGroupByItemSelected } from '../../../store/slices/groupsUI/itemSlice';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';

/**
 * Componente modal para registrar alumnos a un grupo
 * 
 * @param {*} param0 
 * @returns 
 */
export default ({ open, setOpen, groupId = null }) => {

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    const dispatch = useDispatch()
    const feedbackApp = useFeedback()
    const groupSelected = useSelector(selectGroupByItemSelected)
    groupId = groupSelected?.group_id

    /////////////////// LOCAL STATE //////////////////////

    const [student, setStudent] = useState({
        group_id: null,
        school_id: null,
        name: '',
        last_name: '',
        second_last_name: '',
        cellphone: '',
        folio: '',
        colony: '',
        inside_number: '',
        outside_number: '',
        street: '',
        zipcode: '',
        city_id: null,
    })

    const [viewType, setViewType] = useState(1);
    const [filterParameters, setFilterParameters] = useState({})

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectAddStudentToGroupOperationStatus)
    let group = useSelector(selectGroupByItemSelected)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    ////////////////////// ACTIONS ///////////////////

    /**
     * Efecto para reiniciar todos los valores
     */
    useEffect(() => {
        if (!open) {
            setValue(null);
            setInputValue('');
        } else {
            if (group.special) {
                //setFilterParameters({state})
            } else {
                setFilterParameters({ "group_id": null })
            }
        }
    }, [open])

    /**
     * Agregar el alumno al grupo
     * 
     * @returns 
     */
    const guardarDatos = () => {
        if (viewType == 1) {
            dispatch(addStudentToGroup({
                student: value,
                groupId
            }))
                .unwrap()
                .then(({ message }) => {
                    feedbackApp.showFeedback({
                        title: "Alumno agregado al grupo"
                    })

                    handleClose()
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })

        } else {
            dispatch(createStudentToGroup({
                schoolId,
                student,
                groupId
            }))
                .unwrap()
                .then(({ message }) => {
                    feedbackApp.showFeedback({
                        title: "Alumno agregado al grupo"
                    })

                    handleClose()
                }).catch(({ feedback }) => {
                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                })
        }
    }

    const resetModal = () => {
        setValue(null);
        setInputValue('');
        setStudent({
            group_id: null,
            school_id: null,
            name: '',
            last_name: '',
            second_last_name: '',
            cellphone: '',
            folio: '',
            colony: '',
            inside_number: '',
            outside_number: '',
            street: '',
            zipcode: '',
            city_id: null,
        })
    }

    /**
     * Cambiar el tipo de vista
     */
    const onChangeViewType = () => {
        setViewType(viewType == 1 ? 2 : 1)
        setValue(null);
        setInputValue('');

        setStudent({
            group_id: null,
            school_id: null,
            name: '',
            last_name: '',
            second_last_name: '',
            cellphone: '',
            folio: '',
            colony: '',
            inside_number: '',
            outside_number: '',
            street: '',
            zipcode: '',
            city_id: null,
        })
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setStudent({ ...student, [name]: value });
    }

    // DESABILITA LA OPCION DE CERRAR MODAL AL CLICKEAR FUERA //
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        resetModal()
        setOpen(false)
        dispatch(selectGroupItem(null));  
    }


    ////////////////////////// VALDIACIONES /////////////////////
    const nameValid = () => {
        return {
            invalid: student.name == '' || student.name.length > 50,
            message: "Campo requerido, Maximo 50 caracteres"
        }
    }

    const lastNameValid = () => {
        return {
            invalid: student.last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const secondLastNameValid = () => {
        return {
            invalid: false, //userData.second_last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const folioValid = () => {
        return {
            invalid: student.folio == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion para el numero de telefono
     * 
     * @returns 
     */
    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(student.cellphone);

        return {
            invalid: !isPhone,
            message: "Campo requerido o incorrecto"
        }
    }

    const streetValid = () => {
        return {
            invalid: student.street == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const colonyValid = () => {
        return {
            invalid: student.colony == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const outsideNumberValid = () => {
        return {
            invalid: student.outside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const insideNumberValid = () => {
        return {
            invalid: false, //student.inside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const zipcodeValid = () => {
        return {
            invalid: student.zipcode == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const formInvalid = () => {
        if (viewType == 1) {
            return value == null
        } else {
            return (
                nameValid().invalid ||
                lastNameValid().invalid ||
                secondLastNameValid().invalid ||
                folioValid().invalid ||
                cellphoneValid().invalid ||
                streetValid().invalid ||
                colonyValid().invalid ||
                outsideNumberValid().invalid ||
                insideNumberValid().invalid ||
                zipcodeValid().invalid
            )
        }
    }

    /**
     * El alumno se ha seleccionado
     */
    const onSelectParent = (data) => {
        setValue(data)
    }

    ////////////////////// VIEW //////////////////////////

    /**
     * Mostra boton para cambiar entre formas de registro
     * @returns 
     */
    const getButtons = () => {

        let icon = (viewType == 1) ? (<CreateIcon />) : (<SearchIcon />)
        let text = (viewType == 1) ? ("Registrar nuevo") : ("Buscar existente")

        return (
            <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onChangeViewType}
                startIcon={icon}
                disabled={statusOperation == 'pending'}
            >
                {text}
            </Button>
        )
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={viewType == 1 ? "sm" : "md"}
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={ handleClose }
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex', color: fontColor.value }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        Agregar alumno a grupo<br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Grupo {group?.grade}° {group?.group} {getTurnLevel(group?.turn)} {getLevelName(group?.level)}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {
                    viewType == 1 && (
                        <Box>
                            <StudentsSearch
                                title={"Buscar alumno existente"}
                                schoolId={schoolId}
                                onChange={onSelectParent}
                                filterParameters={filterParameters} />
                        </Box>
                    )
                }
                {
                    viewType == 2 && (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" component="div" style={{ width: '100%' }} >
                                    Datos generales
                                </Typography>
                            </Grid>
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={student.name}
                                    onChange={onInputChange}
                                    error={nameValid().invalid}
                                    helperText={nameValid().invalid ? nameValid().message : false}
                                />
                            </Grid>
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="last_name"
                                    name="last_name"
                                    label="Apellido Paterno"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={student.last_name}
                                    onChange={onInputChange}
                                    error={lastNameValid().invalid}
                                    helperText={lastNameValid().invalid ? lastNameValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="second_last_name"
                                    name="second_last_name"
                                    label="Apellido Materno"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    variant="standard"
                                    inputProps={{ maxLength: 50 }}
                                    value={student.second_last_name}
                                    onChange={onInputChange}
                                    error={secondLastNameValid().invalid}
                                    helperText={secondLastNameValid().invalid ? secondLastNameValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="folio"
                                    name="folio"
                                    label="Matricula"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 25 }}
                                    value={student.folio}
                                    onChange={onInputChange}
                                    error={folioValid().invalid}
                                    helperText={folioValid().invalid ? folioValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="cellphone"
                                    name="cellphone"
                                    label="Teléfono"
                                    type="tel"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    value={student.cellphone}
                                    onChange={onInputChange}
                                    error={cellphoneValid().invalid}
                                    helperText={cellphoneValid().invalid ? cellphoneValid().message : false}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" component="div" style={{ width: '100%' }} >
                                    Domicilio
                                </Typography>
                            </Grid>

                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="street"
                                    name="street"
                                    label="Calle"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={student.street}
                                    onChange={onInputChange}
                                    error={streetValid().invalid}
                                    helperText={streetValid().invalid ? streetValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="colony"
                                    name="colony"
                                    label="Colonia"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={student.colony}
                                    onChange={onInputChange}
                                    error={colonyValid().invalid}
                                    helperText={colonyValid().invalid ? colonyValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="outside_number"
                                    name="outside_number"
                                    label="Número Exterior"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 25 }}
                                    value={student.outside_number}
                                    onChange={onInputChange}
                                    error={outsideNumberValid().invalid}
                                    helperText={outsideNumberValid().invalid ? outsideNumberValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="inside_number"
                                    name="inside_number"
                                    label="Número Interior"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 20 }}
                                    value={student.inside_number}
                                    onChange={onInputChange}
                                    error={insideNumberValid().invalid}
                                    helperText={insideNumberValid().invalid ? insideNumberValid().message : false}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="zipcode"
                                    name="zipcode"
                                    label="Código Postal"
                                    type="text"
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 25 }}
                                    value={student.zipcode}
                                    onChange={onInputChange}
                                    error={zipcodeValid().invalid}
                                    helperText={zipcodeValid().invalid ? zipcodeValid().message : false}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </DialogContent>
            <DialogActions>

                {getButtons()}

                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={guardarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Guardar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={ handleClose }
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
