import { combineReducers } from "@reduxjs/toolkit";
import settingsReducer from "./settings";
import partialReducer from "./partials";
import schoolsReducer from "./schools"
import usersReducer from "./users"
import GroupsSlice from "./groups"
import StudentsSlice from "./students"
import SubjectcatalogsSlice from "./subjectcatalogs"
import SubjectsSlice from "./subjects"
import GroupsStudentsSlice from "./groups_students"
import ReportsCatalogsSlice from "./reportscatalogs"
import ReportsSlice from "./reports"
import FilesSlice from "./files"
import noticesSlice from "./notices"
import relationshipsSlice from "./relationships"
import califications from "./califications"
import assists from "./assists"

export const entities = combineReducers({
    settings: settingsReducer,
    partials: partialReducer,
    schools: schoolsReducer,
    users: usersReducer,
    groups: GroupsSlice,
    subjectcatalogs: SubjectcatalogsSlice,
    students: StudentsSlice,
    subjects: SubjectsSlice,
    groupsStudents: GroupsStudentsSlice,
    reportscatalogs: ReportsCatalogsSlice,
    reports: ReportsSlice,
    files: FilesSlice,
    notices: noticesSlice,
    relationships: relationshipsSlice,
    califications,
    assists
})