import { useAuth } from "../../hooks";
import { Error, SkeletonGrid } from "../../components/Feedback";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import * as  _ from "lodash"

const Testing = () => {

    const Auth = useAuth();

    let route = Auth.getHomeRoute()

    console.log(route)

    let data = {
        data: [
            {
                user_id: 409,
                professor_id: 409,
                credential_id: 1283,
                school_id: 21,
                enrollment: "0000001",
                name: "María Teresa",
                last_name: "Reyes",
                second_last_name: "Nava",
                email: "reyesnavamariateresa@gmail.com",
                url_photo_profile: null,
                cellphone: "6181317138",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2023-02-06 16:04:15",
                created_at: "2021-09-09 16:11:06",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 5,
                reports: 0,
                assists_list_percent: 1,
                last_score_created: "2021-11-26 18:12:44",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/409/subjects"
                    }
                ]
            },
            {
                user_id: 410,
                professor_id: 410,
                credential_id: 1284,
                school_id: 21,
                enrollment: "0000002",
                name: "Eduardo Alfredo",
                last_name: "Reyes",
                second_last_name: "Navar",
                email: "adgallo79@gmail.com",
                url_photo_profile: "https://dev.webservice.curaescolar.com.mx//storage/users/si1ELqwdeWCEFPPxbWlD8jms7XKuB5JXSQPNV740.jpeg",
                cellphone: "6191111111",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "605",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01110000000000000000",
                created_by: "408",
                updated_at: "2023-02-06 17:16:38",
                created_at: "2021-09-09 16:14:00",
                push_id: "6aaac27f-429f-4e60-b62c-7738f1c76d14",
                push_token: "e3X6ZM8OTW-dUrKvL71QGK:APA91bGhT9lNrGKprzjdPatqOschdkBu3b-j7f0-tYTtlN7oCdygLWnQElUiaVU76951_QNipcpCq0VWHEhNYftria886EGUxarcp6rgmv2OCqLWT0vDp64CqBrakUfHFz88EW4npQpi",
                subjects: 3,
                groups: 3,
                reports: 0,
                assists_list_percent: 1,
                last_score_created: "2022-05-02 20:50:54",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/410/subjects"
                    }
                ]
            },
            {
                user_id: 411,
                professor_id: 411,
                credential_id: 1285,
                school_id: 21,
                enrollment: "0000003",
                name: "María Catalina",
                last_name: "Muñoz",
                second_last_name: "Sánchez",
                email: "katyhumbolt@gmail.com",
                url_photo_profile: null,
                cellphone: "1000000003",
                has_smartphone: 0,
                send_sms: 1,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-08-01 12:14:27",
                created_at: "2021-09-09 16:18:18",
                push_id: null,
                push_token: null,
                subjects: 3,
                groups: 1,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: "2023-02-06 17:17:16",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/411/subjects"
                    }
                ]
            },
            {
                user_id: 412,
                professor_id: 412,
                credential_id: 1286,
                school_id: 21,
                enrollment: "0000004",
                name: "Linda Patricia",
                last_name: "Cortés",
                second_last_name: "Holguín",
                email: "lindacortes878@gmail.com",
                url_photo_profile: null,
                cellphone: "1000000004",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2021-12-13 20:17:16",
                created_at: "2021-09-09 16:19:23",
                push_id: null,
                push_token: null,
                subjects: 3,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/412/subjects"
                    }
                ]
            },
            {
                user_id: 413,
                professor_id: 413,
                credential_id: 1287,
                school_id: 21,
                enrollment: "0000005",
                name: "Arodi",
                last_name: "Fuentes",
                second_last_name: "Castro",
                email: "arodifc08@outlook.com",
                url_photo_profile: null,
                cellphone: "6182704487",
                has_smartphone: 0,
                send_sms: 1,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2021-09-09 16:21:15",
                created_at: "2021-09-09 16:21:15",
                push_id: null,
                push_token: null,
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/413/subjects"
                    }
                ]
            },
            {
                user_id: 414,
                professor_id: 414,
                credential_id: 1288,
                school_id: 21,
                enrollment: "0000006",
                name: "Eugenia",
                last_name: "Acosta",
                second_last_name: "Alvarez",
                email: "eaa640704@gmail.com",
                url_photo_profile: null,
                cellphone: "6181708656",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 18:01:46",
                created_at: "2021-09-09 16:23:51",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 4,
                reports: 0,
                assists_list_percent: 7,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/414/subjects"
                    }
                ]
            },
            {
                user_id: 415,
                professor_id: 415,
                credential_id: 1289,
                school_id: 21,
                enrollment: "0000007",
                name: "Mariana",
                last_name: "Garcia",
                second_last_name: "Reyes",
                email: "mariana.grcreyes@gmail.com",
                url_photo_profile: null,
                cellphone: "6188069757",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2023-01-06 17:46:44",
                created_at: "2021-09-09 16:26:19",
                push_id: "f7423720-ea14-4ccf-a6da-a087a1298116",
                push_token: "c-36wZ7MTC2sofgDjvNSlN:APA91bHlbHOWVWQ2WM8xae_oaYvYiNJhSpFhbDIIugrHMlc-Nq6mOrsAuiPBXojPB_vowambyz2h5CZrzLeiO-cJ01WBNGiB4H8qnHLgIu5XTDSgMiHnNHrJGCaq_ybwIPUMfgs9YEAl",
                subjects: 6,
                groups: 6,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: "2021-11-26 17:18:18",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/415/subjects"
                    }
                ]
            },
            {
                user_id: 416,
                professor_id: 416,
                credential_id: 1290,
                school_id: 21,
                enrollment: "0000008",
                name: "Pascual",
                last_name: "Jiménes",
                second_last_name: "Ramírez",
                email: "jirap63@gmail.com",
                url_photo_profile: null,
                cellphone: "6181342275",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2021-11-26 19:31:14",
                created_at: "2021-09-09 16:27:14",
                push_id: null,
                push_token: null,
                subjects: 8,
                groups: 4,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: "2021-11-26 19:30:48",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/416/subjects"
                    }
                ]
            },
            {
                user_id: 417,
                professor_id: 417,
                credential_id: 1291,
                school_id: 21,
                enrollment: "0000009",
                name: "Roberto",
                last_name: "Gutiérrez",
                second_last_name: "Jáquez",
                email: "robertogutierrezja@gmail.com",
                url_photo_profile: null,
                cellphone: "6182425741",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 14:56:42",
                created_at: "2021-09-09 16:28:22",
                push_id: null,
                push_token: null,
                subjects: 7,
                groups: 7,
                reports: 0,
                assists_list_percent: 3,
                last_score_created: "2021-11-26 17:36:59",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/417/subjects"
                    }
                ]
            },
            {
                user_id: 418,
                professor_id: 418,
                credential_id: 1292,
                school_id: 21,
                enrollment: "0000010",
                name: "Marcela",
                last_name: "Godina",
                second_last_name: "Salazar",
                email: "godinamarcela72@gmail.com",
                url_photo_profile: null,
                cellphone: "6182039860",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-08-23 20:11:38",
                created_at: "2021-09-09 16:31:04",
                push_id: null,
                push_token: null,
                subjects: 9,
                groups: 6,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: "2021-11-26 19:32:51",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/418/subjects"
                    }
                ]
            },
            {
                user_id: 419,
                professor_id: 419,
                credential_id: 1293,
                school_id: 21,
                enrollment: "0000011",
                name: "Mauricio",
                last_name: "Gámez",
                second_last_name: "Chávez",
                email: "coyotus79@gmail.com",
                url_photo_profile: null,
                cellphone: "1000000011",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 18:17:25",
                created_at: "2021-09-09 16:32:15",
                push_id: null,
                push_token: null,
                subjects: 7,
                groups: 7,
                reports: 0,
                assists_list_percent: 1,
                last_score_created: "2021-11-26 17:29:24",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/419/subjects"
                    }
                ]
            },
            {
                user_id: 420,
                professor_id: 420,
                credential_id: 1294,
                school_id: 21,
                enrollment: "0000012",
                name: "Leslie Marissa",
                last_name: "Vidales",
                second_last_name: "Delfín",
                email: "vidalesleslie09@gmail.com",
                url_photo_profile: null,
                cellphone: "6181333022",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-07 19:00:10",
                created_at: "2021-09-09 16:33:10",
                push_id: null,
                push_token: null,
                subjects: 9,
                groups: 5,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: "2021-11-26 17:41:06",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/420/subjects"
                    }
                ]
            },
            {
                user_id: 421,
                professor_id: 421,
                credential_id: 1295,
                school_id: 21,
                enrollment: "0000013",
                name: "Ana Teresa",
                last_name: "Reyes",
                second_last_name: "Acosta",
                email: "anatreyesa@gmail.com",
                url_photo_profile: null,
                cellphone: "6181099774",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 14:32:30",
                created_at: "2021-09-09 16:34:01",
                push_id: null,
                push_token: null,
                subjects: 7,
                groups: 5,
                reports: 0,
                assists_list_percent: 6,
                last_score_created: "2022-01-22 18:16:20",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/421/subjects"
                    }
                ]
            },
            {
                user_id: 422,
                professor_id: 422,
                credential_id: 1296,
                school_id: 21,
                enrollment: "0000014",
                name: "Ana Karina",
                last_name: "Solis",
                second_last_name: "Campos",
                email: "ana.soliscampos@gmail.com",
                url_photo_profile: null,
                cellphone: "6182052806",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 16:04:55",
                created_at: "2021-09-09 16:35:30",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 5,
                reports: 0,
                assists_list_percent: 23,
                last_score_created: "2022-08-23 21:46:36",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/422/subjects"
                    }
                ]
            },
            {
                user_id: 423,
                professor_id: 423,
                credential_id: 1297,
                school_id: 21,
                enrollment: "0000015",
                name: "Félix Gerardo",
                last_name: "García",
                second_last_name: "Contreras",
                email: "fega1973@gmail.com",
                url_photo_profile: null,
                cellphone: "6182176368",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 19:01:18",
                created_at: "2021-09-09 16:36:17",
                push_id: null,
                push_token: null,
                subjects: 3,
                groups: 3,
                reports: 0,
                assists_list_percent: 9,
                last_score_created: "2022-01-21 17:41:30",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/423/subjects"
                    }
                ]
            },
            {
                user_id: 424,
                professor_id: 424,
                credential_id: 1298,
                school_id: 21,
                enrollment: "0000016",
                name: "José Manuel",
                last_name: "Galarza",
                second_last_name: "Martínez",
                email: "josemanuelgalarza210777@gmail.com",
                url_photo_profile: null,
                cellphone: "1000000016",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 19:12:34",
                created_at: "2021-09-09 16:37:41",
                push_id: null,
                push_token: null,
                subjects: 6,
                groups: 5,
                reports: 0,
                assists_list_percent: 27,
                last_score_created: "2022-01-18 00:23:22",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/424/subjects"
                    }
                ]
            },
            {
                user_id: 425,
                professor_id: 425,
                credential_id: 1299,
                school_id: 21,
                enrollment: "0000017",
                name: "Julia Andrea",
                last_name: "Palacios",
                second_last_name: "Vaquera",
                email: "et.japv@gmail.com",
                url_photo_profile: null,
                cellphone: "6181330820",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 18:10:42",
                created_at: "2021-09-09 16:39:31",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 5,
                reports: 0,
                assists_list_percent: 20,
                last_score_created: "2021-11-29 03:44:36",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/425/subjects"
                    }
                ]
            },
            {
                user_id: 426,
                professor_id: 426,
                credential_id: 1300,
                school_id: 21,
                enrollment: "0000018",
                name: "Martha Patricia",
                last_name: "Lugo",
                second_last_name: "Pérez",
                email: "mlugoperez@gmail.com",
                url_photo_profile: null,
                cellphone: "6181129259",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 15:34:28",
                created_at: "2021-09-09 16:40:28",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 5,
                reports: 0,
                assists_list_percent: 14,
                last_score_created: "2021-11-28 02:06:18",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/426/subjects"
                    }
                ]
            },
            {
                user_id: 427,
                professor_id: 427,
                credential_id: 1301,
                school_id: 21,
                enrollment: "0000019",
                name: "Alfonso",
                last_name: "Álvarez",
                second_last_name: "Retana",
                email: "alfonsoalvarezret644@gmail.com",
                url_photo_profile: null,
                cellphone: "6182182386",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 17:03:14",
                created_at: "2021-09-09 16:41:17",
                push_id: null,
                push_token: null,
                subjects: 2,
                groups: 2,
                reports: 0,
                assists_list_percent: 3,
                last_score_created: "2022-01-20 22:47:37",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/427/subjects"
                    }
                ]
            },
            {
                user_id: 428,
                professor_id: 428,
                credential_id: 1302,
                school_id: 21,
                enrollment: "0000020",
                name: "Martín",
                last_name: "Flores",
                second_last_name: "Rojas",
                email: "profemartinflores@gmail.com",
                url_photo_profile: null,
                cellphone: "6183097039",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-24 19:11:56",
                created_at: "2021-09-09 16:42:07",
                push_id: null,
                push_token: null,
                subjects: 5,
                groups: 5,
                reports: 0,
                assists_list_percent: 13,
                last_score_created: "2022-01-24 02:21:24",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/428/subjects"
                    }
                ]
            },
            {
                user_id: 429,
                professor_id: 429,
                credential_id: 1303,
                school_id: 21,
                enrollment: "0000021",
                name: "Roberto",
                last_name: "Herrera",
                second_last_name: "Alvarado",
                email: "roberthor2020@gmail.com",
                url_photo_profile: null,
                cellphone: "6181272853",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-01-21 02:27:50",
                created_at: "2021-09-09 16:42:52",
                push_id: null,
                push_token: null,
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/429/subjects"
                    }
                ]
            },
            {
                user_id: 430,
                professor_id: 430,
                credential_id: 1304,
                school_id: 21,
                enrollment: "0000022",
                name: "Jazziel",
                last_name: "Ortegón",
                second_last_name: "López",
                email: "jazzielortegonlopez@gmail.com",
                url_photo_profile: null,
                cellphone: "6181178351",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-03-07 22:50:59",
                created_at: "2021-09-09 16:43:38",
                push_id: null,
                push_token: null,
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/430/subjects"
                    }
                ]
            },
            {
                user_id: 431,
                professor_id: 431,
                credential_id: 1305,
                school_id: 21,
                enrollment: "0000023",
                name: "Marlenne Edith",
                last_name: "Perales",
                second_last_name: "García",
                email: "mer.pega20@gmail.com",
                url_photo_profile: null,
                cellphone: "6188323683",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-03-07 22:51:00",
                created_at: "2021-09-09 16:44:28",
                push_id: null,
                push_token: null,
                subjects: 2,
                groups: 2,
                reports: 0,
                assists_list_percent: 42,
                last_score_created: "2021-11-26 17:15:47",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/431/subjects"
                    }
                ]
            },
            {
                user_id: 432,
                professor_id: 432,
                credential_id: 1306,
                school_id: 21,
                enrollment: "0000024",
                name: "Alejandra",
                last_name: "Díaz De León",
                second_last_name: "Gallardo",
                email: "diazdelealejandra@gmail.com",
                url_photo_profile: null,
                cellphone: "1000000024",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-08-23 20:12:57",
                created_at: "2021-09-09 16:45:19",
                push_id: null,
                push_token: null,
                subjects: 8,
                groups: 8,
                reports: 0,
                assists_list_percent: 3,
                last_score_created: "2021-11-27 19:16:21",
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/432/subjects"
                    }
                ]
            },
            {
                user_id: 433,
                professor_id: 433,
                credential_id: 1307,
                school_id: 21,
                enrollment: "0000025",
                name: "Francisco De Jesús",
                last_name: "Morales",
                second_last_name: "Corral",
                email: "moralescorralf@gmail.com",
                url_photo_profile: null,
                cellphone: "6181572631",
                has_smartphone: 1,
                send_sms: 0,
                country_code: "52",
                colony: "Pendiente",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "408",
                updated_at: "2022-03-07 22:49:58",
                created_at: "2021-09-09 16:46:07",
                push_id: "d8011786-48fe-4f31-b98c-a05e36efdaba",
                push_token: "csJN-zZKJAA:APA91bGe7eqy_sEhPFIeq2YWb7BFkQYrXwwfKdtw6PkJrHS3b20k_hHWDiN9V-N-_DRE1pNEcWxzIles9GBGzYxoD5XYQ6ou0DMuVzUT3GR_cAsCUUTQtBSP0ORQNKg_WDUVO9niOGzp",
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/433/subjects"
                    }
                ]
            },
            {
                user_id: 5233,
                professor_id: 5233,
                credential_id: 7057,
                school_id: 21,
                enrollment: "101010101010",
                name: "Pruebas",
                last_name: "Gonzalez",
                second_last_name: "Aguilars",
                email: "elsantos_2@hotmail.com",
                url_photo_profile: null,
                cellphone: "6187777777",
                has_smartphone: 0,
                send_sms: 1,
                country_code: "52",
                colony: "Colonia",
                inside_number: null,
                outside_number: "1223",
                street: "Calle",
                zipcode: "34287",
                city_id: 256,
                status: 1,
                group_id: "11000100000000000000",
                created_by: "401",
                updated_at: "2022-03-08 14:35:17",
                created_at: "2022-03-07 00:28:18",
                push_id: null,
                push_token: null,
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5233/subjects"
                    }
                ]
            },
            {
                user_id: 5236,
                professor_id: 5236,
                credential_id: 7060,
                school_id: 21,
                enrollment: "500022020",
                name: "Prueba",
                last_name: "Prueba",
                second_last_name: "Prueba",
                email: "pendiente@pendiente.com",
                url_photo_profile: null,
                cellphone: "5000000000",
                has_smartphone: 0,
                send_sms: 1,
                country_code: "52",
                colony: "Pendientes",
                inside_number: null,
                outside_number: "0",
                street: "Pendiente",
                zipcode: "34000",
                city_id: 256,
                status: 1,
                group_id: "01000000000000000000",
                created_by: "401",
                updated_at: "2022-08-01 12:16:24",
                created_at: "2022-08-01 12:16:24",
                push_id: null,
                push_token: null,
                subjects: 0,
                groups: 0,
                reports: 0,
                assists_list_percent: 0,
                last_score_created: null,
                link: [
                    {
                        rel: "self",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236"
                    },
                    {
                        rel: "professors.schools",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236/schools"
                    },
                    {
                        rel: "professors.reports",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236/reports"
                    },
                    {
                        rel: "professors.permissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236/permissions"
                    },
                    {
                        rel: "professors.guestpermissions",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236/guestpermissions"
                    },
                    {
                        rel: "professors.subjects",
                        href: "https://dev.webservice.eficazescolar.com.mx/v2/professors/5236/subjects"
                    }
                ]
            }
        ],
        meta: {
            paginated: false,
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 27
        }
    }

    //console.log(data)

    console.log(_.map(sortByDate(data.data, "last_score_created", "asc"), "last_score_created"))
    //let profesror = sortByDate(data.data, "last_score_created", "asc")[0]
    //console.log(profesror)

    _.sortBy()

    function sortByDate(list, field, orderType = "ACS") {

        list.sort((itemA, itemB) => {

            let a = DateTime.fromSQL(itemA[field])
            let b = DateTime.fromSQL(itemB[field])

            console.log({
                "return": "0",
                "a": itemA[field],
                "b": itemB[field]
            })

            if (a > b) {
            return -1;
            }
            if (a < b) {
                return 1;
            }
            // a debe ser igual b
            return 0;
        })

        return list
    }

    return (<Box>
        <SkeletonGrid items={9} direction={"column"} />
    </Box>)

}

export default Testing