let Constantes = {
    RUTA_API: process.env.REACT_APP_RUTA_API,
    VERSION: process.env.REACT_APP_VERSION,
    ID_CLIENT: process.env.REACT_APP_ID_CLIENT,
    SECRET_CLIENT: process.env.REACT_APP_SECRET_CLIENT,
    WEBSERVICE_V: process.env.REACT_APP_WEBSERVICE_V,
    ONESIGNAL_ID: process.env.REACT_APP_ONESIGNAL_ID,
    ONESIGNAL_KEY: process.env.REACT_APP_ONESIGNAL_KEY,
    CLIENT_ID_CLIENT: process.env.REACT_APP_CLIENT_ID_CLIENT,
    CLIENT_SECRET_CLIENT: process.env.REACT_APP_CLIENT_SECRET_CLIENT,
};

console.log("Enviroment: " + process.env.NODE_ENV)
console.log("RUTA_API: " + Constantes.RUTA_API)

export default Constantes;