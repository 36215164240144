import { combineReducers } from "@reduxjs/toolkit";

import items from "./ItemsSlice";
import ui from "./uiSlice";
import server from "./serverSlice";
import operations from "./operationsSlice"

export default combineReducers({
    items,
    server,
    operations,
    ui
})