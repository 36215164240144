import React, { useState } from 'react'
import { Box, Stack, CardContent, Typography, Card, CardActions, TextField, Snackbar, CardHeader, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import "./common.css"

import Services from '../../service/EficazAPI';
import Feedback from '../../service/Feedback';
import ClientHttp from '../../service/HttpClient';

const RecoverAccount = () => {

    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) =>  {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const [loading, setLoading] = React.useState(false);
    
    ///////////////////////// CODIGO DEL COMPONENTE ////////////////////////////////

    const [email, setEmail] = useState('')
 
    const [FeedbackAPI] = useState(() => new Feedback())

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    function onEmailInputChange (event) {
        const value = event.target.value;
        const name = event.target.name;
        
        setEmail(value);
    }

    const emailValid = () => {
        const regexEmail = /^[0-9]{10}$/
        //|| !(regexEmail.test(cellphone))

        return {
            invalid: email == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const onResetPassword = () => {
        setLoading(true);
        
        let client = new ClientHttp()
        client.useDomain()
        let EficazApi = new Services(client)

        EficazApi.recoverAccount({email:email}).then(response => {
            showFeedback("En breve nos comunicaremos con usted")
            setEmail('')
        }).catch(err => {
            let message = FeedbackAPI.getMessage(err)
            showFeedback(message.message)
        }).then(() => {
            setLoading(false);
        })
    }

    return (
        <Box className="background">
            <div>
                <Snackbar
                    open={openFeedback}
                    autoHideDuration={3000}
                    onClose={closeFeedback}
                    message={feedbackMessage}
                />
            </div>

            <Container>
                <Card sx={{maxWidth: 500, margin: '0 auto'}}>
                    <CardHeader
                        title="Recuperar cuenta"
                        titleTypographyProps={{
                            sx: {
                                fontSize: 20
                            }
                        }}
                    />
                    <CardContent>
                        <Typography variant="body1" gutterBottom component="div">
                            Escriba su número de celular o su correo electrónico por el cual será contactado 
                            para recuperar su cuenta.
                        </Typography>

                        <Box component="div" sx={{marginTop:3, marginBottom:2, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <TextField
                                id="cellphone"
                                name="cellphone" 
                                label="Celular o correo"
                                variant="outlined"
                                size="small"
                                value={email}
                                onChange={onEmailInputChange}
                                error={emailValid().invalid}
                                helperText={emailValid().invalid ? emailValid().message: false}
                            />
                        </Box>

                    </CardContent>
                    <CardActions
                        sx={{justifyContent: 'end'}}
                    >
                        <LoadingButton
                            onClick={onResetPassword}
                            endIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            size='small'
                        >
                            Recuperar
                        </LoadingButton> 
                    </CardActions>
                </Card>
            </Container>

          
        </Box>
    )
}


export default RecoverAccount