import { Box, Button, Typography } from "@mui/material"

const Error = (props) => {
    const { onRetry, message} = props

    const onClickRetry = () => {
        onRetry()
    }
    
    return (
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <Typography
                    sx={{
                        mb: {sx: 1, sm: 2}
                    }}
                    variant="h4" component="div">{message}</Typography>
                <Button variant="contained" onClick={onClickRetry}>Intentar de nuevo</Button>
        </Box>
    )
}

export default Error