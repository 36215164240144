import { IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFeedback from "../hooks/useFeedback";
import CloseIcon from '@mui/icons-material/Close';

/**
 * Componente encargado de mostrar informacion al usuario
 * 
 * @param {*} open 
 * 
 * @returns 
 */
const UserFeedback = () => {
    ///////////// SHARED STATE ///////////////////

    const feedback = useFeedback()

    //////////// LOCAL STATE /////////////////////

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        feedback.closeFeedback()
    };

    return (
        <Snackbar
            open={feedback.open}
            autoHideDuration={3000}
            onClose={closeFeedback}
            message={feedback?.title}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={closeFeedback}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}


export default UserFeedback