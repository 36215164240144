import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from "@mui/x-data-grid"
import { useSelector } from 'react-redux';
import { getCatalogSubjects, getCycleSelected, getGroups, getPartails, getPartialsByLevel, getProfessorsReport } from '../../../../store/slices/background'
import Connection from "../../../../service/Connection"
import { getLevelName, getTurnLevel, getCalificationRound } from '../../../../libs/utils';
import { useHistory } from 'react-router-dom';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/**
 * Tabla para mostrar las materias de los alumnos
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const StudentsTable = (props) => {
    const { studentId } = props
    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const onExport = () => {
        props.export({
            report: "student-subjects",
            title: "Materias del alumno",
            query: { student_id: studentId }
        })
    }

    ///////////////////////////// COMPONENTE ////////////////////

    const history = useHistory()

    const goMateriasDetails = (params) => () => {
        history.push({ pathname: `/director/materias/${params.id}` });
    }

    const schoolCycle = useSelector(getCycleSelected)
    const catalogoSubjects = useSelector(getCatalogSubjects)
    const professors = useSelector(getProfessorsReport)
    const groups = useSelector(getGroups)
    const allPartials = useSelector(getPartails)

    const [data, setData] = useState({
        subjects: [],
        califications: [],
        partials: [],
        columns: [
            //{ field: 'folio', headerName: 'Folio', flex: 0.8, sortable: false, },
            { field: 'title', headerName: 'Titulo', flex: 1.5, sortable: false, },
            { field: 'group', headerName: 'Grupo', flex: 1.5, sortable: false, },
            { field: 'special', headerName: 'Especial', flex: 0.8, sortable: false, },
            { field: 'professor', headerName: 'Profesor', flex: 1.5, sortable: false },
        ],
        rows: []
    })

    let parameters = (schoolCycle.cycle_id != -1) ? { snaphot: schoolCycle.snaphot } : {}

    useEffect(() => {
        async function getData() {
            const subjects = await Connection.getSubjectsByStudent(studentId, parameters).then(res => res.data.data)
            const califications = await Connection.calificationsByStudent(studentId, parameters).then(res => res.data.data)

            let partials = []
            let partialColumns = []
            let rows = []

            if (subjects.length > 0) {
                let catalogSubject = catalogoSubjects.find(item => item.catalog_subject_id == subjects[0].catalog_subject_id)

                partials = allPartials.filter(item => item.level == catalogSubject.level)

                for (const partial of partials) {
                    partialColumns.push({
                        field: `partial-${partial.partial_id}`,
                        headerName: `Parcial(${partial.partial})`,
                        flex: 0.8,
                        sortable: true
                    })
                }

                for (const subject of subjects) {

                    let catalogSubject = catalogoSubjects.find(item => item.catalog_subject_id == subject.catalog_subject_id)
                    let professor = professors.find(item => item.professor_id == subject.professor_id)
                    let group = groups.find(item => item.group_id == subject.group_id)


                    let subjectData = {
                        id: subject.subject_id,
                        //folio: catalogSubject.folio,
                        title: catalogSubject.title,
                        special: (subject.special) ? "Si" : "No",
                        professor: (subject.professor_id) ? `${professor.name} ${professor.last_name}` : 'Sin profesor',
                        group: (subject.group_id) ? `${group.grade} ${group.group} ${getTurnLevel(group.turn)}` : 'Sin grupo',
                        total: 0
                    }

                    let promedio = 0
                    let calificationCount = 0

                    for (const partial of partials) {
                        const partialID = partial.partial_id
                        let calification = califications.find(storeItem => storeItem.partial_id == partialID && subject.subject_id == storeItem.subject_id)

                        const calificatioNum = (calification != undefined) ? calification.calification : 0

                        subjectData[`partial-${partialID}`] = calificatioNum

                        promedio += calificatioNum
                        calificationCount += (calificatioNum > 0) ? 1 : 0
                    }

                    subjectData["total"] = (promedio == 0 || calificationCount == 0) ? 0 : getCalificationRound(promedio / calificationCount, 1)

                    rows.push(subjectData)
                }
            }

            return {
                subjects,
                califications: califications,
                columns: [...data.columns, ...partialColumns,
                { field: 'total', headerName: 'Promedio', flex: 1.2, sortable: true, type: 'number' },
                {
                    field: 'actions',
                    headerName: 'Acciones',
                    flex: 1,
                    sortable: false,
                    type: 'number',
                    renderCell: (params) => (
                        <strong>
                            <IconButton aria-label="Detalle" color="primary" onClick={goMateriasDetails(params)}>
                                <InsertLinkIcon />
                            </IconButton>
                        </strong>
                    )
                }],
                rows,
                partials
            }
        }

        if (studentId) {
            getData()
                .then(data => {
                    setData(data)
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [studentId])



    /////////////////////  alumnos /////////////////////////////
    let table = ("")

    if (data.rows.length > 0) {
        table = (<DataGrid style={{ width: '100%' }}
            pageSize={10}
            disableColumnFilter={true}
            autoPageSize={true}
            disableSelectionOnClick={true}
            disableExtendRowFullWidth={true}
            disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
            density="compact" autoHeight={true} rows={data.rows} columns={data.columns}
        />)
    }


    return (
        <Card>
            <CardHeader
                title="Materias"
                titleTypographyProps={{
                    sx: {
                        fontSize: 20
                    }
                }}
                subheader={"Total: " + data.subjects.length}
                sx={{
                    paddingBottom: 2
                }}
                action={
                    <div>
                        <IconButton aria-label="settings" id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onExport}>Exportar</MenuItem>
                        </Menu>
                    </div>
                }
            />

            {table}
        </Card>
    )
}

export default StudentsTable