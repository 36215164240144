import { CardHeader, Stack } from "@mui/material"
import { GridToolbar } from "@mui/x-data-grid"

const ScoresToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader
                title="Calificaciones"
            />
            <GridToolbar {...props} />
        </Stack>
    )
}

export default ScoresToolBar