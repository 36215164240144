import { Box, CircularProgress, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Transfer } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks';
import {
    loadStudentsStepData, selectStudentSelected,
    selectOperationStatusStudentsStep, selectStudentsIsAllSelected, invalidateStepData,
    changeSelectAllStudents, changeStudentsSelected
} from '../../../../store/slices/noticesModalUI/modes/studentsModeSlice';
import { Empty, Error } from "../../../../components/Feedback";
import { selectAllStudents } from '../../../../store/slices/noticesModalUI/entities/studentsSlice';

/**
 * Componente encargado se seleccionar grupos
 * 
 * @returns 
 */
const StudentsStep = () => {

    const Auth = useAuth()
    const dispatch = useDispatch()

    ///////////////// SHARED STATE ////////////////

    const allStudents = useSelector(selectAllStudents)

    const studentsSelected = useSelector(selectStudentSelected)
    const isAllSelected = useSelector(selectStudentsIsAllSelected)

    const operationStatus = useSelector(selectOperationStatusStudentsStep)

    ///////////////// LOCAL STATE ///////////////////

    const [targetKeys, setTargetKeys] = useState(studentsSelected)
    const [selectedKeys, setSelectedKeys] = useState([])

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Efecto para recuperar los grupos e iniciar el el flujo
     */
    useEffect(() => {
        dispatch(loadStudentsStepData(Auth.user.school_id))
    }, []);

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadStudentsStepData(Auth.user.school_id))
    }

    /**
     * Funcion para intercambiar elementos de la lista
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
    const onChangeList = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);

        const allSelected = (nextTargetKeys.length == allStudents.length)

        dispatch(changeSelectAllStudents(allSelected))
        dispatch(changeStudentsSelected(nextTargetKeys))
        dispatch(invalidateStepData('parents'))
    }

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {
        let allIds = (event.target.checked) ? allStudents.map(i => i.student_id) : []
        let isAll = (event.target.checked)

        dispatch(changeSelectAllStudents(isAll))
        dispatch(changeStudentsSelected(allIds))
        dispatch(invalidateStepData('parents'))

        setTargetKeys(allIds)
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && allStudents.length == 0) && (<Empty label="Sin grupos" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAllSelected}
                                        onChange={onChangeAll}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Todos"
                            />
                        </FormGroup>

                        <Transfer
                            style={{ marginTop: '20px' }}
                            showSearch
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            locale={{
                                notFoundContent: ["Sin alumnos", "Sin alumnos"],
                                itemUnit: "Alumnos",
                                itemsUnit: "Alumnos",
                                searchPlaceholder: 'Buscar aquí'
                            }}
                            dataSource={allStudents.map(i => {
                                return { ...i, key: i.student_id }
                            })}
                            titles={['Disponibles', 'Asignados']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChangeList}
                            onSelectChange={onSelectChange}
                            render={item => `${item.name} ${item.last_name} ${item.second_last_name}`}
                        />
                    </>
                )
            }

        </Box>
    )
}


export default StudentsStep