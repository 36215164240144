import { CardHeader, Stack } from "@mui/material"
import { GridToolbar } from "@mui/x-data-grid"

const PartialsToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader
                title="Parciales"
            />
            <GridToolbar {...props} />
        </Stack>
    )
}
const RegulationsToolBar = (props) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CardHeader
                title="Regularizaciones"
            />
            <GridToolbar {...props} />
        </Stack>
    )
}

export {PartialsToolBar,RegulationsToolBar}