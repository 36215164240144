import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    groupSelected: null
}

/**
 * Slice para el settings UI
 */
export const groupsUISlice = createSlice({
    name: 'groupsUI/ui',
    initialState: emptyState,
    reducers:{
        selectGroupItem: (state, {payload}) => {
            state.groupSelected = payload
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { invalidate, selectGroupItem } = groupsUISlice.actions;

export default groupsUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectGroupsUI = (state)=> state.groupsUI.ui;

/**
 * Selector para recuperar el identificador del item seleccionado
 * 
 * @param {*} state 
 * @returns 
 */
export const selectItemSelected = createSelector(
    selectGroupsUI,
    (ui) => ui.groupSelected
)

//////////////// TRUNCKS /////////////////