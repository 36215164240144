import React from 'react'
import StatBiColor from "../StatsBiColor"
import { useSelector } from "react-redux";
import { getSestScoreSubjectCard} from '../../../store/slices/background'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';

const BestScoreSubjectIndicator = (props) => {

    const bestScoreSubject = useSelector(getSestScoreSubjectCard)

    return (
      <StatBiColor
        icon={faStar}
        iconColor={blue[500]}
        value={bestScoreSubject.average_rating}
        title={`${bestScoreSubject.group_name} ${bestScoreSubject.title} ${bestScoreSubject.level}
         ${bestScoreSubject.professor_name}`}
        label={"Mejor materia"}
        mainColor={"#b59dfa"}
        secundaryColor={"#7434a3"}
      >  
      </StatBiColor>
    )
  }

export default BestScoreSubjectIndicator