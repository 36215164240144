import React, { useEffect, useState } from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from "@mui/system"
import { DataGrid } from "@mui/x-data-grid"
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGroupsReportBy } from '../../../../store/slices/background'
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/**
 * Tabla que muestra las materias de un grupo
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const SubjectsTable = ({ groupId, onExport }) => {

    const history = useHistory()
    const match = useRouteMatch()

    ///////////////// SHARES STATE ///////////////////////////

    const group = useSelector(getGroupsReportBy(groupId))

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////
    const handlerExport = () => {
        onExport({
            report: "group-subjects",
            title: "Materias del grupos",
            query: { group_id: groupId }
        })
    }

    ///////////////////////////// COMPONENTE ////////////////////


    let subjects = []

    subjects = (group) ? group.payload.subjects : []

    let rowsSubjects = []

    /////////////////////////////// ACTIONS ///////////////////////////////



    const goSubjectDetails = (params) => () => {
        history.push({ pathname: `/director/materias/${params.id}` });
    }

    const columnsSubjects = [
        //{ field: 'folio', headerName: 'Folio', flex: 0.5, sortable: false, },
        { field: 'title', headerName: 'Titulo', flex: 1.5, sortable: true, },
        { field: 'professor', headerName: 'Profesor', flex: 1.5, sortable: true },
        { field: 'special', headerName: 'Especial', flex: 0.8, sortable: true },
        { field: 'average_rating', headerName: 'Promedio', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 0.8, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goSubjectDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            )
        }
    ];

    for (const item of subjects) {
        rowsSubjects.push({
            id: item.subject_id,
            subject_id: item.subject_id,
            //folio: item.catalog_subject.folio,
            title: `${item.catalog_subject.title}`,
            professor: (item.professor_id) ? `${item.professor.name} ${item.professor.last_name}` : 'Sin profesor',
            average_rating: item.average_rating,
            total_absence: item.total_absence,
            total_presence: item.total_presence,
            special: (item.special) ? "Si" : "No",
        })
    }

    return (
        <Box>
            <Card sx={{ marginBottom: 4 }}>
                <CardHeader
                    title="Materias"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 20
                        }
                    }}
                    subheader={"Total: " + subjects.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handlerExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={rowsSubjects} columns={columnsSubjects}
                />
            </Card>

        </Box>
    )
}



export default SubjectsTable