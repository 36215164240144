import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { Button, Card, CardContent, CardHeader, Grid, Snackbar, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUsers } from '@fortawesome/free-solid-svg-icons';
import CardItem from '../../components/Card/CardItem';
import SyncIcon from '@mui/icons-material/Sync';

import SkeletonPage from './components/SkeletonPage';
import { Error } from '../../components/Feedback';
import { getLevelName, getTurnLevel } from '../../libs/utils';
import { invalidateGroupItem, loadGroupsDetailsUI, selectAllStudentsFromGroupItemSelected, selectGroupItemSelected, selectGroupsStatusServerItemSelected, selectStudentItem } from '../../store/slices/scoresUI/ItemsSlice';
import { useDispatch, useSelector } from 'react-redux';
import GroupsToolBar from './components/GroupsToolBar';

/**
 * Pagina para detalle de grupos
 * 
 * @returns 
 */
const GroupsScores = () => {

    let { id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch()

    ///////////////// SHARES STATE //////////////////////

    const statusServer = useSelector(selectGroupsStatusServerItemSelected)

    const group = useSelector(selectGroupItemSelected())
    const students = useSelector(selectAllStudentsFromGroupItemSelected)

    //////////////// LOCAL STATE //////////////////////


    ////////////// ACTIONS //////////////////////

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadGroupsDetailsUI())
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidateGroupItem(id))
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadGroupsDetailsUI(id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                showFeedback(feedback.title)
            })
    }


    /**
     * Alumno seleccionado
     * 
     * @param {*} student 
     */
    const onSelectStudent = (student) => {
        dispatch(selectStudentItem({
            itemGroupId: id,
            itemStudentId: student.student_id
        }))

        history.push({ pathname: `/calificaciones/${id}/alumno/${student.student_id}` })
    }

    /**
     * Columnas del DataGrid
     */
    const columns = [
        {
            field: 'student_id',
            headerName: 'ID',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'folio',
            headerName: 'Matrícula',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Nombre(s)',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'last_name',
            headerName: 'Apellido Paterno',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'second_last_name',
            headerName: 'Apellido Materno',
            flex: 1,
            minWidth: 130,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            type: 'number',
            flex: 0.8,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            type: 'number',
            flex: 0.6,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            type: 'number',
            flex: 0.8,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            filterable: false,
            flex: 1,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faEye} size="lg" />}
                        onClick={() => onSelectStudent(params.row)}
                    >
                        VER
                    </Button>
                </strong>
            ),
        },
    ];

    const rows = students?.map(row => ({ id: row.student_id, ...row }));

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>

                        </Box>


                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xl={3} md={3} sm={12} xs={12} style={{ alignContent: "center" }}>

                                <CardItem backgroundColor={'#2196f3'} icon={faUsers} iconColor={"#1769aa"} text={"Grupo " + group?.grade + "° " + group?.group + " " + getLevelName(group?.level)} textColor='#fff' title={getTurnLevel(group?.turn)} />

                            </Grid>
                            <Grid item xl={3} md={3} sm={12} xs={12} style={{ alignContent: "center" }}>

                                <CardItem backgroundColor={'#2196f3'} icon={faUsers} iconColor={"#1769aa"} text="Promedio" textColor='#fff' title={group?.average_rating} />

                            </Grid>
                            <Grid item xl={3} md={3} sm={12} xs={12} style={{ alignContent: "center" }}>

                                <CardItem backgroundColor={'#2196f3'} icon={faUsers} iconColor={"#1769aa"} text="Asistencias" textColor='#fff' title={group?.total_presence} />

                            </Grid>
                            <Grid item xl={3} md={3} sm={12} xs={12} style={{ alignContent: "center" }}>

                                <CardItem backgroundColor={'#2196f3'} icon={faUsers} iconColor={"#1769aa"} text="Faltas" textColor='#fff' title={group?.total_absence} />

                            </Grid>
                        </Grid>

                        <Card sx={{ mt: 2 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: 0,
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rows}
                                    columns={columns}
                                    pageSize={20}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GroupsToolBar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                        </Card>
                    </>
                )
            }

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    )
}

export default GroupsScores
