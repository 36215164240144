import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

///////////////// VIEWS //////////////////////

import Alumnos from "./../views/Alumnos/Alumnos";
import AlumnosGroup from "./../views/AlumnosGroup/AlumnosGroup";
import Groups from "./../views/Groups/Groups";
import Configuraciones from "./../views/Configuraciones/Configuraciones";
import Inicio from "./../views/Inicio/Inicio";
import Login from "./../views/Login/Login";
import Reportes from "./../views/Reportes/Reportes";
import Avisos from "./../views/Avisos/Avisos";
import Materias from "./../views/Materias/Materias";
import Usuarios from "./../views/Usuarios/Usuarios";
import Scores from "./../views/Scores";
import ScoresGroups from "./../views/ScoresGroups";
import AvisoPrivacidad from "./../views/AvisoPrivacidad/AvisoPrivacidad";
import ScoreStudents from "./../views/ScoreStudents";
import DirectorHome from './../views/Director'
import Perfil from './../views/Perfil'
import Calendar from './../views/Calendario'
import ParentHome from './../views/ParentHome'

import RecoverAccount from './../views/RecuperarCuenta/RecoverAccount'
import ResetPassword from './../views/RecuperarCuenta/ResetPassword'
import Testing from "./../views/Testing";
import Supervisiones from "./../views/Supervisiones";
import Detalles from "./../views/Detalles";
/////////////// COMPONENTS ////////////////////

import Layout from "./Layout/Layout";
import { AuthProvider } from "./../providers"
import PrivateRoute from "./PrivateRoute";
import FeedbackProvider from "../providers/FeedbackProvider";
import WorkingMiddleware from "./WorkingMiddleware";
import Working from "../views/Working";
import ProfessorScoreEdit from "../views/ProfessorScoreEdit";
import ProfessorAssistanceList from "../views/ProfessorAssistanceList";
import ProfessorMakeReport from "../views/ProfessorMakeReport";
import ProfessorHome from "../views/ProfessorHome";

/**
 * Componente de mantiene todo el arbol del dashboard
 * 
 * @returns 
 */
function Dashboard() {
    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <FeedbackProvider>
                        <Switch>
                            <Route exact path="/" >
                                <Inicio></Inicio>
                            </Route>
                            <Route path="/login">
                                <Login></Login>
                            </Route>
                            <Route path="/aviso-privacidad">
                                <AvisoPrivacidad></AvisoPrivacidad>
                            </Route>
                            <Route path="/recuperar_cuenta">
                                <RecoverAccount></RecoverAccount>
                            </Route>
                            <Route path="/restablecer_cuenta">
                                <ResetPassword></ResetPassword>
                            </Route>
                            <Route path="/working">
                                <Working />
                            </Route>

                            <WorkingMiddleware>
                                <Layout>
                                    <PrivateRoute path="/configuracion">
                                        <Configuraciones></Configuraciones>
                                    </PrivateRoute>
                                    <PrivateRoute path="/usuarios">
                                        <Usuarios></Usuarios>
                                    </PrivateRoute>
                                    <PrivateRoute path="/alumnos">
                                        <Alumnos></Alumnos>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/alumnos-grupo/:id">
                                        <AlumnosGroup></AlumnosGroup>
                                    </PrivateRoute>
                                    <PrivateRoute path="/grupos">
                                        <Groups></Groups>
                                    </PrivateRoute>
                                    <PrivateRoute path="/reportes">
                                        <Reportes></Reportes>
                                    </PrivateRoute>
                                    <PrivateRoute path="/avisos">
                                        <Avisos></Avisos>
                                    </PrivateRoute>
                                    <PrivateRoute path="/padres">
                                        <ParentHome />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/calificaciones/:groupId/alumno/:id">
                                        <ScoreStudents></ScoreStudents>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/calificaciones/:id">
                                        <ScoresGroups></ScoresGroups>
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/calificaciones">
                                        <Scores></Scores>
                                    </PrivateRoute>

                                    <PrivateRoute path="/materias">
                                        <Materias></Materias>
                                    </PrivateRoute>
                                    <PrivateRoute path="/director">
                                        <DirectorHome />
                                    </PrivateRoute>
                                    <PrivateRoute path="/perfil">
                                        <Perfil></Perfil>
                                    </PrivateRoute>
                                    <PrivateRoute path="/calendario">
                                        <Calendar></Calendar>
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/reportar/:id/alumno/:studentId">
                                        <ProfessorMakeReport />
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/lista-asistencia/:id">
                                        <ProfessorAssistanceList />
                                    </PrivateRoute>
                                    <PrivateRoute path="/inicio-maestros/calificaciones/:id">
                                        <ProfessorScoreEdit />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/inicio-maestros">
                                        <ProfessorHome />
                                    </PrivateRoute>
                                    <PrivateRoute path="/testing">
                                        <Testing />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/Supervisiones/Detalles/:id">
                                        <Detalles />
                                    </PrivateRoute>
                                    <PrivateRoute exact path="/Supervisiones">
                                        <Supervisiones />
                                    </PrivateRoute>
                                </Layout>
                            </WorkingMiddleware>


                        </Switch>
                    </FeedbackProvider>
                </AuthProvider>
            </Router>
        </div>
    )
}

export default Dashboard;
