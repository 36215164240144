import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Tooltip, IconButton, Snackbar, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChangeProfessorSubjectModal from './ChangeProfessorSubjectModal';
import { useSelector, useDispatch } from "react-redux";
import { selectSubjectDataByGroupId } from '../../../store/slices/groupsUI/operationsSlice';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import * as Style from '../../../styles/index';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSubjectModal from './DeleteSubjectModal';
import { useEffect } from 'react';
import { loadGroupSubjectsUI, selectGroupByItemSelected, selectSubjectstatusServerItemSelected, selectSubjectsWithDataByGroup } from '../../../store/slices/groupsUI/itemSlice';
import { Error } from '../../../components/Feedback';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';

/**
 * Modal para mostrar los materias de un grupo
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const SubjectModal = ({ open, setOpen }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    ///////////////////// SHARED STATE //////////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const groupSelected = useSelector(selectGroupByItemSelected)

    const statusServer = useSelector(selectSubjectstatusServerItemSelected)
    
    let subjects = useSelector((state) => selectSubjectsWithDataByGroup(state, groupSelected?.group_id))

    const [openMaestros, setOpenMaestros] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [subjectId, setSubjectId] = useState(null);

    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadGroupSubjectsUI(null))
        }
    }, [open])
    //////////// SELECTORES /////////////



    //////////////////////// FUNCIONES /////////////////////

    /**
     * Funcion para cerrar el modal
     */
    const closeModal = () => {
        setOpen(false)
        dispatch(selectGroupItem(null));
    }


    const reload = () => {
        dispatch(loadGroupSubjectsUI())
            .unwrap()
            .then(response => {

            })
    }

    /**
     * Abir modal para eliminar la materia 
     * 
     * @param {*} subject 
     */
    const onOpenDeleteSubjectModal = (subject) => {
        setOpenDeleteModal(true)
        setSubjectId(subject.subject_id)
    }

    /**
     * Abrir modal para actualizar profesor de una materia
     */
    const onShowChangeProfessorSubjectModal = (subject) => {
        setOpenMaestros(true);
        setSubjectId(subject.subject_id)
    }  

    /**
     * La materia fue eliminada correctamente
     */
    const onSubjectDeleted = () => {
        showFeedback("La materia fue eliminada correctamente")
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    return (
        <>
            <ChangeProfessorSubjectModal
                open={openMaestros}
                setOpen={setOpenMaestros}
                subjectId={subjectId}
            />

            <DeleteSubjectModal
                open={openDeleteModal}
                setOpen={() => {
                    setOpenDeleteModal(false);
                }}
                subjectId={subjectId}
                onDeleted={onSubjectDeleted}
            />

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                classes={{ paper: classes.dialogWrapper }}
                onClose={() => {
                    closeModal()
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Materias <br />
                            <span style={{ fontSize: '15px' }}>
                                Grupo {groupSelected?.grade}° {groupSelected?.group} {getTurnLevel(groupSelected?.turn)} {getLevelName(groupSelected?.level)}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        statusServer == "pending" && (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        )
                    }
                    
                    {
                        statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
                    }

{
                        (statusServer == "fulfilled") && (
                    <Box style={{ textAlign: "center" }}>
                        {
                            subjects.length ? (
                                <TableContainer>
                                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                                                <TableCell align='center' style={{ color: fontColor.value }}>FOLIO</TableCell>
                                                <TableCell align='center' style={{ color: fontColor.value }}>MATERIA</TableCell>
                                                <TableCell align='center' style={{ color: fontColor.value }}>PROFESOR</TableCell>
                                                <TableCell align='center' style={{ color: fontColor.value }}>ACCIONES</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subjects.map((row, i) => (

                                                <TableRow key={row.catalog_subject_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row.catalog?.folio}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row.catalog?.title}
                                                    </TableCell> 
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <Button style={{ textTransform: 'none' }} onClick={() => { onShowChangeProfessorSubjectModal(row) }}>
                                                            {row.professor === undefined ?
                                                                "Sin Maestro" :
                                                                `${row.professor.name} ${row.professor.last_name} ${row.professor.second_last_name}`
                                                            }
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <Tooltip title="Eliminar materia">
                                                            <IconButton
                                                                style={{ textTransform: 'none', marginLeft: 10 }}
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => { onOpenDeleteSubjectModal(row) }}
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                                :
                                (
                                    <Box sx={{ mt: 2, mb: 2 }}>
                                        <Typography variant="h5" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                                            Sin Materias
                                        </Typography>
                                    </Box>
                                )
                        }
                    </Box>
                     )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { 
                            
                            closeModal()
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </>
    )
}

export default SubjectModal