import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { AssitstGroupsChart, ScoreGroupsChart, AbsenceGroupsChart, ReportsGroupsChart } from '.';

/**
 * Muestra un conjuneto de Chart 
 * basados en un nuvel
 * 
 * @param Object level Nivel
 * 
 * @returns 
 */
const LevelChartItem = ({ level }) => {

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Box>
                <Typography variant="h5" gutterBottom component="div">
                    {level.title}
                </Typography>
            </Box>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Box>
                        <ScoreGroupsChart level={level.key} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <AssitstGroupsChart level={level.key} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <AbsenceGroupsChart level={level.key} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box>
                        <ReportsGroupsChart level={level.key} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}


export default LevelChartItem