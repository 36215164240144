import React, { useEffect, useState } from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from "@mui/x-data-grid"
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCycleSelected } from '../../../../store/slices/background'
import Connection from "./../../../../service/Connection"
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/**
 * Tabla para mostrar los alumnos de un grupo
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const StudentsTable = ({ groupId, onExport }) => {
    const history = useHistory()

    /////////////// SHARES STATE /////////////////////

    const schoolCycle = useSelector(getCycleSelected)

    /////////////// LOCAL STATE /////////////////////

    const [students, setStudents] = useState([])


    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const handlerExport = () => {
        onExport({
            report: "group-students",
            title: "Alumnos del grupos",
            query: { group_id: groupId }
        })
    }

    /////////////// ACTIONS ////////////////////


    const goStudentDetails = (params) => () => {
        history.push({ pathname: `/director/alumnos/${params.id}` });
    }

    let parameters = (schoolCycle.cycle_id != -1) ? { snaphot: schoolCycle.snaphot } : {}

    useEffect(() => {
        function getStudents() {
            Connection.getStudentsByGroup(groupId, parameters)
                .then(res => {
                    setStudents(res.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        if (groupId) {
            getStudents()
        }
    }, [groupId])

    let rowStudents = []

    /////////////////////  alumnos /////////////////////////////

    const columnsStudents = [
        //{ field: 'folio', headerName: 'Folio', flex: 1, sortable: false, },
        { field: 'name', headerName: 'Nombre', flex: 1.5, sortable: true, },
        { field: 'last_name', headerName: 'Apelido paterno', flex: 1.5, sortable: true, },
        { field: 'second_last_name', headerName: 'Apellido materno', flex: 1.5, sortable: true, },
        { field: 'average_rating', headerName: 'Promedio', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 0.8, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 0.8, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goStudentDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            )
        }
    ];

    for (const item of students) {
        rowStudents.push({
            id: item.student_id,
            //folio: (item.folio != null) ? item.folio : "Sin folio",
            name: item.name,
            last_name: item.last_name,
            second_last_name: item.second_last_name,
            average_rating: item.average_rating,
            total_absence: item.total_absence,
            total_presence: item.total_presence
        })
    }

    return (
        <Card>
            <CardHeader
                title="Alumnos"
                titleTypographyProps={{
                    sx: {
                        fontSize: 20
                    }
                }}
                subheader={"Total: " + students.length}
                sx={{
                    paddingBottom: 2
                }}
                action={
                    <div>
                        <IconButton aria-label="settings" id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handlerExport}>Exportar</MenuItem>
                        </Menu>
                    </div>
                }
            />

            <DataGrid style={{ width: '100%' }}
                pageSize={10}
                disableColumnFilter={true}
                autoPageSize={true}
                disableSelectionOnClick={true}
                disableExtendRowFullWidth={true}
                disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                density="compact" autoHeight={true} rows={rowStudents} columns={columnsStudents}
            />
        </Card>
    )
}



export default StudentsTable