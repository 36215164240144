import { Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import { Box } from "@mui/system"
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory, useRouteMatch } from "react-router-dom"
import { getLevelName, getTurnLevel } from "../../../libs/utils"
import { useDispatch, useSelector } from "react-redux";
import { selectItemById } from "../../../store/slices/professorModule/ItemsSlice";
import { selectCatalogSubjectById, selectGroupById, selectSubjectById } from "../../../store/slices/professorModule/entitiesSlice";
import { selectItem } from "../../../store/slices/professorModule/uiSlice";

const theme = createTheme({
    palette: {
        neutral: {
            main: '#FFFFFF',
            contrastText: '#2894f4'
        },
    },
});

/**
 * Card que muestra un grupo
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const GroupCard = ({ id, sx }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { path, url } = useRouteMatch()

    //////////// SHARES STATE /////////////////////

    const subjecItem = useSelector(selectItemById(id))

    const subject = useSelector(selectSubjectById(id))
    const catalogSubject = useSelector(selectCatalogSubjectById(subject.catalog_subject_id))
    const group = useSelector(selectGroupById(subject.group_id))


    ///////////// LOCAL STATE /////////////////

    const levelName = getLevelName(group.level)
    const turnName = getTurnLevel(group.turn)

    ///////////// ACTIONS //////////////////

    /**
     * Ir a pagina para nombrar lista
     */
    const onAssistensList = () => {
        dispatch(selectItem(id))

        history.push({ pathname: `${path}/lista-asistencia/${subject.subject_id}` });
    }

    /**
     * Ir a pagina para registrar calificaciones
     */
    const onCalificationList = () => {
        dispatch(selectItem(id))

        history.push({ pathname: `${path}/calificaciones/${subject.subject_id}` });
    }

    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ display: 'flex', ...sx, color: "white", backgroundColor: "#2894f4" }} >

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ textAlign: "center", flex: '1 0 auto' }}>
                            <Box >
                                <Typography variant="h6" component="span">
                                    {catalogSubject.title}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" component="span">
                                    {group.grade}°{group.group} {turnName} {levelName}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="neutral" variant="contained" onClick={onAssistensList}>
                            Asistencia
                        </Button>
                        <Button size="small" color="neutral" variant="contained" onClick={onCalificationList}>
                            Calificaciones
                        </Button>
                    </CardActions>
                </Box>
                <Box
                    sx={{
                        width: 151,
                        textAlign: "center",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="h3" component="span">
                        {subject.average_rating}
                    </Typography>
                    <Typography variant="caption" component="span">Promedio </Typography>
                </Box>
            </Card>
        </ThemeProvider>
    )
}

export default GroupCard