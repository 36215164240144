import React, { useEffect, useState } from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from "@mui/x-data-grid"
import { useSelector } from 'react-redux';
import { getCycleSelected, getPartails } from '../../../../store/slices/background'
import Connection from "../../../../service/Connection"
import { useHistory } from 'react-router-dom';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/**
 * Tabla para mostrar todos los alumnos de un grupo
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const StudentsTable = ({ groupId, level, subjectId, onExport }) => {

    const history = useHistory()

    ////////////////// SHARED STATE //////////////////////

    const schoolCycle = useSelector(getCycleSelected)
    const allPartials = useSelector(getPartails)

    let parameters = (schoolCycle.cycle_id != -1) ? { snaphot: schoolCycle.snaphot } : {}

    ///////////////// LOCAL STATE /////////////////////

    const [data, setData] = useState({
        columns: [
            //{ field: 'folio', headerName: 'Folio', flex: 1, sortable: false, },
            { field: 'name', headerName: 'Nombre', flex: 1.5, sortable: true, },
            { field: 'last_name', headerName: 'Apelido paterno', flex: 1.5, sortable: true, },
            { field: 'second_last_name', headerName: 'Apellido materno', flex: 1.5, sortable: true, },
        ],
        rows: [],
        students: [],
        califications: []
    })

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const handlerExport = () => {
        onExport.export({
            report: "subject-califications",
            title: "Alumnos del la materia",
            query: { subject_id: subjectId }
        })
    }

    ///////////////////////////// ACTIONS ////////////////////

    const goStudentsDetails = (params) => () => {
        history.push({ pathname: `/director/alumnos/${params.id}` });
    }


    useEffect(() => {
        async function getData() {
            const students = await Connection.getStudentsByGroup(groupId, parameters).then(res => res.data.data)
            const califications = await Connection.getCalificationaBySubject(subjectId, parameters).then(res => res.data.data)

            let partials = allPartials.filter(item => item.level == level)

            let partialColumns = []
            let rows = []

            for (const partial of partials) {
                const partialID = partial.partial_id

                partialColumns.push({
                    field: 'partial-' + partialID,
                    headerName: 'Partial' + partial.partial,
                    flex: 1,
                    sortable: true,
                    type: 'number'
                })
            }



            for (const student of students) {

                let stundetData = {
                    id: student.student_id,
                    //folio: (student.folio != null) ? student.folio : "Sin folio",
                    name: student.name,
                    last_name: student.last_name,
                    second_last_name: student.second_last_name,
                    average_rating: student.average_rating,
                    total_absence: student.total_absence,
                    total_presence: student.total_presence
                }


                for (const partial of partials) {
                    const partialID = partial.partial_id
                    let calification = califications.find(storeItem => {
                        return storeItem.partial_id == partialID && student.student_id == storeItem.student_id
                    })

                    stundetData[`partial-${partialID}`] = (calification != undefined) ? calification.calification : 0
                }

                rows.push(stundetData)
            }


            return {
                columns: [...data.columns, ...partialColumns,
                {
                    field: 'actions',
                    headerName: 'Acciones',
                    flex: 1,
                    sortable: false,
                    type: 'number',
                    renderCell: (params) => (
                        <strong>
                            <IconButton aria-label="Detalle" color="primary" onClick={goStudentsDetails(params)}>
                                <InsertLinkIcon />
                            </IconButton>
                        </strong>
                    )
                }],
                students,
                rows,
                califications
            }
        }

        if (subjectId) {
            getData()
                .then(data => {
                    setData(data)
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [subjectId])

    let table = null

    if (data.rows.length > 0) {
        table = (
            <DataGrid style={{ width: '100%' }}
                pageSize={10}
                disableColumnFilter={true}
                autoPageSize={true}
                disableSelectionOnClick={true}
                disableExtendRowFullWidth={true}
                disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                density="compact" autoHeight={true} rows={data.rows} columns={data.columns}
            />
        )
    }

    return (
        <Card>
            <CardHeader
                title="Alumnos"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                subheader={"Total:" + data.students.length}
                sx={{
                    paddingBottom: 2
                }}
                action={
                    <div>
                        <IconButton aria-label="settings" id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handlerExport}>Exportar</MenuItem>
                        </Menu>
                    </div>
                }
            />
            {table}

        </Card>
    )
}

export default StudentsTable