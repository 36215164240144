import { Box } from "@mui/material"

const ParentHome = () => {
    return (
        <Box>
            Modulo trabajando
        </Box>
    )
}

export default ParentHome