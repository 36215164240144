import { Box, Button, Container, Stack, Typography } from "@mui/material"
import wrongImage from "./../assets/images/wrong.jpg"

function GlobalErrorFallback({ error, resetErrorBoundary }) {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <img src={wrongImage} style={{ width: '500px' }} />
            <Typography variant="h1" component="div">Algo salió mal</Typography>
            <Typography variant="h3" component="div">Lo sentimos, algo salió mal, inténtalo de nuevo.</Typography>

            <Button
                variant="contained"
                onClick={resetErrorBoundary}
            >
                Iniciar de nuevo
            </Button>
        </Stack >
    )
}

export default GlobalErrorFallback
