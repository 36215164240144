import React, { useState, useEffect } from 'react'

import { Box, TextField, Tooltip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

const InputChange = (props) => {
    const { status } = props
    const [inputUpdated, setInputUpdated] = useState(false)
    const [inputValue, setInputValue] = useState(props.value)
    const [state, setState] = useState(false)

    /**
     * Actualiza el valor de la configuracion 
     * y activa el boton de actualizar
     * 
     * @param {*} $event 
     */
    const onUpdateSetting = ($event) => {
        const value = $event.target.value

        setInputUpdated(true)
        setInputValue(value)
    }

    /**
     * Indica que la actualizacion del input
     * ha terminado
     */
    const saveSetting = () => {
        setState('pending')
        props.onChangeSetting(inputValue, props.settingKey)
    }

    /////////////////// VALDIACION /////////////////////

    const valid = () => {
        return {
            invalid: inputValue == null || inputValue == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Escuchamos las propiedades en busca de una
     * actualizacio
     */
    useEffect(() => {
        if ((status == 'rejected' || status == 'fulfilled') && state == 'pending') {
            setState('idle')
            setInputUpdated(false)
        }
    }, [props]);

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}
        >
            <Tooltip title={props.tooltip}>
                <TextField
                    id="reporte"
                    name="reporte"
                    variant="standard"
                    value={inputValue}

                    {...props.inputProps}
                    fullWidth
                    style={{ width: '80%' }}
                    onChange={onUpdateSetting}
                    error={valid().invalid}
                    helperText={valid().invalid ? valid().message : false}
                >
                </TextField>
            </Tooltip>
            <Box style={{ width: 47 }}>
                {
                    inputUpdated && (
                        <Tooltip title={"Guardar"}>
                            <LoadingButton
                                color="primary"
                                onClick={saveSetting}
                                loading={state == 'pending'}
                                loadingPosition="center"
                                variant="contained"
                                disabled={valid().invalid}
                            >
                                <SaveIcon fontSize="inherit" />
                            </LoadingButton>
                        </Tooltip>
                    )
                }
            </Box>
        </Box>
    )
}


export default InputChange