import { Box, Button, Card, CardContent, Stack } from "@mui/material"
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from "react-router-dom";

const HeaderButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: theme.palette.getContrastText(purple[500]),
    /*'&:hover': {
        backgroundColor: purple[700],
    },*/
}));


const HeaderPage = () => {
    return (
        <Card sx={{
            backgroundColor: "white",
            position: 'sticky',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            "& .MuiCardContent-root:last-child": {
                pb: 1
            }
        }}>
            <CardContent sx={{
                py: 1,
                px: 5
            }}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{

                    }}
                >
                    <Box>
                        <Link to="/">
                            <img className="avatar-icon" style={{ width: "150px" }} src={`${process.env.PUBLIC_URL}/images/logo-horizontal-eficaz.png`} alt="Avatar" />
                        </Link>
                    </Box>

                    <Box spacing={2}>
                        <HeaderButton variant="outlined">
                            <Link to="/login">
                                <FontAwesomeIcon icon={faUser} /> <span>Ingresar</span>
                            </Link>
                        </HeaderButton>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default HeaderPage