import React from 'react'
import { useSelector } from "react-redux";
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { yellow, red, green } from '@mui/material/colors';
import StatsBiColor from '../../../../components/Chart/StatsBiColor';
import { getWorstAbsenceGroupCard } from '../../../../store/slices/background';

/**
 * Componente que muestra el mayor grupo con 
 * faltas
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const WorstAbsenceGroupIndicator = (props) => {

  const worstAbsenceGroupCard = useSelector(getWorstAbsenceGroupCard)

  return (
    <StatsBiColor
      icon={faDoorOpen}
      iconColor={yellow["A700"]}
      value={worstAbsenceGroupCard.total_absence}
      title={`${worstAbsenceGroupCard.title} ${worstAbsenceGroupCard.levelName}`}
      label={"Mayor faltas"}
      mainColor={"#fc4444"}
      secundaryColor={"#fc7474"}
      valueOptions={{ variant: "h5", component: "span" }}
    >
    </StatsBiColor>
  )
}

export default WorstAbsenceGroupIndicator