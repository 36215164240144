import { combineReducers } from "@reduxjs/toolkit";

import groups from "./modes/groupsModeSlice";
import students from "./modes/studentsModeSlice";
import users from "./modes/usersModeSlice";

export default combineReducers({
    groups,
    students,
    users
})
