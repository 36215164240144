import * as constants from "./../service/const";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

/**
 * Retorna el nombre del nivel dependiando del parametro
 * 
 * @param {*} level 
 * @returns 
 */
function getLevelName(level) {
    if (!level) {
        return "Sin nivel"
    }
    let levelName = constants.levelsNames.find(item => item.key == level)

    if (!levelName) {
        return "Sin nivel"
    }

    return levelName.title
}

/**
 * Retorna el nombre del turno dependiando del parametro
 * 
 * @param {*} turn 
 * @returns 
 */
function getTurnLevel(turn) {
    if (!turn) {
        return "Sin turno"
    }
    let levelName = constants.turnsNames.find(item => item.key == turn)

    return levelName.title
}

/**
 * Retorna el nombre espacial dependiando del parametro
 * 
 * @param {*} turn 
 * @returns 
 */
function getSpecialName(spacial) {
    if (spacial == null) {
        return "Sin valor"
    }
    let levelName = constants.specialNames.find(item => item.key == spacial)

    return levelName.title
}

/**
 * Retorna el nombre espacial dependiando del parametro
 * 
 * @param {*} turn 
 * @returns 
 */
function getRelationships(key) {
    if (key == null) {
        return "Sin valor"
    }
    let levelName = constants.relationShipsNames.find(item => item.key == key)

    return levelName
}

/**
 * Retorna el elemento basandoce en el tipo de noticia
 * 
 * @param {*} turn 
 * @returns 
 */
function getNoticeTypeItem(type) {
    if (!type) {
        return {
            title: "Sin tipo",
        }
    }
    let item = constants.NOTICE_ITEMS.find(item => item.key == type)

    return item
}


/**
 * Retorna el elemento basandoce en el tipo de noticia
 * 
 * @param {*} turn 
 * @returns 
 */
function getNoticeStatusItem(type) {
    if (!type) {
        return {
            title: "Sin status",
            icon: <BrokenImageIcon />
        }
    }
    let item = constants.NOTICE_STATUS_ITEMS.find(item => item.key == type)

    return item
}

/**
 * Truncar valores 
 * 
 * @param valor
 */
function getCalificationRound(number, posiciones = 0) {
    let numberFormated = number

    var s = number.toString()
    var l = s.length

    if (s.indexOf('.') > 0) {
        var decimalLength = s.indexOf('.') + 1
        numberFormated = s.substr(0, decimalLength + posiciones)
    }

    return Number(numberFormated)
}


const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

const saveState = (name, state) => {
    try {
        let serializedData = JSON.stringify(state)
        localStorage.setItem(name, serializedData)
    } catch (error) {
        console.log(error)
        // Acá podemos capturar o crear cualquier log que deseemos en caso de que falle el salvado en el storage.    
    }
}

let round = (number, posiciones = 0) => {
    let numberFormated = number

    let s = number.toString()
    //let l = s.length

    if (s.indexOf('.') > 0) {
        let decimalLength = s.indexOf('.') + 1
        numberFormated = s.substr(0, decimalLength + posiciones)
    }

    return Number(numberFormated)
}

export {
    getLevelName, getTurnLevel, getCalificationRound, saveState, removeDuplicates, convertArrayToObject,
    getSpecialName, getNoticeTypeItem, getNoticeStatusItem, getRelationships,
    round
}

