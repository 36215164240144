import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';
import { values } from "lodash";

const groupsStudentsAdapter = createEntityAdapter({
    selectId: (item) => item.id,
    sortComparer: (a, b) => a.id < b.id,
})

const scoresAdapter = createEntityAdapter({
    selectId: (item) => item.calification_id,
    sortComparer: (a, b) => a.calification_id < b.calification_id,
})

const subjectsAdapter = createEntityAdapter({
    selectId: (item) => item.subject_id,
    sortComparer: (a, b) => a.subject_id < b.subject_id,
})

const filesAdapter = createEntityAdapter({
    selectId: (item) => item.file_id,
    sortComparer: (a, b) => a.file_id < b.file_id,
})

const assitsAdapter = createEntityAdapter({
    selectId: (item) => item.assist_id,
    sortComparer: (a, b) => a.assist_id < b.assist_id,
})

const emptyState = {
    catalogSubjects: {},
    groups: {},
    partials: {},
    subjects: subjectsAdapter.getInitialState(),
    students: {},
    califications: scoresAdapter.getInitialState(),
    assits: assitsAdapter.getInitialState(),
    groupsStudents: groupsStudentsAdapter.getInitialState(),
    files: filesAdapter.getInitialState(),
}

/**
 * Slice para el settings UI
 */
export const ProfessorsModuleSlice = createSlice({
    name: 'professorModule/entities',
    initialState: emptyState,
    reducers: {
        setAllInformation: (state, action) => {
            const {
                groups, catalogSubjects, partials, catalogReports, assessors,
            } = action.payload

            state.groups = groups
            state.catalogSubjects = catalogSubjects
            state.partials = partials
            state.catalogReports = catalogReports
            state.assessors = assessors

            state.students = []
        },
        addStudents: (state, action) => {
            state.students = { ...state.students, ...action.payload }
        },
        addCalifications: (state, action) => {
            state.califications = action.payload
        },
        upsertManyScores: (state, action) => {
            scoresAdapter.upsertMany(state.califications, action.payload)
        },
        setManyScores: (state, action) => {
            scoresAdapter.setMany(state.califications, action.payload)
        },
        setManyAssists: (state, action) => {
            assitsAdapter.setMany(state.assits, action.payload)
        },
        addManyAssists: (state, action) => {
            assitsAdapter.addMany(state.assits, action.payload)
        },
        setManySubjects: (state, action) => {
            subjectsAdapter.setMany(state.subjects, action.payload)
        },
        upsertOneSubject: (state, action) => {
            subjectsAdapter.upsertOne(state.subjects, action.payload)
        },
        addManyGroupsStudents: (state, action) => {
            const entitiyItems = Object.values(state.groupsStudents.entities)

            let newGroupsStudent = action.payload.filter(gs => {
                return !entitiyItems.find(gsEntity => gs.group_id == gsEntity.group_id && gs.student_id == gsEntity.student_id)
            })

            let nextId = entitiyItems.length + 1

            let items = newGroupsStudent.map((i, index) => {
                return { ...i, id: nextId + index }
            })

            groupsStudentsAdapter.addMany(state.groupsStudents, items)
        },
        addOneFile: (state, action) => {
            filesAdapter.addOne(state.files, action.payload)
        },
        upsertFile: (state, action) => {
            filesAdapter.upsertOne(state.files, action.payload)
        },
        setManyFiles: (state, action) => {
            filesAdapter.setMany(state.files, action.payload)
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const {
    setAllInformation, addStudents, addCalifications,
    addManyGroupsStudents,
    // SUBJECTS
    setManySubjects, upsertOneSubject,
    /// CALIFICATIONS
    upsertManyScores, setManyScores,
    // FILES 
    addOneFile, setManyFiles, upsertFile,
    // ASSISTS

    setManyAssists, addManyAssists
} = ProfessorsModuleSlice.actions;

export default ProfessorsModuleSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Selector para todas las entidades del modulo
 * 
 * @param {*} state 
 * @returns 
 */
export const selectProfessorsModuleEntities = (state) => state.professorModule.entities;

/////////////////// CATALOG SUBJECTS  /////////////////////

export const selectCatalogSubjectById = (catalogSubjectId) => (state) => state.professorModule.entities.catalogSubjects[catalogSubjectId]

/////////////////// GROUPS  /////////////////////

export const selectGroupById = (groupId) => (state) => state.professorModule.entities.groups[groupId]

/////////////////// SUBJECT  /////////////////////

const subjectsglobalizedSelectors = subjectsAdapter.getSelectors((state) => state.professorModule.entities.subjects)

export const selectSubjectById = (subject_id) => (state) => subjectsglobalizedSelectors.selectById(state, subject_id)

/////////////////// STUDENTS  ///////////////////////

export const selectEntitiesStudents = (state) => state.professorModule.entities.students;

export const selectAllStudents = (state) => Object.values(selectEntitiesStudents);

export const selectStudentById = (student_id) => (state) => state.professorModule.entities.students[student_id]


////////////////// CALIFICATIONS  //////////////////////

const scoresglobalizedSelectors = scoresAdapter.getSelectors((state) => state.professorModule.entities.califications)

export const selectAllCalifications = (state) => scoresglobalizedSelectors.selectAll(state)
export const selectEntitiesCalifications = (state) => scoresglobalizedSelectors.selectAll(state)

////////////////// CATALOG REPORT ///////////////////////////////

export const selectEntitiesCatalogReports = (state) => state.professorModule.entities.catalogReports;

export const selectAllCatalogReports = (state) => Object.values(selectEntitiesCatalogReports(state));

////// USERS /////////////

export const selectEntitiesUsers = (state) => state.professorModule.entities.assessors;

export const selectUserById = (userId) => (state) => selectEntitiesUsers(state)[userId];

///// FILES /////////////

const filesGlobalizedSelectors = filesAdapter.getSelectors((state) => state.professorModule.entities.files)

export const selectEntitiesFiles = (state) => filesGlobalizedSelectors.selectEntities(state);

export const selectAllFiles = (state) => filesGlobalizedSelectors.selectAll(state)

//export const selectAllInactiveFiles = (state) => _.filter(selectAllFiles(state), { status: 0 });

export const selectStudentFiles = createSelector([
    selectAllFiles,
    (state, studentId) => studentId
],
    (allFiles, studentId) => _.filter(allFiles, { student_id: studentId })
)


/**
 * Parciales por materia
 * 
 * @param integer level Nivel a filtrar
 * 
 * @returns 
 */
export const selectScoresBySubject = createSelector([
    selectAllCalifications,
    (store, subjectId) => subjectId
], (allCalifications, subjectId) => {
    return _.filter(allCalifications, { subject_id: subjectId })
})


///////////////// PARTIALS SELECTORS ////////////////////

export const selectEntitiesPartials = (state) => state.professorModule.entities.partials;

export const selectAllPartials = (state) => Object.values(selectEntitiesPartials(state))

/**
 * Parciales por nivel
 * 
 * @param integer level Nivel a filtrar
 * 
 * @returns 
 */
export const selectPartialsByLevel = createSelector([
    selectAllPartials,
    (state, level) => level
], (allPartials, level) => _.filter(allPartials, { level }))


/////////////// GROUPS_STUDENTS SELECTORS /////////////////////

const groupsStudentsglobalizedSelectors = groupsStudentsAdapter.getSelectors((state) => state.professorModule.entities.groupsStudents)

const selectAllGroupsStudents = (state) => groupsStudentsglobalizedSelectors.selectAll(state)
const selectEntitiesGroupsStudents = (state) => groupsStudentsglobalizedSelectors.selectAll(state)


/**
 * Recuperar todos los alumnos por el id de un grupo
 * 
 * @param {*} groupId 
 * 
 * @returns 
 */
export const selectStudentsByGroup = createSelector([
    selectAllGroupsStudents,
    selectEntitiesStudents,
    (store, groupId) => groupId
],
    (allGroupsStudents, entitiesStudents, groupId) => {

        const studentsId = _.filter(allGroupsStudents, { group_id: groupId })

        const students = studentsId.map(s => (entitiesStudents[s.student_id]))

        return students
    }
)



export const getLoading = (state) => state.professorModule.loading;

//////////////////// ENTITIES //////////////////////////////


export const getSubjects = (state) => state.professorModule.entities.subjects;
export const getSchool = (state) => state.professorModule.school;
export const getCatalogSubject = (state) => state.professorModule.entities.catalogSubjects;


/////////////////// GROUPS ITEMS //////////////////////////

export const getGroupItemSelected = (state) => state.professorModule.subjectSelected;



//////////////// TRUNCKS /////////////////

