import {
    Avatar, Box, Button, Card, CardActions, CardContent, createTheme, Snackbar,
    Switch, ThemeProvider, Tooltip, Typography
} from "@mui/material";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, esES, } from '@mui/x-data-grid';

import moment from 'moment'
import { useEffect, useState } from "react";
import { Error, SkeletonGrid } from "../../components/Feedback";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import { selectStudentsByGroup, selectSubjectById } from "../../store/slices/professorModule/entitiesSlice";
import {
    fetchSubjectAditionalData, invalidate, selectItemFetchingStatusSelected,
    selectItemOperationStatusSelected, selectItemSelected, storeAssitsList
} from "../../store/slices/professorModule/ItemsSlice";
import { useAuth } from "../../hooks";
import SyncIcon from '@mui/icons-material/Sync';
import SubjectPreviewCard from "../../components/SubjectPreviewCard";
import { selectStudentReport } from "../../store/slices/professorModule/uiSlice";
import { addStudentsItem, getEmptyStudentItem } from "../../store/slices/professorModule/studentItemsSlice";
import SkeletonPage from "./components/SkeletonPage";

function getCurrentDate() {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const currentDate = moment().format('YYYY-MM-DD hh:mm:ss');;
    const fecha = moment().format('YYYY-MM-DD');

    let m = fecha.substring(5, 7);
    let nM = Number(m);
    let nameMonth = monthNames[nM - 1];

    return fecha.substring(8, 10) + ' de ' + nameMonth + ' ' + fecha.substring(0, 4);
}

const theme = createTheme({
    palette: {
        neutral: {
            main: '#006837',
            contrastText: 'white',
        },
    },
});

/**
 * Pagina para nombrar lista
 * 
 * @returns 
 */
const ProfessorAssistanceList = () => {
    moment.locale('es')
    const history = useHistory()
    const { id } = useParams()
    const dispatch = useDispatch()
    const { path, url } = useRouteMatch()
    const currDate = getCurrentDate()

    const Auth = useAuth()
    const schoolId = Auth.getUser().school_id

    ////////////// SHARES STATE //////////////////

    const subjectItem = useSelector(selectItemSelected)

    const fetchingStatus = useSelector(selectItemFetchingStatusSelected)
    const operationStatus = useSelector(selectItemOperationStatusSelected)

    const subject = useSelector(selectSubjectById(id))

    const groupsStudentsList = useSelector(store => selectStudentsByGroup(store, subject.group_id))

    /////////////////////// LOCAL STATE ////////////////////////////

    let [students, setStudents] = useState([])

    const allowCreateAssitsList = subjectItem.listPerformed

    /**
     * Definicion de las columnas 
     */
    const studentColumns = [
        {
            field: 'url_photo_profile',
            headerName: 'Id',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (props) => (
                <Avatar
                    alt="Estudiante"
                    src={props.value}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                />
            )
        },
        {
            field: 'folio',
            headerName: 'Matricula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Nombre',
            headerName: 'Alumno',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'assits',
            headerName: 'Asistencia',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (props) => (
                <Switch
                    edge="end"
                    onChange={($event) => { onChangeAssitsList(props.id, $event) }}
                    checked={props.value}
                    color="neutral"
                    disabled={allowCreateAssitsList}
                />
            )
        },
        {
            field: 'total_absence',
            headerName: 'Accion',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (props) => (
                <Button
                    variant="contained"
                    onClick={() => { onStudentReport(props.row) }}
                    size={"small"}
                >
                    Reportar
                </Button>
            )
        }
    ]

    //////////////////// ACTIONS ////////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id))
        reload()
    }

    /**
     * Recargar la informacion adicional
     */
    const reload = () => {
        dispatch(fetchSubjectAditionalData({
            groupId: subject.group_id,
            subjectId: subject.subject_id,
            curretnDate: moment().format('YYYY-MM-DD')
        }))
    }

    /**
     * Recargar los datos
     */
    useEffect(() => {
        if (id) {
            reload()
        }
    }, [id])

    /**
     * Efecto para crear agregarle a alumno la pripiedad de la sistencia
     */
    useEffect(() => {
        let students = groupsStudentsList.map(s => ({ ...s, assits: true }))

        setStudents(students)
    }, [id, groupsStudentsList])

    /**
     * Registra las asistencias en webservices
     */
    const onSaveAssitsList = () => {

        let assitsRequest = students.map(item => ({
            assistance: (item.assits == true) ? 1 : 0,
            date: moment().format('YYYY-MM-DD'),
            stundetId: item.student_id,
            subjectId: id,
        }))

        dispatch(storeAssitsList({
            postData: assitsRequest,
            groupId: subject.group_id,
            subjectId: id
        }))
            .unwrap()
            .then(response => {
                showFeedback("Guardado correctamente")
            }).catch(({ feedback }) => {
                showFeedback(feedback.title)
            })
    }
    /**
     * Cuando se cambia la lista de asistencia
     * 
     */
    const onChangeAssitsList = (studentID, event) => {

        const student = students.map(item => ({
            ...item,
            assits: (item.student_id == studentID) ? event.target.checked : item.assits
        }))

        setStudents(student)
    }

    /**
     * Metodo para ir a pagina para reportar alumnos
     * 
     * @param {*} student 
     */
    const onStudentReport = (student) => {
        dispatch(addStudentsItem(getEmptyStudentItem(student)));
        dispatch(selectStudentReport(parseInt(student.student_id)))

        history.push({ pathname: `/inicio-maestros/reportar/${id}/alumno/${student.student_id}` });
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >

            {
                fetchingStatus == "pending" && (<SkeletonPage />)
            }

            {
                fetchingStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                fetchingStatus == 'fulfilled' && (
                    <>

                        <Box
                            sx={{
                                marginBottom: 2,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Typography variant="h4" component="div">Lista de asistencia: {currDate} </Typography>

                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>

                        <SubjectPreviewCard subjectId={subject.subject_id} />

                        <Card
                            sx={{
                                mt: 3,
                            }}
                        >
                            <CardContent>
                                <ThemeProvider theme={theme}>
                                    <DataGrid
                                        getRowId={(row) => row.student_id}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        rows={students}
                                        columns={studentColumns}
                                        pageSize={100}
                                        rowsPerPageOptions={[100]}
                                        disableSelectionOnClick
                                        autoHeight
                                        disableDensitySelector
                                    />
                                </ThemeProvider>
                            </CardContent>
                            <CardActions
                                sx={{

                                    direction: 'row',
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}
                            >
                                <LoadingButton
                                    size="small"
                                    endIcon={<SendIcon />}
                                    loading={operationStatus == 'pending'}
                                    loadingPosition="end"
                                    variant="contained"
                                    onClick={onSaveAssitsList}
                                    disabled={allowCreateAssitsList}
                                >
                                    Guardar
                                </LoadingButton>
                            </CardActions>
                        </Card>
                    </>
                )
            }

            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    )
}

export default ProfessorAssistanceList