import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "underscore";

/**
 * Adaptador para los parentescos
 */
const assistsAdapter = createEntityAdapter({
    selectId: (relation) => relation.assist_id,
    sortComparer: (a, b) => a.assist_id - b.assist_id
})

/**
 * Slice para la entidades de parentescos
 */
export const relationshipsSlice = createSlice({
    name: 'relationships',
    initialState: assistsAdapter.getInitialState(),
    reducers: {
        addOneAssist: assistsAdapter.addOne,
        addManyAssists: assistsAdapter.addMany,
        upsertManyAssists: assistsAdapter.upsertMany,
        upsertOneAssist: assistsAdapter.upsertOne,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return assistsAdapter.getInitialState()
        })
    }
})

/**
 * Acciones generadas por la librerias
 */
export const {
    addOneAssist,
    addManyAssists,
    upsertManyAssists,
    upsertOneAssist
} = relationshipsSlice.actions

/**
 * Exportaciones por defecto del reducer generado por la libreria
 */
export default relationshipsSlice.reducer

//////////////////// SELECTORES //////////////////

const globalizedSelectors = assistsAdapter.getSelectors((state) => state.entities.assists)

/**
 * Selecciona todas las asistencias
 */
export const selectAllAssists = (store) => globalizedSelectors.selectAll(store)

/**
 * Selecciona una asistencia por ID
 */
export const selectAssistsById = (id) => (store) => globalizedSelectors.selectById(store, id)


/**
 * Selecciona una asistencia por ID
 */
export const selectAssistsBy = (params) => (store) => {
    return _.filter(selectAllAssists(store), params)
}


//////////////////// TRUNKS /////////////////////
