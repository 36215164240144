import React, { useState } from 'react';
import { Dialog, DialogContent, FormControl, Grid, makeStyles } from '@material-ui/core';
import { TextField, Snackbar, Stepper, Step, StepLabel, Button} from '@mui/material';
import { Box } from '@mui/system';

const useStyles= makeStyles(theme =>({
    dialogWrapper: {
        //padding: theme.spacing(0),
        //position: 'absolute',
        //top: theme.spacing(5)
    },
    dialogTitle:{
        paddingRight: '0px',
    }
}));
/**
 * MODAL PARA REGISTRO DE ADMINISTRADORES
 * 
 * @param {*} param0 
 * @returns 
 */
const ModalRegistrar = ({title, children, openModal, setOpen, setOpenModal, other=null}) => {

    const classes= useStyles();
    
    const [activeStep, setActiveStep] = useState(0);

    const [snackBar, setSnackBar] = React.useState({
      open: false,
      vertical: 'bottom',
      horizontal: 'left',
    });

    const { vertical, horizontal, open } = snackBar;

    const nextStep= ()=>{
        if(activeStep < 1){
            setActiveStep((currentStep)=> currentStep + 1);
        }
        
    }

    const previusStep= ()=>{
        if(activeStep !== 0){
            setActiveStep((currentStep)=> currentStep -1 );
        }
        
    }

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false)    
    }
    

    return (
      <>
        <Dialog 
        open={openModal} 
        onClose={handleClose}
        fullWidth={true} 
        maxWidth="sm" 
        classes={{ paper:classes.dialogWrapper}} >

             

             {/* CONTENIDO*/}  
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1,  margin:0 }}>

                    <Stepper activeStep={activeStep}>
                        <Step>
                           <StepLabel>Datos Personales</StepLabel> 
                        </Step>
                        <Step>
                           <StepLabel>Datos Generales</StepLabel> 
                        </Step>
                    </Stepper>
                    {
                        activeStep===0 ?
                        (
                            <Grid container spacing={2}>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="nombre"
                                            name="nombre"
                                            label="Nombre(s)"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="apellidoP"
                                            name="apellidoP"
                                            label="Apellido Paterno"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="apellidoM"
                                            name="apellidoM"
                                            label="Apellido Materno"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="telefono"
                                            name="telefono"
                                            label="Teléfono"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="correo"
                                            name="correo"
                                            label="Correo Electrónico"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                            //helperText={password === "" ? 'Campo Requerido' : ' '}
                                            id="password"
                                            name="password"
                                            label="Contraseña"
                                            type="password"
                                            size="small"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //value={password}
                                            //onChange={event => {setPassword( event.target.value ); habilitarBtn();}}
                                            //error={password === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <TextField
                                        //helperText={passwordConf === "" ? 'Campo Requerido' : ' '}
                                        id="passwordConf"
                                        name="passwordConf"
                                        label="Repetir contraseña"
                                        type="password"
                                        size="small"
                                        variant="standard"
                                        inputProps={{ maxLength: 50 }}
                                        //value={passwordConf}
                                        //onChange={event => {setPasswordConf( event.target.value ); habilitarBtn();}}
                                        //error={passwordConf === ""}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xl={12} sm={12} md={12} xs={12} style={{marginTop: '10px', textAlign: "right"}}>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" color="primary" onClick={handleClose}>Cerrar</Button>
                                        <Button variant="outlined" color="primary" onClick={()=> nextStep()}>Continuar</Button>

                                        <Button variant="outlined" color="primary" onClick={()=> previusStep()}>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                        :
                        (
                            <Grid container spacing={2}>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="calle"
                                            name="calle"
                                            label="Calle"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="colonia"
                                            name="colonia"
                                            label="Colonia"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 50 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="numeroExt"
                                            name="numeroExt"
                                            label="Número Exterior"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 10 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="numeroInt"
                                            name="numeroInt"
                                            label="Número Interior"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 10 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6} sm={6} md={6} xs={12} style={{marginTop: '10px', textAlign: "center"}}>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                            //helperText={telefono === "" ? "Campo Requerido" : " "}
                                            id="codigo"
                                            name="codigo"
                                            label="Código Postal"
                                            type="text"
                                            size="small"
                                            autoComplete="current-password"
                                            variant="standard"
                                            inputProps={{ maxLength: 5 }}
                                            //onChange={handleInputChange}
                                            //value={telefono}
                                            //onChange={(event) => {setTelefono(event.target.value);}}
                                            //error={telefono === ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid item xl={12} sm={12} md={12} xs={12} style={{marginTop: '10px', textAlign: "right"}}>
                                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                   
                                        <Button variant="outlined" color="primary" onClick={handleClose}>Cerrar</Button>
                                        <Button variant="outlined" color="primary" onClick={()=> nextStep()}>Continuar</Button>

                                        <Button variant="outlined" color="primary" onClick={()=> previusStep()}>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                    

                    
                </Box>
            </DialogContent>
        </Dialog>
        
      </>
    )
}

export default ModalRegistrar;
