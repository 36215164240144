import { TreeItem, TreeView } from "@mui/lab"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectPreviewDataTree } from "../../../../store/slices/noticesModalUI/modes/studentsModeSlice";
import { useEffect, useState, } from "react";
import PreviewNotice from "../../../../components/DistributionNoticeModal/PreviewNotice";

/**
 * Componente para mostrar a quien se le enviara la notificacion
 * 
 * @returns 
 */
const SendNoticeStep = () => {


    ///////////////////// SHARED STATE //////////////////////

    /**
     * Elementos de los pasos que ha sido seleccionados 
     */
    let previewDataThree = useSelector(selectPreviewDataTree)


    ///////////////////// LOCAL STATE ////////////////////
    const [data, setData] = useState({})

    /**
     * Efecto para dar formato al arbol
     */
    useEffect(() => {

        const dataThree = previewDataThree.map(s => {
            return {
                id: s.student_id,
                name: `${s.name} ${s.last_name} ${s.second_last_name}`,
                children: s.parents.map(p => {
                    return {
                        id: p.user_id,
                        name: `${p.name} ${p.last_name} ${p.second_last_name}`,
                        payload: p
                    }
                }),
                payload: s
            }
        })

        setData({
            id: 'root',
            name: 'Alumnos',
            children: dataThree
        })

    }, [])

    /**
     * Renderiza el arbol de los elementos 
     * 
     * @param {*} nodes 
     * @returns 
     */
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    )

    return (
        <Box
        >
            <Box
                style={{
                    marginTop: "10px",
                    textAlign: "center",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                }}
                sx={{ mb: 2 }}
            >
                <Typography
                    variant="h5"
                    component="div"
                    style={{ width: "100%", color: "#000" }}
                >
                    Un último vistazo
                </Typography>
            </Box>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-evenly"
                alignItems="baseline"
            >
                <PreviewNotice />
                <TreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: 200, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                    {renderTree(data)}
                </TreeView>
            </Stack>
        </Box>
    )
}


export default SendNoticeStep