import { Box, IconButton, Card, CardContent, CardActions, TextField } from "@mui/material";
import * as  _ from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import { esES, DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from 'react';
import Services from "./../../service/Connection";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { SupervisorToolBar} from "../Supervisiones/components";

/**
 * 
 * Pagina Supervisiones
 * 
 * @returns
 */
const Supervisiones = () => {
  const history = useHistory();
  const [school, setSchools] = useState([]);
  const match = useRouteMatch();

  ///////////////// HOOKS EFFECTS ////////////////////

  useEffect(() => {
    Services.getHomeSupervisor().then(data => {
      console.log(data)

      let row = data.data.map((schoolRow, index) => {
        return { ...schoolRow, ...schoolRow.school}
      })
      console.log(row)
      setSchools(row)
    })
  }, []);

  /**
 * Columnas de la Tabla
 */
  const supervisor_columns = [
    {
      field: 'clave',
      headerName: 'CCT',
      type: 'number',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'Escuela',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
       return `${params.row.name}`;
    }
    },
    {
      field: 'last_supervition',
      headerName: 'Ultima Supervision',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (params.row.last_supervition) {
          return params.row.last_supervition;
        }
        return "No Supervisado";
     }
    },
    {
      field: 'user',
      headerName: 'Supervisor',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => { 
        if (params.row.user) {
          return `${params.row.user.nombre || ''} ${params.row.user.app || ''}`;
        }
        return "Sin Supervisor";
     }
    },
    {
      field: 'total_students',
      headerName: 'Total Estudiantes',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.total_students}`;
     }
    },
    {
      field: 'total_users',
      headerName: 'Total de usuarios',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.total_users}`;
     }
    },
    {
      field: 'total_users_trained',
      headerName: 'Usuarios Capacitados',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.total_users_trained}`;
     }
    },
    {
      field: 'Calculo_query',
      headerName: 'Progreso',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => { 
          return `${(params.row.total_users * params.row.total_users_apk)/100 || ''} ${"%" || ''}`
     }
    },
    {
      field: 'details',
      headerName: 'Detalle',
      type: 'number',
      flex: 0.4,
      minWidth: 80,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <strong>
            <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
                <InsertLinkIcon />
            </IconButton>
        </strong>
    ),
    },
  ];

      /**
     * Funcion para ir al detalle
     * 
     * @param {*} params 
     * @returns 
     */
      const goDetails = (params) => () => {
        history.push({ pathname: `${match.path}/Detalles/${params.id}` });
    }

  return (
    <Box sx={{
      flexGrow: 1,
      paddingTop: {
        xs: 1,
        sm: 2,
        md: 2,
      },
      paddingLeft: {
        xs: 1,
        sm: 2,
        md: 5,
      },
      paddingRight: {
        xs: 1,
        sm: 2,
        md: 5,
      },
      paddingBottom: {
        xs: 1,
        sm: 2,
        md: 5,
      },
    }}>
      <Card sx={{ mt: 3 }}>
      <CardContent
                    sx={{
                        pl: 0,
                        pr: 0,
                        pb: 0,
                        ' & .MuiDataGrid-root': {
                            border: 'none'
                        }
                    }}
                >
        <DataGrid
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          getRowId={({ school_id }) => school_id}
          disableColumnFilter={false} disableColumnSelector={false} disableColumnMenu={false}
          rows={school}
          columns={supervisor_columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
          components={{ Toolbar: SupervisorToolBar }}
          disableDensitySelector          
        />
        </CardContent>
        <CardActions sx={{ direction: "row", justifyContent: "flex-end", alignItems: "center" }}></CardActions>       
      </Card>
    </Box>
  );
};
export default Supervisiones;