import React from 'react'
import {
    Grid, Box, Card, Stack, Container, TextField, Button, Typography, Divider,
    CardContent, CardActions
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from "react-redux";
import ChangePassword from './components/ChangePassword'
import ChangeUsername from './components/ChangeUsername'
import { useAuth } from '../../hooks'
import { updateUser } from '../../store/slices/auth'
import SaveIcon from '@mui/icons-material/Save';
import UserPermissions from './components/UserPermissions';
import useFeedback from '../../hooks/useFeedback';
import UserImage from './components/UserImage';

/**
 * Pagina de parfil
 * 
 * @returns 
 */
const Perfil = () => {
    const feedbackApp = useFeedback()
    const dispatch = useDispatch();

    const Auth = useAuth()
    let user = Auth.getUser()

    const [userState, updateUserState] = useState({
        //city_id: user.city_id,
        colony: user.colony,
        //country_code: user.country_code,
        email: user.email,
        enrollment: user.enrollment,
        inside_number: user.inside_number,
        last_name: user.last_name,
        name: user.name,
        outside_number: user.outside_number,
        second_last_name: user.second_last_name,
        street: user.street,
        zipcode: user.zipcode
    })

    /////////////// ACTIONS //////////////////

    /**
     * Actualizacion de la contraseña
     * 
     * @param {*} event 
     */
    const onSubmitUser = () => {
        let endpoint = Auth.getEndPoint()

        dispatch(updateUser({
            userId: Auth.getUserID(),
            endpoint,
            userData: userState
        }))
            .unwrap()
            .then(response => {
                feedbackApp.showFeedback({
                    title: "Actualizado correctamente"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    function onInputUserChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        let oldState = {
            email: userState.email,
            name: userState.name,
            last_name: userState.last_name,
            second_last_name: userState.second_last_name,
            colony: userState.colony,
            //country_code: userState.country_code,
            inside_number: userState.inside_number,
            outside_number: userState.outside_number,
            street: userState.street,
            zipcode: userState.zipcode,
            //city_id: userState.city_id,
            //enrollment: ""
        }

        oldState[name] = value

        updateUserState(oldState);
    }


    /////////////////////////////////// VALIDACIONES DE LOS CAMPOS //////////////////////////////////////
    const emailValid = () => {
        const email = userState.email
        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return {
            invalid: userState.email == "" || !(regexEmail.test(email)),
            message: "Campo requerido o incorrecto"
        }
    }

    const nameValid = () => {
        return {
            invalid: userState.name == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const lastnameValid = () => {
        return {
            invalid: userState.last_name == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const secondLastnameValid = () => {
        return {
            invalid: false, //userState.second_last_name != "",
            message: "Campo requerido o incorrecto"
        }
    }

    const colonyValid = () => {
        return {
            invalid: userState.colony == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const outsideNumberValid = () => {
        return {
            invalid: userState.outside_number == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const insideNumberValid = () => {
        return {
            invalid: false, //userState.inside_number != "",
            message: "Campo requerido o incorrecto"
        }
    }

    const streetValid = () => {
        return {
            invalid: userState.street == "",
            message: "Campo requerido o incorrecto"
        }
    }

    const zipValid = () => {
        return {
            invalid: userState.zipcode == "",
            message: "Campo requerido o incorrecto"
        }
    }


    const userForminValid = () => {
        return (
            emailValid().invalid || nameValid().invalid || lastnameValid().invalid ||
            secondLastnameValid().invalid || colonyValid().invalid || insideNumberValid().invalid ||
            outsideNumberValid().invalid || outsideNumberValid().invalid || streetValid().invalid ||
            zipValid().invalid
        )
    }

    return (
        <Container
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={3}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                divider={<Divider orientation="horizontal" variant="middle" flexItem />}
                                spacing={4}
                            >


                                <Box
                                    component="div"
                                    sx={{ padding: 1 }}
                                >
                                    <UserImage />
                                </Box>

                                <Box>
                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom component="div">
                                            Telefono: {user.cellphone}
                                        </Typography>
                                        {
                                            user.enrollment && (
                                                <Typography variant="subtitle1" gutterBottom component="div">
                                                    Folio: {user.enrollment}
                                                </Typography>
                                            )
                                        }
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={9}>
                            <Box>
                                <Stack spacing={2} sx={{ paddingLeft: 2 }}>
                                    <Box direction="div">
                                        <Typography variant="h6" component="div" sx={{ marginBottom: 1 }}>Información personal</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="name"
                                                    name="name"
                                                    label="Nombre(*)"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    value={userState.name}
                                                    onChange={onInputUserChange}
                                                    error={nameValid().invalid}
                                                    helperText={nameValid().invalid ? nameValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="last_name"
                                                    name="last_name"
                                                    label="Apellido paterno(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.last_name}
                                                    onChange={onInputUserChange}
                                                    error={lastnameValid().invalid}
                                                    helperText={lastnameValid().invalid ? lastnameValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="second_last_name"
                                                    name="second_last_name"
                                                    label="Apellido materno"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.second_last_name}
                                                    onChange={onInputUserChange}
                                                    error={secondLastnameValid().invalid}
                                                    helperText={secondLastnameValid().invalid ? secondLastnameValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label="Correo electronico(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.email}
                                                    onChange={onInputUserChange}
                                                    error={emailValid().invalid}
                                                    helperText={emailValid().invalid ? emailValid().message : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Divider flexItem />

                                    <Box>
                                        <Typography variant="h6" component="div" sx={{ marginBottom: 1 }}>Información ubicación</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="street"
                                                    name="street"
                                                    label="Calle(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.street}
                                                    onChange={onInputUserChange}
                                                    error={streetValid().invalid}
                                                    helperText={streetValid().invalid ? streetValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="colony"
                                                    name="colony"
                                                    label="Colonia(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.colony}
                                                    onChange={onInputUserChange}
                                                    error={colonyValid().invalid}
                                                    helperText={colonyValid().invalid ? colonyValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {/* 
                                                <TextField
                                                    id="country_code"
                                                    name="country_code" 
                                                    label="Codigo del paiz"
                                                    variant="outlined"
                                                    size="small"
                                                    value={userState.country_code}
                                                    onChange={onInputUserChange}
                                                />
                                                */}

                                                <TextField
                                                    id="outside_number"
                                                    name="outside_number"
                                                    label="Numero exterior(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.outside_number}
                                                    onChange={onInputUserChange}
                                                    error={outsideNumberValid().invalid}
                                                    helperText={outsideNumberValid().invalid ? outsideNumberValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="inside_number"
                                                    name="inside_number"
                                                    label="Numero interiir"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.inside_number}
                                                    onChange={onInputUserChange}
                                                    error={insideNumberValid().invalid}
                                                    helperText={insideNumberValid().invalid ? insideNumberValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="zipcode"
                                                    name="zipcode"
                                                    label="Codigo postal(*)"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={userState.zipcode}
                                                    onChange={onInputUserChange}
                                                    error={zipValid().invalid}
                                                    helperText={zipValid().invalid ? zipValid().message : false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="city_id"
                                                    name="city_id"
                                                    label="Ciudad"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value="Durango"
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions
                    sx={{
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        variant="contained"
                        size="small"
                        disabled={userForminValid()}
                        startIcon={<SaveIcon />}
                        onClick={onSubmitUser}
                    >
                        Actualizar
                    </Button>
                </CardActions>
            </Card>

            {
                Auth.isAdminister() && (
                    <UserPermissions sx={{ marginTop: 2 }} />
                )
            }

            <Grid container
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                sx={{ marginTop: 2 }}
            >
                <Grid item xs={12} sm={6}>
                    <ChangePassword></ChangePassword>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ChangeUsername></ChangeUsername>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Perfil