import { Grid, Skeleton } from "@mui/material"

export default () => {

    return (
        <>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 4 }} animation="wave" />

            <Grid container spacing={{ xs: 1, md: 4 }} >
                {[1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} >
                            <Skeleton variant="rectangular" height={150} sx={{ mt: 2 }} animation="wave" />
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
