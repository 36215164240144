import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    subjectSelected: null,
    studentReportSelected: null
}

/**
 * Slice para el settings UI
 */
export const ProfessorsModuleSlice = createSlice({
    name: 'professorModule/ui',
    initialState: emptyState,
    reducers: {
        selectItem: (state, { payload }) => {
            state.subjectSelected = payload
        },
        selectStudentReport: (state, action) => {
            state.studentReportSelected = action.payload
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { selectItem, selectStudentReport } = ProfessorsModuleSlice.actions;

export default ProfessorsModuleSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectProfessorsModuleUI = (state) => state.professorModule.ui;


export const selectSubjectItemIdSelected = (state) => selectProfessorsModuleUI(state).subjectSelected


export const selectStudentIdSelected = (state) => selectProfessorsModuleUI(state).studentReportSelected


//////////////// TRUNCKS /////////////////

