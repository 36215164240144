import React, { useEffect, useState } from 'react';
import {
    FormControlLabel, Typography, Grid, FormGroup, Checkbox,
    Chip, FormHelperText, Box
} from '@mui/material';


const Permissions = ({
    initialPermissions, onChange, noValidate = false,
    helperText = "El usuario almenos tiene que contar un permiso",
    sx
}) => {

    /**
     * Lista de permisos disponibles
     */
    const [permissions, setPermissions] = useState({
        ADMINISTER: {
            name: "ADMINISTER",
            label: "Administrador",
            position: 1,
            value: false
        },
        PROFESSOR: {
            name: "PROFESSOR",
            label: "Profesor",
            position: 2,
            value: false
        },
        ASSESSOR: {
            name: "ASSESSOR",
            label: "Orientador",
            position: 3,
            value: false
        },
        PARENT: {
            name: "PARENT",
            label: "Padre",
            position: 4,
            value: false
        },
        SPOKESMAN: {
            name: "SPOKESMAN",
            label: "Vosero",
            position: 5,
            value: false
        },
        DIRECTOR: {
            name: "DIRECTOR",
            label: "Director",
            position: 6,
            value: false
        },
        ADMINISTRATIVE: {
            name: "ADMINISTRATIVE",
            label: "Administrativo",
            position: 7,
            value: false
        },
        TUTOR: {
            name: "TUTOR",
            label: "Tutor",
            position: 8,
            value: false
        }
    })

    //////////////////// EVENTOS ///////////////
    /**
     * Evento lanzado cadaves que el usuario
     * interactua con alguno de los permisos
     * 
     * @param {*} event 
     */
    const onChangePermission = (event) => {

        const value = event.target.checked;
        const name = event.target.name;

        let newState = {
            ...permissions, [name]: {
                ...permissions[name],
                value
            }
        }

        setPermissions(newState);

        onChange(encodePermissios(newState))
    }

    ////////////// VALIDACIONES //////////////
    /**
 * Validacion para los los permisos del usuario
 * 
 * @returns 
 */
    const permisionsValid = () => {
        let newPermissons = Object.values(permissions).filter(i => i.value)

        return {
            invalid: newPermissons.length == 0,
            message: helperText
        }
    }

    /**
     * Decodificacion de los permisos de usuario en el formato
     * ########### a una reprecentacion de objetos 
     * 
     * @param String Cadena de permisos
     *  
     * @returns 
     */
    const decodePermissions = async (cadena) => {
        if (cadena == undefined || cadena == null) {
            return
        }

        let array = cadena + "";

        let permissionsCollect = {}

        for (let i = 0; i < 8; i++) {

            switch (i) {
                case 0:
                    if (array[i] === '1') {

                        permissionsCollect["ADMINISTER"] = {
                            ...permissions["ADMINISTER"],
                            value: true
                        }
                    }
                    break;
                case 1:
                    if (array[i] === '1') {
                        console.log(permissions["PROFESSOR"])
                        permissionsCollect["PROFESSOR"] = {
                            ...permissions["PROFESSOR"],
                            value: true
                        }
                    }
                    break;
                case 2:
                    if (array[i] === '1') {
                        permissionsCollect["ASSESSOR"] = {
                            ...permissions["ASSESSOR"],
                            value: true
                        }
                    }
                    break;
                case 3:
                    if (array[i] === '1') {
                        permissionsCollect["PARENT"] = {
                            ...permissions["PARENT"],
                            value: true
                        }
                    }
                    break;
                case 4:
                    if (array[i] === '1') {
                        permissionsCollect["SPOKESMAN"] = {
                            ...permissions["SPOKESMAN"],
                            value: true
                        }
                    }
                    break;
                case 5:
                    if (array[i] === '1') {
                        permissionsCollect["DIRECTOR"] = {
                            ...permissions["DIRECTOR"],
                            value: true
                        }
                    }
                    break;
                case 6:
                    if (array[i] === '1') {
                        permissionsCollect["ADMINISTRATIVE"] = {
                            ...permissions["ADMINISTRATIVE"],
                            value: true
                        }
                    }
                    break;
                case 7:
                    if (array[i] === '1') {
                        permissionsCollect["TUTOR"] = {
                            ...permissions["TUTOR"],
                            value: true
                        }
                    }
                    break;

                default:
                    break;
            }
        }
        setPermissions({ ...permissions, ...permissionsCollect })
    }

    /**
     * 
     * @param {*} newStatePermissions 
     * 
     * @returns 
     */
    const encodePermissios = (newStatePermissions) => {

        let currentPermissons = getPermissionsName(initialPermissions)

        let newUnorderPermisions = Object.values(newStatePermissions)

        let newPermiss = newUnorderPermisions.filter(i => i.value).map(i => {
            return i.name
        })

        let deletePermissons = newUnorderPermisions.filter(i => !i.value)
            .filter(newP => {
                return currentPermissons.find(currP => currP.name == newP.name)
            }).map(i => {
                return i.name
            })

        let permissionsData = {
            added: newPermiss.join(','),
            removed: deletePermissons.length > 0 ? deletePermissons.join(',') : ''
        }

        return permissionsData
    }

    /**
     * Inicia la lectura de los permisos
     */
    useEffect(() => {
        decodePermissions(initialPermissions)
    }, [])

    ////////////////// VIEW /////////////

    /**
    * Retorna una lista con los nombre de los permisos
    * 
    * @param {*} groupsIds 
    * @returns 
    */
    const getPermissionsName = (groupsIds) => {

        if (groupsIds == undefined || groupsIds == null) {
            return []
        }

        let array = groupsIds + "";

        let permissionsCollect = []

        for (let i = 0; i < 8; i++) {

            switch (i) {
                case 0:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "ADMINISTER",
                            position: 1,
                            label: "Administrador"
                        })
                    }
                    break;
                case 1:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "PROFESSOR",
                            position: 2,
                            label: "Profesor"
                        })
                    }
                    break;
                case 2:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "ASSESSOR",
                            position: 3,
                            label: "Asesor"
                        })
                    }
                    break;
                case 3:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "PARENT",
                            position: 4,
                            label: "Padre"
                        })
                    }
                    break;
                case 4:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "SPOKESMAN",
                            position: 5,
                            label: "Vosero"
                        })
                    }
                    break;
                case 5:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "DIRECTOR",
                            position: 6,
                            label: "Director"
                        })
                    }
                    break;
                case 6:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "ADMINISTRATIVE",
                            position: 7,
                            label: "Director"
                        })
                    }
                    break;
                case 7:
                    if (array[i] === '1') {
                        permissionsCollect.push({
                            name: "TUTOR",
                            position: 8,
                            label: "Tutor"
                        })
                    }
                    break;

                default:
                    break;
            }
        }

        return permissionsCollect
    }

    const permissionEditUI = () => {

        let currentPermissons = getPermissionsName(initialPermissions)

        let newUnorderPermisions = Object.values(permissions)

        newUnorderPermisions.sort(function (a, b) { return a.position - b.position });

        let newPermissons = newUnorderPermisions.filter(i => i.value)
            .filter(newP => {
                return !currentPermissons.find(currP => currP.name == newP.name)
            }).map((i, key) => {
                return (<Chip key={key} label={i.label} color="success" size="small" />)
            })

        let deletePermissons = newUnorderPermisions.filter(i => !i.value)
            .filter(newP => {
                return currentPermissons.find(currP => currP.name == newP.name)
            }).map((i, key) => {
                return (<Chip key={key} label={i.label} color="error" size="small" />)
            })

        return (
            <>
                <Grid item xs={6}>
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" component="div">
                            Nuevos permisos
                        </Typography>
                        {
                            newPermissons.length > 0 && newPermissons
                        }
                        {
                            newPermissons == 0 && (
                                <Typography variant="subtitle1" component="div">
                                    Sin permisos
                                </Typography>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" component="div">
                            Permisos a remover
                        </Typography>
                        {
                            deletePermissons.length > 0 && deletePermissons
                        }
                        {
                            deletePermissons == 0 && (
                                <Typography variant="subtitle1" component="div">
                                    Sin permisos
                                </Typography>
                            )
                        }
                    </Box>
                </Grid>
            </>
        );
    }

    return (
        <Box sx={{ sx }}>
            <Grid
                container
                xl={12} md={12} sm={12} xs={12}
                style={{ paddingLeft: 30 }}
            >
                <Grid item xl={4} md={4} sm={12} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.ADMINISTER.value}
                                    onChange={onChangePermission}
                                    name="ADMINISTER"
                                />
                            }
                            label="Administrador"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.PROFESSOR.value}
                                    onChange={onChangePermission}
                                    name="PROFESSOR"
                                />
                            }
                            label="Profesor"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xl={4} md={4} sm={12} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.PARENT.value}
                                    onChange={onChangePermission}
                                    name="PARENT"
                                />
                            }
                            label="Padre"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.SPOKESMAN.value}
                                    onChange={onChangePermission}
                                    name="SPOKESMAN"
                                />
                            }
                            label="Vocero"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xl={4} md={4} sm={12} xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.ASSESSOR.value}
                                    onChange={onChangePermission}
                                    name="ASSESSOR"
                                />
                            }
                            label="Orientador"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.DIRECTOR.value}
                                    onChange={onChangePermission}
                                    name="DIRECTOR"
                                />
                            }
                            label="Director"
                        />

                        {/*
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.ADMINISTRATIVE.value}
                                    onChange={onChangePermission}
                                    name="ADMINISTRATIVE"
                                />
                            }
                            label="Administrativo"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={permissions.TUTOR.value}
                                    onChange={onChangePermission}
                                    name="TUTOR"
                                />
                            }
                            label="Tutor"
                        />*/}
                    </FormGroup>
                </Grid>

            </Grid>
            <Box>
                {
                    permisionsValid().invalid && !noValidate && (
                        <FormHelperText style={{ color: "red" }}>{permisionsValid().message}</FormHelperText>
                    )
                }
            </Box>
            <Grid container>
                {permissionEditUI()}
            </Grid>
        </Box>
    )
}
export default Permissions