
import React, { Components } from 'react'
import StatBiColor from "../../../../components/Chart/StatsBiColor"
import { useSelector } from "react-redux";
import { getBestScoreStudents } from '../../../../store/slices/background'

import { getLevelName, getTurnLevel } from "../../../../libs/utils";

/**
 * Estadistica que muestra al mejoro alumno
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const BestScoreStudentsIndicator = ({ level, theme }) => {

    let themeOptions = theme || { mainColor: "#fb349c", secundaryColor: "#ff4ce4" }

    /////////////////// SHARED DATA ///////////////////

    const bestStudent = useSelector(getBestScoreStudents(level))

    let value = 0
    let title = "Sin alumno"
    let levelName = "Plantel"


    if (bestStudent != null) {
        let group = bestStudent.group
        let groupTitle = "Sin grupo"

        if (group) {
            groupTitle = ` ${group.grade} ${group.group} ${getTurnLevel(group.turn)}  ${getLevelName(group.level)}`
        }

        value = bestStudent.average_rating
        title = `${bestStudent.name} ${bestStudent.last_name} ${groupTitle}`
    }

    if (level != -1) {
        levelName = getLevelName(level)
    }

    return (
        <StatBiColor
            value={value}
            title={title}
            label={"Mejor promedio " + levelName}
            mainColor={themeOptions.mainColor}
            secundaryColor={themeOptions.secundaryColor}
        >
        </StatBiColor>
    )
}

export default BestScoreStudentsIndicator


