import React from 'react'
import { Box, Grid, Card, IconButton, Menu, MenuItem, CardHeader } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from '@mui/x-data-grid';
import ExportableModal from "../../../components/Chart/ExportableModal"
import { useSelector } from "react-redux";
import { getLastAssitsListProfessorsCard, getLastCalificationProfessorsCard, getProfessorsReport, getSchool } from '../../../store/slices/background'
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment'
import 'moment/locale/es';
import LastAssitsListProfessorsIndicator from './Components/LastAssitsListProfessorsIndicator';
import LastCalificationProfessorsCard from './Components/LastCalificationProfessorsCard';
import { useAuth } from '../../../hooks';

/**
 * Pagina  de profesores
 * 
 * @returns 
 */
const ProfessorsView = () => {
    moment.locale('es')
    const history = useHistory()
    const match = useRouteMatch()
    const Auth = useAuth()

    /////////////////// SHARED STATE ///////////////////

    const professors = useSelector(getProfessorsReport)
    const lastAssitsListProfessors = useSelector(getLastAssitsListProfessorsCard)
    const lastCalificationProfessorsCard = useSelector(getLastCalificationProfessorsCard)


    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { school_id: null }
    });

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = () => {
        setReportData({
            report: "school-professors",
            title: "Reporte de profesores",
            query: { school_id: Auth.getUser().school_id }
        })
        setOpenExportModal(true);
    }

    ///////////////////////////// ACTIONS ////////////////////

    const columns = [
        //{ field: 'enrollment', headerName: 'Folio', flex: 1, sortable: false},
        { field: 'name', headerName: 'Nombre', flex: 1.5, sortable: true },
        { field: 'assists_list_percent', headerName: 'Lista asistencia', flex: 1.5, sortable: true, type: 'number' },
        { field: 'last_score_created', headerName: 'Ultima calificacion registrada', flex: 1.5, sortable: true },
        { field: 'subjects', headerName: 'Materias', flex: 1, sortable: true, type: 'number' },
        { field: 'groups', headerName: 'Grupos', flex: 1, sortable: true, type: 'number' },
        { field: 'reports', headerName: 'Reportes', flex: 1, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            ),
        }
    ];

    let filas = []


    for (const professor of professors) {
        let date = "Sin registro"

        if (professor.last_score_created) {
            date = moment(professor.last_score_created).format("DD MMMM YYYY")
        }

        filas.push({
            id: professor.professor_id,
            //enrollment: professor.enrollment,
            name: `${professor.name} ${professor.last_name}`,
            subjects: professor.subjects,
            groups: professor.groups,
            assists_list_percent: `${professor.assists_list_percent}%`,
            reports: professor.reports,
            last_score_created: date,
            bestAssistsListPercent: professor.professor_id == lastAssitsListProfessors.professor_id,
            lastScoreCreated: professor.professor_id == lastCalificationProfessorsCard.professor_id,
        })
    }

    /**
     * Funcion para ir al detalle de grupo
     * 
     * @param {*} params 
     * @returns 
     */
    const goDetails = (params) => () => {
        history.push({ pathname: `${match.path}/${params.id}` });
    }

    return (
        <Box>

            <Grid container spacing={2} sx={{ mb: 5 }} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sx="12" sm="auto">
                    <LastAssitsListProfessorsIndicator />
                </Grid>
                <Grid item sx="12" sm="auto">
                    <LastCalificationProfessorsCard />
                </Grid>
            </Grid>

            <Card>
                <CardHeader
                    title="Maestros"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 18
                        }
                    }}
                    subheader={"Total:" + professors.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={filas} columns={columns}
                    getCellClassName={(params) => {
                        if (params.field === 'assists_list_percent' && (params.formattedValue == "0%" || params.row.bestAssistsListPercent)) {
                            return "datagrid-app-orange"
                        }

                        if (params.field === 'last_score_created' && params.row.lastScoreCreated) {
                            return "datagrid-app-red"
                        }

                        return '';
                    }}
                />

            </Card>
            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}

export default ProfessorsView