import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Typography, Box
} from '@mui/material';
import { Transfer } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { selectSubjectcatalogsByLevel } from '../../../store/slices/entities/subjectcatalogs';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import useFeedback from '../../../hooks/useFeedback';
import { addSubjectGroup, LoadSubjectGroup, selectAddGroupSubjectOperationStatus } from '../../../store/slices/groupsUI/operationsSlice';
import Feedback from '../../../service/Feedback';
import { selectGroupByItemSelected, selectSubjectsWithDataByGroup } from '../../../store/slices/groupsUI/itemSlice';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';

/**
 * Modal para asignar materias a un grupo
 * 
 * @param {*} param0 
 * @returns 
 */
const AssingSubjectsModal = ({ open, setOpen, groupId }) => {

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    let FeedbackService = new Feedback()
    const feedbackApp = useFeedback()

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const groupSelected = useSelector(selectGroupByItemSelected)
    groupId = groupSelected?.group_id

    const statusOperation = useSelector(selectAddGroupSubjectOperationStatus)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const subjectsCatalog = useSelector(selectSubjectcatalogsByLevel(groupSelected?.level));
    const currentSubjects = useSelector((state) => selectSubjectsWithDataByGroup(state, groupId));

    /////////////////// ESTADO //////////////////

    const [subjectsTargetKeys, setSubjectsTargetKeys] = useState([]);
    const [subjectsSelectedKeys, setSubjectsSelectedKeys] = useState([]);

    ////////////////////// FUNCIONES ////////////////////
    useEffect(() => {
        dispatch(LoadSubjectGroup(schoolId))
    }, []);
    /**
     * Guardar datos
     */
    const guardarDatos = () => {

        let data = getFormatedData()

        dispatch(addSubjectGroup({
            groupId,
            subjectsCreate: data.create,
            subjectDelete: data.delete
        }))
            .unwrap()
            .then(({ message }) => {
                let initialMessage = message ? message : "Materias registradas"

                feedbackApp.showFeedback({
                    title: initialMessage
                })

                closeModal()
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Funcion para cerrar el modal
     */
    const closeModal = () => {
        setOpen(false)
        dispatch(selectGroupItem(null));
    }

        /* No cerrar click afuera del modal */
        const handleClose = (event, reason) => {
            if (reason === 'backdropClick') {
                return;
            }
            setOpen(false)    
        }

    /**
     * Determina si la materia sera creada
     * 
     * @param catalogsSubjectId Identificador del catalogo de la materia
     */
    const isWillNewSubject = (catalogsSubjectId) => {
        let subjectInTarget = subjectsTargetKeys.find(scId => scId == catalogsSubjectId)

        if (!subjectInTarget) {
            return false
        }

        let isInCurrent = currentSubjects.filter(s => s.catalog_subject_id == subjectInTarget)

        return isInCurrent.length == 0
    }

    /**
     * Determina si la materia sera creada
     * 
     * @param catalogsSubjectId Identificador del catalogo de la materia
     */
    const isWillDeleteSubject = (catalogsSubjectId) => {

        let availableSubjets = subjectsCatalog.filter(sc => !subjectsTargetKeys.find(stk => stk === sc.catalog_subject_id))

        let subjetinAvailable = availableSubjets.find(sc => sc.catalog_subject_id == catalogsSubjectId)

        if (!subjetinAvailable) {
            return false
        }

        let isInCurrent = currentSubjects.filter(s => s.catalog_subject_id == subjetinAvailable.catalog_subject_id)
        return isInCurrent.length > 0
    }

    /**
     * Recuperamos las materias que seran eliminadas
     * y elimnadas
     * 
     * @returns 
     */
    const getFormatedData = () => {
        let subjectsToCreate = subjectsCatalog.filter(sc => {
            return isWillNewSubject(sc.catalog_subject_id)
        })

        let subjectsCatlogTODelete = currentSubjects.filter(s => {
            return isWillDeleteSubject(s.catalog_subject_id)
        })

        return {
            create: subjectsToCreate,
            delete: subjectsCatlogTODelete
        }
    }

    /**
     * Determina si el catalogo de materia es nuevo
     */
    const getItemTranfer = (item) => {
        //let a = currentSubjects.find(s => s.catalog_subject_id == item)

        let willNewSubject = isWillNewSubject(item.catalog_subject_id)

        let color = 'black'

        if (willNewSubject) {
            color = 'green'
        } else {
            color = isWillDeleteSubject(item.catalog_subject_id) ? "red" : "black"
        }

        return (
            <Box
                sx={{
                    color: color
                }}
            >
                {item.folio} {item.title} {getLevelName(item.level)}
            </Box>
        )
    }

    /**
     * Funcion Ejecutada cuando el usuario intercambia elementos
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setSubjectsTargetKeys(nextTargetKeys);
    };

    /**
     * Funcion ejecutada cuando el usuario selecciona un elemento
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSubjectsSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        
        if (open) {
            let currentSubjectsIds = currentSubjects.map(i => i.catalog.catalog_subject_id)
            setSubjectsTargetKeys(currentSubjectsIds)
        }
    }, [open]);

    ////////////////////////// VALIDACIONES ////////////////////////

    const formInvalid = () => {
        let data = getFormatedData()

        return data.create.length == 0 && data.delete.length == 0
    }
    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={handleClose}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Agregar Materias <br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Grupo {groupSelected?.grade}° {groupSelected?.group} {getTurnLevel(groupSelected?.turn)} {getLevelName(groupSelected?.level)}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    <Transfer
                        style={{ marginTop: '20px' }}
                        showSearch
                        listStyle={{
                            width: 400,
                            height: 400,
                        }}
                        locale={{
                            notFoundContent: ["Sin materias", "Sin materias"],
                            itemUnit: "Materias",
                            itemsUnit: "Materias",
                            searchPlaceholder: 'Buscar aquí'
                        }}
                        dataSource={subjectsCatalog.map(i => {
                            return { ...i, key: i.catalog_subject_id }
                        }
                        )}
                        titles={['Disponibles', 'Asignadas']}
                        targetKeys={subjectsTargetKeys}
                        selectedKeys={subjectsSelectedKeys}
                        onChange={onChange}
                        onSelectChange={onSelectChange}
                        render={item => {
                            return getItemTranfer(item)
                        }}
                        filterOption={(inputValue, option) => {

                            let filter = option.title + " " + option.folio
                            return filter.includes(inputValue)
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={guardarDatos}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Guardar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AssingSubjectsModal