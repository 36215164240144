import React from 'react'
import { Card, Stack, Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import { red, green } from '@mui/material/colors';

/**
 * Estadistica que muestra el indice de asistencia
 * 
 * @param {*} props 
 * @returns 
 */
export default (props) => {

    function pu(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }


    function getValueFormated(value) {
        if (value == 0) {
            return 0
        }
        return pu(value, 2)
    }

    const badValue = () => {
        return getValueFormated(Math.trunc(props.bad * 100))
    }

    const goodValue = () => {
        return getValueFormated(Math.trunc(props.good * 100))
    }

    return (
        <Card sx={{ height: 80, width: 300, p: 1 }} >

            <Stack
                direction="column"
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ color: green[500] }}>
                        <FontAwesomeIcon icon={faLongArrowAltUp} size="2x" />
                        <Typography variant="h5" component="span"> {goodValue()}% </Typography>
                    </Box>


                    <Box sx={{ color: red[500] }}>
                        <FontAwesomeIcon icon={faLongArrowAltDown} size="2x" />
                        <Typography variant="h5" component="span"> {badValue()}% </Typography>
                    </Box>
                </Stack>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                    <Typography variant="subtitle1" gutterBottom component="span">
                        {props.title}
                    </Typography>
                </Box>
            </Stack>

        </Card >
    )
}