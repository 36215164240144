import React from 'react'

import { useSelector } from "react-redux";
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';
import StatsBiColor from '../../../../components/Chart/StatsBiColor';
import { getBestAssistsGroupCard } from '../../../../store/slices/background';

/**
 * Componente que muestra el mayor grupo con 
 * asistencias
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const BestAssistsGroupIndicator = (props) => {

  const bestAssistsGroupCard = useSelector(getBestAssistsGroupCard)

  return (
    <StatsBiColor
      icon={faChalkboardTeacher}
      iconColor={blue[500]}
      value={bestAssistsGroupCard.total_presence}
      title={`${bestAssistsGroupCard.title} ${bestAssistsGroupCard.levelName}`}
      label={"Mejor asistencia"}
      mainColor={"#33ccff"}
      secundaryColor={"#0099cc"}
      valueOptions={{ variant: "h5", component: "span" }}
    >
    </StatsBiColor>
  )
}

export default BestAssistsGroupIndicator