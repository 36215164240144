import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

/**
 * Adaptador para grupos
 */
const groupsStudentsAdapter = createEntityAdapter({
    selectId: (groupStudent) => groupStudent.id,
    sortComparer: (a, b) => a.id - b.id 
})

/**
 * Slice para la entidades de alumnos
 */
const  groupsStudentsSlice = createSlice({
    name: "noticesModalUI/entities/groupsStudents",
    initialState: groupsStudentsAdapter.getInitialState(),
    reducers: {
        addManyGroupsStudents: (state, {payload}) => {

            const entitiyItems = Object.values(state.entities)
            let nextId = entitiyItems.length + 1

            let items = payload.filter(i => !entitiyItems.find(ei => i.group_id == ei.group_id && i.student_id == ei.student_id))
                            .map((i, index) => {
                                return {...i, id: nextId + index}
                            })

            groupsStudentsAdapter.addMany(state, items)
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return groupsStudentsAdapter.getInitialState()
        })
        /**
         * Limpiar los datos al cerar el modal
         */
        builder.addCase('noticesModalUI/reset', (state, action) => groupsStudentsAdapter.getInitialState())
    }
})

export const { addManyGroupsStudents } = groupsStudentsSlice.actions

//////////////////////// SELECTORES ///////////////////////

const globalizedSelectors = groupsStudentsAdapter.getSelectors((state) => state.noticesModalUI.entities.groupsStudents)

/**
 * Selecciona todos los grupos
 */
export const selectAllGroupsStudents = (store) => globalizedSelectors.selectAll(store)


export default groupsStudentsSlice.reducer