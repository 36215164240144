import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Button, Divider, IconButton, LinearProgress, Box, Tooltip
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Connection from "../../../service/Connection"
import { selectStudentById } from '../../../store/slices/entities/students';
import Feedback from '../../../service/Feedback';
import { DataGrid, esES, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import _ from 'lodash';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import useFeedback from '../../../hooks/useFeedback';
import { assignMainGroup, selectAssignMainGroupStatus } from '../../../store/slices/studentsUI/operationsSlice';

/**
 * Modal para asignar grupo a un alumno
 * 
 * @param {number} studentId Identificador del alumno
 * 
 * @returns 
 */
const AssignGroupsStudentsModal = ({ open, setOpen, studentId = null }) => {

    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    let FeedbackService = new Feedback()
    const feedbackApp = useFeedback()

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const student = useSelector(selectStudentById(studentId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const operationStatus = useSelector(selectAssignMainGroupStatus)

    ///////////////// LOCAL STATE ////////////////////

    const [loading, setLoading] = useState('idle')

    const [groups, setGroups] = useState([])
    const [groupSelected, setGroupSelected] = useState(null)

    let currentGroup = _.find(groups, { 'group_id': student?.group_id })

    let groupsAvailables = groups.filter(item => {

        if (!currentGroup) {
            return true
        }

        return item.level == currentGroup.level && item.grade == currentGroup.grade && item.group_id != currentGroup?.group_id
    })

    /**
     * Definicion de las columnas 
     */
    const columns = [
        {
            field: 'grade',
            headerName: 'Grado',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return `${value || ''}°`;
            }
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        /*{
            field: 'special',
            headerName: 'Tipo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return getSpecialName(value)
            }
        },*/
        {
            field: 'turn',
            headerName: 'Turno',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return getTurnLevel(value)
            }
        },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: ({ value }) => {
                return getLevelName(value)
            }
        },
        {
            field: 'subjects',
            headerName: 'Materias',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'students',
            headerName: 'Alumnos',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group_id',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {

                if (params.value == student.group_id) {
                    return (
                        <Tooltip title={"Grupo actual"}>
                            <IconButton
                                size="small"
                                variant="contained"
                                disabled={true}
                            >
                                <AccessibilityNewIcon color={"disabled"} />
                            </IconButton>
                        </Tooltip>
                    )
                }

                return (
                    <Tooltip title={"Seleccionar grupo"}>
                        <IconButton
                            size="small"
                            variant="contained"
                            onClick={() => { onSelectGroup(params.row) }}
                            disabled={params.row.selected || operationStatus == 'pending'}
                        >
                            <TouchAppIcon color={params.row.selected ? "primary" : "disabled"} />
                        </IconButton>
                    </Tooltip>
                )
            }
        },
    ];

    ///////////// ACTIONS ///////////////////////

    /**
     * Grupos seleccionado
     * 
     * @param {*} group 
     */
    const onSelectGroup = (group) => {

        let newGroups = groups.map(i => {
            if (i.selected) {
                i.selected = false
            }
            if (i.group_id == group.group_id) {
                i.selected = true
            }
            return i
        })

        setGroups(newGroups)
        setGroupSelected(group)
    }

    /**
     * Manejador de boton guradar 
     */
    const enviarDatos = () => {
        dispatch(assignMainGroup({ student, groupSelected }))
            .unwrap()
            .then((data) => {
                setOpen(false)

                feedbackApp.showFeedback({
                    title: "Alumno agregado a grupo"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Efecto lanzado cuando se abre el modal
     */
    useEffect(() => {
        if (!open) {
            setLoading('idle')
            setGroups([])
        } else {
            setLoading('loading')

            Connection.groupsBySchool(schoolId, {
                filters: { "special": 0 }
            })
                .then(i => i.data.data)
                .then(g => {

                    const groups = g.map(i => ({ id: i.group_id, ...i, selected: false }))
                    setGroups(groups)
                    setLoading('loaded')
                })
                .catch(err => {
                    const feedback = FeedbackService.getMessage(err)

                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                }).then(() => {
                    setLoading('idle')
                })
        }
    }, [open])

    ////////////////////////// VALIDACIONES //////////////////////////////////

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        return groupSelected == null
    }

    ////////////////////// VIEW //////////////////////////

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    function CurrentGroup({ groups, student }) {

        let currGroup = _.find(groups, { 'group_id': student.group_id })

        if (!currGroup) {
            return <></>
        }
        return (
            <Box sx={{ mb: 2 }}>
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <Typography variant='h6'>
                        Grupo actual
                    </Typography>
                    <Typography variant='h5'>
                        {currGroup.grade}°{currGroup.group} {getTurnLevel(currGroup.turn)} {getLevelName(currGroup.level)}
                    </Typography>
                </Box>
                <Divider>Grupos</Divider>
            </Box>
        )
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            disableEscapeKeyDown={operationStatus == 'pending'}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        {(student?.group_id == null || student?.group_id == 0) ? "Asignar grupo" : "Cambiar grupo"}
                        <br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Alumno: {student?.name} {student?.last_name}
                        </span>
                    </Typography>
                </div>

            </DialogTitle>
            <Box sx={{ width: '100%' }}>
                <LinearProgress sx={{ visibility: (loading == 'loading') ? 'visible' : 'hidden' }} />
            </Box>
            <DialogContent>

                <CurrentGroup groups={groups} student={student} />

                <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={groupsAvailables}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[5]}
                    autoHeight
                    components={{ Toolbar: CustomToolbar }}
                    density="compact"
                    disableSelectionOnClick
                />

            </DialogContent>

            <DialogActions>

                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={operationStatus == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    {(student?.group_id == null || student?.group_id == 0) ? "Asignar" : "Cambiar"}
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpen(false) }}
                    disabled={operationStatus == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AssignGroupsStudentsModal