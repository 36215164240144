import { Avatar, Box, Button, Stack, Switch, TextField, Typography } from "@mui/material";
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      neutral: {
        main: '#006837',
        contrastText: 'white',
      },
    },
});

const ReportItemCard = (props) => {
    const { report, disabled, otroDescription} = props

    const handleChange = (event) => {
        props.onChange(report.catalog_report_id, event.target.checked)
    }

    const handleChangeDescription = (event) => {
        props.onChangeDescription(report.catalog_report_id, event.target.value)
    }


    let showDescripction = ((report.title.toLowerCase()) == 'otro' ||  (report.title.toLowerCase()) == 'otros') && report.reportCheck == 1

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center"}}>
                <Stack direction="row" spacing={2} sx={{ flex: '1' }} justifyContent={"flex-start"} alignItems={"center"}>
                    <Typography variant="body1" component="div">
                        {report.title}
                    </Typography>
                </Stack>

                {
                    showDescripction && (
                        <Box>
                            <TextField
                                id="outlined-multiline-static"
                                label="Descripción"
                                value={otroDescription}
                                onChange={handleChangeDescription}
                            />
                        </Box>
                    )
                }

                <Stack direction="row" spacing={{xs: 1, sm: 2}} sx={{flex: '2'}} justifyContent={"flex-end"} alignItems={"center"}>
                    <Box sx={{width: 100}}> 
                        <Switch
                            edge="end"
                            checked={report.reportCheck}
                            onChange={handleChange}
                            checked={report.reportCheck == 1}
                            color="neutral"
                            disabled={disabled}
                        />
                    </Box>
                </Stack>
            </Box>
        </ThemeProvider>
    )
} 

export default ReportItemCard