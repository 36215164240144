import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Box } from '@mui/system';
import { Button, Grid, Tooltip, Snackbar } from '@mui/material';
import * as _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import CardItem from '../../components/Card/CardItem';
import { faCheck, faFile, faTimes, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../hooks';
import { Error } from '../../components/Feedback';
import SkeletonPage from './components/SkeletonPage';
import SyncIcon from '@mui/icons-material/Sync';
import { invalidateStudentItem, loadStudentsDetailsUI, selectStudentCalificationsPage, selectStudentStatusServer } from '../../store/slices/scoresUI/ItemsSlice';
import { getLevelName, getTurnLevel } from '../../libs/utils';
import AssistsCard from './components/AssistsCard';
import ScoresCard from './components/ScoresCard';

/**
 * Pagina de calificaciones por alumno
 * 
 * @returns 
 */
const StudentScores = () => {

    let { groupId, id } = useParams();

    const Auth = useAuth()
    let user = Auth.getUser()
    const schoolId = user.school_id
    const dispatch = useDispatch()

    ///////////////////// SHARED STATE //////////////////////

    const statusServer = useSelector(selectStudentStatusServer(groupId, id))

    const pageData = useSelector(selectStudentCalificationsPage(groupId, id, schoolId))
    const { group, student } = pageData

    /////////////////// LOCAL STATE ////////////////////////


    ////////////////// ACTIONS ///////////////////////////

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadStudentsDetailsUI({ groupId, studentId: id, schoolId, level: group.level }))
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidateStudentItem({
            groupId,
            studentId: id
        }))
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadStudentsDetailsUI({ groupId, studentId: id, schoolId, level: group.level }))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                showFeedback(feedback.title)
            })
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");


    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>
            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>

                        </Box>

                        <Grid
                            container
                            sx={{ mt: 4 }}
                            spacing={2}
                        >

                            <Grid item xl={6} sm={12} md={6} xs={12}>

                                <CardItem
                                    backgroundColor={'#313238'}
                                    icon={faUserGraduate}
                                    iconColor={"#646672"}
                                    text={student.name + " " + student.last_name + " " + student.second_last_name}
                                    textColor='#fff' title={"Matrícula: " + student.folio}
                                />

                            </Grid>

                            <Grid item xl={6} sm={12} md={6} xs={12}>

                                <CardItem
                                    backgroundColor={'#313238'}
                                    icon={faUsers}
                                    iconColor={"#646672"}
                                    text={group.grade + "° " + group.group + " - " + getLevelName(group.level)}
                                    textColor='#fff'
                                    title={"Turno: " + getTurnLevel(group.turn)}
                                />

                            </Grid>
                            <Grid item xl={4} sm={4} md={4} xs={12}>

                                <CardItem
                                    backgroundColor={'#4EB450'}
                                    icon={faCheck}
                                    iconColor={"#46A248"}
                                    text={student.total_presence}
                                    textColor='#fff'
                                    title="Total Asistencias"
                                />

                            </Grid>
                            <Grid item xl={4} sm={4} md={4} xs={12}>

                                <CardItem
                                    backgroundColor={'#C70039'}
                                    icon={faTimes}
                                    iconColor={"#860127"}
                                    text={student.total_absence}
                                    textColor='#fff'
                                    title="Total Faltas"
                                />

                            </Grid>
                            <Grid item xl={4} sm={12} md={4} xs={12}>

                                <CardItem
                                    backgroundColor={'#5DADE2'}
                                    icon={faFile}
                                    iconColor={"#4682A9"}
                                    text={student.average_rating}
                                    textColor='#fff'
                                    title="Promedio"
                                />

                            </Grid>
                        </Grid>


                        <ScoresCard groupId={groupId} studentId={id} schoolId={schoolId} />
                        <AssistsCard groupId={groupId} studentId={id} schoolId={schoolId} />
                    </>
                )
            }

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    )
}

export default StudentScores;
