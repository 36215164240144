import React from 'react'
import { Box, Grid, Card, ListItemText, Typography, List, ListItem, ListItemIcon, ListItemAvatar, Divider, ListSubheader, Button } from "@mui/material"
import ExportableModal from "../../../components/Chart/ExportableModal"
import SubjectsTable from "./Components/SubjectsTable"
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getFetchStatus, getProfessorsReportByID } from '../../../store/slices/background';
import { ColorBox } from '../../../components/Chart/Cards';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import Masonry from '@mui/lab/Masonry';
import moment from 'moment'
import 'moment/locale/es';
import Empty from '../components/Empty';

/**
 * Pagina que muestra los detalles del padre
 * 
 * @returns 
 */
const ProfessorsDetails = () => {
    moment.locale('es')

    const history = useHistory()

    let { id } = useParams();
    if (isNaN(id)) {
        goProfessors()
    }

    ////////////////// SHARES STATE /////////////////////////

    const professor = useSelector(getProfessorsReportByID(id))
    const fetchStatus = useSelector(getFetchStatus)


    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { professor_id: null }
    });

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = (data) => {
        setReportData(data)
        setOpenExportModal(true);
    }

    ///////////////////////////// ACTIONS ////////////////////

    const goProfessors = () => {
        history.push({ pathname: `/director/profesores` });
    }

    const ProfessorsDetails = ({ professor }) => {
        const name = `${professor.name} ${professor.last_name} ${professor.second_last_name}`
        let date = 'Sin registro'

        if (professor.last_score_created) {
            date = moment(professor.last_score_created).format("DD MMMM YYYY")
        }

        return (
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Card>
                            <List dense={true}>
                                <ListSubheader component="div">
                                    Detalle de contacto
                                </ListSubheader>

                                <ListItem dense={true}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <PhoneAndroidIcon />
                                        </ListItemIcon>
                                    </ListItemAvatar>
                                    <ListItemText primary={professor.cellphone} secondary="Celular" />
                                </ListItem>

                                <ListItem dense={false}>
                                    <ListItemIcon>
                                        <MailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={professor.email} secondary="Correo" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <FormatListNumberedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={professor.enrollment} secondary="Folio" />
                                </ListItem>

                                <Divider />

                                <ListSubheader component="div">
                                    Detalle de ubicacion
                                </ListSubheader>

                                <ListItem>
                                    <ListItemIcon>
                                        <MapsHomeWorkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={professor.colony} secondary="Colonia" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={professor.street} secondary="Calle" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={professor.zipcode} secondary="Codigo postal" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={professor.outside_number} secondary="Numero exterior" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(professor.inside_number) ? professor.inside_number : "Sin número"}
                                        secondary="Numero interior"
                                    />
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <ColorBox
                            value={name}
                            label="Profesor"
                            mainColor={"#7434a3"}
                            sx={{ width: "100%" }}
                        />
                        <Masonry columns={4} spacing={2} sx={{ marginTop: 2 }}>

                            <ColorBox value={professor.assists_list_percent} label="Lista asistencia" mainColor={"#2596be"} />

                            <ColorBox value={date} label="Ultima calificacion" mainColor={"#5d4037"} valueVariant={"h6"} />

                            <ColorBox value={professor.reports} label="Reportes" mainColor={"#d70206"} />

                            <ColorBox value={professor.subjects} label="Materias" mainColor={"#d70206"} />

                            <ColorBox value={professor.groups} label="Grupos" mainColor={"#fc6404"} />

                        </Masonry>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    return (
        <Box>
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant={"h4"} component={"div"}>Detalle del profesor</Typography>
            </Box>

            {
                professor && (<ProfessorsDetails professor={professor} />)
            }

            {
                professor && (<SubjectsTable professorId={professor.professor_id} export={onExport} />)
            }

            {
                (professor == null && fetchStatus == 'pending') && (<Empty label="Buscando">  </Empty>)
            }

            {
                (professor == null && fetchStatus != 'pending') &&
                (
                    <Empty label="Profesor no controntrado">
                        <Button variant="contained" onClick={goProfessors}>Ir a profesores</Button>
                    </Empty>
                )
            }


            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}



export default ProfessorsDetails