import React from 'react'
import { useSelector } from "react-redux";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';
import { getBestScoreGroupCard } from '../../../../store/slices/background';
import StatsBiColor from '../../../../components/Chart/StatsBiColor';

/**
 * Componente que muestra el mayor grupo con 
 * calificacion
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const BestScoreGroupIndicator = (props) => {

  const bestScoreGroup = useSelector(getBestScoreGroupCard)

  return (
    <StatsBiColor
      value={bestScoreGroup.average_rating}
      title={`${bestScoreGroup.title} ${bestScoreGroup.levelName}`}
      label={"Mejor promedio"}
      mainColor={"#b59dfa"}
      secundaryColor={"#7434a3"}
    >
    </StatsBiColor>
  )
}

export default BestScoreGroupIndicator