import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Typography, TextField, Divider, Avatar, Stack
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudentById } from '../../store/slices/entities/students';
//import { deleteStudent, selectStatusOperation } from '../../store/slices/groups';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../hooks';
import { selectSettingBySchoolId } from '../../store/slices/entities/settings';
import useFeedback from '../../hooks/useFeedback';
import { useEffect } from 'react';
import { deleteStudent } from '../../store/slices/groupsUI/operationsSlice';
/**
 * Modal para dar de baja un alumno 
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const DeleteStudentModal = ({ open, setOpen, studentId }) => {
    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARE STATE /////////////

    const student = useSelector(selectStudentById(studentId))
    const config = useSelector(selectSettingBySchoolId(schoolId))
    //const statusOperation = useSelector(selectStatusOperation)
    const [loading, setLoading] = useState(false)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');


    //////////// LOCAL STATE /////////////////

    const [password, setPassword] = useState('')

    //////////////////// ACTIONS /////////////////
    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false)    
    }

    /**
     * Funcion para dar de baja al alumno
     */
    const enviarDatos = () => {
        dispatch(deleteStudent({ studentId, password }))
            .unwrap()
            .then((data) => {
                setOpen(false)

                feedbackApp.showFeedback({
                    title: "El alumno fue dado de baja correctamente"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }


    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        //const name = event.target.name;

        setPassword(value);
    }

    useEffect(() => {
        if (!open) {
            setPassword("")
        }
    }, [open])

    /**
     * Escucha eventos del teclado en input de contraseña
     * 
     * @param {*} event 
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enviarDatos()
        }
    }

    //////////////////////// VALIDATIONS ////////////////////

    const passwordValid = () => {
        return {
            invalid: (password == ''),
            message: "Campo requerido"
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xs"}
            fullWidth={true}
            disableEscapeKeyDown={loading}
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <Typography variant="h6" component="div" style={{ color: fontColor.value }}>
                    Dar de baja
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    sx={{ mt: 1, mb: 2 }}
                >
                    <Avatar alt={student?.name} src={student?.url_photo_profile} sx={{ width: 74, height: 74, mb: 1 }} />

                    <Typography variant="body1" color="text.secondary">
                        <strong>{(student?.folio) ? (student?.folio) : "Sin folio"}</strong>
                    </Typography>

                    <Typography variant="h5">
                        {student?.name} {student?.last_name} {student?.second_last_name}
                    </Typography>

                    <Typography variant="h6">
                        Será dado de baja del sistema
                    </Typography>
                </Stack>

                <Divider>Por seguridad</Divider>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Contraseña"
                    fullWidth
                    variant="standard"
                    type="password"
                    value={password}
                    onChange={onInputChange}
                    error={passwordValid().invalid}
                    helperText={passwordValid().invalid ? passwordValid().message : false}
                    onKeyDown={handleKeyDown}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="error"
                    onClick={enviarDatos}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<DeleteOutlineIcon />}
                    variant="contained"
                    disabled={passwordValid().invalid}
                >
                    Dar de baja
                </LoadingButton>

                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    size="small"
                    disabled={loading}
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default DeleteStudentModal
