import { Card, CardContent, CardHeader } from "@mui/material"
import { Box } from "@mui/system"
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid"
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStudentCalificationsPage } from "../../../store/slices/scoresUI/ItemsSlice";
import AssistsToolBar from "./AssistsToolBar"

/**
 * Componente encargado de mostrar las asistencias de un alumno
 * 
 * @returns 
 */
const AssistsCard = ({ groupId, studentId, schoolId }) => {

    ////////////////// SHARES STATE //////////////////
    const pageData = useSelector(selectStudentCalificationsPage(groupId, studentId, schoolId))

    ////////////////// LOCAL STATE /////////////////

    const [assistsRows, setAssistsRows] = useState([]);
    const [assistsColumns, setAssistsColumns] = useState([]);

    /////////////// ACTIONS /////////////////

    /**
     * Inicia el proceso para dar formato a los datos
     */
    useEffect(() => {

        const dataAssits = buildAssitsDataGrid(pageData)

        let assistsColumns = dataAssits.columns.map(i => {
            return {
                field: i.column,
                headerName: i.title,
                flex: 0.4,
                minWidth: 80,
                editable: false,
                headerAlign: 'center',
                align: 'center',
                headerClassName: i.partialActive ? 'partial-active--cell' : '',
                cellClassName: (params) => {
                    let row = params.row[params.field]?.partialActive

                    if (row) {
                        return 'partial-active--cell'
                    }

                    return ''
                },
                valueGetter: (params) => {

                    return params.value.value
                }
            }
        })

        let assistsRows = dataAssits.rows.map((i, index) => ({ id: index, ...i }))

        setAssistsRows(assistsRows);
        setAssistsColumns(assistsColumns);


    }, [groupId, studentId, schoolId])

    /**
     * Funcion para construir los datos del
     * data grid de asistencias
     */
    const buildAssitsDataGrid = ({
        partials,
        subjects,
        subjectCatalog,
        assists }) => {

        let currentDate = moment().format('YYYY-MM-DD hh:mm:ss');;

        let subjectItems = []
        let columnsItems = []
        let columnsUI = [
            {
                column: 'name',
                title: "MATERIAS",
                sticky: true,
                className: ""
            }
        ]

        for (let subjet of subjects.map(i => Object.assign({}, i))) {
            let subjectIItem = {}

            let catalog = _.find(subjectCatalog, { catalog_subject_id: subjet.catalog_subject_id })
            subjet.detalles = catalog

            subjectIItem.name = {
                name: true,
                value: catalog.title
            }

            let partialsUI = {}

            let total = 0
            let totalAbs = 0

            for (let partial of partials) {

                let assiten = _.filter(assists, { subject_id: subjet.subject_id, partial_id: partial.partial_id })

                ///HERE!-------------
                let absence = assiten.filter(e => e.assistance == 0)

                total += assiten.length - absence.length
                totalAbs += absence.length
                partialsUI[`Asistencias p-${partial.partial}`] = {
                    partial: true,
                    value: assiten.length - absence.length,
                    partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
                }
                partialsUI[`Faltas p-${partial.partial}`] = {
                    partial: true,
                    value: absence.length,
                    partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
                }

            }

            partialsUI['total'] = {
                total: true,
                value: total
            }
            ///HERE!----------------
            partialsUI['total faltas'] = {
                total: true,
                value: totalAbs
            }

            subjectItems.push({ ...subjectIItem, ...partialsUI })
        }

        for (let partial of partials) {

            columnsItems.push({
                column: `Asistencias p-${partial.partial}`,
                title: `Asist P${partial.partial}`,
                sticky: false,
                className: "parcial-column",
                partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
            })
            ///HERE!-------------
            columnsItems.push({
                column: `Faltas p-${partial.partial}`,
                title: `Faltas P${partial.partial}`,
                sticky: false,
                className: "parcial-column",
                partialActive: (currentDate >= partial.start_date && currentDate <= partial.limit_date)
            })
        }

        columnsItems.push({
            column: `total`,
            title: `TOTAL ASIST`,
            sticky: false,
            className: "parcial-column"
        },
            {
                column: `total faltas`,
                title: `TOTAL FALTAS`,
                sticky: false,
                className: "parcial-column"
            }

        )

        columnsUI = [...columnsUI, ...columnsItems]

        return {
            columns: columnsUI,
            rows: subjectItems
        }
    }

    return (
        <Card sx={{ mt: 4 }}>
            <CardContent
                sx={{
                    pt: 0,
                    pl: 0,
                    pr: 0,
                    pb: 0,
                    ' & .MuiDataGrid-root': {
                        border: 'none'
                    }
                }}
            >
                <Box
                    sx={{
                        '& .partial-active--cell': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: '#1a3e72',
                            fontWeight: '600',
                        },
                    }}>

                    <DataGrid
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        rows={assistsRows}
                        columns={assistsColumns}
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        autoHeight
                        components={{ Toolbar: AssistsToolBar }}
                        disableDensitySelector
                    />
                </Box>
            </CardContent>
        </Card>
    )
}

export default AssistsCard