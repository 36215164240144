import { Box, Button, Snackbar, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SyncIcon from '@mui/icons-material/Sync';

import moment from 'moment'
import { useEffect, useState } from "react";
import { Error, } from "../../components/Feedback";

import { selectSubjectById } from "../../store/slices/professorModule/entitiesSlice";
import {
    fetchSubjectAditionalData, selectItemFetchingStatusSelected,
    selectItemSelected, invalidate
} from "../../store/slices/professorModule/ItemsSlice";
import { useAuth } from "../../hooks";
import SubjectPreviewCard from "../../components/SubjectPreviewCard";
import ScoresCard from "./components/ScoresCard";
import SkeletonPage from "./components/SkeletonPage";

/**
 * Vista de calificaciones
 * 
 * @returns 
 */
const ProfessorScoreEdit = () => {
    moment.locale('es')
    const { id } = useParams()
    const dispatch = useDispatch()
    const Auth = useAuth()

    const schoolId = Auth.getUser().school_id

    ////////////////////// SHARES STATUS //////////////////////

    const groupItem = useSelector(selectItemSelected)

    const serverStatus = useSelector(selectItemFetchingStatusSelected)

    const subject = useSelector(selectSubjectById(id))

    ////////////////////////// ACTIONS //////////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate(id))
        reload()
    }

    /**
     * Recargar los datos
     */
    const reload = () => {
        dispatch(fetchSubjectAditionalData({
            groupId: subject.group_id,
            subjectId: subject.subject_id,
            curretnDate: moment().format('YYYY-MM-DD')
        }))
    }

    /**
     * Evento lanzado cuanto el proceso
     * de guardar o crear calificaciones ha terminado
     */
    const onSaveScores = (type, data) => {
        if (type == 'done') {
            showFeedback('Calificaciones registradas')
        } else {
            showFeedback(data.feedback.title)
        }
    }

    ///////////////////////// EFECTOS SECUNDARIOS ////////////////////////

    useEffect(() => {
        if (id) {
            reload()
        }
    }, [id])


    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >

            {
                serverStatus == "pending" && (<SkeletonPage />)
            }

            {
                serverStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                serverStatus == 'fulfilled' && (
                    <>
                        <Box
                            sx={{
                                marginBottom: 2,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Typography variant="h4" component="div">Calificaciones</Typography>

                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>

                        <SubjectPreviewCard subjectId={subject.subject_id} />

                        <ScoresCard
                            schoolId={schoolId}
                            groupId={subject?.group_id}
                            subjectId={subject?.subject_id}
                            onSave={onSaveScores}
                        />

                    </>
                )

            }

            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    )
}

export default ProfessorScoreEdit