
import React from 'react'
import { useSelector } from "react-redux";
import { getLastCalificationProfessorsCard } from '../../../../store/slices/background'
import moment from 'moment'
import 'moment/locale/es';
import StatsBiColor from '../../../../components/Chart/StatsBiColor';

const LastCalificationProfessorsCard = (props) => {
    moment.locale('es')

    const profesor = useSelector(getLastCalificationProfessorsCard)

    let lastCalification = "Sin registro"

    if (profesor.last_score_created) {
        lastCalification = moment(profesor.last_score_created).format("DD MMMM YYYY")
    }

    return (
        <StatsBiColor
            valueOptions={{
                variant: "subtitle1",
                component: "span"
            }}
            valueBoxOptions={{
                flex: 2
            }}
            value={lastCalification}
            title={`${profesor.name} ${profesor.last_name}`}
            label={"Profesor en rezago de calificaciones"}
            sx={{ height: 80, width: 300 }}
            mainColor={"#fc4444"}
            secundaryColor={"#fc7474"}
        >
        </StatsBiColor>
    )
}

export default LastCalificationProfessorsCard


