import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { selectGroupsById } from "../groups";

const emptyState = {
    byId: {}
}

/**
 * Slice para los parciales
 */
export const SubjectsSlice = createSlice({
    name: 'subjects',
    initialState: emptyState,
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneSubject: (state, action) => {
            if (!state.byId[action.payload.subject_id]) {
                state.byId[action.payload.subject_id] = action.payload
            }
        },
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManySubjects: (state, action) => {
            state.byId = { ...state.byId, ...action.payload }
        },
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setSubjects: (state, action) => {
            state.byId = action.payload;
        },
        updateSubject: (state, action) => {
            const { partial_id, partial, start_date, end_date, limit_date, level } = action.payload
            // find this todo in the list
            const parcial = state.list.find(t => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial;
            parcial.start_date = start_date;
            parcial.end_date = end_date;
            parcial.limit_date = limit_date;
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        removeOneSubject: (state, action) => {
            delete state.byId[action.payload.subject_id]
        },
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneSubject: (state, action) => {
            let { subject_id } = action.payload

            state.byId[subject_id] = { ...state.byId[subject_id], ...action.payload }
        },
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManySubjects: (state, action) => {
            for (const user of action.payload) {
                let { subject_id } = user

                state.byId[subject_id] = { ...state.byId[subject_id], ...user }
            }
        },
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setSubjects, addManySubjects, removeOneSubject,
    updateSubject,
    addOneSubject, upsertOneSubject, upsertManySubjects
} = SubjectsSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar todos los parciales de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectSubjects = (state) => state.entities.subjects.byId;

/**
 * Selector para recuperar todas las materias
 * 
 * @param {*} state 
 * @returns 
 */
export const selectAllSubjects = (state) => Object.values(state.entities.subjects.byId)

/**
 * Selector para recuperar una materia por id
 * 
 * @param {*} subjectId 
 * @returns 
 */
export const selectSubjectByid = (subjectId) => (state) => selectSubjects(state)[subjectId]

/**
 * Selector para recuperar materias filtradas
 * 
 * @param {*} state 
 * @returns 
 */
export const selectSubjectsBy = (filter) => (state) => {
    const allSubjects = selectAllSubjects(state)

    return _.filter(allSubjects, filter)
}


/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectSubjectDataById = (subjectId) => {
    return (state) => {

        let subject = state.entities.subjects.byId[subjectId];

        if (!subject) {
            return null
        }

        const group = selectGroupsById(subject.group_id)(state)
        const catalog = state.entities.subjectcatalogs.byId[subject.catalog_subject_id]

        return {
            ...subject,
            group,
            catalog
        }
    }
}


/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 * 
 * @deprecated
 */
export const selectSubjectByGroupId = (groupId) => {
    return (state) => {

        if (!groupId) return []

        return _.filter(Object.values(state.entities.subjects.byId), { group_id: parseInt(groupId) })
    }
}

/**
 * Selector para recuperar las materias de un grupo
 */
export const selectSubjectsByGroupId = createSelector(
    selectAllSubjects,
    (state, groupId) => groupId ,
    (allSubjects, groupId) => { return _.filter(allSubjects, ["group_id", groupId]) }
)


export default SubjectsSlice.reducer;
