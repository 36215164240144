import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Connection from "../../../service/Connection"
import * as constants from "../../../service/const";
import Feedback from "../../../service/Feedback";
import Authentication from "../../../service/Login";
import { selectEntitiesFiles, upsertManyFiles } from "../entities/files";
import { selectGroupsById, upsertManyGroup } from "../entities/groups";
import { upsertManyReports } from "../entities/reports";
import { addOneReportCatalog, upsertManyReportsCatalogs, upsertOneReportCatalog } from "../entities/reportscatalogs";
import { selectStudentById, upsertManyStudents } from "../entities/students";
import { selectUserById, upsertManyUsers } from "../entities/users";
import * as _ from 'lodash';

const emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
            title: null,
            message: null,
            payload: null,
        },
    },
    ui: {}
}

export const ReportSlice = createSlice({
    name: 'reportsUI',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.server.didInvalidate = true
        },
        setReport: (state, action) => {
            state.list = action.payload;
        },
        addReport: (state, action) => {
            state.list.push(action.payload)
        },
        updateReport: (state, action) => {
            const { partial_id, partial, start_date, end_date, limit_date, level } = action.payload
            // find this todo in the list
            const parcial = state.list.find(t => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial;
            parcial.start_date = start_date;
            parcial.end_date = end_date;
            parcial.limit_date = limit_date;
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        deleteReport: (state, action) => {

            state.list = state.list.filter((item) => item.partial_id !== action.payload);
        },
        setReports: (state, action) => {
            state.reports = action.payload;
        },

        addReports: (state, action) => {
            state.report.push(action.payload)
        },
        updateReports: (state, action) => {
            const { catalog_report_id, school_id, title, status } = action.payload
            // find this todo in the list
            const catalogReport = state.reports.find(t => t.catalog_report_id === catalog_report_id);
            // update that todo
            catalogReport.title = title;
            // note: .find() might return undefined, so you may want to handle that
        },
        changeStatusReport: (state, action) => {
            const { catalog_report_id, school_id, title, status } = action.payload
            // find this todo in the list
            const catalogReport = state.reports.find(t => t.catalog_report_id === catalog_report_id);
            // update that todo
            catalogReport.status = status;
            // note: .find() might return undefined, so you may want to handle that
        },

    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending'
        }

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + constants.REPORTESUI_EXPIRE_TIME))
            state.server.ferchingAt = Date.now()
            state.server.didInvalidate = false

            state.server.statusServer = 'fulfilled'
        })
        builder.addCase(loadUI.pending, pendingServerStatus)
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected'
            state.server.feedback = action.payload.feedback
        })

        //////////////// ALMACENAR CATALOG //////////////////////

        builder.addCase(addCatalogReport.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(addCatalogReport.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(addCatalogReport.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////// ACTUALIZAR CATALOG //////////////////////

        builder.addCase(updateCatalogReport.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateCatalogReport.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateCatalogReport.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

        //////////////// ACTUALIZAR STATUS CATALOG //////////////////////

        builder.addCase(updateStatusCatalogReport.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(updateStatusCatalogReport.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(updateStatusCatalogReport.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        })

    }
});

export const {
    setReport, addReport, deleteReport, updateReport, setReports, changeStatusReport,
    addReports, updateReports, invalidate
} = ReportSlice.actions;


/**
 * Selector para la tbla de reportes 
 * 
 * @param {*} state 
 */
export const selectReports = (state) => {

    //return state.reportsUI.reports
    let reportsCollect = Object.values(state.entities.reports.byId).map(r => {
        let reportcatalog = state.entities.reportscatalogs.byId[r.catalog_report_id]

        let file = selectEntitiesFiles(state)[r.file_id]

        let student = selectStudentById(file.student_id)(state)
        let assessor = selectUserById(file.assessor_id)(state)
        let group = selectGroupsById(student.group_id)(state)
        let reporter = selectUserById(r.profesor_id)(state)

        return {
            ...r,
            file,
            student,
            group,
            reportcatalog,
            assessor,
            reporter
        }
    })

    return reportsCollect
};


//////////////////// SELECTORES //////////////////
export const selectGroupsUI = (state) => state.reportsUI.ui;

export const selectGroupsData = (state) => state.reportsUI.data;

export const selectGroupsServer = (state) => state.reportsUI.server;

export const selectStatusServer = (state) => state.reportsUI.server.statusServer

export const selectStatusOperation = (state) => state.reportsUI.server.statusOperation


export default ReportSlice.reducer;


/**
 * Cargar informacion de la UI
 */
export const loadUI = createAsyncThunk(
    'reportsUI/fetch/data',
    async (schoolId, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let reports = await Connection.getResourcesPaginatedFromServer('getReportBySchool', [schoolId]).then(i => i.data)
            const groups = await Connection.getResourcesPaginatedFromServer('groupsBySchool', [schoolId]).then(i => i.data)
            const assesors = await Connection.getAssesorsBySchool(schoolId).then(i => i.data.data)
            const catalogs = await Connection.getReportCatalogBySchool(schoolId).then(i => i.data.data)
            const professorsList = await Connection.professorsBySchool(schoolId).then(i => i.data.data)

            let students = []
            let files = []
            let reporters = []

            for (let report of reports) {
                let file = files.find(i => i.file_id == report.file_id)

                if (!file) {
                    file = await Connection.getFilesByReport(report.report_id).then(i => i.data.data)

                    files.push(file)
                }

                let student = students.find(i => i.student_id == file.student_id)

                if (!student) {
                    let student = await Connection.getStudentByFile(file.file_id).then(i => i.data.data)

                    students.push(student)
                }

                let reporter = reporters.find(i => i.professor_id == report.profesor_id)

                if (!reporter) {
                    let reporter = professorsList.find(i => i.professor_id == report.profesor_id)

                    reporters.push(reporter)
                }

            }

            let fullUsers = reporters.concat(assesors)

            let users = _.uniqBy(fullUsers.map(i => {
                let userId = Auth.getUserID(i)

                return {
                    ...i,
                    user_id: userId
                }
            }), 'user_id')

            thunkAPI.dispatch(upsertManyUsers(users));
            thunkAPI.dispatch(upsertManyGroup(groups));
            thunkAPI.dispatch(upsertManyReportsCatalogs(catalogs));
            thunkAPI.dispatch(upsertManyStudents(students));
            thunkAPI.dispatch(upsertManyFiles(files));
            thunkAPI.dispatch(upsertManyReports(reports));

            return {
                reports,
                groups,
                reporters,
                assesors,
                catalogs
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }, {
    condition: (arg, { getState, extra }) => {
        let { didInvalidate, expireIn } = getState().reportsUI.server

        const valid = expireIn > Date.now()

        if (!didInvalidate && valid) {
            return false
        }

    }
}
)

export const fetchCatalogReport = (schoolId) => async (dispatch) => {

    try {

        let catalogReport = await Connection.getReportCatalogBySchool(schoolId);
        dispatch(setReport(catalogReport.data.data));

    } catch (err) {
        console.log(err);
    }


}


/**
 * Cgregar un nuevo catalogo
 */
export const addCatalogReport = createAsyncThunk(
    'reportsUI/create/catalog',
    async ({ schoolId, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let SubjectCatalog = await Connection.setReportCatalogBySchool(schoolId, data).then(i => i.data.data)
            thunkAPI.dispatch(addOneReportCatalog(SubjectCatalog));

            return {
                SubjectCatalog
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Agregar un nuevo catalogo
 */
export const updateCatalogReport = createAsyncThunk(
    'reportsUI/update/catalog',
    async ({ idCatalog, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let reportCatalog = await Connection.updateReportCatalog(idCatalog, data).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneReportCatalog(reportCatalog));

            return {
                reportCatalog
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Cgregar un nuevo catalogo
 */
export const updateStatusCatalogReport = createAsyncThunk(
    'reportsUI/update/catalog-status',
    async ({ idCatalog, data }, thunkAPI) => {
        let FeedbackService = new Feedback()

        let Auth = new Authentication()

        try {
            let reportCatalog = await Connection.updateReportCatalog(idCatalog, data).then(i => i.data.data)
            thunkAPI.dispatch(upsertOneReportCatalog(reportCatalog));

            return {
                reportCatalog
            }
        } catch (err) {
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)