import {
    Box, Button,
    Stack, Grid, Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../hooks';
import { invalidate, loadUI, selectStatusServer } from '../../store/slices/settingsUI';
import { Error } from '../../components/Feedback';
import SchoolGeneralDataCard from '../../components/SchoolGeneralDataCard';
import { SchoolImage } from './components/SchoolImage';
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from './components/SkeletonPage';
import MakeSnaphotModal from './components/MakeSnaphotModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesPacking } from '@fortawesome/free-solid-svg-icons'
import useFeedback from '../../hooks/useFeedback';
import PartialsCard from './components/PartialsCard';
import NotificationsSettingsCard from './components/NotificationsSettingsCard';
import ThemeSettingsCard from './components/ThemeSettingsCard';

/**
 * Pagina de configuraciones para una escuela
 * 
 * @returns 
 */
const Configuraciones = () => {

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();

    //////////////// SHARED STATE ///////////////////

    const statusServer = useSelector(selectStatusServer)

    ///////////////// LOCAL STATE ///////////////////

    const [showMakeSnaphotModal, setShowMakeSnaphotModal] = useState(false);

    ///////////// ACTIONS /////////////////

    /**
    * Funcion para recargar los datos de la UI
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Invalida y recargar la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
     * Mostrar modal para crear un corte de ciclo
     */
    const onShowMakeSnaphotModal = () => {
        setShowMakeSnaphotModal(true)
    }

    /**
     * Ejecutado cada ves que ingresamos a la pagina
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, [])

    return (
        <Box sx={{
            flexGrow: 1,
            paddingTop: {
                xs: 1,
                sm: 2,
                md: 2,
            },
            paddingLeft: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingRight: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingBottom: {
                xs: 1,
                sm: 2,
                md: 5,
            },
        }}>

            <MakeSnaphotModal
                open={showMakeSnaphotModal}
                setOpen={setShowMakeSnaphotModal}
            />

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Stack
                            direction={"row"}
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                            spacing={2}
                            sx={{ mb: 2 }}
                        >
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={onShowMakeSnaphotModal}
                                startIcon={<FontAwesomeIcon icon={faBoxesPacking} />}
                            >
                                Iniciar corte
                            </Button>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Stack>

                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} sm={6} lg={3}>
                                <SchoolImage />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <ThemeSettingsCard />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <NotificationsSettingsCard />
                            </Grid>
                        </Grid>

                        <SchoolGeneralDataCard />

                        <PartialsCard />
                    </>
                )
            }
        </Box>
    )
}

export default Configuraciones
