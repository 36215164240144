import React from 'react'
import { Box, Grid, Card, IconButton, Menu, MenuItem, CardHeader } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    getGroups, getBestScoreGroupCard, getBestAssistsGroupCard, getGroupsReport,
    getWorstAbsenceGroupCard, getBestReportsGroupCard, selectAllGroups
} from '../../../store/slices/background'
import { useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import * as constants from "../../../service/const";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import ExportableModal from "../../../components/Chart/ExportableModal"

import { LevelChartItem } from '../components/ChartsGroups';
import { useHistory, useRouteMatch } from "react-router-dom";
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import _ from 'lodash';
import BestAssistsGroupIndicator from './components/BestAssistsGroupIndicator';
import BestScoreGroupIndicator from './components/BestScoreGroupIndicator';
import WorstAbsenceGroupIndicator from './components/WorstAbsenceGroupIndicator';
import BestReportsGroupIndicator from './components/BestReportsGroupIndicator';
import { useAuth } from '../../../hooks';

/**
 * Pagina de grupos
 * 
 * @returns 
 */
const GroupView = () => {

    const history = useHistory()
    const match = useRouteMatch()
    const Auth = useAuth()

    /////////////// SHARED STATE ///////////////////

    const groups = useSelector(getGroups)
    const allGroups = useSelector(selectAllGroups)
    const groupsReport = useSelector(getGroupsReport)

    const bestScoreGroup = useSelector(getBestScoreGroupCard)
    const bestAssistsGroupCard = useSelector(getBestAssistsGroupCard)
    const worstAbsenceGroupCard = useSelector(getWorstAbsenceGroupCard)
    const bestReportsGroupCard = useSelector(getBestReportsGroupCard)

    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { school_id: null }
    });

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = () => {
        setReportData({
            report: "groups",
            title: "Reporte de grupos",
            query: { school_id: Auth.getUser().school_id }
        })

        setOpenExportModal(true);
    }


    ///////////////////////////// COMPONENTE ////////////////////

    const columns = [
        //{ field: 'id', headerName: 'Materia', flex: 1, sortable: false},
        { field: 'grade', headerName: 'Grado', flex: 0.8, sortable: true, },
        { field: 'group', headerName: 'Grupo', flex: 0.8, sortable: true, },
        { field: 'turn', headerName: 'Turno', flex: 1, sortable: true, },
        { field: 'level', headerName: 'Nivel', flex: 1.5, sortable: true },
        { field: 'assessor_id', headerName: 'Asesor', flex: 1.5, sortable: true },
        { field: 'special', headerName: 'Especial', flex: 1, sortable: true },
        { field: 'students', headerName: 'Alumnos', flex: 1, sortable: true, type: 'number' },
        { field: 'average_rating', headerName: 'Promedio', flex: 1, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 1, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 1, sortable: true, type: 'number' },
        { field: 'total_reports', headerName: 'Reportes', flex: 1, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            ),
        }
    ];


    let filas = []

    for (const group of groupsReport) {
        filas.push({
            id: group.id,
            grade: group.grade,
            group: group.group,
            turn: getTurnLevel(group.turn),
            level: getLevelName(group.level),
            average_rating: group.average_rating,
            total_absence: group.total_absence,
            total_presence: group.total_presence,
            total_reports: group.total_reports,
            assessor_id: group.assessor_id,
            students: group.students,
            special: group.special,
            bestScore: group.group_id == bestScoreGroup.group_id,
            bestAssists: group.group_id == bestAssistsGroupCard.group_id,
            worstAbsence: group.group_id == worstAbsenceGroupCard.group_id,
            bestReports: group.group_id == bestReportsGroupCard.group_id
        })
    }

    /**
     * Funcion para ir al detalle de grupo
     * 
     * @param {*} params 
     * @returns 
     */
    const goDetails = (params) => () => {
        history.push({ pathname: `${match.path}/${params.id}` });
    }

    /**
     * Componente encargado de mostrar
     * los graficas de cada nivel escolas
     * 
     * @returns 
     */
    const ChartsSection = () => {

        const levelsWithGroups = _.filter(constants.levelsNames, (item) => {
            return _.filter(allGroups, { level: item.key }).length > 0
        })

        return (
            <Box>
                {levelsWithGroups.map(level =>
                    (<LevelChartItem key={level.key} level={level}> </LevelChartItem>)
                )}
            </Box>
        )
    }

    return (
        <Box>
            <Grid container spacing={2} sx={{ marginBottom: 5 }} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <BestScoreGroupIndicator></BestScoreGroupIndicator>
                </Grid>
                <Grid item>
                    <BestAssistsGroupIndicator></BestAssistsGroupIndicator>
                </Grid>
                <Grid item>
                    <WorstAbsenceGroupIndicator></WorstAbsenceGroupIndicator>
                </Grid>
                <Grid item>
                    <BestReportsGroupIndicator></BestReportsGroupIndicator>
                </Grid>
            </Grid>

            {ChartsSection()}

            <Card>
                <CardHeader
                    title="Grupos"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 20
                        }
                    }}
                    subheader={"Total: " + groups.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />


                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={filas} columns={columns}
                    getCellClassName={(params) => {
                        if (params.field === 'average_rating' && params.row.bestScore) {
                            return "datagrid-app-purple"
                        }

                        if (params.field === 'total_presence' && params.row.bestAssists) {
                            return "datagrid-app-blue"
                        }

                        if (params.field === 'total_absence' && params.row.worstAbsence) {
                            return "datagrid-app-red"
                        }

                        if (params.field === 'total_reports' && params.row.bestReports) {
                            return "datagrid-app-green"
                        }

                        return '';
                    }}
                />
            </Card>
            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}


export default GroupView