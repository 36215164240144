import React from 'react'
import { Card, CardContent, Box, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@mui/material'

const StatsBiColor = (props) => {
    const {sx, mainColor, secundaryColor} = props

    const valueOptions = props.valueOptions || {variant: "h4", component: "span"} 

    const defaultValueBoxOptions = {background: mainColor, color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center' }
    const customeValueBoxOptions = props.valueBoxOptions || {flex: 1}

    const valueBoxOptions = {...defaultValueBoxOptions, ...customeValueBoxOptions}
    
    return (
      <Card sx={{ display: 'flex', height: 80, width: 300, ...sx}}>
        <Box sx={valueBoxOptions}>
            <Typography {...valueOptions}>
                 {props.value}
            </Typography>
        </Box>
        
        <Box
          sx={{
              background: secundaryColor, color: "white", display: 'flex', flexDirection: 'column',  alignItems: 'center', flex: 3,
              padding: "5px 5px 5px 0",
              textAlign: "center"
          }}
        >
            <Typography variant="body1" gutterBottom component="span">
              {props.label}
            </Typography>
            <Typography variant="caption" gutterBottom component="span">
              {props.title}
            </Typography>
        </Box>

      </Card>
    )
  }

export default StatsBiColor