import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    studentSelected: null
}

/**
 * Slice para el settings UI
 */
export const studentsUISlice = createSlice({
    name: 'studentsUI/ui',
    initialState: emptyState,
    reducers:{
        selectStudentItem: (state, {payload}) => {
            state.studentSelected = payload
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { invalidate, selectStudentItem } = studentsUISlice.actions;

export default studentsUISlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStudentsUI = (state)=> state.studentsUI.ui;


//////////////// TRUNCKS /////////////////

