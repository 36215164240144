import { Autocomplete, Avatar, Box, createFilterOptions, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { throttle } from "lodash";
import React, { useState } from "react";
import Connection from "../../service/Connection"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

/**
 * Componente para buscar usuario
 */
const UsersSearch = ({ onChange }) => {

    /**
     * Opciones para el componente Autocomplete
     */
    const filterOptions = createFilterOptions({
        limit: 10
    });

    //////////////// LOCAL STATE ///////////

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false)

    const [options, setOptions] = useState([]);

    ///////////////////////// ACTIONS /////////////////////// 

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                //autocompleteService.current.getPlacePredictions(request, callback);

                Connection.findUsers(request).then(i => {
                    callback(i.data.data)
                })

            }, 1000),
        [],
    );

    /**
     * Recupera todos los usuario
     */
    React.useEffect(() => {
        let active = true;
        console.log(inputValue)
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const request = {
            search: inputValue,
            filters: { status: 1 },
            per_page: 20,
            fields: {
                only: ["cellphone", "email", "has_smartphone", "last_name", "name", "user_id", "second_last_name", "Apm", "url_photo_profile"]
            }
        }
        setLoading(true)

        fetch(request, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
                setLoading(false)
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    /**
     * Funcion lanzada si un usuario a sido seleccionado
     * 
     * @param {*} event 
     * @param {*} newValue 
     */
    const onChangeItem = (event, newValue) => {
        setValue(newValue)

        if (newValue) {
            Connection.getUserById(newValue.user_id).then(i => {
                onChange(i.data.data)
            }).catch(err => {
                console.log(err)
                // TODO METER UN MENSAJE DE ERRO
                setInputValue('')
                onChange(null)
            })
        } else {
            onChange(null)
        }
    }

    return (
        <Box>
            <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                Buscar usuario
            </Typography>

            <Autocomplete
                id="controllable-states-demo"
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                loading={loading}
                loadingText='Buscando...'
                noOptionsText='No se encontraron resultados'
                onChange={onChangeItem}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => `${option.name} ${option.last_name} ${option.second_last_name}`}
                renderInput={(params) => (
                    <TextField {...params} label="ej Pedro" fullWidth />
                )}
                renderOption={(props, option) => {
                    return (
                        <>
                            <li {...props}>
                                <Stack direction={"row"} spacing={2} justifyContent="flex-start" alignItems="center">
                                    <Avatar alt={option.name} src={option.url_photo_profile} sx={{ width: 34, height: 34, mr: 1 }} />
                                    <Stack direction={"column"} spacing={1}>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>{option.name} {option.last_name} {option.second_last_name}</strong>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <FontAwesomeIcon icon={faPhone} /> {option.cellphone}
                                        </Typography>
                                    </Stack>
                                </Stack>

                            </li>
                            <Divider variant="inset" component="li" />
                        </>

                    );
                }}
            />
        </Box>
    )
}

export default UsersSearch