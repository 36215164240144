import React, { Fragment, useEffect, useState } from 'react'
import { Box, Grid, Card, ListItemText, Typography, List, ListItem, ListItemIcon, ListItemAvatar, Divider, ListSubheader, Button } from "@mui/material"
import ExportableModal from "../../../components/Chart/ExportableModal"
import Empty from '../components/Empty';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCycleSelected, getDataStatus, getStudentByID } from '../../../store/slices/background';
import CalificationsTable from './components/StudentsTable';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Masonry from '@mui/lab/Masonry';
import { ColorBox } from '../../../components/Chart/Cards';
import Connection from "../../../service/Connection"
import AccountBoxIcon from '@mui/icons-material/AccountBox';

/**
 * Pagina para mostrar detalles de un alumno
 * 
 * @returns 
 */
const StudentsDetails = () => {

    const history = useHistory()
    let { id } = useParams()
    if (isNaN(id)) {
        goStudents()
    }

    /////////////////// SHARES STATE //////////////////

    const schoolCycle = useSelector(getCycleSelected)
    const student = useSelector(getStudentByID(id))
    const dataStatus = useSelector(getDataStatus)

    let parameters = (schoolCycle.cycle_id != -1) ? { snaphot: schoolCycle.snaphot } : {}

    //////////////// LOCAL STATE //////////////////

    const [data, setData] = useState({
        parents: []
    })
    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { student_id: null }
    });


    ////////////////// MENU DE OPCIONES ////////////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    ////////////////// ACTIONS ///////////////////////


    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = (data) => {
        setReportData(data)
        setOpenExportModal(true);
    }

    const goStudents = () => {
        history.push({ pathname: `/director/alumnos` });
    }

    useEffect(() => {
        async function getAditionalData() {
            const parents = await Connection.getParentsByStudent(id, parameters).then(res => res.data.data)

            return {
                parents
            }
        }

        if (id) {
            getAditionalData()
                .then(data => {
                    setData(data)
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [id])


    const StudentDetail = ({ student }) => {
        const name = `${student.name} ${student.last_name} ${student.second_last_name}`

        return (
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Card>
                            <List dense={true}>
                                <ListSubheader component="div">
                                    Detalle de contacto
                                </ListSubheader>

                                <ListItem dense={true}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <PhoneAndroidIcon />
                                        </ListItemIcon>
                                    </ListItemAvatar>
                                    <ListItemText primary={student.cellphone} secondary="Celular" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <FormatListNumberedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={student.folio} secondary="Folio" />
                                </ListItem>

                                {data.parents.length > 0 &&
                                    <Fragment>
                                        <Divider />

                                        <ListSubheader component="div">
                                            Tutor
                                        </ListSubheader>
                                    </Fragment>
                                }

                                {data.parents.map(item => {
                                    return (
                                        <ListItem key={item.parent_id}>
                                            <ListItemIcon>
                                                <AccountBoxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={item.cellphone} />
                                        </ListItem>
                                    )
                                })}

                                <Divider />

                                <ListSubheader component="div">
                                    Detalle de ubicacion
                                </ListSubheader>

                                <ListItem>
                                    <ListItemIcon>
                                        <MapsHomeWorkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={student.colony} secondary="Colonia" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={student.street} secondary="Calle" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={student.zipcode} secondary="Codigo postal" />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText primary={student.outside_number} secondary="Numero exterior" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(student.inside_number) ? student.inside_number : "Sin número"}
                                        secondary="Numero interior"
                                    />
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <ColorBox
                            value={name}
                            label="Alumno"
                            mainColor={"#7434a3"}
                            sx={{ width: "100%" }}
                        />

                        <Masonry columns={3} spacing={2} sx={{ marginTop: 2 }}>

                            <ColorBox value={student.average_rating} label="Promedio" mainColor={"#2596be"} />

                            <ColorBox value={student.total_absence} label="Faltas" mainColor={"#5d4037"} valueVariant={"h6"} />

                            <ColorBox value={student.total_presence} label="Asistencias" mainColor={"#d70206"} />

                        </Masonry>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <Typography variant={"h4"} component={"div"}>Detalle del alumno</Typography>
            </Box>

            {
                (student) && (<StudentDetail student={student} />)
            }

            {
                (student) && (<CalificationsTable studentId={id} export={onExport}></CalificationsTable>)
            }

            {
                (student == null && dataStatus == 'pending') && (<Empty label="Buscando">  </Empty>)
            }

            {
                (student == null && dataStatus != 'pending') &&
                (
                    <Empty label="Alumno no controntrado">
                        <Button variant="contained" onClick={goStudents}>Ir a alumnos</Button>
                    </Empty>
                )
            }


            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />
        </Box>
    )
}



export default StudentsDetails