import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks";
import useFeedback from "../../../hooks/useFeedback";
import { selectSettingBySchoolId } from "../../../store/slices/entities/settings";
import { selectStudentById } from "../../../store/slices/entities/students";
import { activateStudent, selectActivateStudentStatus } from "../../../store/slices/studentsUI/operationsSlice";
import SaveIcon from '@mui/icons-material/Save';

/**
 * Modal para activar alumnos que estan dados de baja
 * 
 * @returns 
 */
const ActivateStudentModal = ({ open, setOpen, studentId = null }) => {

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const student = useSelector(selectStudentById(studentId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const loading = useSelector(selectActivateStudentStatus)

    /////////////////// ACTIOS ///////////////////


    /**
    * Guardar datos del usuario
    */
    const persistData = () => {
        dispatch(activateStudent({
            studentId
        })).unwrap()
            .then((data) => {
                setOpen(false)

                feedbackApp.showFeedback({
                    title: "Alumno activado"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
            disableEscapeKeyDown={loading == 'pending'}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Activar alumno <br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Alumno: {student?.name} {student?.last_name}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
                    <Typography variant="h5" component="div">
                        El alumno estará nuevamente disponible en el sistema
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={persistData}
                    loading={loading == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Activar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpen(false) }}
                    disabled={loading == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ActivateStudentModal
