import React, { useEffect, useState } from 'react';
import {
    TextField, Typography, Grid
} from '@mui/material';
import { Box } from '@mui/system';

/**
 * Componente que se encarga solo del formulario de los usuarios
 * 
 */
const UserForm = ({
    tipoUsuario,
    value = {
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: '',
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        group_id: '',
    },
    onChange,
    hiddenUserCredentials = false
}) => {

    //////////////// LOCAL STATE //////////////////

    /////////////////// ACTIONS //////////////////////

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const newValue = event.target.value;
        const name = event.target.name;

        onChange({
            value: newValue,
            name,
            valid: !formInvalid({ ...value, [name]: newValue })
        })
    }

    ////////////////////////// VALIDACIONES //////////////////////////////////

    const nameValid = ({ name }) => {
        return {
            invalid: name == '' || name.length > 50,
            message: "Campo requerido, Maximo 50 caracteres"
        }
    }

    const lastNameValid = ({ last_name }) => {
        return {
            invalid: last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const secondLastNameValid = (second_last_name) => {
        return {
            invalid: false, //value.second_last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const enrollmentValid = ({ enrollment }) => {
        return {
            invalid: tipoUsuario != 'PARENT' && enrollment == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion para el correo
     * 
     * @returns 
     */
    const emailValid = ({ email }) => {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const isEmail = mailformat.test(email);

        return {
            invalid: !isEmail && email != '' && email != null,
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion para el numero de telefono
     * 
     * @returns 
     */
    const cellphoneValid = ({ cellphone }) => {
        const isPhone = /^[0-9]{10}$/.test(cellphone);

        return {
            invalid: !isPhone,
            message: "Campo requerido o incorrecto"
        }
    }

    const streetValid = ({ street }) => {
        return {
            invalid: street == '',
            message: "Campo requerido o incorrecto"
        }
    }
    const colonyValid = ({ colony }) => {
        return {
            invalid: colony == '',
            message: "Campo requerido o incorrecto"
        }
    }
    const outsideNumberValid = ({ outside_number }) => {
        return {
            invalid: outside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }
    const insideNumberValid = ({ inside_number }) => {
        return {
            invalid: false, //value.inside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }
    const zipcodeValid = ({ zipcode }) => {
        return {
            invalid: zipcode == '',
            message: "Campo requerido o incorrecto"
        }
    }
    const countryCodeValid = ({ country_code }) => {
        return {
            invalid: country_code == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const passwordValid = ({ password }) => {
        return {
            invalid: (password == '' || password.length < 6),
            message: "Campo requerido, La contraseña debe tener una longitud de 6 carácteres"
        }
    }

    const passwordConfirmationValid = ({ password, password_confirmation }) => {

        let isInvalid =
            (
                password_confirmation == '' ||
                (password != password_confirmation) ||
                password_confirmation.length < 6
            )

        return {
            invalid: isInvalid,
            message: "Campo requerido, La contraseña debe tener una longitud de 6 carácteres"
        }
    }

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = (data) => {

        if (!data) {
            return false
        }
        let inValid = (nameValid(data).invalid ||
            lastNameValid(data).invalid ||
            secondLastNameValid(data).invalid ||
            enrollmentValid(data).invalid ||
            emailValid(data).invalid ||
            cellphoneValid(data).invalid ||
            streetValid(data).invalid ||
            colonyValid(data).invalid ||
            outsideNumberValid(data).invalid ||
            insideNumberValid(data).invalid ||
            zipcodeValid(data).invalid ||
            countryCodeValid(data).invalid
        )

        if (hiddenUserCredentials) {
            return inValid
        }

        const invalidUserCredentials = passwordValid(data).invalid || passwordConfirmationValid(data).invalid

        return invalidUserCredentials
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {
                    !hiddenUserCredentials && (
                        <>
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    size="small"
                                    variant="standard"
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    value={value.password}
                                    onChange={onInputChange}
                                    error={passwordValid(value).invalid}
                                    helperText={passwordValid(value).invalid ? passwordValid(value).message : false}
                                />
                            </Grid>
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    label="Repetir contraseña"
                                    type="password"
                                    size="small"
                                    variant="standard"
                                    inputProps={{ maxLength: 50 }}
                                    fullWidth
                                    value={value.password_confirmation}
                                    onChange={onInputChange}
                                    error={passwordConfirmationValid(value).invalid}
                                    helperText={passwordConfirmationValid(value).invalid ? passwordConfirmationValid(value).message : false}
                                />
                            </Grid>
                            <Grid container item xl={4} md={4} sm={12} xs={12}>
                                <TextField
                                    id="cellphone"
                                    name="cellphone"
                                    label="Teléfono"
                                    type="tel"
                                    size="small"
                                    autoComplete="current-password"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    value={value.cellphone}
                                    onChange={onInputChange}
                                    error={cellphoneValid(value).invalid}
                                    helperText={cellphoneValid(value).invalid ? cellphoneValid(value).message : false}
                                />
                            </Grid>
                        </>
                    )
                }

                <Grid container item xl={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" component="div" style={{ width: '100%' }} >
                        Datos generales
                    </Typography>
                </Grid>

                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="name"
                        name="name"
                        label="Nombre"
                        type="text"
                        size="small"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        value={value.name}
                        onChange={onInputChange}
                        error={nameValid(value).invalid}
                        helperText={nameValid(value).invalid ? nameValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="last_name"
                        name="last_name"
                        label="Apellido Paterno"
                        type="text"
                        size="small"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        value={value.last_name}
                        onChange={onInputChange}
                        error={lastNameValid(value).invalid}
                        helperText={lastNameValid(value).invalid ? lastNameValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="second_last_name"
                        name="second_last_name"
                        label="Apellido Materno"
                        type="text"
                        size="small"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        value={value.second_last_name}
                        onChange={onInputChange}
                        error={secondLastNameValid(value).invalid}
                        helperText={secondLastNameValid(value).invalid ? secondLastNameValid(value).message : false}
                    />
                </Grid>
                {
                    tipoUsuario != 'PARENT' && (<Grid container item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="enrollment"
                            name="enrollment"
                            label="Matrícula"
                            type="text"
                            size="small"
                            variant="standard"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            value={value.enrollment}
                            onChange={onInputChange}
                            error={enrollmentValid(value).invalid}
                            helperText={enrollmentValid(value).invalid ? enrollmentValid(value).message : false}
                        />
                    </Grid>)
                }
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="email"
                        name="email"
                        label="Correo"
                        type="tel"
                        size="small"
                        variant="standard"
                        fullWidth
                        value={value.email}
                        onChange={onInputChange}
                        error={emailValid(value).invalid}
                        helperText={emailValid(value).invalid ? emailValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" component="div" style={{ width: '100%' }} >
                        Domicilio
                    </Typography>
                </Grid>
                <Grid container item xl={6} md={6} sm={12} xs={12}>
                    <TextField
                        id="street"
                        name="street"
                        label="Calle"
                        type="text"
                        size="small"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        value={value.street}
                        onChange={onInputChange}
                        error={streetValid(value).invalid}
                        helperText={streetValid(value).invalid ? streetValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={6} md={6} sm={12} xs={12}>
                    <TextField
                        id="colony"
                        name="colony"
                        label="Colonia"
                        type="text"
                        size="small"
                        variant="standard"
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        value={value.colony}
                        onChange={onInputChange}
                        error={colonyValid(value).invalid}
                        helperText={colonyValid(value).invalid ? colonyValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="outside_number"
                        name="outside_number"
                        label="Número Exterior"
                        type="text"
                        size="small"
                        autoComplete="current-password"
                        variant="standard"
                        inputProps={{ maxLength: 25 }}
                        fullWidth
                        value={value.outside_number}
                        onChange={onInputChange}
                        error={outsideNumberValid(value).invalid}
                        helperText={outsideNumberValid(value).invalid ? outsideNumberValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="inside_number"
                        name="inside_number"
                        label="Número Interior"
                        type="text"
                        size="small"
                        variant="standard"
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        value={value.inside_number}
                        onChange={onInputChange}
                        error={insideNumberValid(value).invalid}
                        helperText={insideNumberValid(value).invalid ? insideNumberValid(value).message : false}
                    />
                </Grid>
                <Grid container item xl={4} md={4} sm={12} xs={12}>
                    <TextField
                        id="zipcode"
                        name="zipcode"
                        label="Código Postal"
                        type="text"
                        size="small"
                        autoComplete="current-password"
                        variant="standard"
                        inputProps={{ maxLength: 25 }}
                        fullWidth
                        value={value.zipcode}
                        onChange={onInputChange}
                        error={zipcodeValid(value).invalid}
                        helperText={zipcodeValid(value).invalid ? zipcodeValid(value).message : false}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default UserForm;
