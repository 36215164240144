import React, { useEffect, useState } from "react";
import {
    Button,
    CardContent,
    Tooltip,
    Card,
    Snackbar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useAuth } from "../../hooks";
import SkeletonPage from "./components/SkeletonPage";
import { Error } from "../../components/Feedback";
import SyncIcon from '@mui/icons-material/Sync';
import { getLevelName, getSpecialName, getTurnLevel } from "../../libs/utils";
import { invalidate, loadUI, selectStatusServer } from "../../store/slices/scoresUI/serverSlice";
import { selectAllGroupsItems } from "../../store/slices/scoresUI/ItemsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectItem } from "../../store/slices/scoresUI/uiSlice";
import { SCHOOL_LEVELS, SPECIAL_TYPES, TURNS_TYPES } from "../../constants";

/**
 * Componente para las calificaciones
 * 
 * @returns 
 */
const ScoresPage = () => {

    const history = useHistory();
    const Auth = useAuth()
    let user = Auth.getUser()
    const dispatch = useDispatch()

    /////////////// SHARED STATE //////////////////

    const statusServer = useSelector(selectStatusServer)

    const groups = useSelector(selectAllGroupsItems);

    /////////////// LOCAL STATE /////////////////


    ////////////// ACTIONS //////////////////////

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, []);

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                showFeedback(feedback.title)
            })
    }

    /**
     * Seleccionar un grupo
     * 
     * @param {*} group 
     */
    const onSelectGroup = (group) => {
        dispatch(selectItem(group.group_id))
        history.push({ pathname: `/calificaciones/${group.group_id}` })
    }

    /**
     * Columnas del DataGrid
     */
    const columns = [
        {
            field: 'grade',
            headerName: 'Grado',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => {
                return `${value || ''}`;
            }
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'special',
            headerName: 'Tipo',
            type: 'singleSelect',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SPECIAL_TYPES.map(i => i.title),
            valueGetter: (params) => {
                return getSpecialName(params.row.special)
            },
        },
        {
            field: 'turn',
            headerName: 'Turno',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: TURNS_TYPES.map(i => i.title),
            valueGetter: (params) => {
                return getTurnLevel(params.row.turn)
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueOptions: SCHOOL_LEVELS.map(i => i.title),
            valueGetter: (params) => {
                return getLevelName(params.row.level)
            },

        }, {
            field: 'students',
            headerName: 'Alumnos',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            filterable: false,
            flex: 0.5,
            minWidth: 100,
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            renderCell: (params) => (
                <strong>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => onSelectGroup(params.row)}
                        startIcon={<FontAwesomeIcon icon={faEye} size="lg" />}
                    >
                        VER
                    </Button>
                </strong>
            ),
        },
    ];


    const dataGrid = groups.map(row => ({ id: row.group_id, ...row }));

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>

                        </Box>

                        <Card sx={{ mt: 5 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: 0,
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: "flex-end"
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataGrid}
                                    columns={columns}
                                    pageSize={20}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                        </Card>
                    </>
                )
            }
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    );
};

export default ScoresPage;
