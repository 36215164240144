import * as constants from "./../service/const";


const SCHOOL_LEVELS = constants.levelsNames

const NOTICE_TYPES = constants.NOTICE_ITEMS
const SPECIAL_TYPES = constants.specialNames
const TURNS_TYPES = constants.turnsNames


export {
    SCHOOL_LEVELS,
    NOTICE_TYPES,
    SPECIAL_TYPES,
    TURNS_TYPES
}