import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Button,
    Stack,
    Tooltip
} from "@mui/material";
import ListCatalogReportsModal from './components/ListCatalogReportsModal'
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { invalidate, loadUI, selectReports, selectStatusServer } from "../../store/slices/reports";
import { useAuth } from "../../hooks";
import ViewListIcon from '@mui/icons-material/ViewList';
import { Error } from "../../components/Feedback";
import { useHistory } from "react-router-dom";
import { getTurnLevel } from "../../libs/utils";
import moment from 'moment'
import SkeletonPage from "./components/SkeletonPage";
import SyncIcon from '@mui/icons-material/Sync';
import { selectAllReportsCatalogs } from "../../store/slices/entities/reportscatalogs";
import useFeedback from "../../hooks/useFeedback";
import CatalogReportsCard from "./components/CatalogReportsCard";

/**
 * Pagina para mostrar el catalogo de los reportes
 * 
 * @returns 
 */
const Reportes = () => {

    moment.locale('es')
    const history = useHistory();
    const Auth = useAuth()
    const dispatch = useDispatch();
    const feedbackApp = useFeedback()


    //////////////// SHARES STATE /////////////////

    const statusServer = useSelector(selectStatusServer)
    const reports = useSelector(selectReports);

    const reportCatalog = useSelector(selectAllReportsCatalogs)

    //////////////// LOCAL STATE //////////////////

    const [openModal, setOpenModal] = useState(false);
    const [catalogSelected, setCatalogSelected] = useState(null);

    const columns = [
        {
            field: 'expediente',
            headerName: 'No. Ex.',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.file.file_id}`
        },
        {
            field: 'matricula',
            headerName: 'Matrícula',
            flex: 0.5,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.student.folio}`
        },
        {
            field: 'nombre',
            headerName: 'Alumno',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.student.name} ${params.row.student.last_name}`,
        },
        {
            field: 'grupo',
            headerName: 'Grupo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                let groupData = 'Sin grupo'

                if (row.group) {
                    groupData = `${row.group?.grade} ${row.group?.group} ${getTurnLevel(row.group?.turn)}`
                }


                return groupData
            },
        },
        {
            field: 'categoria',
            headerName: 'Categoría del reporte',
            type: 'singleSelect',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.reportcatalog.title}`,
            valueOptions: reportCatalog.map(i => i.title),
        },
        {
            field: 'description',
            headerName: 'Descripción del reporte',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'orientador',
            headerName: 'Orientador educativo',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => `${row.assessor.name} ${row.assessor.last_name}`,
        },
        {
            field: 'reporter',
            headerName: 'Reportador',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => `${row.reporter.name} ${row.reporter.last_name}`,
        },
        {
            field: 'created_at',
            headerName: 'Fecha',
            type: 'date',
            flex: 1,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => {
                return moment(value).format("DD MMMM YYYY")
            }
        }
    ];

    /////////////////////// ACTIONS ////////////////////

    /**
     * Si el usuario a entrado a la aplicacion 
     * iniciamos el proceso de recuperacion de datos
     * 
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, []);


    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    const dataGrid = reports?.map(row => ({ id: row.report_id, ...row }));

    return (
        <Box sx={{
            flexGrow: 1,
            paddingTop: {
                xs: 1,
                sm: 2,
                md: 2,
            },
            paddingLeft: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingRight: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingBottom: {
                xs: 1,
                sm: 2,
                md: 5,
            },
        }}>

            <ListCatalogReportsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Stack
                            spacing={1}
                            direction="row"
                            alignItems={"flex-end"}
                            justifyContent={"flex-end"}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { setCatalogSelected(null); setOpenModal(true) }}
                                size="small"
                                startIcon={<ViewListIcon />}
                            >
                                Catalogo
                            </Button>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Stack>

                        <Card sx={{ mt: 5 }}>
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: '0 !important',
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: "flex-end"
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    rows={dataGrid}
                                    columns={columns}
                                    pageSize={10}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </Box>
    );
};

export default Reportes;
