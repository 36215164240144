import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from 'lodash';

const emptyState = {
    groupSelected: null
}

/**
 * Slice para el settings UI
 */
export const scoresSlice = createSlice({
    name: 'scoresUI/ui',
    initialState: emptyState,
    reducers: {
        selectItem: (state, { payload }) => {
            state.groupSelected = payload
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

export const { selectItem } = scoresSlice.actions;

export default scoresSlice.reducer;

//////////////////// SELECTORES //////////////////
/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectScoresUI = (state) => state.scoresUI.ui;


//////////////// TRUNCKS /////////////////

