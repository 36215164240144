import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Connection from "../../../service/Connection"
import Feedback from "../../../service/Feedback";
import Authentication, { USERS } from "../../../service/Login";
import { addOneNotice } from "../entities/notices";

const emptyState = {
    typeView: 1,
	distributionMode: null,
    title: '',
    description: '',
    server: {
        statusOperation: "idle",
        feedback: {
          title: null,
          message: null,
          payload: null,
        }
    }
}

/**
 * Reductor para las entidades normalizadas
 */
export const uiSlice = createSlice({
    name: "noticesModalUI/ui",
    initialState: emptyState,
    reducers: {
        /**
         * Seleccionar el tipo de vista
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setTypeView: (state, action) => {
            state.typeView = action.payload 
        },
        /**
         * Seleccionar el modo de distribucion
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setDistributionMode: (state, action) => {
            state.distributionMode = action.payload 
        },
        /**
         * Cambia la informacion de la noticia
         * 
         * @param {*} state 
         * @param {*} action 
         */
        changeFormData: (state, {payload}) => {
            state.title = payload.title
            state.description = payload.description
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
                    
            return emptyState
        })
        /**
         * Limpiar los datos al cerar el modal
         */
         builder.addCase('noticesModalUI/reset', (state, action) => {
                    
            return emptyState
        })
        

        //////////////// AGREGAGRE NOTICIA //////////////////

        builder.addCase(storeNotice.rejected, (state, action) => {
            state.server.statusOperation = 'rejected'
            state.server.feedback = action.payload.feedback
        })
        builder.addCase(storeNotice.fulfilled, (state, action) => {
            state.server.statusOperation = 'fulfilled'
        })
        builder.addCase(storeNotice.pending, (state, action) => {
            state.server.statusOperation = 'pending'
        }) 

    }
})

export const  { setTypeView, setDistributionMode, changeFormData } = uiSlice.actions


//////////////////// SELECTORES //////////////////

/**
 * Consulta para recuperar el tipo de vista
 * 
 *      1.- Formulario para escribir el mensaje
 *      2.- Seleccionar el modo de distribución
 * 
 * @param {*} state 
 * @returns 
 */
 export const selectTypeView = (state) => state.noticesModalUI.ui.typeView;


 /**
 * Consulta para recuperar el modo de distribución actual
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectDistributionMode = (state) => state.noticesModalUI.ui.distributionMode;


 /**
 * Consulta para recuperar el titulo de la noticia
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectTitle = (state) => state.noticesModalUI.ui.title;

 /**
 * Consulta para recuperar el titulo de la noticia
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectDescription = (state) => state.noticesModalUI.ui.description;

 /**
 * Consulta para recuperar el titulo de la noticia
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectNoticeData = (state) => {
    return {
        title: state.noticesModalUI.ui.title,
        description: state.noticesModalUI.ui.description
    }
};


 /**
 * Consulta para recuperar el titulo de la noticia
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
export const selectStatusOperation = (state) => state.noticesModalUI.ui.server.statusOperation;


 /**
 * Consulta para recuperar el titulo de la noticia
 * 
 *      groups.- Distribucion por grupos
 *      students.- Distribucion por alumnos
 * 
 * @param {*} state 
 * @returns 
 */
  export const selectSchemaData = (state) => {

    const data = state.noticesModalUI.distributinModes.users.steps.users
    
    return {
        filters: data.filters,
        selected: data.selected
    }
};


export default uiSlice.reducer


/**
 * Crea o actualiza todas las calificaciones
 */
export const storeNotice = createAsyncThunk(
    'noticesModule/storeNotice',
    async ({userId, distributionMode}, thunkAPI) => {
        let FeedbackService = new Feedback()

        try {
            let store = thunkAPI.getState()

            let noticeData = {
                title: store.noticesModalUI.ui.title,
                description: store.noticesModalUI.ui.description,
                schema: JSON.stringify(getSchema(distributionMode, store))
            } 
            
            let notice = await Connection.storeNotice(userId, noticeData).then(response => response.data.data)

            thunkAPI.dispatch(addOneNotice(notice))
            
            return {
                notice
            }

        } catch(err){
            console.log(err)
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    }
)


/**
 * Retornar una eschema que reprecentara el envio de notificaciones
 */
 const getSchema = (distributionMode, store) => {

    if (distributionMode == 'groups') {
        return getSchemaByGroupsDistribution(store)
    } else if (distributionMode == 'students') {
        return getSchemaByStudentsDistribution(store)
    } else if (distributionMode == 'users') {
        return getSchemaByUserDistribution(store)
    }

    return {}
}

/**
 * Crear el esquema para la distribucion de grupos
 * 
 * @param {*} store 
 */
const getSchemaByGroupsDistribution = (store) => {
    const completedSteps = Object.values(store.noticesModalUI.distributinModes.groups.completedSteps)

    const {students, users} = store.noticesModalUI.entities
    
    const groupsStep = store.noticesModalUI.distributinModes.groups.steps.groups
    const studentsStep = store.noticesModalUI.distributinModes.groups.steps.students
    const parentsStep = store.noticesModalUI.distributinModes.groups.steps.parents

    if (completedSteps.length == 1) {
        /*
            Si Se envia desde la pantalla de grupos 
        */
        if (groupsStep.all) {
            return {
                groups: 'all'
            }
        } else {
            return {
                groups: groupsStep.selected
            }
        }

    } else if (completedSteps.length == 2) {
        /*
            Si Se envia desde la pantalla de alumnos 
        */
        if (studentsStep.all && groupsStep.all) {
            return {
                groups: 'all'
            }
        }

        return {
            groups: studentsStep.selected
        }

    } else {
        /*
            Si se envia desde la pantalla de padres
        */
        if (studentsStep.all && groupsStep.all && parentsStep.all) {
            return {
                groups: 'all'
            }
        }

        let schema = studentsStep.selected.map(gs => {
            return {
                ...gs,
                students: gs.students.map(studentId  => parentsStep.selected.find(p => p.student_id == studentId))
            }
        })

        return {
            groups: schema
        }
    }
}

/**
 * Crear el esquema para la distribucion de alumnos
 * 
 * @param {*} store 
 */
const getSchemaByStudentsDistribution = (store) => {
    const completedSteps = Object.values(store.noticesModalUI.distributinModes.students.completedSteps)

    const {students, users} = store.noticesModalUI.entities
    
    const studentsStep = store.noticesModalUI.distributinModes.students.steps.students
    const parentsStep = store.noticesModalUI.distributinModes.students.steps.parents

    if (completedSteps.length == 1) {
        /*
            Si Se envia desde la pantalla de alumnos 
        */
        if (studentsStep.all) {
            return {
                students: 'all'
            }
        } else {
            return {
                students: studentsStep.selected
            }
        }

    } else {
        /*
            Si se envia desde la pantalla de padres
        */

        if (studentsStep.all && parentsStep.all) {
            return {
                students: 'all'
            }
        }
            
        return {
            students: parentsStep.selected
        }
    }
}

/**
 * Crear el esquema para la distrubucion de usuarios
 * 
 * @param {*} store 
 * 
 * @returns 
 */
const getSchemaByUserDistribution = (store) => {
    let Auth = new Authentication()

    const {filters, selected} = store.noticesModalUI.distributinModes.users.steps.users
    const {users} = store.noticesModalUI.entities

    let {all} = filters

    if (all) {
        return {
            profiles: 'all'
        }
    } else {
        let relationships = {
            'professors': USERS.PROFESSOR,
            'administrators': USERS.ADMINISTER,
            'parents': USERS.PARENT,
            'assessors': USERS.ASSESSOR,
            'directors': USERS.DIRECTOR,
            'spokesman': USERS.SPOKESMAN 
        }

        let typesSwitchs = Object.entries(filters).filter(i => i[0] != 'all' && i[1]).map(i => relationships[i[0]])

        /**
         * Eliminar a todos los usuarios que almenos tengan algun tipo de usuario
         * de los swtich activos 
         */
        let userWithoutGroups  = selected.map(i => {
            let user = users.entities[i]

            Auth.setUser(user)

            return {
                user_id: i,
                types: Auth.getUserTypes().map(i => i.id)
            }
        })
        .filter(i => {
            return i.types.filter(userType => typesSwitchs.find(ts => ts == userType)).length == 0 

        })
        .map(i => i.user_id)

        return {
            profiles: typesSwitchs.map(i => i.toLowerCase()).join('|'),
            users: userWithoutGroups
        }
    }

}

