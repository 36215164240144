import React, { useEffect, useState } from 'react';
import {
    Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle, Typography
} from '@mui/material';
import 'antd/dist/antd.css';
import 'moment/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { deletePartial, selectStatusOperation } from '../../../store/slices/settingsUI';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { getNameSchoolLevel } from '../../../helper';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Modal para eliminar parciales
 * 
 * @param {*} param0 
 * @returns 
 */
const DeletePartialModal = ({ open, onClose, partial }) => {

    const dispatch = useDispatch();
    const feedbackApp = useFeedback()
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////////// SHARED STATE //////////////
    const statusOperation = useSelector(selectStatusOperation)

    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    //////////////// LOCAL STATE ///////////////

    const [partialItem, setPartial] = useState({
        partial_id: null,
        partial: null,
        start_date: null,
        end_date: null,
        limit_date: null,
        level: -1
    });


    /**
     * Efecto escuchando a show para limpiar los valores
     */
    useEffect(() => {
        if (open) {
            setPartial({ ...partialItem, ...partial })
        } else {
            setPartial({
                partial_id: null,
                partial: null,
                start_date: null,
                end_date: null,
                limit_date: null,
                level: -1
            })
        }
    }, [open]);

    /**
     * Funcion para eliminar un parcial
     * 
     * @param {*} idParcial 
     */
    const onDelete = async () => {
        dispatch(deletePartial(partialItem.partial_id))
            .unwrap()
            .then(res => {
                feedbackApp.showFeedback({
                    title: partialItem?.is_regularized ? 'Regularización eliminada' : 'Parcial eliminado'
                })
                onClose()
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    return (
        <Dialog
            open={open}
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={() => {
                onClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                id="alert-dialog-title"
            >
                Eliminar { partialItem?.is_regularized ? ' regularización' : ' parcial' }
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                    <Typography variant="h6" component={"div"}>
                        ¿Verdaderamente desea eliminar {partialItem?.is_regularized ? ' la regularización' : ' el parcial'}?
                    </Typography>

                    <Typography variant="body1" component={"div"} sx={{ mt: 2 }}>
                        {partialItem?.is_regularized ? 'La regularización ' : 'El parcial '}
                        <strong>{partialItem.partial}</strong> del nivel
                        <strong> {getNameSchoolLevel(partialItem.level)}</strong> será eliminado permanentemente
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="error"
                    onClick={onDelete}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    variant="contained"
                >
                    Eliminar
                </LoadingButton>

                <Button
                    variant="contained"
                    size="small"
                    onClick={onClose}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default DeletePartialModal
