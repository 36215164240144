import React, { useState } from 'react'
import { Link, Redirect } from "react-router-dom";
import Typed from 'react-typed';
import './Inicio.css';
import googleApp from './img/playstore.png';
import appleApp from './img/appstore.png';
import RegistrarModal from "../../components/Modal/ModalRegistrar";
import { Avatar, Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import HeaderPage from '../../components/HeaderPage';
import Testimonial from './Components/Testimonial';

import { deepOrange, deepPurple, green, lightBlue, red } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Feature from './Components/Feature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faGaugeSimpleHigh, faUsers, faMobile } from "@fortawesome/free-solid-svg-icons";

/**
 * Pagina para el inicio
 * 
 * @returns 
 */
const Inicio = () => {

    const [openModal, setOpenModal] = useState(false);

    return (
        <Box
            sx={{
                backgroundColor: "#FFFFFF"
            }}
        >

            <RegistrarModal
                title="Registrar"
                openModal={openModal}
                setOpenModal={setOpenModal}
            >
            </RegistrarModal>

            <HeaderPage />

            <Box id="hero" sx={{
                paddingTop: {
                    xs: 6,
                    sm: 2,
                },
                paddingLeft: {
                    xs: 5,
                    sm: 2,
                },
                paddingRight: {
                    xs: 5,
                    sm: 2,
                }
            }}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <Typography variant="h2" component="h2" sx={{
                                margin: 0,
                                fontSize: "5em",
                                fontWeight: 700,
                                lineHeight: "100px",
                                color: "white",
                                position: "sticky",
                                textAlign: "left",
                            }}>
                                Eficaz es:
                            </Typography>
                            <Typed
                                strings={[
                                    'Control de Asistencias',
                                    'Atención Oportuna',
                                    'Calificaciones en tiempo real',
                                    'Involucramiento de los Padres',
                                    'Todo al alcance de 3 Clicks'
                                ]}
                                typeSpeed={140}
                                backSpeed={100}
                                loop
                            >
                                <p
                                    style={{
                                        color: "white",
                                        margin: "15px 0 0 0",
                                        fontSize: "3em",
                                        fontFamily: "'Poppins', sans-serif",
                                        position: "sticky",
                                        textAlign: "left",
                                    }}
                                >
                                    <span
                                        className="typing typed"
                                        style={{
                                            color: "rgb(247, 249, 252)",
                                            letterSpacing: "1px"
                                        }}></span>
                                </p>
                            </Typed>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                maxWidth: "540px"
                            }}
                        >
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ mt: 5, mb: 5 }}>
                <Box sx={{ textAlign: "center", mb: 5 }}>
                    <Typography sx={{ mb: 5 }} component={"div"} variant={"h4"}>Características principales</Typography>
                    <Box sx={{ ml: 5, mr: 5 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Feature
                                    icon={<AssignmentIcon />}
                                    iconColor={red[500]}
                                    title={"Control de Asistencias"}
                                    message={`Los profesores pueden nombrar su lista de asistencia de la manera más eficiente, ya que los alumnos de sus grupos estarán siempre cargados`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Feature
                                    icon={<FontAwesomeIcon icon={faUsers} />}
                                    iconColor={deepPurple[500]}
                                    title={"Involucramiento de los Padres"}
                                    message={`Los padres estarán al pendiente de sus hijos en todo momento, ya que Eficaz los mantendrá al tanto de manera inmediata`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Feature
                                    icon={<FontAwesomeIcon icon={faGaugeSimpleHigh} />}
                                    iconColor={lightBlue[500]}
                                    title={"Calificaciones en tiempo real"}
                                    message={`Las calificaciones estarán actualizadas y disponibles en todo momento`}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box
                sx={{
                    backgroundColor: green[400],
                    color: "white",
                    p: 5,
                    textAlign: "center"
                }}
            >
                <Typography component={"div"} variant={"h4"}>
                    Automatización en tiempo real
                </Typography>
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 249, 252)",
                    px: 2,
                    py: 5,
                    mb: 5
                }}
            >
                <Box sx={{ textAlign: "center", mb: 5 }}>
                    <Typography component={"div"} variant={"h4"}>Testimonios</Typography>
                    <Typography component={"div"} variant={"h5"} sx={{ mt: 2 }}>
                        Vea como Eficaz ayuda a la educación
                    </Typography>
                </Box>
                <Box sx={{ ml: 5, mr: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Testimonial
                                icon={<FontAwesomeIcon icon={faMobile} />}
                                message={`Excelente aplicación para mantenernos informados sobre nuestros hijos y lo mejor es desarrollada en México.`}
                                name={"Un usuario de Google"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Testimonial
                                icon={<FontAwesomeIcon icon={faMobile} />}
                                message={`Muy buena aplicación, nos sirve mucho para mantener contacto escuela-familia.`}
                                name={"Un usuario de Google"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Testimonial
                                icon={<FontAwesomeIcon icon={faMobile} />}
                                message={`Excelente app! Muy útil y fácil! 100 % recomendable! ;)`}
                                name={"Un usuario de Google"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Testimonial
                                icon={<FontAwesomeIcon icon={faMobile} />}
                                message={`Todas las escuelas deberían de tener esta aplicación para poder estar comunicados con los padres de familia en tiempo real`}
                                name={"Un usuario de Google"}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <div id="footer">
                <div className="container">
                    <Typography component={"div"} variant={"h4"}>Descarga la aplicación</Typography>

                    <div className="tienda">
                        <div className="playstore">
                            <a href='https://play.google.com/store/apps/details?id=com.eficaz.appmovil' target="_blank" rel="noreferrer">
                                <img alt='Disponible en Google Play' style={{ width: "140px" }} src={googleApp} />
                            </a>
                        </div>
                        <div className="appstore">
                            <a href='https://apps.apple.com/mx/app/eficaz/id1498693599' target="_blank" rel="noreferrer">
                                <img alt='Disponible en App Store' style={{ width: "170px" }} src={appleApp} />
                            </a>
                        </div>
                    </div>
                    <div className="copyright">
                        &copy; Copyright <strong><span>Eficaz</span></strong>. All Rights Reserved
                    </div>
                    <div className="copyright">
                        <strong><span><Link to="/aviso-privacidad" target="_blank" rel="noopener noreferrer">Aviso de privacidad</Link></span></strong>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default Inicio;
