import { createSlice } from "@reduxjs/toolkit";

const emptyState = {
    byId: {}
}

/**
 * Slice para los parciales
 */
export const noticesSlice = createSlice({
    name: 'notices',
    initialState: emptyState,
    reducers:{
        /**
         * Si la materia no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneNotice: (state, action) => {
            if (!state.byId[action.payload.notice_id]) {
                state.byId[action.payload.notice_id] = action.payload
            }
        },
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyNotices: (state, action)=>{
            state.byId = {...state.byId, ...action.payload}
        },
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setNotices: (state,action)=>{
            state.byId = action.payload;
        },
        updateNotice: (state,action)=>{
            const {partial_id, partial, start_date, end_date, limit_date, level} = action.payload
            // find this todo in the list
            const parcial = state.list.find(t => t.partial_id === partial_id);
            // update that todo
            parcial.partial = partial; 
            parcial.start_date = start_date; 
            parcial.end_date = end_date; 
            parcial.limit_date = limit_date; 
            parcial.level = level;
            // note: .find() might return undefined, so you may want to handle that
        },
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        removeOneNotice: (state,action) => {
            delete state.byId[action.payload.notice_id]
        },
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneNotice: (state, action) => {
            let {notice_id} = action.payload

            state.byId[notice_id] = {...state.byId[notice_id], ...action.payload}
        },
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManyNotices: (state, action) => {
            for (const notice of action.payload) {
                let {notice_id} = notice

                state.byId[notice_id] = {...state.byId[notice_id], ...notice}
            }
        },
    },  
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setNotices, addManyNotices, removeOneNotice,
    updateNotice,  
    addOneNotice, upsertOneNotice, upsertManyNotices
} = noticesSlice.actions;

//////////////////// SELECTORES //////////////////

/**
 * Selector para recuperar todos los parciales de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectNotices = (state) => state.entities.notices.byId;


/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 */
 export const selectNoticesDataById = (subjectId) => {
    return (state) => {

        let notice = state.entities.subjects.byId[subjectId];

        if (!notice) {
            return null
        }
    
        return {
            ...notice,
    
        }
    }
}

export default noticesSlice.reducer;
