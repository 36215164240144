import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchInitialInformation, selectFetchingStatus } from "../../store/slices/professorModule/fetchSlice";
import { useAuth } from "../../hooks";
import GroupsCard from "./components/GroupCard"
import { Empty, Error } from "../../components/Feedback";
import { invalidate } from "../../store/slices/professorModule/fetchSlice";
import SyncIcon from '@mui/icons-material/Sync';
import SkeletonPage from "./components/SkeletonPage";
import { selectItemsIds } from "../../store/slices/professorModule/ItemsSlice";

/**
 * Pagina de home de los maestros
 * 
 * @returns 
 */
const ProfessorHome = () => {
    const Auth = useAuth()
    const dispatch = useDispatch()

    //////////////// SHARES STATE /////////////////

    const groupsItemsIds = useSelector(selectItemsIds)
    const fetchingStatus = useSelector(selectFetchingStatus)

    ///////////// ACTIONS /////////////////

    /**
     * Iniciar el proceso de recuperacion de datos
     */
    const reload = () => {
        dispatch(fetchInitialInformation({
            professorId: Auth.getUserID(),
            schoolId: Auth.user.school_id
        }))
    }

    useEffect(() => {
        reload()
    }, [])

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /*
    const subjectGroupsCard = []

    if (groupsItems) {
        for (const groupItem of Object.values(groupsItems)) {
       
            subjectGroupsCard.push({...groupItem})
        }
        
        subjectGroupsCard.sort((a, b) => {
            const ac = a.group.grade + a.group.group
            const bc = b.group.grade + b.group.group
            if (ac > bc) {
              return 1;
            }
            if (ac < bc) {
              return -1;
            }
            return 0;
        })
    }*/

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            {
                fetchingStatus == "pending" && (<SkeletonPage items={9} />)
            }

            {
                fetchingStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (fetchingStatus == "fulfilled") && (
                    <>
                        <Box
                            style={{
                                marginBottom: 2,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Typography
                                variant="h4"
                                component="div"
                            >
                                Mis grupos
                            </Typography>

                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>

                        <Box sx={{ mt: 5 }}>

                            {
                                (groupsItemsIds.length == 0) ?
                                    (
                                        <Empty label="Sin grupos" />
                                    )
                                    :
                                    (
                                        <Grid container spacing={{ xs: 1, md: 4 }} >
                                            {groupsItemsIds.map((item, index) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                                        <GroupsCard id={item}> </GroupsCard>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )
                            }
                        </Box>
                    </>
                )
            }

        </Box>
    )
}

export default ProfessorHome