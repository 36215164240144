import { Avatar, Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

const Feature = ({ icon, title, message, iconColor }) => {
    return (
        <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Avatar sx={{ bgcolor: iconColor, width: 56, height: 56, mb: 2 }} variant="rounded">
                {icon}
            </Avatar>
            <Typography sx={{ mb: 1 }} component={"div"} variant={"h6"}>{title}</Typography>
            <Typography component={"div"} variant={"body1"}>
                {message}
            </Typography>
        </Stack>
    )
}

export default Feature