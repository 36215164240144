import React, { useEffect, useState } from "react";
import {
    Button,
    Tooltip,
    Card,
    CardContent,
    Avatar,
    CardActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import ChangeAssessorsGroupModal from './components/ChangeAssessorsGroupModal'
import SubjectsModal from './components/SubjectsModal';
import AddGroupModal from './components/AddGroupModal';
import StudentsModal from './components/StudentsModal';
import { DataGrid, esES, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import { getLevelName, getTurnLevel, getSpecialName } from "../../libs/utils";
import { Error } from "../../components/Feedback";
import { selectAssessors } from "../../store/slices/entities/users";
import { useAuth } from "../../hooks";
import AddIcon from '@mui/icons-material/Add';
import SkeletonPage from "./components/SkeletonPage";
import SyncIcon from '@mui/icons-material/Sync';
import useFeedback from "../../hooks/useFeedback";
import AssingSubjectsModal from "./components/AssingSubjectsModal";
import AddStudentModal from "./components/AddStudentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap, faBookMedical } from "@fortawesome/free-solid-svg-icons";
import { loadUI, invalidate, selectStatusServer } from "../../store/slices/groupsUI/serverSlice";
import { selectAllGroupsItems } from "../../store/slices/groupsUI/itemSlice";
import { selectGroupItem } from "../../store/slices/groupsUI/uiSlice";
import { selectGroupItemSelected } from "../../store/slices/groupsUI/itemSlice";


/**
 * Pagina de grupos
 * 
 * @returns 
 */
const Groups = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const feedbackApp = useFeedback()
    const Auth = useAuth();

    ///////////////// LOCAL STATE /////////////////

    const [openMaterias, setOpenMaterias] = useState(false); //ESTADO DEL MODAL DE 
    const [openGrupo, setOpenGrupo] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openAlumnos, setOpenAlumnos] = useState(false);
    const [openAsignarMaterias, setOpenAsignarMaterias] = useState(false);
    const [openAddStudentModal, setOpenAddStudentModal] = useState(false);
    const [grupoSelect, setGrupoSelect] = useState(null);

    ///////////////// SHARES STATE ////////////////////

    const groups = useSelector(selectAllGroupsItems);
    const assessors = useSelector(selectAssessors)
    const statusServer = useSelector(selectStatusServer)

    /////////////////// ACTIONS //////////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }
    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, [history]);

    /**
     * Mostrar modal para actualizar el asesor
     * @param {*} data 
     */
    const cambiarAsesor = (data) => {
        setGrupoSelect(data.group_id);
        setOpenModal(true);
    }

    const columns = [
        {
            field: 'grade',
            headerName: 'Grado',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'special',
            headerName: 'Tipo',
            type: 'singleSelect',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getSpecialName(params.row.special)
            },
        },
        {
            field: 'turn',
            headerName: 'Turno',
            type: 'singleSelect',
            flex: 0.5,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getTurnLevel(params.row.turn)
            },
        },
        {
            field: 'level',
            headerName: 'Nivel',
            type: 'singleSelect',
            flex: 0.6,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return getLevelName(params.row.level)
            },
        },
        {
            field: 'assessor',
            headerName: 'Orientador Educativo',
            flex: 1,
            minWidth: 250,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            /*valueGetter: useEffect(() => {
                if (params.row.assessor_id) {
                    let groupAssessor = assessors.find(i => i.assessor_id == params.row.assessor_id)
                    return `${groupAssessor?.name || ''} ${groupAssessor?.last_name || ''} ${groupAssessor?.second_last_name || ''}`;
                }
                return 'Sin orientador'
            }, [params.row.assessor_id]),*/
            valueGetter: (params) => {
                if (params.row.assessor_id) {
                    let groupAssessor = assessors.find(i => i.assessor_id == params.row.assessor_id)
                    return `${groupAssessor?.name || ''} ${groupAssessor?.last_name || ''} ${groupAssessor?.second_last_name || ''}`;
                }
                return 'Sin orientador'
            },
            renderCell: (params) => {
                return (
                    <Tooltip title="Mostrar orientador">
                        <>
                            {
                                (params.row.assessor_id != null && params.row.assessor_id != 0) && (
                                    <Tooltip title={"Cambiar a " + params.value}>
                                        <Button
                                            style={{ textTransform: 'none' }}
                                            variant="text" onClick={() => { dispatch(selectGroupItem(params.id)); cambiarAsesor(params.row) }}
                                            startIcon={<Avatar sx={{ width: 24, height: 24 }} src={params.row.assessor?.url_photo_profile} />}
                                        >
                                            {params.value}
                                        </Button>
                                    </Tooltip>
                                )
                            }
                            {
                                (params.row.assessor_id == null || params.row.assessor_id == 0) && (
                                    <Tooltip title={"Asignar un orientador"}>
                                        <Button
                                            style={{ textTransform: 'none' }}
                                            variant="text" onClick={() => { dispatch(selectGroupItem(params.id)); cambiarAsesor(params.row) }}
                                        >
                                            Sin Orientador
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </>
                    </Tooltip>
                )
            },
        },
        {
            field: 'subjects',
            headerName: 'Materias',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar materias">
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={() => {
                            dispatch(selectGroupItem(params.id)); setOpenMaterias(true)
                        }}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'students',
            headerName: 'Alumnos',
            type: 'number',
            flex: 0.4,
            minWidth: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <>
                    <Tooltip title="Mostrar alumnos">
                        <Button size="small" variant="outlined" startIcon={<ListIcon />}
                            onClick={() => { dispatch(selectGroupItem(params.id)); setOpenAlumnos(true) }}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                </>
            ),
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.4,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => ([
                <GridActionsCellItem
                    icon={< FontAwesomeIcon icon={faBookMedical} size="lg" />}
                    title="Agregar materia"
                    label="Agregar materia"
                    onClick={() => {  
                        dispatch(selectGroupItem(params.id)); 
                        setOpenAsignarMaterias(true);
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={< FontAwesomeIcon icon={faGraduationCap} size="lg" />}
                    title="Agregar alumno"
                    label="Agregar alumno"
                    onClick={() => {  
                        dispatch(selectGroupItem(params.id)); 
                        setOpenAddStudentModal(true) }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    title="Editar"
                    label="Editar"
                    onClick={() => {
                        dispatch(selectGroupItem(params.id)); 
                        setOpenGrupo(true) 
                    }}
                    showInMenu
                />,
            ]),
        },
    ];

    return (
        <Box sx={{
            flexGrow: 1,
            paddingTop: {
                xs: 1,
                sm: 2,
                md: 2,
            },
            paddingLeft: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingRight: {
                xs: 1,
                sm: 2,
                md: 5,
            },
            paddingBottom: {
                xs: 1,
                sm: 2,
                md: 5,
            },
        }}>
           <ChangeAssessorsGroupModal
                open={openModal}
                setOpen={setOpenModal}
                groupId={grupoSelect}
            />

            <SubjectsModal
                open={openMaterias}
                setOpen={setOpenMaterias}
            />
        
            <AddGroupModal
                open={openGrupo}
                setOpen={setOpenGrupo}
            />

            <StudentsModal
                open={openAlumnos}
                setOpen={setOpenAlumnos}
            />

            <AssingSubjectsModal
                open={openAsignarMaterias}
                setOpen={setOpenAsignarMaterias}
                groupId={grupoSelect}
            />
            
            <AddStudentModal
                open={openAddStudentModal}
                setOpen={() => {
                    setOpenAddStudentModal(false);
                }}
                groupId={grupoSelect}
            />

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>
                        </Box>

                        <Card
                            sx={{
                                mt: 5,
                            }}
                        >
                            <CardContent
                                sx={{
                                    pt: 0,
                                    pl: 0,
                                    pr: 0,
                                    pb: 0,
                                    ' & .MuiDataGrid-root': {
                                        border: 'none'
                                    },
                                    ' & .MuiDataGrid-toolbarContainer': {
                                        justifyContent: "flex-end"
                                    }
                                }}
                            >
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    getRowId={({ group_id }) => group_id}
                                    rows={groups}
                                    columns={columns}
                                    pageSize={10}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                />
                            </CardContent>
                            <CardActions sx={{ direction: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { 
                                        setOpenGrupo(true) 
                                    }}
                                    size="small"
                                    startIcon={<AddIcon />}
                                >
                                    Agregar
                                </Button>
                            </CardActions>
                        </Card>
                    </>
                )
            }
        </Box>
    );
};

export default Groups;
