import { Box, FormControlLabel, FormGroup, List, ListSubheader, Switch, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLevelName, getTurnLevel } from '../../../../libs/utils';
import {
    loadStudentsStepData, selectGroupsSelected, selectStudentsByGroupsSelected,
    changeStudentsSelected, changeSelectAllStudents, selectStudentSelected,
    selectStudentIsAllSelected, selectOperationStatusStudentsStep, invalidateStepData
} from '../../../../store/slices/noticesModalUI/modes/groupsModeSlice';
import StudentListItem from './StudentListItem';
import { Empty, Error } from "../../../../components/Feedback";

/**
 * Componente que muestra componentes para selecionar a que hijos se le enviaran una notificacion
 * 
 * @param dataSource Fuente de alumnos
 * @param onChange Evento lanzado cada vez que un alumno es seleccionado 
 * @param selected Alumnos seleccionados
 * 
 * @returns 
 */
const StudentsStep = () => {
    const dispatch = useDispatch()

    ///////////////// SHARED STATE ////////////////

    const groupsSelected = useSelector(selectGroupsSelected)
    const studentSelected = useSelector(selectStudentSelected)

    const students = useSelector(selectStudentsByGroupsSelected)

    const operationStatus = useSelector(selectOperationStatusStudentsStep)


    const isAllSelected = useSelector(selectStudentIsAllSelected)

    ///////////////// LOCAL STATE ///////////////////

    const [groupsStudents, setGroupsStudents] = useState([])
    const [all, setAll] = useState(isAllSelected);

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Recuperacion de los datos de los alumnos
     */
    useEffect(() => {
        dispatch(loadStudentsStepData(groupsSelected))
    }, [])

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadStudentsStepData(groupsSelected))
    }


    /**
     * Lo que recuperamos del selector lo almacenamos en un estado local
     */
    useEffect(() => {
        if (operationStatus == "fulfilled") {
            let groups = Object.values(_.groupBy(students, (value) => value.group.group_id))
                .map(g => ({
                    ...g[0].group,
                    students: g.map(i => i.student)
                }))
                .map(g => {
                    let group = studentSelected.find(gs => gs.group_id == g.group_id)

                    if (!group) {
                        return g
                    }

                    let students = g.students.map(s => ({ ...s, checked: group.students.filter(sS => sS == s.student_id).length != 0 }))

                    return {
                        ...g,
                        students: students
                    }
                })

            setGroupsStudents(groups)
        }
    }, [operationStatus])

    /**
     * Activar o desactivar el envio de notificacion a un estudiante de un grupo
     * 
     * @param {*} value 
     * @returns 
     */
    const handleStudentToggle = (student_id, group_id, checked) => {

        const studentsUpdated = enableOneStudent(student_id, group_id, checked)

        const isAll = getIsAllSelected(studentsUpdated)
        const studentsSelected = getStudentSelected(studentsUpdated)

        setGroupsStudents(studentsUpdated)
        setAll(isAll)

        dispatch(changeStudentsSelected(studentsSelected))
        dispatch(changeSelectAllStudents(isAll))
        dispatch(invalidateStepData('parents'))
    }

    /**
     * Identifica si todos los alumnos ha sido seleccionado
     * 
     * @param {*} groups 
     * 
     * @returns 
     */
    const getIsAllSelected = (groups) => {
        return _.flatten(groups.map(g => g.students)).map(s => s.checked).every(i => i)
    }

    /**
     * Calcula y devuelve el listado de alumnos seleccionados
     * 
     * @param groups Lista de grupos con alumnos (Actualizada)
     */
    const getStudentSelected = (groups) => {
        let studentSelected = groups.map(g => {
            let students = g.students.filter(s => s.checked).map(i => i.student_id)

            return {
                group_id: g.group_id,
                students
            }
        }).filter(g => g.students.length > 0)

        return studentSelected
    }

    /**
     * Funcion para deshabilitar todo los estudiantes
     */
    const disableAllStudents = () => {
        let newData = groupsStudents.map(g => {
            return {
                ...g,
                students: g.students.map(s => ({ ...s, checked: false }))
            }
        })

        return newData
    }

    /**
     * Funcion para habilitar todo los estudiantes
     */
    const enableAllStudents = () => {
        let newData = groupsStudents.map(g => {
            return {
                ...g,
                students: g.students.map(s => ({ ...s, checked: true }))
            }
        })

        return newData
    }

    /**
     * Abilitar un elemento
     * 
     * @param student_id 
     * @param group_id 
     * @param checked 
     * 
     * @returns 
     */
    const enableOneStudent = (student_id, group_id, checked) => {
        return groupsStudents.map(g => {

            if (g.group_id == group_id) {
                return {
                    ...g,
                    students: g.students.map(s => (s.student_id == student_id ? ({ ...s, checked }) : s))
                }
            }

            return g
        })
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {
        setAll(event.target.checked)

        const isAll = event.target.checked
        let studentAfected = []
        let studentSelected = []

        if (event.target.checked) {
            studentAfected = enableAllStudents()

            studentSelected = getStudentSelected(studentAfected)
        } else {
            studentAfected = disableAllStudents()
        }

        setGroupsStudents(studentAfected)

        dispatch(changeStudentsSelected(studentSelected))
        dispatch(changeSelectAllStudents(isAll))
        dispatch(invalidateStepData('parents'))
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >

            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && groupsStudents.length == 0) && (<Empty label="Sin alumnos" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={all}
                                        onChange={onChangeAll}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Todos"
                            />
                        </FormGroup>

                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                            dense
                        >
                            {
                                groupsStudents.map((item) => (
                                    <li key={`section-${item.group_id}`}>
                                        <ul>
                                            <ListSubheader>
                                                {`${item.grade}° ${item.group} ${getTurnLevel(item.turn)} ${getLevelName(item.level)}`}
                                            </ListSubheader>
                                            {
                                                item.students.map((student) => {
                                                    return (
                                                        <StudentListItem
                                                            student={student}
                                                            group={item}
                                                            onChange={handleStudentToggle}
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                ))
                            }
                        </List>
                    </>
                )
            }
        </Box>
    )
}


export default StudentsStep