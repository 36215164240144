import React, { useEffect, useState } from 'react';
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Snackbar } from '@mui/material';
import 'antd/dist/antd.css';
import 'moment/locale/es';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatusOperation, updateImageSchool } from '../../../store/slices/settingsUI';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { blue } from '@mui/material/colors';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Modal para eliminar parciales
 * 
 * @param {*} param0 
 * @returns 
 */
export const SchoolImage = () => {

    const Auth = useAuth();
    const dispatch = useDispatch();
    const feedbackApp = useFeedback()

    const schoolId = Auth.getUser().school_id
    let refInputFile = React.createRef();

    /////////////// SELECTORS /////////////

    const statusOperation = useSelector(selectStatusOperation)
    const config = useSelector(selectSettingBySchoolId(schoolId))

    //////////////// ESTADO ///////////////

    const [state, setState] = useState('idle')


    /**
    * Actualziacion la imagen del usuario
    * 
    * @param {*} event 
    */
    function changePhotoEvent($e) {
        setState('pending')
        let loginSetting = config.find(res => res.key === 'theme-logo-school');

        let settingId = loginSetting.configuration_id

        let imgRemplazar = $e.target.files[0]

        let formData = new FormData()
        formData.append('value', imgRemplazar)
        formData.append('_method', 'PATCH')

        dispatch(updateImageSchool({ settingId, formData }))
            .unwrap()
            .then(res => {
                $e.target.value = ''

                feedbackApp.showFeedback({
                    title: "Actualizado correctamente"
                })

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    function onChangeImage() {
        refInputFile.current.click()
    }

    /**
     * Escuchamos las propiedades en busca de una
     * actualizacio y poder desactivar el loading
     */
    useEffect(() => {
        if ((statusOperation == 'rejected' || statusOperation == 'fulfilled') && state == 'pending') {
            setState('idle')
        }
    }, [statusOperation]);

    /**
     * Obtenemos el icono del usuario
     * 
     * @returns 
     */
    function getImageSchoolUI() {
        let loginSetting = config.find(res => res.key === 'theme-logo-school');

        let value = loginSetting.value

        const iconSX = { width: 200, height: 200, bgcolor: blue[500], marginLeft: "auto", marginRight: "auto" }

        if (value != null) {
            return (
                <Avatar
                    alt="Imagen de usuario"
                    src={value}
                    sx={iconSX}
                >
                </Avatar>
            )
        }

        return (
            <Avatar
                alt="Imagen de usuario"
                sx={iconSX}
            >
                U
            </Avatar>
        )
    }


    return (
        <Card
            sx={{
                height: 376,
            }}
        >
            <CardHeader
                title="Imagen escolar"
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 2
                }}
            />
            <CardContent>
                <Box
                    component="div"
                    noValidate
                    autoComplete="off"
                    sx={{ padding: 1 }}
                >
                    <Box sx={{ display: "none" }}>
                        <input ref={refInputFile} type="file" accept="image/*" onChange={changePhotoEvent} />
                    </Box>

                    {getImageSchoolUI()}

                </Box>
            </CardContent>
            <CardActions
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
            >
                <LoadingButton
                    size="small"
                    color="primary"
                    sx={{ marginTop: 2 }}
                    onClick={onChangeImage}
                    loading={state == 'pending'}
                    loadingPosition="start"
                    startIcon={<FileUploadIcon fontSize="inherit" />}
                    variant="contained"
                >
                    Cambiar
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

export default SchoolImage
