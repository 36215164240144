import { Box, FormControlLabel, FormGroup, List, ListSubheader, Switch, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadParentsStepData, selectStudentSelected, selectParentItemsbyStudentSelected,
    changeParentsSelected, changeSelectAllParents, selectParentsSelected,
    selectParentsIsAllSelected, selectOperationStatusParentsStep
} from '../../../../store/slices/noticesModalUI/modes/studentsModeSlice';
import { Empty, Error } from "../../../../components/Feedback";
import ParentListItem from './ParentListItem';
import { useAuth } from '../../../../hooks';

/**
 * Componente que muestra componentes para selecionar a que hijos se le enviaran una notificacion
 * 
 * @param dataSource Fuente de alumnos
 * @param onChange Evento lanzado cada vez que un alumno es seleccionado 
 * @param selected Alumnos seleccionados
 * 
 * @returns 
 */
const ParentsStep = () => {
    const dispatch = useDispatch()
    const Auth = useAuth()

    ///////////////// SHARED STATE ////////////////

    /**
     * Alumnos seleccionados del paso anterior
     */
    const studentsSelected = useSelector(selectStudentSelected)

    /**
     * Elementos del paso de padres seleccionados 
     */
    const parentsItemsSelected = useSelector(selectParentsSelected)

    /**
     * Todos los padres de los alumnos seleccionados en el paso anterior
     */
    const allParentsItems = useSelector(selectParentItemsbyStudentSelected)

    const operationStatus = useSelector(selectOperationStatusParentsStep)

    const isAllSelected = useSelector(selectParentsIsAllSelected)

    ///////////////// LOCAL STATE ///////////////////

    const [localParentsItems, setLocalParentItems] = useState([])

    /////////////////// FUNCTIONS ///////////////////////////

    /**
     * Recuperacion de los datos de los alumnos
     */
    useEffect(() => {
        dispatch(loadParentsStepData({
            studentIds: studentsSelected,
            schoolId: Auth.getUser().school_id
        }))
    }, [])

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadParentsStepData({
            studentIds: studentsSelected,
            schoolId: Auth.getUser().school_id
        }))
    }


    /**
     * Lo que recuperamos del selector lo almacenamos en un estado local
     */
    useEffect(() => {
        if (operationStatus == "fulfilled") {
            let studentsItems = Object.values(_.groupBy(allParentsItems, (value) => value.student.student_id))
                .map(g => ({
                    ...g[0].student,
                    parents: g.map(i => i.parent)
                }))
                .map(s => {
                    let student = parentsItemsSelected.find(ps => ps.student_id == s.student_id)

                    if (!student) {
                        return s
                    }

                    let parents = s.parents.map(p => ({ ...p, checked: student.parents.filter(sS => sS == p.user_id).length != 0 }))

                    return {
                        ...s,
                        parents: parents
                    }
                })

            console.log(studentsItems)

            setLocalParentItems(studentsItems)
        }
    }, [operationStatus])

    /**
     * Activar o desactivar el envio de notificacion a un estudiante de un grupo
     * 
     * @param {*} value 
     * @returns 
     */
    const handleStudentToggle = (parent_id, student_id, checked) => {

        const studentsUpdated = enableOneStudent(parent_id, student_id, checked)

        const isAll = getIsAllSelected(studentsUpdated)
        const studentsSelected = getItemsSelected(studentsUpdated)

        setLocalParentItems(studentsUpdated)

        dispatch(changeParentsSelected(studentsSelected))
        dispatch(changeSelectAllParents(isAll))
    }

    /**
     * Identifica si todos los todos los elementos estan seleccionados
     * 
     * @param {*} items 
     * 
     * @returns 
     */
    const getIsAllSelected = (items) => {
        return _.flatten(items.map(g => g.parents)).map(s => s.checked).every(i => i)
    }

    /**
     * Calcula y devuelve el listado de elementos seleccionados
     * 
     * @param items Lista de elementos (Actualizada)
     */
    const getItemsSelected = (items) => {
        let parentsSelected = items.map(g => {

            return {
                student_id: g.student_id,
                parents: g.parents.filter(s => s.checked).map(i => i.user_id)
            }
        }).filter(g => g.parents.length > 0)

        return parentsSelected
    }

    /**
     * Le cambia el estado a todos los elementos
     * 
     * @param {*} checked 
     * @returns 
     */
    const changeCheckedAllItem = (checked) => localParentsItems.map(g => ({ ...g, parents: g.parents.map(s => ({ ...s, checked })) }))

    /**
     * Funcion para deshabilitar todo los estudiantes
     */
    const disableAllStudents = () => changeCheckedAllItem(false)

    /**
     * Funcion para habilitar todo los estudiantes
     */
    const enableAllStudents = () => changeCheckedAllItem(true)

    /**
     * Abilitar un elemento
     * 
     * @param student_id 
     * @param group_id 
     * @param checked 
     * 
     * @returns 
     */
    const enableOneStudent = (user_id, student_id, checked) => {
        return localParentsItems.map(g => {

            if (g.student_id == student_id) {
                return {
                    ...g,
                    parents: g.parents.map(s => (s.user_id == user_id ? ({ ...s, checked }) : s))
                }
            }

            return g
        })
    }

    /**
     * Cambio el swtich para seleccionar todos
     * 
     * @param {*} data 
     */
    const onChangeAll = (event) => {

        const isAll = event.target.checked
        let studentAfected = []
        let parentsSelected = []

        if (event.target.checked) {
            studentAfected = enableAllStudents()

            parentsSelected = getItemsSelected(studentAfected)
        } else {
            studentAfected = disableAllStudents()
        }

        setLocalParentItems(studentAfected)

        dispatch(changeParentsSelected(parentsSelected))
        dispatch(changeSelectAllParents(isAll))
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >

            {
                operationStatus == "pending" && (
                    <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                operationStatus == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (operationStatus == "fulfilled" && localParentsItems.length == 0) && (<Empty label="Sin alumnos" />)
            }

            {
                (operationStatus == "fulfilled") && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAllSelected}
                                        onChange={onChangeAll}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Todos"
                            />
                        </FormGroup>

                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                            dense
                        >
                            {
                                localParentsItems.map((item) => (
                                    <li key={`section-${item.student_id}`}>
                                        <ul>
                                            <ListSubheader>
                                                {`${item.name} ${item.last_name} ${item.last_name}`}
                                            </ListSubheader>
                                            {
                                                item.parents.map((parent) => {
                                                    return (
                                                        <ParentListItem
                                                            parent={parent}
                                                            student={item}
                                                            onChange={handleStudentToggle}
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                ))
                            }
                        </List>
                    </>
                )
            }
        </Box>
    )
}


export default ParentsStep