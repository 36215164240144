import React, { useEffect, useState } from 'react';
import {
    Typography, Dialog, DialogActions, DialogContent, DialogTitle,
    Button, TableContainer, Table, TableHead, TableBody, TableCell, TableRow
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from "react-redux";
import * as Style from '../../../styles/index';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { selectSubjectCatalogDataByID } from '../../../store/slices/materias';
import { getSpecialName, getTurnLevel } from '../../../libs/utils';


/**
 * Modal para mostrar los grupos de una materia
 * 
 * @param {*} param0 
 * @returns 
 */
export default ({ openModal, setOpenModal, subjectCatalogId = null }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    //const statusOperation = useSelector(selectStatusOperation)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    let subjectCatalog = useSelector(selectSubjectCatalogDataByID(subjectCatalogId))

    return (
        <>
            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth="sm"
                classes={{ paper: classes.dialogWrapper }}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Lista de grupos <br />
                            <span style={{ fontSize: '15px' }}>
                                Materia: {subjectCatalog?.folio} {subjectCatalog?.title}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        subjectCatalog?.groups.length > 0 && (
                            <TableContainer>
                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                                            <TableCell align='center' style={{ color: fontColor.value }}>GRADO</TableCell>
                                            <TableCell align='center' style={{ color: fontColor.value }}>GRUPO</TableCell>
                                            <TableCell align='center' style={{ color: fontColor.value }}>TURNO</TableCell>
                                            <TableCell align='center' style={{ color: fontColor.value }}>TIPO</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {subjectCatalog?.groups.map((row, i) => (
                                            <TableRow key={row.group_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {row.grade}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {row.group}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {getTurnLevel(row.turn)}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {getSpecialName(row.special)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    }

                    {
                        subjectCatalog?.groups.length == 0 && (<Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h5" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                                Sin grupos
                            </Typography>
                        </Box>)
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { setOpenModal(false) }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
