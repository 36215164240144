import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

const groupsStudentsAdapter = createEntityAdapter({
    selectId: (groupStudent) => groupStudent.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id, 'en-US')
})

/**
 * Slice para los parciales
 */
export const GroupsStudentsSlice = createSlice({
    name: 'groupsStudents',
    initialState: groupsStudentsAdapter.getInitialState(),
    reducers: {
        /**
         * Si el alumno no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneGroupsStudents: (state, action) => {
            const entitiyItems = Object.values(state.entities)
            let newRecord = action.payload

            // SI EL REGISTRO ESTA REPETIDO LO ELIMINAMOS

            if (!entitiyItems.find(gsEntity => newRecord.group_id == gsEntity.group_id && newRecord.student_id == gsEntity.student_id)) {

                newRecord["id"] = uuidv4();

                groupsStudentsAdapter.addOne(state, newRecord)
            }
        },
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyGroupsStudents: (state, action) => {
            const entitiyItems = Object.values(state.entities)

            // ELIMINAMOS LOS REGISTROS REPETIDOS

            let newGroupsStudent = action.payload.filter(gs => {
                return !entitiyItems.find(gsEntity => gs.group_id == gsEntity.group_id && gs.student_id == gsEntity.student_id)
            })

            let items = newGroupsStudent.map(i => ({ ...i, id: uuidv4() }))

            groupsStudentsAdapter.addMany(state, items)
        },
        /**
         * Elimina GroupsStudents
         * 
         * @param {*} state 
         * @param {*} action 
         */
        removeOneGroupsStudents: groupsStudentsAdapter.removeOne
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return groupsStudentsAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setGroupsStudents, addManyGroupsStudents, removeOneGroupsStudents,
    selectSingleStudent, addOneGroupsStudents
} = GroupsStudentsSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = groupsStudentsAdapter.getSelectors((state) => state.entities.groupsStudents)

/**
 * Selector para recuperar todas las entidades 
 * 
 * @param any Estado completo de la app
 * 
 * @returns any
 */
export const selectEntitiesGroupsStudents = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar todas las configuraciones 
 * 
 * @param any Estado completo de la app
 *  
 * @returns any[]
 */
export const selectAllGroupsStudents = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para seleccionar una configuracion por ID
 * 
 * @param number Identificador de la configuracion
 * 
 * @returns any
 */
export const selectGroupStudentById = (id) => (state) => globalizedSelectors.selectById(state, id)



/**
 * Selector para recuperar todos los parciales de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectGroupsStudents = (state) => selectEntitiesGroupsStudents(state)


/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 */

export const selectGroupsStudentsByStudentId = (studentId) => {
    return (state) => {

        if (!studentId) {
            return []
        }

        let data = selectAllGroupsStudents(state).filter(i => i.student_id == studentId);

        return data
    }
}

export default GroupsStudentsSlice.reducer;
