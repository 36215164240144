import React, { useEffect, useState } from 'react';
import {
    TextField, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Snackbar, Autocomplete, FormControlLabel, Switch, 
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useAuth } from '../../../hooks';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { createStudent, selectStatusOperation, updateStudent } from '../../../store/slices/studentsUI/serverSlice';
import _ from 'lodash';
import GroupsSelector from './GroupsSelector';
import { DatePicker } from 'antd';
import moment, { locale, localeData } from 'moment';

/**
 * Modal para actualizar o agregar alumno
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const AddStudentModal = ({ open, setOpen, student = null }) => {

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    const dispatch = useDispatch()
    const optionGender = ['Niño', 'Niña']
    const optionSize = ['ch', 'md', 'gd']
    const optionModality = ['Presencial', 'En linea']

    /////////////// LOCAL STATE ////////////////////
    const [studentItem, setStudentItem] = useState({
        student_id: null,
        group_id: null,
        school_id: null,
        name: '',
        last_name: '',
        second_last_name: '',
        cellphone: '',
        folio: '',
        colony: '',
        inside_number: '',
        outside_number: '',
        street: '',
        zipcode: '',
        city_id: null,
        gender: null,
        date_birth: '',
        curp: '',
        height: '',
        weight: '',
        observation: '',
        email: '',
        is_indigenous: false,
        is_foreign: false,
        preschool_years: '',
        has_scholar_grants: false,
        uniform_size: '',
        is_immigrant: false,
        school_modality: '',
        in_repetition: false,
        in_wrong_degree: false,
        apc: false,
        situation: '',
        created_at: '',
        updated_at: ''
    })

    const [optGender, setGender] = useState(optionGender[0]);
    const [genderValue, setGenderValue] = useState('');

    const [optSize, setSize] = useState(optionSize[0]);
    const [sizeValue, setSizeValue] = useState('');

    const [optModality, setModality] = useState(optionModality[0]);
    const [modalityValue, setModalityValue] = useState('');

    const [targetKeys, setTargetKeys] = useState([]);
    const [primaryGroup, setPrimaryGroup] = useState('');

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)

    ///Establece la fecha limite de nacimiento a hoy hace 3 años
    let datebirthLimit = moment().subtract(3, 'y');

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setStudentItem({ ...studentItem, [name]: value });
    }

    /**
     * Efecto al abrir el modal
     */
    useEffect(() => {
        if (!open) {
            setStudentItem({
                student_id: null,
                group_id: null,
                school_id: null,
                name: '',
                last_name: '',
                second_last_name: '',
                cellphone: '',
                folio: '',
                colony: '',
                inside_number: '',
                outside_number: '',
                street: '',
                zipcode: '',
                city_id: null,
                gender: null,
                date_birth: '',
                curp: '',
                height: '',
                weight: '',
                observation: '',
                email: '',
                is_indigenous: null,
                is_foreign: null,
                preschool_years: '',
                has_scholar_grants: null,
                uniform_size: '',
                is_immigrant: null,
                school_modality: '',
                in_repetition: false,
                in_wrong_degree: false,
                apc: null,
                situation: '',
                created_at: '',
                updated_at: ''
            })
            /*setLevel(-1)
            setTargetKeys([]);
            setSelectedKeys([]);
            setPrimaryGroup(-1)*/
        } else {
            setStudentItem({ ...studentItem, ...student })
        }
    }, [open])


    /**
     * Guardar o actualziar alumno
     */
    const saveStudent = () => {
        if (studentItem.student_id == null) {
            dispatch(createStudent({
                student: studentItem,
                primaryGroupId: primaryGroup,
                specialgroups: targetKeys,
                schoolId
            }))
                .unwrap()
                .then(({ message }) => {
                    showFeedback("Alumno agregado")
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000)
                }).catch(({ feedback }) => {
                    showFeedback(feedback.title)
                })
        } else {
            dispatch(updateStudent({
                student: studentItem,
            }))
                .unwrap()
                .then(response => {
                    showFeedback("Alumno actualizado")
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000)
                }).catch(({ feedback }) => {
                    showFeedback(feedback.title)
                })
        }
    }

    /**
     * Guardamos los grupos seleccionados
     * 
     * @param {*} param0 
     */
    const onChangeGroups = ({ primaryGroup, specialgroups }) => {
        setTargetKeys(specialgroups)
        setPrimaryGroup(primaryGroup)
    }


    ////////////////////////// VALDIACIONES /////////////////////

    ///Fecha de nacimiento validación
    const disabledDateBirth = (date) => {
        let endDate = moment(datebirthLimit)
        if (date > endDate) {
            return true
        }
    }

    const nameValid = () => {
        return {
            invalid: studentItem.name == '' || studentItem.name.length > 50,
            message: "Campo requerido, Máximo 50 caracteres"
        }
    }

    const lastNameValid = () => {
        return {
            invalid: studentItem.last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const secondLastNameValid = () => {
        return {
            invalid: false, //userData.second_last_name == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const folioValid = () => {
        return {
            invalid: studentItem.folio == '',
            message: "Campo requerido o incorrecto"
        }
    }

    /**
     * Validacion para el numero de telefono
     * 
     * @returns 
     */
    const cellphoneValid = () => {
        const isPhone = /^[0-9]{10}$/.test(studentItem.cellphone);

        return {
            invalid: !isPhone,
            message: "Campo requerido o incorrecto"
        }
    }

    const streetValid = () => {
        return {
            invalid: studentItem.street == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const colonyValid = () => {
        return {
            invalid: studentItem.colony == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const outsideNumberValid = () => {
        return {
            invalid: studentItem.outside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const insideNumberValid = () => {
        return {
            invalid: false, //studentItem.inside_number == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const zipcodeValid = () => {
        return {
            invalid: studentItem.zipcode == '',
            message: "Campo requerido o incorrecto"
        }
    }

    const groupsValid = () => {
        return {
            invalid: false,
            message: "Al menos se tiene que seleccionar un grupo"
        }
    }
    const genderValid = () => {
        return {
            invalid: studentItem.gender == '' | null,
            message: "Campo requerido o incorrecto"
        }
    }
    const curpValid = () => {
        const isCurp = studentItem.curp?.length == 18

        return {
            invalid: !isCurp,
            message: "Campo requerido o incorrecto"
        }
    }

    const formInvalid = () => {
        return (
            nameValid().invalid ||
            lastNameValid().invalid ||
            secondLastNameValid().invalid ||
            folioValid().invalid ||
            cellphoneValid().invalid ||
            streetValid().invalid ||
            colonyValid().invalid ||
            outsideNumberValid().invalid ||
            insideNumberValid().invalid ||
            zipcodeValid().invalid ||
            genderValid().invalid
        )
    }


    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    /////////////// UI ///////////////////
    /**
     * Componente del titulo
     * 
     * @param {*} props 
     * 
     * @returns 
     */
    function Title(props) {
        if (student) {
            return "Actualizar alumno"
        }

        return "Agregar alumno"
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown={statusOperation == 'pending'}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex', color: fontColor.value }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        <Title />
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" style={{ width: '100%' }} >
                            Datos generales
                        </Typography>
                    </Grid>
                    <Grid container item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="name"
                            name="name"
                            label="Nombre"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            value={studentItem.name}
                            onChange={onInputChange}
                            error={nameValid().invalid}
                            helperText={nameValid().invalid ? nameValid().message : false}
                        />
                    </Grid>
                    <Grid container item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="last_name"
                            name="last_name"
                            label="Apellido Paterno"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            value={studentItem.last_name}
                            onChange={onInputChange}
                            error={lastNameValid().invalid}
                            helperText={lastNameValid().invalid ? lastNameValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="second_last_name"
                            name="second_last_name"
                            label="Apellido Materno"
                            type="text"
                            size="small"
                            fullWidth
                            variant="standard"
                            inputProps={{ maxLength: 50 }}
                            value={studentItem.second_last_name}
                            onChange={onInputChange}
                            error={secondLastNameValid().invalid}
                            helperText={secondLastNameValid().invalid ? secondLastNameValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="folio"
                            name="folio"
                            label="Matrícula"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 25 }}
                            value={studentItem.folio}
                            onChange={onInputChange}
                            error={folioValid().invalid}
                            helperText={folioValid().invalid ? folioValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="cellphone"
                            name="cellphone"
                            label="Teléfono"
                            type="tel"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            value={studentItem.cellphone}
                            onChange={onInputChange}
                            error={cellphoneValid().invalid}
                            helperText={cellphoneValid().invalid ? cellphoneValid().message : false}
                        />
                    </Grid>

                    {/*<Grid item xl={4} md={4} sm={12} xs={12}>
                        <Autocomplete
                            id="gender"
                            name="gender"
                            value={optGender}
                            onChange={(event, newValue) => {
                                setGender(newValue);
                            }}
                            inputValue={genderValue}
                            onInputChange={(event, newInputValue) => {
                                setGenderValue(newInputValue)
                                if (newInputValue != null) {
                                    (newInputValue == 'Niño') ? studentItem.gender = 1 : studentItem.gender = 2;
                                }
                                else{
                                    studentItem.gender = 1
                                }
                            }}
                            options={optionGender}
                            error={genderValid().invalid}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Género"
                                    size="small"
                                    variant="standard"
                                />}
                        />
                            </Grid>

                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <DatePicker
                            id="date_birth"
                            name="date_birth"
                            label="Fecha de nacimiento"
                            locale={locale}
                            value={moment(datebirthLimit, 'YYYY,MM,DD')}
                            disabledDate={disabledDateBirth}
                            onChange={(event, newInputValue) => {
                                studentItem.date_birth = newInputValue
                            }}
                            size="large"
                            style={{ width: '100%' }}
                            renderInput={(params) =>
                                <TextField {...params}
                                    helperText={'Fecha de naciemiento'}
                                />}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="curp"
                            name="curp"
                            label="CURP"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 18 }}
                            value={studentItem.curp}
                            onChange={onInputChange}
                            error={curpValid().invalid}
                            helperText={curpValid().invalid ? curpValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="height"
                            name="height"
                            label="Estatura"
                            type="number"
                            size="small"
                            variant="standard"
                            fullWidth
                            value={studentItem.height}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="weight"
                            name="weight"
                            label="Peso"
                            type="number"
                            size="small"
                            variant="standard"
                            fullWidth
                            value={studentItem.weight}
                            onChange={onInputChange}
                        />
                    </Grid>
                    
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            value={studentItem.email}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="preschool_years"
                            name="preschool_years"
                            label="Años de preescolar"
                            type="number"
                            size="small"
                            variant="standard"
                            fullWidth
                            value={studentItem.preschool_years}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Autocomplete
                            id="size"
                            name="size"
                            value={optSize}
                            onChange={(event, newValue) => {
                                setSize(newValue);
                            }}
                            inputValue={sizeValue}
                            onInputChange={(event, newInputValue) => {
                                setSizeValue(newInputValue);
                                if (newInputValue != null) {
                                    studentItem.size=newInputValue
                                }
                                else{
                                    studentItem.size='md'
                                }

                            }}
                            options={optionSize}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Talla"
                                    size="small"
                                    variant="standard"
                                />}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Autocomplete
                            id="school_modality"
                            name="school_modality"
                            value={optModality}
                            onChange={(event, newValue) => {
                                setModality(newValue)
                                studentItem.school_modality = newValue;
                            }}
                            inputValue={modalityValue}
                            onInputChange={(event, newInputValue) => {
                                setModalityValue(newInputValue);
                                if (newInputValue != null) {
                                    (newInputValue=='Presencial')?studentItem.school_modality=1:studentItem.school_modality=2;
                                }
                                else{
                                    studentItem.school_modality=1
                                }
                            }}
                            options={optionModality}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Modalidad"
                                    size="small"
                                    variant="standard"
                                />}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="situation"
                            name="situation"
                            label="Situación"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            value={studentItem.situation}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.is_indigenous} onChange={() => {
                                let sw = studentItem.is_indigenous
                                studentItem.is_indigenous = !sw
                            }} />
                        } label="Es indigena" />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.has_scholar_grants} onChange={() => {
                                let sw = studentItem.has_scholar_grants
                                studentItem.has_scholar_grants = !sw
                            }} />
                        } label="Posee una beca" />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.is_foreign} onChange={() => {
                                let sw = studentItem.is_foreign
                                studentItem.is_foreign = !sw
                            }} />
                        } label="Es foraneo" />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.is_immigrant} onChange={() => {
                                let sw = studentItem.is_immigrant
                                studentItem.is_immigrant = !sw
                            }} />
                        } label="Es inmigrante" />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.in_repetition} onChange={() => {
                                let sw = studentItem.in_repetition
                                studentItem.in_repetition = !sw
                            }} />
                        } label="Está repitiendo año" />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <FormControlLabel control={
                            <Switch defaultChecked={studentItem.in_wrong_degree} onChange={() => {
                                let sw = studentItem.in_wrong_degree
                                studentItem.in_wrong_degree = !sw
                            }} />
                        } label="Cursa un grado que no le corresponde" />
                    </Grid>
                    */}
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" style={{ width: '100%' }} >
                            Domicilio
                        </Typography>
                    </Grid>

                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="street"
                            name="street"
                            label="Calle"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            value={studentItem.street}
                            onChange={onInputChange}
                            error={streetValid().invalid}
                            helperText={streetValid().invalid ? streetValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="colony"
                            name="colony"
                            label="Colonia"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            value={studentItem.colony}
                            onChange={onInputChange}
                            error={colonyValid().invalid}
                            helperText={colonyValid().invalid ? colonyValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="outside_number"
                            name="outside_number"
                            label="Número Exterior"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 25 }}
                            value={studentItem.outside_number}
                            onChange={onInputChange}
                            error={outsideNumberValid().invalid}
                            helperText={outsideNumberValid().invalid ? outsideNumberValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="inside_number"
                            name="inside_number"
                            label="Número Interior"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            value={studentItem.inside_number}
                            onChange={onInputChange}
                            error={insideNumberValid().invalid}
                            helperText={insideNumberValid().invalid ? insideNumberValid().message : false}
                        />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                        <TextField
                            id="zipcode"
                            name="zipcode"
                            label="Código Postal"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 25 }}
                            value={studentItem.zipcode}
                            onChange={onInputChange}
                            error={zipcodeValid().invalid}
                            helperText={zipcodeValid().invalid ? zipcodeValid().message : false}
                        />
                    </Grid>
                    {/*<Grid item xl={12} md={12} sm={12} xs={12}>
                        <TextField
                            id="observation"
                            name="observation"
                            label="Observaciones"
                            type="text"
                            size="small"
                            variant="standard"
                            fullWidth
                            multiline
                            value={studentItem.observation}
                            onChange={onInputChange}
                        />
                    </Grid>
                    */}
                </Grid>
                {
                    !student && (<GroupsSelector showFeedback={showFeedback} onChange={onChangeGroups} />)
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={()=>{
                        saveStudent()
                    }}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Guardar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        
                        setOpen(false)
                    }}
                    disabled={statusOperation == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    )
}

export default AddStudentModal
