import React from 'react'
import StatBiColor from "../StatsBiColor"
import { useSelector } from "react-redux";
import { getBestAssistsSubjectCard} from '../../../store/slices/background'
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import { blue, red, green } from '@mui/material/colors';

const BestAssistsSubjectIndicator = (props) => {

    const bestAssistsSubject = useSelector(getBestAssistsSubjectCard)

    return (
      <StatBiColor
        icon={faChalkboardTeacher}
        iconColor={blue[500]}
        value={bestAssistsSubject.total_presence}
        title={`${bestAssistsSubject.group_name} ${bestAssistsSubject.title} ${bestAssistsSubject.level}
         ${bestAssistsSubject.professor_name}`}
        label={"Mejor asistencia"}
        mainColor={"#33ccff"}
        secundaryColor={"#0099cc"}
        valueOptions={{variant: "h5", component: "span"}}
      >  
      </StatBiColor>
    )
  }

export default BestAssistsSubjectIndicator