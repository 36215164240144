import { useAuth } from "../hooks";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();


    const render = () => ({ location }) => {
        if (!auth.getUser()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            )
        } else {
            return children
        }
    }

    return (<Route {...rest} render={render()} />)
}

export default PrivateRoute