import React, { useState, useEffect } from 'react';
import {
    Typography, Box, Divider, Grid, FormControl, InputLabel, 
    Select, MenuItem, LinearProgress } from '@mui/material';
import { getTurnLevel } from '../../../libs/utils';
import { useAuth } from '../../../hooks';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Transfer } from 'antd';
import * as constants from "../../../service/const";
import { findGroups, selectStatusOperation } from '../../../store/slices/studentsUI/serverSlice';
import _ from 'lodash';

/**
 * Componente para seleccionar grupo
 * 
 * @returns 
 */
const GroupsSelector = ({showFeedback, onChange}) => {

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id


    /////////// LOCAL STATE /////////////////////
    const [localstatusOperation, setLocalstatusOperation] = useState(false)
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    const [level, setLevel] = useState('');
    const [primaryGroup, setPrimaryGroup] = useState('');
    let [groups, setGroups] = useState([])


    let specialgroups = _.filter(groups, {special: 1})
    let primarygroups = _.filter(groups, {special: 0})

    //////////// SHARED STATE /////////////
    const statusOperation = useSelector(selectStatusOperation)

    //////////// FUNCTIONS //////////////////

    useEffect(() => {
        if ((statusOperation == 'rejected' || statusOperation == 'fulfilled') && localstatusOperation) {
            setLocalstatusOperation(false) 
        }
    }, [statusOperation])

    /**
     * Funcion para intercambiar elementos de la lista
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
     const onChangeTargetKeys = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);

        onChange({
            primaryGroup,
            specialgroups: nextTargetKeys
        })
    }

    /**
     * Funcion para marcar grupos como seleccionados en la lista
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    /**
     * Cambia el tipo de nivel
     * 
     * @param {*} event 
     */
    const handleChange = (event) => {
        setTargetKeys([]);
        setSelectedKeys([]);
        setPrimaryGroup('')

        setLevel(event.target.value)

        setLocalstatusOperation(true) 

        dispatch(findGroups({
            level: event.target.value,
            schoolId
        }))
        .unwrap()
        .then(({groups}) => {
            setGroups(groups)
        }).catch(({feedback}) => {
            showFeedback(feedback.title)
        })
    }

    /**
     * Cambia el tipo de nivel
     * 
     * @param {*} event 
     */
    const onSelectPrimaryGroup = (event) => {
        setPrimaryGroup(event.target.value)

        onChange({
            primaryGroup: event.target.value,
            specialgroups: targetKeys
        })
    }
   
    return (
        <>
            <Divider sx={{mt: 2, mb: 2}}>
                <Typography variant="h6" component="div" style={{textAlign:'center'}}>
                    Seleccionar grupos
                </Typography>
                {
                    localstatusOperation && (
                        <Box sx={{ width: '100%' }}>
                           <LinearProgress />
                        </Box>
                    )
                }
            </Divider>

            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Nivel escolar</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={level}
                                label="Nivel"
                                onChange={handleChange}
                            >
                                {
                                    constants.levelsNames.map(i => {
                                        return (<MenuItem key={i.key} value={i.key}>{i.title}</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Grupo principal</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={primaryGroup}
                                label="Nivel"
                                onChange={onSelectPrimaryGroup}
                            >
                                {
                                    primarygroups.map(i => {
                                        return (
                                            <MenuItem key={i.group_id} value={i.group_id}>
                                                {`${i.grade}° ${i.group} ${getTurnLevel(i.turn)}`}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                <FormControl
                    variant="standard"
                    sx={{ width:'100%', mt: 2}}
                >         
                    <Box
                        sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
                    >
                        <Typography variant="subtitle1" component="div" style={{flexGrow:1}}>
                            Grupos especiales
                        </Typography>

                        <Transfer
                            style={{marginTop:'20px'}}
                            listStyle={{
                                width: 300,
                                height: 300,
                            }}
                            locale={{
                                notFoundContent: ["Sin grupos", "Sin grupos"],
                                itemUnit: "Grupos",
                                itemsUnit: "Grupos",
                            }}
                            dataSource={specialgroups.map(i => {
                                return {...i, key: i.group_id}
                            })}
                            titles={['Disponibles', 'Asignados']}
                            targetKeys={targetKeys}
                            selectedKeys={selectedKeys}
                            onChange={onChangeTargetKeys}
                            onSelectChange={onSelectChange}
                            render={item => `${item.grade}° ${item.group} ${getTurnLevel(item.turn)}`}
                            showSearch
                        />
                    </Box>
                </FormControl>
            </Box>
        </>
    )
}

export default GroupsSelector
