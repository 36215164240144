import { Box, Grid, TextField, CardHeader, CardContent, Card, Stack, Paper, styled } from "@mui/material";
import * as  _ from "lodash";
import { useHistory } from "react-router-dom";
import React from 'react';
import { esES, DataGrid } from "@mui/x-data-grid";
import { DetallesSupervisorToolBar } from "../Supervisiones/components";

/**
 * 
 * Pagina Detalles de los Supervisores
 * 
 * @returns
 */

const Detalles = () => {
  const EncuestaArray = [];
  const EncuestaList = EncuestaArray.map((E_item) => <p>{E_item}</p>)
  console.log(EncuestaList)

///////////////// ESTILO EN STACKS ITEMS /////////////////

 const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: theme.palette.text.secondary,
 }));

 //   /**
 //    * Supervisor seleccionado
 //    * 
 //    * @param {*} supervisor 
 //    */
 //   const onSelectStudent = (supervisor) => {
 //     dispatch(selectStudentItem({
 //         itemGroupId: id,
 //         itemSupervisorId: student.clave
 //     }))
//
 //     history.push({ pathname: `/Supervisiones/${clave}}` })
 // }

  ///////////////// TABLEFILLED ////////////////////

  const details_columns = [
    {
    details_id: 2,
    capables: "40",
    type: "Maestro",
    total_teachers: "30",
    app_used: "330",
    installed_app: "340",
    total_users: "20",
    users_capables: "22",
  },
  {
    details_id: 4,
    capables: "40",
    type: "Asesor",
    total_teachers: "30",
    app_used: "330",
    installed_app: "330",
    total_users: "20",
    users_capables: "22",
  },
  {
    details_id: 6,
    capables: "40",
    type: "Director",
    total_teachers: "30",
    app_used: "330",
    installed_app: 'app_used',
    total_users: "20",
    users_capables: "22",
  }
]

/**
* TABLE HEADER
*/
  const columns = [
    {
        field: 'id_supervisor',
        headerName: 'ID',
        type: 'number',
        flex: 0.4,
        minWidth: 80,
        editable: false,
        headerAlign: 'center',
        align: 'center',
    },  
    {
          field: 'type',
          headerName: 'Tipo',
          type: 'number',
          flex: 0.4,
          minWidth: 80,
          editable: false,
          headerAlign: 'center',
          align: 'center',
      },
      {
          field: 'capables',
          headerName: 'Capacitados',
          flex: 0.4,
          minWidth: 80,
          editable: false,
          headerAlign: 'center',
          align: 'center',
      },
      {
        field: 'totals',
        headerName: 'Totales',
        flex: 0.4,
        minWidth: 80,
        editable: false,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'calculo',
        headerName: 'Calculo en porcentajes',
        flex: 0.4,
        minWidth: 80,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => 
        {
            console.log(params);
            return "Datos de Supervisor"
        }
      },
      {
        field: 'school_details',
        headerName: 'Detalles de la escuela',
        flex: 0.4,
        minWidth: 80,
        editable: false,
        headerAlign: 'center',
        align: 'center',
      },
    ];  
  return (
      <Box sx={{
          flexGrow: 1,
          paddingTop: {
              xs: 1,
              sm: 2,
              md: 2,
          },
          paddingLeft: {
              xs: 1,
              sm: 2,
              md: 5,
          },
          paddingRight: {
              xs: 1,
              sm: 2,
              md: 5,
          },
          paddingBottom: {
              xs: 1,
              sm: 2,
              md: 5,
          },
      }}>
       <Card sx={{ mt: 5, }}>                                    
       <DataGrid
         localeText={esES.components.MuiDataGrid.defaultProps.localeText}
         getRowId={({ details_id }) => details_id}
         rows={details_columns}
         columns={columns}
         pageSize={10}
         disableSelectionOnClick
         autoHeight
         components={{ Toolbar: DetallesSupervisorToolBar }}
         disableDensitySelector
        />
          </Card>
          <Stack 
          sx={{ mt: 5, }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          spacing={0}
          >
              <Item>
              <CardHeader title="LISTA DE VERIFICACIÓN DE VISITA DEL SUPERVISOR C.U.R.A"/>
              <h5>CONTACTO DEL ENLACE ESCOLAR</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
              <h5>SECTOR C.U.R.A</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>MOTIVO DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
              <h5>NOMBRE DEL SUPERVISOR</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>INSTITUCIÓN</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
              <h5>FECHA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>HORA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
              </Item>
              <Item>
              <CardHeader variant="h1" title="ENCUESTA DE SATISFACCIÓN C.U.R.A"/>
              <h5>¿QUÉ PERFIL UTILIZAS PARA INGRESAR A LA APLICACIÓN?</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5></h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>HORA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>HORA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>HORA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
                      <h5>HORA DE LA VISITA</h5>
              <TextField
                          id=""
                          name=""
                          label="RESPUESTA"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled
                      />
              </Item>      
          </Stack>
          <Card sx={{ mt: 5, }}>                                    
          <CardHeader title="Detalles de la Encuesta"/>
          <CardContent>
                  <Grid item xl={4} sm={4} md={4} xs={12} style={{ padding: 10 }}>
                      <TextField
                          id=""
                          name=""
                          label="Encuestas"
                          type="text"
                          size="small"
                          variant="standard"
                          fullWidth={true}
                          disabled                           
                      />
                  </Grid>                   
          </CardContent>
          </Card> 
    </Box>   
  );
};

export default Detalles;
