import React, { useEffect, useState } from 'react'

import { Card, CardHeader, Pagination } from '@mui/material'
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import * as Plugin from '../../libs/chartist-bar-labels.js'

/**
 * Card que contiene un chart con la posibilidad
 * de ser paginado
 * 
 * @param string title Titulo de la card
 * @param integer title Elementos por pagina
 * @param any[] items Lista de elementos
 * @param any label Donde se recuperar el label
 * @param any value Donde se recuperar el valor
 * 
 * @returns 
 */
const BarChartCard = ({
    title,
    perPage = 7,
    items = [],
    label = 'label',
    value = 'value',
    colorBar = '#ffac20'
}) => {

    //////////// LOCAL STATE ////////////////

    const [page, setPage] = useState(1)
    const [data, setData] = useState({
        labels: [],
        series: [[]]
    })

    ////////// ACTIONS //////////////

    const onchangePage = (event, value) => {
        setPage(value)
    }

    /**
     * Opciones del chart
     */
    let options = {
        seriesBarDistance: 100,
        reverseData: true,
        horizontalBars: true,
        stackBars: true,
        chartPadding: {
            top: 10,
            right: 40,
            bottom: 0,
            left: 0
        },
        axisX: {
            onlyInteger: true
        },
        axisY: {
            offset: 110,
            showGrid: false,
        },
        scaleMinSpace: 100,
        plugins: [
            Chartist.plugins.ctBarLabels({
                labelClass: 'ct-bar-label-black',
                position: {
                    x: function (data) {
                        return data.x1 + 130
                    }
                },
                labelOffset: {
                    y: 5
                },
                labelInterpolationFnc: function (text) {
                    if (text == undefined) {
                        return 0
                    }
                    return text
                }
            })
        ]
    };

    /**
     * Listener para el chart
     */
    const listeners = {
        "draw": (data) => {
            if (data.type === 'bar') {

                const barWidth = 14

                data.element.attr({
                    style: `stroke-width: ${barWidth}px; stroke: ${colorBar}`
                });
            }
        }
    }


    useEffect(() => {
        items = items.slice((page - 1) * perPage, perPage * page)

        let serieOne = []
        let labels = []

        for (const item of items) {

            let labelText = ''

            if (typeof label === 'function') {
                labelText = label(item)
            } else {
                labelText = item[label]
            }

            labels.push(labelText)

            let valueText = '0'

            if (typeof value === 'function') {
                valueText = value(item)
            } else {
                valueText = item[value]
            }


            serieOne.push(valueText)
        }

        let newData = {
            series: [serieOne],
            labels: labels
        }
        setData(newData)

    }, [page])

    return (
        <Card sx={{ width: 265 }} variant="outlined">
            <CardHeader
                title={title}
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 0
                }}
            />
            <ChartistGraph
                className={'ct-chart ct-square'}
                data={data}
                options={options}
                type="Bar"
                listener={listeners}
            />
            {
                (items.length > perPage) && (
                    <Pagination
                        count={Math.ceil(items.length / perPage)}
                        page={page}
                        onChange={onchangePage}
                        size="small"
                        sx={{
                            mt: 1, mb: 1,
                            '& .MuiPagination-ul': {
                                justifyContent: "center",
                            },
                        }}
                    />
                )
            }
        </Card>
    )
}


export default BarChartCard