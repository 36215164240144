import React, { useState, useEffect } from 'react'
import { Box, Stack, Tab, Snackbar, Tabs, Grid, Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllResources, getLoading, getCycleSelected, getCurrentTab,
    setCurrentTab, invalidate
} from '../../store/slices/background'

import DirectorHome from "./GeneralView"
import GroupView from "./Group"
import GroupDetails from "./Group/GroupDetails"
import SubjectsView from "./Subjects"
import SubjectsDetails from "./Subjects/SubjectsDetails"
import ProfessorsView from "./Professors"
import ProfessorsDetails from "./Professors/ProfessorsDetails"
import StudentsView from "./Students"
import StudentsDetails from "./Students/StudentsDetails"
import CyclesView from "./CyclesView"

import { CyclesSelect } from '../../components/Director';
import Feedback from "../../service/Feedback"
import { useAuth } from '../../hooks';
import SyncIcon from '@mui/icons-material/Sync';


/**
 * Pagina para el director
 * 
 * @returns 
 */
const DirectorContainer = () => {

    function usePageViews() {
        let location = useLocation();

        let currentTab = location.pathname.replace("/", '').split("/")[1]

        let tabRoute = (currentTab == undefined) ? "inicio" : currentTab

        useEffect(() => {
            dispatch(setCurrentTab(`/${tabRoute}`))
        }, [location]);
    }

    usePageViews()

    const dispatch = useDispatch()
    let match = useRouteMatch()
    const Auth = useAuth()
    let user = Auth.getUser()

    /////////////// SHARED STATE //////////////

    const schoolCycle = useSelector(getCycleSelected)
    const serverState = useSelector(getLoading)
    const currentTab = useSelector(getCurrentTab)

    ////////////////// LOCAL STATE  ///////////////////

    const [feedback] = useState(() => new Feedback());

    ///////////////////////// ACTIONS ////////////////////////////////

    useEffect(() => {
        dispatch(fetchAllResources({
            school_id: user.school_id,
            cycle: schoolCycle
        }))
    }, [])

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        fetchAllInformation()
    }

    /**
     * Selecciona una nueva Tab
     * 
     * @param {*} event 
     * @param {*} newValue 
     */
    const onChangeTab = (event, newValue) => {
        dispatch(setCurrentTab(newValue))
    };

    /**
     * Se cambio de ciclo
     * 
     * @param {*} cycle 
     */
    const onChangeCycle = (cycle) => {
        dispatch(invalidate())
        fetchAllInformation(cycle)
    }

    /**
     * Funcion para recuperar todo los datos del servidor
     */
    async function fetchAllInformation(cycle = null) {
        let dataRequest = {
            school_id: user.school_id,
            cycle: (cycle) ? cycle : schoolCycle
        }

        dispatch(fetchAllResources(dataRequest))
            .unwrap()
            .then(response => {

            }).catch(err => {
                let message = feedback.getMessage(err)
                showFeedback(message.message)
            })
    }

    /**
     * Componente para las tabs
     * 
     * @param {*} props 
     * 
     * @returns 
     */
    function LinkTab(props) {
        return (
            <Tab
                component={Link}
                {...props}
            />
        );
    }

    ///////////////////// DATOS PARA EL FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    // <LinkTab label="Ciclos" value="/ciclos" to={`${match.path}/ciclos`}/>

    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{ mb: 4 }}
            >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={currentTab}
                            onChange={onChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ display: { xs: "none", sm: "flex" } }}
                        >
                            <LinkTab label="Inicio" value="/inicio" to={`${match.path}/inicio`} />
                            <LinkTab label="Grupos" value="/grupos" to={`${match.path}/grupos`} />
                            <LinkTab label="Materias" value="/materias" to={`${match.path}/materias`} />
                            <LinkTab label="Profesor" value="/profesores" to={`${match.path}/profesores`} />
                            <LinkTab label="Alumnos" value="/alumnos" to={`${match.path}/alumnos`} />
                        </Tabs>

                        <Tabs
                            value={currentTab}
                            onChange={onChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            orientation="vertical"
                            sx={{ display: { xs: "flex", sm: "none" } }}
                        >
                            <LinkTab label="Inicio" value="/inicio" to={`${match.path}/inicio`} />
                            <LinkTab label="Grupos" value="/grupos" to={`${match.path}/grupos`} />
                            <LinkTab label="Materias" value="/materias" to={`${match.path}/materias`} />
                            <LinkTab label="Profesor" value="/profesores" to={`${match.path}/profesores`} />
                            <LinkTab label="Alumnos" value="/alumnos" to={`${match.path}/alumnos`} />
                        </Tabs>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Stack
                        spacing={2}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <CyclesSelect
                            disabled={serverState == "pending"}
                            onChange={onChangeCycle}
                        />
                        <Tooltip title="Sincronizar información">
                            <LoadingButton
                                onClick={invalidateUI}
                                startIcon={<SyncIcon />}
                                loading={serverState == "pending"}
                                loadingPosition="start"
                                variant="contained"
                                size="small"
                            >
                                Sincronizar
                            </LoadingButton>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>

            <Box>
                <Switch>
                    <Route path={`${match.path}/inicio`}>
                        <DirectorHome></DirectorHome>
                    </Route>
                    <Route exact path={`${match.path}/grupos`}>
                        <GroupView></GroupView>
                    </Route>
                    <Route path={`${match.path}/grupos/:id`}>
                        <GroupDetails></GroupDetails>
                    </Route>
                    <Route exact path={`${match.path}/materias`}>
                        <SubjectsView></SubjectsView>
                    </Route>
                    <Route path={`${match.path}/materias/:id`}>
                        <SubjectsDetails></SubjectsDetails>
                    </Route>
                    <Route exact path={`${match.path}/profesores`}>
                        <ProfessorsView></ProfessorsView>
                    </Route>
                    <Route path={`${match.path}/profesores/:id`}>
                        <ProfessorsDetails></ProfessorsDetails>
                    </Route>
                    <Route exact path={`${match.path}/alumnos`}>
                        <StudentsView></StudentsView>
                    </Route>
                    <Route path={`${match.path}/alumnos/:id`}>
                        <StudentsDetails></StudentsDetails>
                    </Route>
                    <Route path={`${match.path}/ciclos`}>
                        <CyclesView></CyclesView>
                    </Route>
                    <Route path={`${match.path}/`}>
                        <DirectorHome></DirectorHome>
                    </Route>
                </Switch>
            </Box>

            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Box>
    )
}

export default DirectorContainer
