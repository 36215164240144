import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Services from "../service/Connection";
import { selectSettingBySchoolId } from "../store/slices/entities/settings";
import { useAuth } from "../hooks";
import { useSelector } from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import Feedback from "../service/Feedback";

/**
 * Componente para solicituar permisos
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const RequestPermission = ({ open, title, content, OnPermissionGrantedResolved }) => {

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    let FeedbackService = new Feedback()

    ////////////////// SHARED STATE ///////////////
    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    ////////////////// LOCAL STATE ////////////////
    const [password, setPassword] = useState('')
    const [statusOperation, setStatusOperation] = useState('idle')

    /////////////////// ACTIONS //////////////////////

    useEffect(() => {
        if (!open) {
            setPassword('')
        }
    }, [open])

    /**
     * Escucha eventos del teclado en input de contraseña
     * 
     * @param {*} event 
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onCheckPassword()
        }
    }

    /**
     * Actualizacion de los inputs de formulario de nombre de usuario
     * 
     * @param {*} event 
     */
    const onInputChange = (event) => {
        const value = event.target.value;
        //const name = event.target.name;

        setPassword(value);
    }

    /**
     * Solicitu cancelada por el usuario
     */
    const handleClose = () => {
        OnPermissionGrantedResolved('user-cancel')
    }

    /**
     * Verifica que la contrasela enviada sea igual que en el backend
     */
    const onCheckPassword = () => {
        setStatusOperation('pending')

        Services.grantPermission({ password }).then(i => {
            setStatusOperation('fulfilled')
            OnPermissionGrantedResolved('allow')
        }).catch(err => {
            setStatusOperation('rejected')
            let feedbackError = FeedbackService.getMessage(err)
            showFeedback(feedbackError.title)
        })
    }

    //////////////////////// VALIDATIONS ////////////////////

    const passwordValid = () => {
        return {
            invalid: (password == ''),
            message: "Campo requerido"
        }
    }


    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <Typography variant="h6" component="div">
                    {title}
                </Typography>

            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
                    {content}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Contraseña"
                    fullWidth
                    variant="standard"
                    password
                    type="password"
                    value={password}
                    onChange={onInputChange}
                    error={passwordValid().invalid}
                    helperText={passwordValid().invalid ? passwordValid().message : false}
                    onKeyDown={handleKeyDown}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={onCheckPassword}
                    loading={statusOperation == 'pending'}
                    loadingPosition="start"
                    startIcon={<LockIcon />}
                    variant="contained"
                    disabled={passwordValid().invalid}
                >
                    Solicitar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    disabled={statusOperation == 'pending'}
                >
                    Cancelar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={3000}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>
    )
}

export default RequestPermission