import React, { useEffect, useState } from "react";
import { DataGrid, esES, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import {
    Button,
    Card,
    CardContent,
    CardActions,
    Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../hooks";
import { invalidate, loadUI, selectStatusServer } from "../../store/slices/studentsUI/serverSlice";
import { selectAllStudentsItems } from "../../store/slices/studentsUI/ItemsSlice";
import { Error, SkeletonGrid } from "../../components/Feedback";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import { selectStudentItem } from "../../store/slices/studentsUI/uiSlice";
import StudentGroupsModal from "./components/StudentGroupsModal";
import StudentSubjectsModal from "./components/StudentSubjectsModal";
import StudentParentsModal from "./components/StudentParentsModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardUser, faPeopleRoof, faUserCheck, faUsersViewfinder, faUsersRays } from '@fortawesome/free-solid-svg-icons';
import AddStudentModal from "./components/AddStudentModal";
import DeleteStudentModal from "../../components/Modal/DeleteStudentModal";
import AddParentModal from "./components/AddParentModal";
import UpdateParentModal from "./components/UpdateParentModal";
import DeleteStudentFromGroupModal from "../../components/Modal/DeleteStudentFromGroupModal";
import SkeletonPage from "./components/SkeletonPage";
import SyncIcon from '@mui/icons-material/Sync';
import AssignGroupsStudentsModal from "./components/AssignGroupsStudentsModal";
import ChangeSpecialGroupsModal from "./components/ChangeSpecialGroupsModal";
import useFeedback from "../../hooks/useFeedback";
import AddSpecialGroupsModal from "./components/AddSpecialGroupsModal";
import ActivateStudentModal from "./components/ActivateStudentModal";

/**
 * Pagina encargada de los alumnos
 * 
 * @returns 
 */
const Alumnos = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const Auth = useAuth()
    let user = Auth.getUser()
    const feedbackApp = useFeedback()

    //////////////// LOCAL STATE /////////////////////

    /**
     * Estados para modals
     */
    const [openSubjectsModal, setOpenSubjectsModal] = useState(false);
    const [openaddStudentModal, setOpenaddStudentModal] = useState(false);
    const [openRegistaerParentModal, setOpenRegistaerParentModal] = useState(false);
    const [openAssignGroupsStudentsModal, setOpenAssignGroupsStudentsModal] = useState(false);
    const [openChangeSpecialGroupsModal, setOpenChangeSpecialGroupsModal] = useState(false);
    const [openAddSpecialGroupsModal, setOpenAddSpecialGroupsModal] = useState(false);
    const [openActivateModal, setOpenActivateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openParentsModal, setOpenParentsModal] = useState(false);
    const [openUpdateParentModal, setOpenUpdateParentModal] = useState(false);
    const [openGroupsModal, setOpenGroupsModal] = useState(false);
    const [openDeleteStudentGroupModal, setOpenDeleteStudentGroupModal] = useState(false);
    const [studentSelected, setStudentSelected] = useState(null);
    const [studentId, setStudentId] = useState(null);
    const [userData, setUserData] = useState({
        userId: null,
        relationshipId: null
    });
    const [deleteGroupData, setDeleteGroupData] = useState({
        studentId: null,
        groupId: null
    });

    //////////////////// SHARED STATE ////////////////////

    const students = useSelector(selectAllStudentsItems);
    const statusServer = useSelector(selectStatusServer)

    /////////////////////// FUNCIONES ////////////////////

    /**
     * Invalida la UI
     */
    const invalidateUI = () => {
        dispatch(invalidate())
        reload()
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadUI(Auth.getUser().school_id))
            .unwrap()
            .then(response => {

            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Inicia el proceso de recuperacion de datos
     */
    useEffect(() => {
        dispatch(loadUI(Auth.getUser().school_id))
    }, [history]);


    /**
     * Abrir el modal de alumnos
     */
    const showAddStudentModal = (student) => {
        setStudentSelected(student)
        setOpenaddStudentModal(true)
    }

    /**
     * Abrir el modal para asignar un grupo
     */
    const showAssignGroupsStudentsModal = (studentId) => {
        setStudentId(studentId)
        setOpenAssignGroupsStudentsModal(true)
    }

    /**
     * Abrir el modal para activar alumno
     */
    const showActivateStudentModal = (studentId) => {
        setStudentId(studentId)
        setOpenActivateModal(true)
    }

    /**
    * Abrir el modal para asignar un grupo
    */
    const showAssignSpecialGroupsStudentsModal = (studentId) => {
        setStudentId(studentId)
        setOpenChangeSpecialGroupsModal(true)
    }

    /**
    * Abrir el modal para agergar un grupo especial
    */
    const showOpenAddSpecialGroupsModal = (studentId) => {
        setStudentId(studentId)
        setOpenAddSpecialGroupsModal(true)
    }

    /**
     * Muestra los modal disponibles
     */
    const showModal = (studentId, modal) => (event) => {
        dispatch(selectStudentItem(studentId))
        if (modal == 'groups') {
            setOpenGroupsModal(true)
        } else if (modal == 'subjects') {
            setOpenSubjectsModal(true)
        } else if (modal == 'parents') {
            setOpenParentsModal(true)
        }
    }

    /**
     * Mostrar modal para dara de baja un alumno del sistema
     * 
     * @param {*} studentId 
     */
    const onOpenDeleteStudentModal = (studentId) => {
        setStudentId(studentId)
        setOpenDeleteModal(true)
    }

    /**
     * Mostrar el modal para registrar un padre
     */
    const showOpenRegistaerParentModal = (studentId) => {
        setStudentId(studentId)
        setOpenRegistaerParentModal(true)
    }

    /**
     * Mostrar el modal para editar el padre
     */
    const showEditParentModal = (userId, relationshipId) => {
        setUserData({
            userId,
            relationshipId
        })
        setOpenUpdateParentModal(true)
    }

    /**
     * Mostrar modal para eliminar alumno
     */
    const showDeleteGroupModal = (studentId, groupId) => {

        setDeleteGroupData({
            studentId: studentId,
            groupId: groupId
        })

        setOpenDeleteStudentGroupModal(true);
    }

    /**
     * Llamado di el alumno fue eliminado del grupo
     */
    const onStudentStudentDeleted = () => {
        feedbackApp.showFeedback({
            title: "El alumno fue eliminado del grupo"
        })
    }

    /**
     * Definicion de las columnas 
     */
    const studentColumns = [
        {
            field: 'student_id',
            headerName: 'Id',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'folio',
            headerName: 'Matricula',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Nombre',
            headerName: 'Nombre',
            flex: 1,
            minWidth: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                return `${params.row.name || ''} ${params.row.last_name || ''} ${params.row.second_last_name || ''}`;
            }
        },
        {
            field: 'groups',
            headerName: 'Grupos',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar grupos">
                    <Button
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={showModal(params.id, 'groups')}
                        disabled={params.row.status == 0}
                    >
                        {params.row.groups}
                    </Button>
                </Tooltip>
            )
        },
        {
            field: 'amount_subjects',
            headerName: 'Materias',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar materias">
                    <Button
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={showModal(params.id, 'subjects')}
                        disabled={params.row.status == 0}
                    >
                        {params.row.amount_subjects}
                    </Button>
                </Tooltip>
            )
        },
        {
            field: 'relationships',
            headerName: 'Parentescos',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Tooltip title="Mostrar parentescos">
                    <Button
                        variant="outlined"
                        startIcon={<ListIcon />}
                        onClick={showModal(params.id, 'parents')}
                        disabled={params.row.status == 0}
                    >
                        {params.row.relationships}
                    </Button>
                </Tooltip>
            )
        },
        {
            field: 'average_rating',
            headerName: 'Promedio',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_absence',
            headerName: 'Faltas',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total_presence',
            headerName: 'Asistencias',
            type: 'number',
            flex: 0.5,
            minWidth: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            getActions: (params) => {
                let dinamicItems = []
                let startDinamicItems = []

                if (params.row.status == 0) {
                    startDinamicItems.push(
                        <GridActionsCellItem
                            icon={<FontAwesomeIcon icon={faUserCheck} size="lg" />}
                            color="primary"
                            title="Activar"
                            label="Activar"
                            showInMenu
                            onClick={() => showActivateStudentModal(params.id)}
                        />
                    )
                }

                if (params.row.group_id) {
                    dinamicItems.push(
                        <GridActionsCellItem
                            icon={<FontAwesomeIcon icon={faChalkboardUser} size="lg" />}
                            color="error"
                            title="Cambiar de grupo"
                            label="Cambiar de grupo"
                            showInMenu
                            onClick={() => showAssignGroupsStudentsModal(params.id)}
                            disabled={params.row.status == 0}
                        />
                    )
                } else {
                    dinamicItems.push(
                        <GridActionsCellItem
                            icon={<GroupAddIcon />}
                            color="error"
                            title="Asignar a grupo"
                            label="Asignar a grupo"
                            showInMenu
                            onClick={() => showAssignGroupsStudentsModal(params.id)}
                            disabled={params.row.status == 0}
                        />
                    )
                }

                if (params.row.has_special_groups) {
                    dinamicItems.push(
                        <GridActionsCellItem
                            icon={<FontAwesomeIcon icon={faUsersRays} size="lg" />}
                            color="error"
                            title="Cambiar grupo especial"
                            label="Cambiar grupo especial"
                            showInMenu
                            onClick={() => showAssignSpecialGroupsStudentsModal(params.id)}
                            disabled={params.row.status == 0}
                        />
                    )
                }

                return [
                    ...startDinamicItems,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        color="primary"
                        title="Editar alumno"
                        label="Editar"
                        onClick={() => showAddStudentModal(params.row)}
                        showInMenu
                        disabled={params.row.status == 0}
                    />,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faPeopleRoof} size="lg" />}
                        color="error"
                        title="Agregar parentesco"
                        label="Agregar parentesco"
                        showInMenu
                        onClick={() => showOpenRegistaerParentModal(params.id)}
                        disabled={params.row.relationships >= 3 || params.row.status == 0}
                    />,
                    ...dinamicItems,
                    <GridActionsCellItem
                        icon={<FontAwesomeIcon icon={faUsersViewfinder} size="lg" />}
                        color="error"
                        title="Agregar grupo especial"
                        label="Agregar grupo especial"
                        showInMenu
                        onClick={() => showOpenAddSpecialGroupsModal(params.id)}
                        disabled={params.row.status == 0}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteOutlineIcon />}
                        color="error"
                        title="Dar de baja alumno"
                        label="Dar de baja alumno"
                        onClick={() => onOpenDeleteStudentModal(params.id)}
                        showInMenu
                        disabled={params.row.status == 0}
                    />
                ]
            }
        },
    ]

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: {
                    xs: 1,
                    sm: 2,
                    md: 2,
                },
                paddingLeft: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingRight: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
                paddingBottom: {
                    xs: 1,
                    sm: 2,
                    md: 5,
                },
            }}>

            <StudentGroupsModal
                open={openGroupsModal}
                setOpen={setOpenGroupsModal}
                onDelete={showDeleteGroupModal}
            />

            <StudentSubjectsModal
                open={openSubjectsModal}
                setOpen={setOpenSubjectsModal}
            />

            <StudentParentsModal
                open={openParentsModal}
                setOpen={setOpenParentsModal}
                onEdit={showEditParentModal}
            />

            <AddStudentModal
                open={openaddStudentModal}
                setOpen={setOpenaddStudentModal}
                student={studentSelected}
            />

            <DeleteStudentModal
                open={openDeleteModal}
                setOpen={() => {
                    setOpenDeleteModal(false);
                }}
                studentId={studentId}
            />

            <AddParentModal
                openModal={openRegistaerParentModal}
                setOpenModal={setOpenRegistaerParentModal}
                studentId={studentId}
            />

            <UpdateParentModal
                openModal={openUpdateParentModal}
                setOpenModal={setOpenUpdateParentModal}
                {...userData}
            />

            <DeleteStudentFromGroupModal
                open={openDeleteStudentGroupModal}
                setOpen={() => {
                    setOpenDeleteStudentGroupModal(false);
                }}
                {...deleteGroupData}
                onStudentDeleted={onStudentStudentDeleted}
            />

            <AssignGroupsStudentsModal
                open={openAssignGroupsStudentsModal}
                setOpen={setOpenAssignGroupsStudentsModal}
                studentId={studentId}
            />

            <ChangeSpecialGroupsModal
                open={openChangeSpecialGroupsModal}
                setOpen={setOpenChangeSpecialGroupsModal}
                studentId={studentId}
            />

            <AddSpecialGroupsModal
                open={openAddSpecialGroupsModal}
                setOpen={setOpenAddSpecialGroupsModal}
                studentId={studentId}
            />

            <ActivateStudentModal
                open={openActivateModal}
                setOpen={setOpenActivateModal}
                studentId={studentId}
            />

            {
                statusServer == "pending" && (<SkeletonPage />)
            }

            {
                statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
            }

            {
                (statusServer == "fulfilled") && (
                    <>
                        <Box style={{ marginBottom: 2, display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <Tooltip title="Sincronizar información">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={invalidateUI}
                                    startIcon={<SyncIcon />}
                                >
                                    Sincronizar
                                </Button>
                            </Tooltip>

                        </Box>

                        <Card sx={{ mt: 5 }}>
                                <DataGrid 
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    getRowId={({ student_id }) => student_id}
                                    rows={students}
                                    columns={studentColumns}
                                    pageSize={20}
                                    rowsPerPageOptions={[5]}
                                    disableSelectionOnClick
                                    autoHeight
                                    components={{ Toolbar: GridToolbar }}
                                    disableDensitySelector
                                    getRowClassName={(GridRowParams) => {
                                        if (GridRowParams.row.status == 0) {
                                            return `super-app-theme--disabled`
                                        }
                                    }}
                                />
                            <CardActions sx={{ direction: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => { showAddStudentModal(null) }}
                                >
                                    Alumno
                                </Button>
                            </CardActions>
                        </Card>
                    </>
                )
            }
        </Box>
    )
}

export default Alumnos;
