import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const filesAdapter = createEntityAdapter({
    selectId: (file) => file.file_id,
    sortComparer: (a, b) => a.file_id - b.file_id
})

/**
 * Slice para los parciales
 */
export const FilesSlice = createSlice({
    name: 'files',
    initialState: filesAdapter.getInitialState(),
    reducers: {
        /**
         * Si la materia no se encuentra en las entidades es agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOneFile: filesAdapter.addOne,
        /**
         * Agrega muchos elementos, si esque estos aun no se encuentran agregados
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyFiles: filesAdapter.addMany,
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setAllFiles: filesAdapter.setAll,
        updateOneFile: filesAdapter.updateOne,
        updateManyFiles: filesAdapter.updateMany,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        removeOneFile: filesAdapter.removeOne,
        /**
         * Si el elemento existe realziara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertOneFile: filesAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManyFiles: filesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return filesAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    setAllFiles, addManyFiles, removeOneFile,
    updateFile, updateManyFiles,
    addOneFile, upsertOneFile, upsertManyFiles
} = FilesSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = filesAdapter.getSelectors((state) => state.entities.files)

/**
 * Selector para recuperar todos los expedientes de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectEntitiesFiles = (state) => globalizedSelectors.selectEntities(state);

/**
 * Selector para recuperar todos los expedientes de la coleccion 
 * 
 * @param {*} state 
 * @returns 
 */
export const selectAllFiles = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para recuperar parciales de una escuela especifica
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectFilesByAssessor = (userID) => {
    return (state) => {
        let allFiles = selectAllFiles(state)

        return _.filter(allFiles, { "assessor_id": userID })
    }
}

export default FilesSlice.reducer;
