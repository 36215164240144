import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { convertArrayToObject, removeDuplicates } from "../../../libs/utils";
import { loadUI, storeNotice } from "./thunks";
import { NOTICESUI_EXPIRE_TIME } from "../../../service/const";

let emptyState = {
    data: {},
    server: {
        expireIn: null,
        ferchingAt: null,
        statusServer: "idle",
        statusOperation: "idle",
        didInvalidate: true,
        feedback: {
          title: null,
          message: null,
          payload: null,
        },
    },
    ui: {

    }
}


export const userSlice = createSlice({
    name: 'noticesModule',
    initialState: emptyState,
    reducers:{
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state,action) => {
            state.server.didInvalidate = true
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
         builder.addCase('app/clear', (state, action) => {
            
            return emptyState
        })

        const pendingServerStatus = (state, action) => {
            state.server.statusServer = 'pending'
        }

        /**
         * Termina la carga de informacion
         */
        builder.addCase(loadUI.fulfilled, (state, action) => {
            state.server.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + NOTICESUI_EXPIRE_TIME))
            state.server.ferchingAt = Date.now()
            state.server.didInvalidate = false

            state.server.statusServer = 'fulfilled'
        })
        builder.addCase(loadUI.pending, pendingServerStatus)
        builder.addCase(loadUI.rejected, (state, action) => {
            state.server.statusServer = 'rejected'
            state.server.feedback = action.payload.feedback
        })
    }    
});


export const { invalidate
             } = userSlice.actions;

export default userSlice.reducer;