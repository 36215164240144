import React, { useState } from 'react';
import {
    Typography, TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, Button, Switch,
    Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, IconButton
} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { selectStatusOperation, updateStatusCatalogReport } from "../../../store/slices/reports";

import AddCatalogoReportModal from './AddCatalogoReportModal';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import * as Style from '../../../styles/index';
import { selectReportsCatalogsAsArray } from '../../../store/slices/entities/reportscatalogs';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import useFeedback from '../../../hooks/useFeedback';

/**
 * Modal para mostrar el catalogo de reportes
 */
export default ({ openModal, setOpenModal }) => {

    const Auth = useAuth()
    const schoolId = Auth.getUser().school_id
    const feedbackApp = useFeedback()

    const classes = Style.tableModalStyle();

    /////////////// LOCAL STATE /////////////////

    const [openReportModal, setOpenReportModal] = useState(false);
    const [catalog, setCatalog] = useState(null);

    const dispatch = useDispatch();
    const catalogo = useSelector(selectReportsCatalogsAsArray())

    ////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    const statusOperation = useSelector(selectStatusOperation)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    //////////////////// ACTIONS //////////////////////

    /**
     * Cambiar de estatus al catalogo
     * 
     * @param {*} data 
     */
    const cambiarStatus = async (data) => {
        let status = (data.status === 1) ? 0 : 1;
        let object = {
            status: status
        };

        dispatch(updateStatusCatalogReport({
            idCatalog: data.catalog_report_id,
            data: object,
        }))
            .unwrap()
            .then(({ message }) => {
                feedbackApp.showFeedback({
                    title: "Catalogo actualizado"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    };

    /**
     * Actualizar catalogo
     * 
     * @param {*} data 
     */
    let actualizar = async (data) => {
        setCatalog(data);
        setOpenReportModal(true);
    }

    return (
        <>
            <AddCatalogoReportModal
                openModal={openReportModal}
                setOpenModal={setOpenReportModal}
                catalog={catalog}
            />

            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth="xs"
                classes={{ paper: classes.dialogWrapper }}
                disableEscapeKeyDown={statusOperation == 'pending'}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Catálogo de reportes
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow style={{ backgroundColor: titleBarBackground.value }}>
                                    <TableCell align="center" style={{ color: fontColor.value }}>TITULO</TableCell>
                                    <TableCell align="center" style={{ color: fontColor.value }}>EDITAR</TableCell>
                                    <TableCell align="center" style={{ color: fontColor.value }}>DESHABILITAR</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {catalogo?.map(row =>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={row.catalog_report_id} >
                                        <TableCell align="center">{row.title}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Editar">
                                                <IconButton style={{ marginRight: 5 }} aria-label="Detalle" color="primary" onClick={() => { actualizar(row) }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Switch
                                                onChange={(e) => { cambiarStatus(row) }} {...(row.status === 1 ? { checked: true } : {})}
                                                disabled={statusOperation == 'pending'}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { setCatalog(null); setOpenReportModal(true) }}
                        sx={{ ml: 2 }}
                        startIcon={<AddIcon />}
                    >
                        Agregar
                    </Button>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { setOpenModal(false) }}
                        sx={{ ml: 2 }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
