import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const partialsAdapter = createEntityAdapter({
    selectId: (partial) => partial.partial_id,
    sortComparer: (a, b) => a.partial_id - b.partial_id
})

/**
 * Slice para los parciales
 */
export const PartialSlice = createSlice({
    name: 'partials',
    initialState: partialsAdapter.getInitialState(),
    reducers: {
        /**
         * Remplazar todos los parciales
         * 
         * @param {*} state 
         * @param {*} action 
         */
        setAllPartials: partialsAdapter.setAll,
        /**
         * Agrega parciales a la coleccion
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addManyPartials: partialsAdapter.addMany,
        /**
         * Agrega un parcial a la coleccio
         * 
         * @param {*} state 
         * @param {*} action 
         */
        addOnePartial: partialsAdapter.addOne,
        /**
         * Elimina un parcial de la coleccion
         * @param {*} state 
         * @param {*} action 
         */
        removeOnePartial: partialsAdapter.removeOne,
        /**
        * Si el elemento existe realziara una actualziacion superficial
        * y los campos seran fucionados, si el elemento no esta precente este
        * sera agregado a las entidades
        * 
        * @param {*} state 
        * @param {*} action 
        */
        upsertOnePartial: partialsAdapter.upsertOne,
        /**
         * Si el elemento existe realizara una actualziacion superficial
         * y los campos seran fucionados, si el elemento no esta precente este
         * sera agregado a las entidades
         * 
         * @param {*} state 
         * @param {*} action 
         */
        upsertManyPartials: partialsAdapter.upsertMany
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return partialsAdapter.getInitialState()
        })
    }
});

/**
 * Acciones generadas por la librerias
 */
export const {
    addManyPartials, removeOnePartial,
    upsertOnePartial,
    addOnePartial, upsertManyPartials, setAllPartials
} = PartialSlice.actions;

//////////////////// SELECTORES //////////////////

const globalizedSelectors = partialsAdapter.getSelectors((state) => state.entities.partials)


/**
 * Selector para recuperar todas las entidades 
 * 
 * @param any Estado completo de la app
 * 
 * @returns any
 */
export const selectEntitiesPartials = (state) => globalizedSelectors.selectEntities(state)

/**
 * Selector para recuperar todas los parciales 
 * 
 * @param any Estado completo de la app
 *  
 * @returns any[]
 */
export const selectAllPartials = (state) => globalizedSelectors.selectAll(state)

/**
 * Selector para seleccionar un parcial por ID
 * 
 * @param number Identificador del parcial
 * 
 * @returns any
 */
export const selectPartialById = (id) => (state) => globalizedSelectors.selectById(state, id)


/**
 * Selector para recuperar parciales de una escuela
 * 
 * @param number Identificador de la escuela 
 * 
 * @returns any[]
 */
export const selectPartialsBySchoolId = (schoolId) => {
    return (state) => selectAllPartials(state).filter(i => i.school_id == schoolId);
}

/**
 * Selector para recuperar parciales de una escuela por nivel
 * 
 * @param {*} schoolId 
 * @returns 
 */
export const selectPartialsBySchoolLevel = (schoolId, level) => {
    return (state) => {
        return !level ? [] : _.filter(selectPartialsBySchoolId(schoolId)(state), { level: level })
    }
}

export default PartialSlice.reducer;
