import React from 'react'

import { Card, CardHeader, Typography, Button } from '@mui/material'
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import * as Plugin from '../../../../../libs/chartist-bar-labels.js'
import * as constants from "../../../../../service/const";
import { getLevelName, getTurnLevel } from "../../../../../libs/utils";
import { useSelector } from "react-redux";
import { getGroupsReportBy, getSubjectReportByGroup, getSubjectsReport } from '../../../../../store/slices/background'
import tooltip from "chartist-plugin-tooltip"
import { Box, height } from '@mui/system';
import { useHistory, useRouteMatch } from 'react-router-dom';

const ScoreGroupSubjectsChart = (props) => {
    const { groupId } = props

    const group = useSelector(getGroupsReportBy(groupId))
    const subjects = useSelector(getSubjectReportByGroup(groupId))

    ///////////////////////////// CHART CONFIGURATION ////////////////////
    const history = useHistory()
    const match = useRouteMatch()

    const turnName = getTurnLevel(group.turn)
    const levelName = getLevelName(group.level)

    const groupLabel = `${group.grade} ${group.group} ${turnName}`

    let data = {
        labels: [],
        series: [[]]
    };

    let options = {
        seriesBarDistance: 100,
        reverseData: true,
        horizontalBars: true,
        stackBars: true,
        chartPadding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 0
        },
        axisX: {
            onlyInteger: true
        },
        axisY: {
            offset: 130,
            showGrid: false
        },
        scaleMinSpace: 100,
        plugins: [
            //Chartist.plugins.tooltip(),
            Chartist.plugins.ctBarLabels({
                labelClass: 'ct-label-detail',
                position: {
                    x: function (data) {
                        return data.x1 + 205
                    }
                },
                labelOffset: {
                    y: 5
                },
                labelInterpolationFnc: function (text) {
                    if (text == undefined) {
                        return 0
                    }
                    return text
                }
            })
        ]
    };

    const listeners = {
        "draw": (data) => {
            if (data.type === 'bar') {

                const barWidth = 15

                data.element.attr({
                    style: `stroke-width: ${barWidth}px; stroke: #6cac44`
                });
            }
        }
    }


    let subjectsPre = subjects.filter(item => item.payload.group.level == props.level)

    let serieOne = []

    for (const subject of subjectsPre) {
        const subjectName = `${subject.title}`

        data.labels.push(`(${subject.payload.catalogSubject.folio}) ${subjectName}`)
        serieOne.push({ meta: 'description', value: subject.average_rating },)

    }

    data.series[0] = serieOne

    /**
     * Funcion para ir al detalle de materias
     * 
     * @param {*} params 
     * @returns 
     */
    const goDetails = () => {
        history.push({ pathname: `grupos/${groupId}` });
    }

    const content = () => {
        if (subjects.length > 0) {
            return (<ChartistGraph className={'ct-chart ct-square'} data={data} options={options} type="Bar" listener={listeners} />)
        }

        return (
            <Box sx={{ height: 100, width: "100%", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={"body1"} component="div">No hay materias en este grupo</Typography>
            </Box>
        )
    }

    return (
        <Card sx={{ width: 360 }} variant="outlined">
            <CardHeader
                title={groupLabel}
                titleTypographyProps={{
                    sx: {
                        fontSize: 18
                    }
                }}
                sx={{
                    paddingBottom: 0
                }}
                action={
                    <Button onClick={goDetails}>
                        Detalle
                    </Button>
                }
            />
            {content()}
        </Card>
    )
}


export default ScoreGroupSubjectsChart