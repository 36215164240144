import { useAuth } from "../hooks";
import { Redirect, Route } from "react-router-dom";

/**
 * Componente que funciona como midleware para 
 * evitar el acceso a usuario con modulos incompletos
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
function WorkingMiddleware({ children, ...rest }) {
    let auth = useAuth();

    const render = () => ({ location }) => {

        // TODO CODIGO TEMPORAL DESPUES DE AJUSTAR LAS URL USADO /app
        // ESO SERA USADO POR PrivateRoute
        if (!auth.getUser()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            )
        }

        if (auth.getUser() && !auth.isMultiUser()) {

            if (auth.belongToGroup('ASSESSOR') ||
                auth.belongToGroup('PARENT') ||
                auth.belongToGroup('ADMINISTRATIVE') ||
                auth.belongToGroup('TUTOR') ||
                auth.belongToGroup('ADMIN_TI') ||
                auth.belongToGroup('SUPER_ADMIN')) {
                return (
                    <Redirect
                        to={{
                            pathname: "/working",
                            state: { from: location }
                        }}
                    />
                )
            }
        }

        return children
    }

    return (<Route {...rest} render={render()} />)
}

export default WorkingMiddleware