import React from 'react'
import { Box, Grid, Card, IconButton, Menu, MenuItem, CardHeader } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from "react-redux";
import { getSubjectsReport, selectAllGroups, selectAllSubjects } from '../../../store/slices/background'
import { DataGrid } from '@mui/x-data-grid';
import ExportableModal from "../../../components/Chart/ExportableModal"
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { WorstAbsenceSubjectsIndicator, BestScoreSubjectIndicator, BestAssistsSubjectIndicator } from "../../../components/Chart/IndicatorsSubjects"
import * as constants from "../../../service/const";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getLevelName } from '../../../libs/utils';
import { useAuth } from '../../../hooks';
import { LevelLineItem } from './Components/ChartsSubjects';
import _ from 'lodash';

/**
 * Pagina para las materias
 * 
 * @returns 
 */
const SubjectView = () => {

    const history = useHistory()
    const match = useRouteMatch()
    const Auth = useAuth()

    ////////////////// SHARED STATE //////////////

    const subjects = useSelector(getSubjectsReport)
    const allGroups = useSelector(selectAllGroups)

    /////////////// EXPORT ///////////////////

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    //////////////////  MODAL PARA EXPORTAR ///////////////////////

    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [reportData, setReportData] = React.useState({
        report: null,
        title: null,
        query: { school_id: null }
    });

    const onCloseExportableModal = () => {
        setOpenExportModal(false);
    };

    const onExport = () => {
        setReportData({
            report: "subjects",
            title: "Reporte de materias",
            query: { school_id: Auth.getUser().school_id }
        })
        setOpenExportModal(true);
    }

    ///////////////////////////// ACTIONS ////////////////////

    const columns = [
        //{ field: 'id', headerName: 'Materia', flex: 1, sortable: false},
        //{ field: 'folio', headerName: 'Folio', flex: 0.8, sortable: false},
        { field: 'title', headerName: 'Titulo', flex: 1.5, sortable: true },
        {
            field: 'level',
            headerName: 'Nivel',
            flex: 1.5,
            sortable: true,
            valueGetter: ({ row }) => {
                if (row.payload.catalogSubject) {
                    return getLevelName(row.payload.catalogSubject.level)
                }

                return "Sin nivel"
            }
        },
        {
            field: 'professor_name',
            headerName: 'Profesor',
            flex: 1.5,
            sortable: true,
        },
        {
            field: 'grage',
            headerName: 'Grado',
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => {
                if (row.payload.group) {
                    return row.payload.group.grade
                }

                return ""
            }
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => {
                if (row.payload.group) {
                    return row.payload.group.group
                }

                return ""
            }
        },
        {
            field: 'turn',
            headerName: 'Turno',
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => {
                if (row.payload.group) {
                    return row.payload.group.turn
                }

                return ""
            }
        },
        {
            field: 'special',
            headerName: 'Especial',
            flex: 0.9,
            sortable: true,
            valueGetter: ({ row }) => {

                return (row.special) ? "Si" : "No"
            }

        },
        { field: 'average_rating', headerName: 'Promedio', flex: 0.9, sortable: true, type: 'number' },
        { field: 'total_presence', headerName: 'Asistencias', flex: 0.9, sortable: true, type: 'number' },
        { field: 'total_absence', headerName: 'Faltas', flex: 0.9, sortable: true, type: 'number' },
        {
            field: 'actions',
            headerName: 'Acciones',
            flex: 1,
            sortable: false,
            type: 'number',
            renderCell: (params) => (
                <strong>
                    <IconButton aria-label="Detalle" color="primary" onClick={goDetails(params)}>
                        <InsertLinkIcon />
                    </IconButton>
                </strong>
            )
        }
    ];


    let filas = subjects.map(subejct => ({ id: subejct.subject_id, ...subejct }))


    /**
     * Funcion para ir al detalle de materias
     * 
     * @param {*} params 
     * @returns 
     */
    const goDetails = (params) => () => {
        history.push({ pathname: `${match.path}/${params.id}` });
    }

    /**
     * Componente encargado de mostrar
     * los graficas de cada nivel escolas
     * 
     * @returns 
     */
    const ChartsSection = () => {

        const levelsWithGroups = _.filter(constants.levelsNames, (item) => {
            return _.filter(allGroups, { level: item.key }).length > 0
        })

        return (
            <Box container sx={{ mb: 5 }}>
                {levelsWithGroups.map(level =>
                    (<LevelLineItem key={level.key} level={level} />)
                )}
            </Box>
        )
    }

    return (
        <Box>
            <Grid container spacing={2} sx={{ mb: 5 }} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sx="4">
                    <BestScoreSubjectIndicator></BestScoreSubjectIndicator>
                </Grid>
                <Grid item sx="4">
                    <BestAssistsSubjectIndicator></BestAssistsSubjectIndicator>
                </Grid>
                <Grid item sx="4">
                    <WorstAbsenceSubjectsIndicator></WorstAbsenceSubjectsIndicator>
                </Grid>
            </Grid>

            {ChartsSection()}

            <Card>
                <CardHeader
                    title="Materias"
                    titleTypographyProps={{
                        sx: {
                            fontSize: 18
                        }
                    }}
                    subheader={"Total:" + subjects.length}
                    sx={{
                        paddingBottom: 2
                    }}
                    action={
                        <div>
                            <IconButton aria-label="settings" id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={onExport}>Exportar</MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <DataGrid style={{ width: '100%' }}
                    pageSize={10}
                    disableColumnFilter={true}
                    autoPageSize={true}
                    disableSelectionOnClick={true}
                    disableExtendRowFullWidth={true}
                    disableDensitySelector={true} disableColumnSelector={true} disableColumnMenu={true}
                    density="compact" autoHeight={true} rows={filas} columns={columns}
                />

            </Card>
            <ExportableModal
                show={openExportModal}
                data={reportData}
                onClose={onCloseExportableModal}
            />

        </Box>
    )
}


export default SubjectView