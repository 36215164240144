import React from 'react'
import { Card, CardHeader, IconButton, Menu, MenuItem, Button, CardActions, Typography, Box} from '@mui/material'

import { useSelector, useDispatch } from "react-redux";
import { getTopReportsGroups, setCurrentTab } from '../../../store/slices/background'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist'
import * as Plugin from '../../../libs/chartist-bar-labels.js'

const TopReportsGroups = (props) => {

    const topScoreSubject = useSelector(getTopReportsGroups)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onExport = () => {
        props.export({
            report: "top-reports-groups",
            title: "Top reportes por grupos"
        })
    }

    
 ///////////////////////////// CHART CONFIGURATION ////////////////////

    const dispatch = useDispatch()

    let data = {
        labels: [],
        series: [
            []
        ]
    };

    let options = {
        seriesBarDistance: 100,
        reverseData: true,
        horizontalBars: true,
        stackBars: true,
        chartPadding: {
            top: 0,
            right: 40,
            bottom: 0,
            left: 0
        },
        axisX: {
            onlyInteger: true
        },
        axisY: {
        offset: 130,
        showGrid: false,
        },
        scaleMinSpace: 100,
        plugins: [
            Chartist.plugins.ctBarLabels({
                labelClass: 'ct-bar-label-black',
                position: {
                    x: function (data) {
                    return data.x1 + 205
                    }
                },
                labelOffset: {
                    y: 5
                },
                labelInterpolationFnc: function (text) {
                    if (text == undefined) {
                        return 0
                    }
                    return text
                }
            })
        ]
    };

    const listeners = {
        "draw" : (data) => {
            if(data.type === 'bar') {

                const barWidth = 20

                data.element.attr({
                    style: `stroke-width: ${barWidth}px; stroke: #5c94d2`
                });
            }
        }
    }

    const onDetailClick = () => {
        props.goDetails("/grupos")
    }
 
    function topReportsGroupsUI () {
        let filas = []
        let serieOne = []

        for (const item of topScoreSubject) {
            data.labels.push(`${item.title} ${item.levelName}`)
            serieOne.push(item.total_reports)

            filas.push({
                id: item.group_id,
                title: item.title,
                reports: item.total_reports,
                level: item.levelName
            })
        }

        data.series[0] = serieOne

        return (
            <Card sx={{ width: 360 }} variant="outlined">
            <CardHeader
                title="Top reportes por grupos "
                titleTypographyProps={{
                    sx: {
                        fontSize: 19
                    }
                }}
                sx={{
                    paddingBottom: 0
                }}
                action={
                    <div>
                        <IconButton aria-label="settings" id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onExport}>Exportar</MenuItem>
                        </Menu>
                    </div>
                }
            />
                <ChartistGraph className={'ct-chart ct-square'} data={data} options={options} type="Bar" listener={listeners}/>
                <CardActions>
                    <Button size="small" variant="outlined" sx={{width: "100%"}} onClick={onDetailClick}>Ver mas</Button>
                </CardActions>

            </Card>
        )
    }


    return (topReportsGroupsUI())
}

export default TopReportsGroups
