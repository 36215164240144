import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Button, Skeleton, Box, Stack, Avatar
} from '@mui/material';
import { getRelationships, } from '../../../libs/utils';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';

import {
    loadParentsUI,
    selectParentstatusServerItemSelected,
    selectStudentItemSelected
} from '../../../store/slices/studentsUI/ItemsSlice';
import { useDispatch } from 'react-redux';
import { DataGrid, esES, GridActionsCellItem } from '@mui/x-data-grid';
import * as Style from '../../../styles/index';
import { Error } from '../../../components/Feedback';

/**
 * Modal para mostrar los padre de un alumno
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const StudentParentsModal = ({ open, setOpen, onEdit }) => {
    const classes = Style.tableModalStyle();

    const dispatch = useDispatch();
    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARED STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))
    //const statusOperation = useSelector(selectStatusOperation)

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const studentSelected = useSelector(selectStudentItemSelected(null))
    const statusServer = useSelector(selectParentstatusServerItemSelected)

    /**
     * Iniciamos el flujo de recuperacion de los grupos
     * del alumno seleccionado
     */
    useEffect(() => {
        if (open) {
            dispatch(loadParentsUI())
        }
    }, [open])

    /////////////////////// ACTIONS ////////////////////
    /**
     * 
     */
    const closeModal = () => {
        setOpen(false)
    }

    /**
    * Funcion para recargar los datos del usuario
    */
    const reload = () => {
        dispatch(loadParentsUI())
            .unwrap()
            .then(response => {

            })
    }

    /**
     * Se a precionado el boton de editar
     */
    const onEditParent = (parfentID, relationshipId) => {
        onEdit(parfentID, relationshipId)
        closeModal()
    }

    /**
     * Definicion de las columnas
     */
    const columns = [
        {
            field: 'parent',
            headerName: 'Padre',
            flex: 1,
            width: 80,
            editable: false,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
            disableReorder: true,
            renderCell: (params) => (
                <>
                    <Avatar alt="Remy Sharp" src={params.row.parent.url_photo_profile} sx={{ width: 34, height: 34, mr: 2 }} />
                    {params.value}
                </>
            ),
            valueGetter: (params) => {
                return `${params.row.parent.name} ${params.row.parent.last_name} ${params.row.parent.second_last_name}`
            }
        },
        {
            field: 'relationship',
            headerName: 'Parentesco',
            flex: 0.5,
            width: 80,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => {
                return `${getRelationships(params.row.relationship).title}`
            }
        },
        {
            field: 'cellphone',
            headerName: 'Celular',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            valueGetter: (params) => params.row.parent.cellphone
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            description: 'Esta columna no se puede ordenar',
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            headerAlign: 'center',
            align: 'center',
            type: 'actions',
            headerClassName: 'super-app-theme--header',
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        color="primary"
                        title="Editar padre"
                        label="Editar"
                        showInMenu
                        onClick={() => { onEditParent(params.row.parent_id, params.row.id) }}
                    />
                ]
            }
        },
    ];

    const studentsGroups = studentSelected.relationshipsList.map(i => {
        return {
            id: i.relationship_id,
            ...i,
        }
    })

    return (
        <>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="sm"
                classes={{ paper: classes.dialogWrapper }}
                disableEscapeKeyDown={statusServer == 'pending'}
                onClose={() => {
                    closeModal()
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Padres<br />
                            <span style={{ fontSize: '15px' }}>
                                Alumno: {`${studentSelected.name} ${studentSelected.last_name}`}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        statusServer == "pending" && (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        )
                    }

                    {
                        statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
                    }

                    {
                        (statusServer == "fulfilled") && (
                            <Box
                                sx={{
                                    '& .super-app-theme--header': {
                                        backgroundColor: titleBarBackground.value,
                                        color: fontColor.value
                                    },
                                    '& .MuiDataGrid-root': {
                                        border: 0
                                    },
                                }}
                            >
                                <Box sx={{ textAlign: "center" }}>
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        rows={studentsGroups}
                                        columns={columns}
                                        disableSelectionOnClick
                                        autoHeight
                                        hideFooterPagination
                                        disableColumnMenu
                                        disableColumnFilter
                                    />
                                </Box>
                            </Box>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={closeModal}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default StudentParentsModal
