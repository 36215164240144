

//////////////////// SELECTORES //////////////////

import { selectUserById } from "../entities/users";

/**
 * Recuperamos las configuraciones de la escuela
 * 
 * @param {*} state 
 * @returns 
 */
export const selectUsersUI = (state) => state.noticesModule.ui;

export const selectUsersData = (state) => state.noticesModule.data;

export const selectUsersServer = (state) => state.noticesModule.server;

export const selectStatusServer = (state) => state.noticesModule.server.statusServer

export const selectStatusOperation = (state) => state.noticesModule.server.statusOperation

////////////////////// ESTADOS /////////////////////////

export const getNotices = (state) => {

    const notices = Object.values(state.entities.notices.byId).map(n => {

        let emmiter = selectUserById(n.user_id)(state)

        return {
            ...n,
            spokeman: emmiter
        }
    });

    return notices
}