import { combineReducers } from "@reduxjs/toolkit";
import groups from "./entities/groupsSlice"
import students from "./entities/studentsSlice" 
import groupsStudents from "./entities/groupsStudentsSlice" 
import users from "./entities/usersSlice" 
import relationships from "./entities/relationshipsSlice" 


/**
 * Reductor para las entidades normalizadas
 */
export default combineReducers({
    groups,
    students,
    groupsStudents,
    users,
    relationships
})
