import { Grid, Skeleton } from "@mui/material"

/**
 * Component que muestra la carga de datos
 * del Selector de grupos
 * 
 * @returns 
 */
const GroupsSelectorSkeleton = () => {

    return (
        <>
            <Skeleton variant="rectangular" height={30} animation="wave" sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ mb: 3 }} />

            <Skeleton variant="rectangular" height={30} animation="wave" sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" height={100} animation="wave" />
        </>
    )
}

export default GroupsSelectorSkeleton
