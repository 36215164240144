
import React from 'react'
import StatBiColor from "../../../../components/Chart/StatsBiColor"
import { useSelector } from "react-redux";
import { getWorstAbsenceStudents } from '../../../../store/slices/background'
import { getLevelName } from "../../../../libs/utils";

/**
 * Estadistica que muestra el alumno con mas faltas
 * 
 * @param {*} props 
 * 
 * @returns 
 */
const WorstAbsenceStudentsIndicator = ({ level, theme }) => {

    /////////////////// SHARED DATA ///////////////////

    const worstAbsenceStudent = useSelector(getWorstAbsenceStudents(level))

    let value = 0
    let title = "Sin alumno"
    let levelName = "Plantel"

    if (worstAbsenceStudent != null) {
        value = worstAbsenceStudent.total_absence
        title = worstAbsenceStudent.name
    }

    if (level != -1) {
        levelName = getLevelName(level)
    }


    return (
        <StatBiColor
            value={value}
            title={title}
            label={"Con mas faltas " + levelName}
            mainColor={theme.mainColor}
            secundaryColor={theme.secundaryColor}
        >
        </StatBiColor>
    )
}

export default WorstAbsenceStudentsIndicator


