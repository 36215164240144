import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Button, IconButton, Tooltip, Avatar, Snackbar, Stack, Skeleton
} from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from "react-redux";
import { DataGrid, esES } from '@mui/x-data-grid';
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { getLevelName, getTurnLevel } from '../../../libs/utils';
import { selectStudentsByGroupId } from '../../../store/slices/entities/students';
import * as Style from '../../../styles/index';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DeleteStudentFromGroupModal from '../../../components/Modal/DeleteStudentFromGroupModal';
import { loadGroupStudentsUI, selectGroupByItemSelected, selectStudentStatusServerItemSelected } from '../../../store/slices/groupsUI/itemSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Error } from '../../../components/Feedback';
import { selectGroupItem } from '../../../store/slices/groupsUI/uiSlice';

/**
 * Modal para agregar alumnos
 * 
 * @param {*} param0
 *  
 * @returns 
 */
export default ({ open, setOpen}) => {

    const classes = Style.tableModalStyle();
    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    const [studentId, setStudentId] = useState(null);
    const [openDeleteStudentGroupModal, setOpenDeleteStudentGroupModal] = useState(false);

    //////////// SELECTORES /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');


    const groupSelected = useSelector(selectGroupByItemSelected)
    const statusServer = useSelector(selectStudentStatusServerItemSelected)

    let students = useSelector(selectStudentsByGroupId(groupSelected?.group_id))

    useEffect(() => {
        if (open) {
            dispatch(loadGroupStudentsUI())
        }
    }, [open])


    /**
     * Funcion para cerrar el modal
     */
    const closeModal = () => {
        setOpen(false)
        dispatch(selectGroupItem(null));
    }
    
    const columns = [

        {
            field: 'idUsuario',
            headerName: 'ID',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'matricula',
            headerName: 'Matrícula',
            flex: 0.5,
            width: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 2,
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Avatar sx={{ width: 24, height: 24 }} src={params.value.url_photo_profile} />
                    <span>
                        {params.value.name} {params.value.last_name} {params.value.second_last_name}
                    </span>
                </Stack>
            ),
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            flex: 0.5,
            width: 50,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <>
                    <Tooltip title="Sacar de grupo">
                        <IconButton
                            style={{ textTransform: 'none', marginLeft: 10 }}
                            variant="outlined"
                            color="error"
                            onClick={() => { onOpenDeleteStudentFromGroupModal(params.id) }}
                        >
                            <PersonRemoveIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),

        },
    ];

    const dataGrid = students?.map(row => {
        return {
            id: row.student_id,
            idUsuario: row.student_id,
            matricula: row.folio,
            nombre: row,
            acciones: row.status,
        }
    });

    ///////////////////// ACCIONES ///////////////

    /**
* Funcion para recargar los datos del usuario
*/
    const reload = () => {
        dispatch(loadGroupStudentsUI())
            .unwrap()
            .then(response => {

            })
    }

    /**
     * Mostrar modal para eliminar alumno de grupo
     * 
     * @param {*} studentId 
     */
    const onOpenDeleteStudentFromGroupModal = (studentId) => {
        setStudentId(studentId)
        setOpenDeleteStudentGroupModal(true);
    }

    /**
     * Mostrar modal para agregar alumno al grupo
     */
    const onOpenAddStudentModal = () => {
        //setOpenAddStudentModal(true)
    }

    /**
     * LLamado si el alumno fue dado de baja correctamente
     */
    const onStudentDeleted = () => {
        showFeedback("El alumno fue dado de baja correctamente")
    }

    /**
     * Llamado di el alumno fue eliminado del grupo
     */
    const onStudentStudentDeleted = () => {
        showFeedback("El alumno fue eliminado del grupo")
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    return (
        <>
            <DeleteStudentFromGroupModal
                open={openDeleteStudentGroupModal}
                setOpen={() => {
                    setOpenDeleteStudentGroupModal(false);
                }}
                studentId={studentId}
                groupId={groupSelected?.group_id}
                onStudentDeleted={onStudentStudentDeleted}
            />

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth="md"
                classes={{ paper: classes.dialogWrapper }}
                onClose={() => {
                    closeModal()
                }}
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Lista de alumnos <br />
                            <span style={{ fontSize: '15px' }}>
                                Grupo {groupSelected?.grade}° {groupSelected?.group} {getTurnLevel(groupSelected?.turn)} {getLevelName(groupSelected?.level)}
                            </span>
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        statusServer == "pending" && (
                            <Stack spacing={2} sx={{ padding: 2 }}>
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                                <Skeleton variant="rectangular" height={30} />
                            </Stack>
                        )
                    }

                    {
                        statusServer == "rejected" && (<Error onRetry={reload} message={"Estamos teniedo problemas"} />)
                    }

                    {
                        (statusServer == "fulfilled") && (
                            <Box
                                sx={{
                                    '& .super-app-theme--header': {
                                        backgroundColor: titleBarBackground.value,
                                        color: fontColor.value
                                    },
                                    '& .MuiDataGrid-root': {
                                        border: 0
                                    },
                                }}
                            >
                                <Box sx={{ textAlign: "center" }}>
                                    {
                                        students.length ? (
                                            <DataGrid
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                rows={dataGrid}
                                                columns={columns}
                                                pageSize={10}
                                                rowsPerPageOptions={[10]}
                                                disableSelectionOnClick
                                                autoHeight
                                            />
                                        )
                                            :
                                            (
                                                <Typography variant="h6" component="div" sx={{ mt: 3 }}>
                                                    Sin alumnos
                                                </Typography>
                                            )
                                    }
                                </Box>
                            </Box>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    {/*<Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={onOpenAddStudentModal}
                    >
                        Agregar Alumno
                        </Button>*/}
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => { closeModal() }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </>
    )
}
