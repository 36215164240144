/**
 * Funcion para redondear las calificaciones
 * 
 * @param number 
 * @param posiciones 
 * @returns 
 */
export default function (number, posiciones = 0) {
    let numberFormated = number

    var s = number.toString()
    var l = s.length

    if (s.indexOf('.') > 0) {
        var decimalLength = s.indexOf('.') + 1
        numberFormated = s.substr(0, decimalLength + posiciones)
    }

    return Number(numberFormated)
}