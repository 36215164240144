
import React from 'react'
import { useSelector } from "react-redux";
import StatsBiColor from '../../../../components/Chart/StatsBiColor';
import { getLastAssitsListProfessorsCard } from '../../../../store/slices/background'

const LastAssitsListProfessorsIndicator = (props) => {

    const lastAssitsListProfessors = useSelector(getLastAssitsListProfessorsCard)

    return (
        <StatsBiColor
            valueOptions={{
                variant: "h6",
                component: "span"
            }}
            valueBoxOptions={{
                flex: 1
            }}
            value={`${lastAssitsListProfessors.assists_list_percent}%`}
            title={`${lastAssitsListProfessors.name} ${lastAssitsListProfessors.last_name}`}
            label={"Profesor en rezago de asistencias"}
            mainColor={"#fc6404"}
            secundaryColor={"#fcb47c"}
        >
        </StatsBiColor>
    )
}

export default LastAssitsListProfessorsIndicator


