import React from 'react'
import { Box, Stack, Grid, Typography, Card, CardActions, Button } from '@mui/material'

const CyclesView = () => {
    return (
        <Box>
            Comparativo de Ciclos
            Índice de Descerción
            Índice de Ingreso
        </Box>
    )
}

export default CyclesView