import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Button, LinearProgress, Box
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Connection from "../../../service/Connection"
import { selectStudentById } from '../../../store/slices/entities/students';
import Feedback from '../../../service/Feedback';
import _ from 'lodash';
import useFeedback from '../../../hooks/useFeedback';
import { addAndRemoveSpecialGroups, exchangeSpecialGroup, selectAddSpecialGroupsStatus, selectExchangeSpecialGroupStatus } from '../../../store/slices/studentsUI/operationsSlice';
import TableTransfer from './TableTransfer';
import { getNameSchoolLevel, getTurnsLabel } from '../../../helper';

/**
 * Modal para agergar grupo especiales a un
 * 
 * @param {number} studentId Identificador del alumno
 * 
 * @returns 
 */
const AddSpecialGroupsModal = ({ open, setOpen, studentId = null }) => {

    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id
    let FeedbackService = new Feedback()
    const feedbackApp = useFeedback()

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const student = useSelector(selectStudentById(studentId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    const operationStatus = useSelector(selectAddSpecialGroupsStatus)

    ///////////////// LOCAL STATE ////////////////////

    const [loading, setLoading] = useState('idle')

    /**
     * Todos los grupos 
     */
    const [groups, setGroups] = useState([])


    const [groupsTargetKeys, setGroupsTargetKeys] = useState([]);
    const [groupsSelectedKeys, setGroupsSelectedKeys] = useState([]);

    /**
     * Grupos actuales del alumno
     */
    const [currentGroups, setCurrentGroups] = useState([])

    /**
     * Definicion de las columnas para el transfer
     */
    const columnsTableTransfer = [
        {
            dataIndex: 'group',
            title: 'Grupo',
            render: (value, row) => <Box sx={row.styled}>{`${row.grade} ${row.group}`}</Box>
        },
        {
            dataIndex: 'turn',
            title: 'Turno',
            render: (value, row) => <Box sx={row.styled}>{getTurnsLabel(value)}</Box>
        },
        {
            dataIndex: 'level',
            title: 'Nivel',
            render: (value, row) => <Box sx={row.styled}>{getNameSchoolLevel(value)}</Box>
        },
        {
            dataIndex: 'subjects',
            title: 'Materias',
            render: (value, row) => <Box sx={row.styled}>{value}</Box>
        },
        {
            dataIndex: 'students',
            title: 'Alumnos',
            render: (value, row) => <Box sx={row.styled}>{value}</Box>
        },
    ];

    ///////////// ACTIONS ///////////////////////

    /**
     * Funcion Ejecutada cuando el usuario intercambia elementos
     * 
     * @param {*} nextTargetKeys 
     * @param {*} direction 
     * @param {*} moveKeys 
     */
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setGroupsTargetKeys(nextTargetKeys);
    }

    /**
     * Funcion ejecutada cuando el usuario selecciona un elemento
     * 
     * @param {*} sourceSelectedKeys 
     * @param {*} targetSelectedKeys 
     */
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setGroupsSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    /**
     * Manejador de boton guradar 
     */
    const enviarDatos = () => {

        let data = getFormatedData()

        dispatch(addAndRemoveSpecialGroups({
            student,
            groupsToCreate: data.create,
            groupsToDelete: data.delete
        })).unwrap()
            .then((data) => {
                setOpen(false)

                feedbackApp.showFeedback({
                    title: "Agrupos actualizados"
                })
            }).catch(({ feedback }) => {
                feedbackApp.showFeedback({
                    title: feedback.title
                })
            })
    }

    /**
     * Determina si la materia sera creada
     * 
     * @param groupId Identificador del grupo
     */
    const isWillNewGroup = (groupId) => {
        let groupInTarget = groupsTargetKeys.find(gId => gId == groupId)

        if (!groupInTarget) {
            return false
        }

        let isInCurrent = currentGroups.filter(s => s.group_id == groupInTarget)

        return isInCurrent.length == 0
    }

    /**
     * Determina si la materia sera creada
     * 
     * @param groupId Identificador del grupo
     */
    const isWillDeleteGroup = (groupId) => {

        let availablegroups = groups.filter(sc => !groupsTargetKeys.find(stk => stk === sc.group_id))

        let groupAvailable = availablegroups.find(sc => sc.group_id == groupId)

        if (!groupAvailable) {
            return false
        }

        let isInCurrent = currentGroups.filter(s => s.group_id == groupAvailable.group_id)
        return isInCurrent.length > 0
    }

    /**
     * Recuperamos los grupos que seran creados
     * y elimnados
     * 
     * @returns 
     */
    const getFormatedData = () => {
        let groupsToCreate = groups.filter(sc => {
            return isWillNewGroup(sc.group_id)
        })

        let groupsTODelete = currentGroups.filter(s => {
            return isWillDeleteGroup(s.group_id)
        })

        return {
            create: groupsToCreate,
            delete: groupsTODelete
        }
    }

    /**
     * Efecto lanzado cuando se abre el modal
     */
    useEffect(() => {

        async function getData() {
            const allGroups = await Connection.groupsBySchool(schoolId, {
                filters: { "special": 1 }
            })
                .then(i => i.data.data)

            const studentGroups = await Connection.getSpecialGroupsByStudent(studentId)
                .then(i => i.data.data)

            return {
                allGroups,
                studentGroups
            }
        }

        if (!open) {
            setLoading('idle')

            setCurrentGroups([])
            setGroups([])
            setGroupsSelectedKeys([])
            setGroupsTargetKeys([])
        } else {
            setLoading('loading')

            getData()
                .then(({ allGroups, studentGroups }) => {
                    setGroups(allGroups)
                    setCurrentGroups(studentGroups)

                    setGroupsTargetKeys(studentGroups.map(i => i.group_id))
                })
                .catch(err => {
                    const feedback = FeedbackService.getMessage(err)

                    feedbackApp.showFeedback({
                        title: feedback.title
                    })
                }).then(() => {
                    setLoading('idle')
                })
        }
    }, [open])


    /**
     * Determina si el catalogo de materia es nuevo
     */
    const getStyleItem = (item) => {
        let willNewSubject = isWillNewGroup(item.group_id)

        let color = 'black'

        if (willNewSubject) {
            color = 'green'
        } else {
            color = isWillDeleteGroup(item.group_id) ? "red" : "black"
        }

        return {
            color
        }
    }


    ////////////////////////// VALIDACIONES //////////////////////////////////

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        let data = getFormatedData()

        return data.create.length == 0 && data.delete.length == 0
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            disableEscapeKeyDown={operationStatus == 'pending'}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Agregar grupo especial<br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Alumno: {student?.name} {student?.last_name}
                        </span>
                    </Typography>
                </div>

            </DialogTitle>
            <Box sx={{ width: '100%' }}>
                <LinearProgress sx={{ visibility: (loading == 'loading') ? 'visible' : 'hidden' }} />
            </Box>
            <DialogContent>
                <TableTransfer
                    //rowKey={(group_id) => group_id}
                    titles={['Disponibles', 'Asignadas']}
                    locale={{
                        notFoundContent: ["Sin grupos", "Sin grupos"],
                        itemUnit: "Grupos",
                        itemsUnit: "Grupos",
                        searchPlaceholder: 'Buscar aquí'
                    }}
                    dataSource={groups.map(i => ({ ...i, key: i.group_id }))}
                    targetKeys={groupsTargetKeys}
                    selectedKeys={groupsSelectedKeys}
                    showSearch={true}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    styleItem={getStyleItem}
                    currentGroups={currentGroups}
                    filterOption={(inputValue, item) => {
                        return `${item.grade} ${item.group}`.includes(inputValue)
                    }}
                    leftColumns={columnsTableTransfer}
                    rightColumns={columnsTableTransfer}
                />
            </DialogContent>

            <DialogActions>

                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={operationStatus == 'pending'}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Cambiar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpen(false) }}
                    disabled={operationStatus == 'pending'}
                >
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>

    )
}


export default AddSpecialGroupsModal