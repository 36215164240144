import { Grid, Skeleton } from "@mui/material"

const SkeletonGrid = (props) => {
    const { items } = props

    return (
        <Grid container spacing={{ xs: 1, md: 4 }} columns={{ xs: 1, sm: 2, md: 3 }}>
            {Array(items).fill(0).map((item, index) => {
                return (
                    <Grid item xs={1} sm={1} md={1} key={index}>
                        <Skeleton variant="rectangular" width={210} height={118} />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default SkeletonGrid