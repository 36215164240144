
import enviroment from "./Service";
import axios from "axios";

/**
 * Clase que funciona como envoltorio para el cliente HTTP
 * 
 */
export default class ClientHttp {

    client = null
    token = null

    /**
     * En la contruccion de esta clase el cliente tomara
     * datos de la enviroments que se encuentren por defecto
     * al correo el bundle
     */
    constructor () {
        this.client = axios.create({
            baseURL: enviroment.RUTA_API + enviroment.VERSION,
            proxy: false
        })

        this.interceptorsRequest()
    }

    /**
     * Retorna el objeto http nativo
     * 
     * @returns 
     */
    getClient () {
        return this.client
    }

    get(url, config) {
        return this.client.get(url, config)
    }

    delete(url, config) {
        return this.client.delete(url, config)
    }

    post(url, data, config) {
        return this.client.post(url, data, config)
    }

    put(url, data, config) {
        return this.client.put(url, data, config)
    }

    patch(url, data, config) {
        return this.client.patch(url, data, config)
    }

    /**
     * Remplaza el token actual por el enviado por parametro
     * 
     * @param token Token a usar por el cliente
     */
    setToken (token) {
        this.token = token

        this.client.defaults.headers.common['Authorization'] =  'Bearer '+ this.token.getToken()?.access_token;
    }

    /**
     * Hacer que las peticiones se relicen sobre el dominio
     */
    useDomain() {
        this.client = axios.create({
            baseURL: enviroment.RUTA_API,
            proxy: false
        });

        this.interceptorsRequest()
    }
    
    /**
     * Hacer que las peticiones se relicen sobre el dominio
     */
    makeAPI(version=null) {
        version = version ? version : enviroment.VERSION
        

        this.client = axios.create({
            baseURL: enviroment.RUTA_API + version,
            proxy: false
        });

        this.interceptorsRequest()
    }


    interceptorsRequest = () => {
        this.client.interceptors.request.use(config => {
            //console.log(config)
            return config
        })
    }

}

