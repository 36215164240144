
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { convertArrayToObject } from "../../../libs/utils";
import { PROFESSORS_HOME_EXPIRE_TIME, USERSUI_EXPIRE_TIME } from "../../../service/const";
import Connection from "../../../service/Connection"
import Feedback from "../../../service/Feedback"
import { addOneItem, getEmptyItem } from "./ItemsSlice";
import { setAllInformation, setManySubjects } from "./entitiesSlice";

let emptyState = {
    expireIn: null,
    ferchingAt: null,
    status: "idle",
    operation: "idle",
    didInvalidate: true,
    feedback: {
        title: null,
        message: null,
        payload: null,
    }
}

export const userSlice = createSlice({
    name: 'professorModule',
    initialState: emptyState,
    reducers: {
        /**
         * Invalidar datos de la UI
         */
        invalidate: (state, action) => {
            state.didInvalidate = true
        },
        setFeedback: (state, action) => {
            state.feedback = { ...state.feedback, message: action.payload }
        },
        deleteFeedback: (state, action) => {
            state.feedback = { ...state.feedback, message: null }
        },
        setGroups: (state, action) => {
            state.groups = action.payload;
        },
        setSubjects: (state, action) => {
            state.subjects = action.payload;
        },
        setCatalogSubject: (state, action) => {
            state.catalogSubjects = action.payload;
        },
        setStudents: (state, action) => {
            state.entities.students = action.payload;
        },
        setCalifications: (state, action) => {
            state.entities.califications = action.payload;
        },
        setAssists: (state, action) => {
            state.entities.assits = action.payload;
        },
        loading: (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
            }
        },
        hideLoading: (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle'
            }
        },
        loadingData: (state, action) => {
            if (state.dataStatus === 'idle') {
                state.dataStatus = 'pending'
            }
        },
        loadedData: (state, action) => {
            if (state.dataStatus === 'pending') {
                state.dataStatus = 'idle'
            }
        }
    },
    extraReducers: (builder) => {
        /**
         * Limpiar la store
         */
        builder.addCase('app/clear', (state, action) => {
            return emptyState
        })

        ////////////////// RECUPERAR GRUPOS //////////////////

        builder.addCase(fetchInitialInformation.fulfilled, (state, action) => {
            state.expireIn = (new Date()).setMinutes(((new Date()).getMinutes() + PROFESSORS_HOME_EXPIRE_TIME))
            state.ferchingAt = Date.now()
            state.didInvalidate = false
            state.status = 'fulfilled'
        })
        builder.addCase(fetchInitialInformation.pending, (state, action) => {
            state.status = 'pending'
        })
        builder.addCase(fetchInitialInformation.rejected, (state, action) => {
            state.status = 'rejected'
            //state.feedback = action.payload.feedback
        })

    }
});


export const { loadedData, loading, hideLoading,
    setAssists, setSubjects, setGroups, setCatalogSubject,
    setCalifications, setStudents,
    setFeedback, deleteFeedback, invalidate
} = userSlice.actions;

export default userSlice.reducer;


///////////// SELECTORES  ////////////

/**
 * Selector para recuperar el fetch
 * 
 * @param {*} state
 *  
 * @returns 
 */
export const selectFetch = (state) => state.professorModule.fetch;

/**
 * Selector para recuperar el estado del fetching
 * 
 * @param {*} state
 *  
 * @returns 
 */
export const selectFetchingStatus = (state) => selectFetch(state).status;


/**
 * Recuperar la informacion inicial de un grupo
 */
export const fetchInitialInformation = createAsyncThunk(
    'professorsModule/data',
    async (data, thunkAPI) => {
        const { professorId, schoolId } = data

        let FeedbackService = new Feedback()
        try {
            return Promise.all([
                Connection.getGroupsByProfessor(professorId).then(response => response.data.data),
                Connection.getSubjectsByProfessor(professorId).then(response => response.data.data),
                Connection.getSubjectCatalogBySchool(schoolId).then(response => response.data.data),
                Connection.schoolInfo(schoolId).then(response => response.data.data),
                Connection.getPartials(schoolId).then(response => response.data.data),
                Connection.getReportCatalogBySchool(schoolId).then(response => response.data.data),
                Connection.getAssesorsBySchool(schoolId).then(response => response.data.data)
            ]).then(responses => {
                let groups = responses[0]
                let subjects = responses[1]
                let catalogSubjects = responses[2]
                let school = responses[3]
                let partials = responses[4]
                let catalogReports = responses[5]
                let assessors = responses[6]

                const initialDate = {
                    groups: convertArrayToObject(groups, "group_id"),
                    catalogSubjects: convertArrayToObject(catalogSubjects, "catalog_subject_id"),
                    partials: convertArrayToObject(partials, "partial_id"),
                    catalogReports: convertArrayToObject(catalogReports, "catalog_report_id"),
                    assessors: convertArrayToObject(assessors, "assessor_id"),
                }


                thunkAPI.dispatch(setManySubjects(subjects))
                thunkAPI.dispatch(setAllInformation(initialDate))

                ///////////// CREAR LOS ELEMENTOS DE GRUPO ////////////////

                for (const subject of subjects) {
                    thunkAPI.dispatch(addOneItem(getEmptyItem(subject.subject_id)))
                }
            })
        } catch (err) {
            return thunkAPI.rejectWithValue({
                feedback: FeedbackService.getMessage(err)
            })
        }
    },
    {
        condition: (arg, { getState, extra }) => {
            let { didInvalidate, expireIn } = selectFetch(getState())

            const valid = expireIn > Date.now()

            if (!didInvalidate && valid) {
                return false
            }

        }
    }
)