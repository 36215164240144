import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, Snackbar, Button, Divider, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../../hooks';
import { selectSettingBySchoolId } from '../../../store/slices/entities/settings';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import UserForm from '../../../components/UserForm';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import Connection from "../../../service/Connection"
import UsersSearch from '../../../components/Search/UsersSearch';
import { addOneRelationship } from '../../../store/slices/entities/relationships';
import { addOneUser } from '../../../store/slices/entities/users';
import { relationShipsNames } from '../../../service/const';
import { selectStudentById, upsertOneStudent } from '../../../store/slices/entities/students';
import Feedback from '../../../service/Feedback';

/**
 * Modal para registrar y asociar un parentesco a un alumno
 * 
 * @param {*} param0 
 * 
 * @returns 
 */
const AddParentModal = ({ openModal, setOpenModal, studentId = null }) => {

    const dispatch = useDispatch();

    const Auth = useAuth();
    const schoolId = Auth.getUser().school_id

    //////////// SHARES STATE /////////////

    const config = useSelector(selectSettingBySchoolId(schoolId))

    const student = useSelector(selectStudentById(studentId))

    let titleBarBackground = config.find(res => res.key === 'theme-color-title-bar');
    let fontColor = config.find(res => res.key === 'theme-color-font');

    let FeedbackService = new Feedback()

    ///////////////// LOCAL STATE ////////////////////

    const [userData, setUserData] = useState({
        name: '',
        last_name: '',
        second_last_name: '',
        password: '',
        password_confirmation: '',
        enrollment: '',
        email: '',
        cellphone: '',
        street: '',
        colony: '',
        outside_number: '',
        inside_number: '',
        zipcode: '',
        country_code: '12',
        group_id: '',
    });

    const [parentFound, setParentFound] = useState(null)
    const [validForm, setValidForm] = useState(false);
    const [viewType, setViewType] = useState(1);
    const [relationshipType, setRelationshipType] = useState('')
    const [loading, setLoading] = useState(false)

    ///////////// ACTIONS ///////////////////////

    /**
     * Funcion para reiniciar los malores del modal
     */
    const resetUserData = () => {
        setUserData({
            name: '',
            last_name: '',
            second_last_name: '',
            password: '',
            password_confirmation: '',
            enrollment: '',
            email: '',
            cellphone: '',
            street: '',
            colony: '',
            outside_number: '',
            inside_number: '',
            zipcode: '',
            country_code: '12',
            group_id: '',
        })
    }

    /**
     * Guardar datos del usuario
     */
    const enviarDatos = () => {
        setLoading(true)
        createAndLinkParent().then(({ relationship, parent }) => {
            dispatch(addOneRelationship(relationship))
            dispatch(addOneUser(parent))

            let studentUpdate = { ...student, relationships: student.relationships + 1 }

            dispatch(upsertOneStudent(studentUpdate))

            setTimeout(() => {
                setOpenModal(false)
            }, 3000)
            showFeedback("Parentesco agregado")

        }).catch(err => {
            const feedback = FeedbackService.getMessage(err)

            showFeedback(feedback.title)
        }).then(() => {
            setLoading(false)
        })
    }

    /**
     * Crear y linkiar el padre con el hijo
     */
    const createAndLinkParent = async () => {
        let parent = null

        if (viewType == 1) {
            parent = parentFound
        } else {
            parent = await Connection.storeParentSchool(schoolId, userData).then(i => i.data.data)
        }

        const userId = Auth.getUserID(parent)

        let newRelationship = await Connection.storeRelationship(studentId, userId, {
            relationship: relationshipType
        }).then(i => i.data.data)

        parent.user_id = userId

        return {
            relationship: newRelationship,
            parent
        }
    }

    /**
     * Efecto lanzado cuando se abre el modal
     */
    useEffect(() => {
        if (!openModal) {
            resetUserData()
            setViewType(1)
            setRelationshipType('')
            setParentFound(null)
        }
    }, [openModal])

    /**
     * Si el formulario a cambiado
     */
    const onChangeUserForm = ({ value, name, valid }) => {
        setUserData({ ...userData, [name]: value });
        setValidForm(valid)
    }

    /* No cerrar click afuera del modal */
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpenModal(false)    
    }

    ////////////////////////// VALIDACIONES //////////////////////////////////

    /**
     * Validacion global para el formulario
     * 
     * @returns 
     */
    const formInvalid = () => {
        if (viewType == 1) {
            return relationshipType == '' || !parentFound
        }

        return (
            !validForm || relationshipType == ''
        )
    }

    /**
     * Cambiar el tipo de vista
     */
    const onChangeViewType = () => {
        setViewType(viewType == 1 ? 2 : 1)
        resetUserData()
        setParentFound(null)
    }

    /**
     * El padre se ha seleccionado
     */
    const onSelectParent = (data) => {
        setParentFound(data)
    }

    /**
     * Cambio el tipo de relacion 
     * 
     * @param {*} data 
     */
    const onChangeRelationshiptype = (event) => {
        setRelationshipType(event.target.value)
    }

    ///////////////////// FEEDBACK ///////////////////////////////////////////

    const showFeedback = (message) => {
        setFeedbackMessage(message)
        setOpenFeedback(true);
    }

    const closeFeedback = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFeedback(false);
    };

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");

    ////////////////////// VIEW //////////////////////////

    /**
     * Mostra boton para cambiar entre formas de registro
     * @returns 
     */
    const ViewTypeButton = (props) => {

        let icon = (viewType == 1) ? (<CreateIcon />) : (<SearchIcon />)
        let text = (viewType == 1) ? ("Registrar nuevo") : ("Buscar existente")

        return (
            <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onChangeViewType}
                startIcon={icon}
                disabled={loading}
            >
                {text}
            </Button>
        )
    }


    return (

        <Dialog
            open={openModal}
            fullWidth={true}
            maxWidth={viewType == 1 ? "sm" : "md"}
            disableEscapeKeyDown={loading}
            onClose={handleClose}
        >
            <DialogTitle
                style={{ backgroundColor: titleBarBackground.value, color: fontColor.value }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1, color: '#fff' }}>
                        Agregar parentesco <br />
                        <span style={{ fontSize: '15px', color: '#fff' }}>
                            Alumno: {student?.name} {student?.last_name}
                        </span>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="select-relationship-label">Tipo de relación</InputLabel>
                    <Select
                        labelId="select-relationship-label"
                        id="select-relationship"
                        value={relationshipType}
                        label="Tipo de relación"
                        onChange={onChangeRelationshiptype}
                    >
                        <MenuItem value={-1} selected disabled>
                            Selecciona una opción
                        </MenuItem>
                        {
                            relationShipsNames.map(i => {
                                return (<MenuItem key={i.key} value={i.key}>{i.title}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>

                <Divider />

                {
                    viewType == 1 && (<UsersSearch onChange={onSelectParent} />)
                }
                {
                    viewType == 2 && (
                        <UserForm
                            value={userData}
                            tipoUsuario={'PARENT'}
                            onChange={onChangeUserForm}
                        />
                    )
                }
            </DialogContent>

            <DialogActions>
                <ViewTypeButton />

                <LoadingButton
                    size="small"
                    color="primary"
                    onClick={enviarDatos}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={formInvalid()}
                >
                    Agregar
                </LoadingButton>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => { setOpenModal(false) }}
                    disabled={loading}
                >
                    Cerrar
                </Button>
            </DialogActions>
            <Snackbar
                open={openFeedback}
                autoHideDuration={2500}
                onClose={closeFeedback}
                message={feedbackMessage}
            />
        </Dialog>

    )
}

export default AddParentModal;
